import { LightningIcon } from '@noah-labs/core-web-ui/src/icons';
import type { TpIcon } from '@noah-labs/core-web-ui/src/types';
import { Network, SortDirection, TransactionStatus } from '@noah-labs/noah-schema';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { cryptoCurrencyFromCode } from '../../../utils';
import { useUserInit } from '../../user/data/useUserInit';
import type { RecentTransactionsQuery } from '../data';
import { useRecentTransactionsQuery, useWalletParams } from '../data';

export type TpRecentAddresses = {
  Icon: TpIcon | null;
  NetworkIcon: TpIcon | null;
  address: string;
  network: Network | undefined;
};

function isValidAndUnique(
  ad: TpRecentAddresses | undefined,
  index: number,
  self: (TpRecentAddresses | undefined)[]
): boolean {
  return Boolean(ad) && self.findIndex((i) => compareStrings(i?.address, ad?.address)) === index;
}

function isLightningNetwork(network: Network): boolean {
  return [Network.Lightning, Network.LightningTest].includes(network);
}

function selectRecentAddresses(
  data: RecentTransactionsQuery,
  limit: number
): TpRecentAddresses[] | undefined {
  if (!data.transactions.items) {
    return undefined;
  }

  return data.transactions.items
    .map((t) =>
      t.Destination
        ? {
            address: t.Destination.Address,
            Icon: cryptoCurrencyFromCode(t.Currency).Icon,
            network: t.Network,
            NetworkIcon: isLightningNetwork(t.Network) ? LightningIcon : null,
          }
        : undefined
    )
    .filter(isValidAndUnique)
    .slice(0, limit) as TpRecentAddresses[];
}

const RECENT_ADDRESS_LIMIT = 5;

export function useRecentAddresses(): TpRecentAddresses[] {
  const { CurrencyCode } = useWalletParams();
  const { data: userInitData } = useUserInit();
  const { data: addresses } = useRecentTransactionsQuery(
    {
      filter: {
        Currency: {
          eq: CurrencyCode,
        },
        Destination: {
          attributeExists: true,
        },
        SourceAccountID: {
          contains: userInitData?.userProfile.UserID,
        },
        Status: {
          eq: TransactionStatus.Settled,
        },
      },
      limit: RECENT_ADDRESS_LIMIT * 2, // fetch more addresses than needed to account for duplicates
      sortDirection: SortDirection.DESC,
    },
    {
      enabled: !!userInitData?.userProfile.UserID,
      select: (data) => selectRecentAddresses(data, RECENT_ADDRESS_LIMIT),
    }
  );

  return addresses || [];
}
