import { LibraryAddCheck } from '@mui/icons-material';
import { CopyIcon } from '../icons';

type PpDynamicCopyIcon = {
  copied: boolean;
};

export function DynamicCopyIcon({ copied }: PpDynamicCopyIcon): React.ReactElement {
  return copied ? <LibraryAddCheck sx={{ color: 'success.main' }} /> : <CopyIcon />;
}
