import React from 'react';
import { List } from '@mui/material';
import type { PpWOC } from '../types';
import { SkeletonAccountCardList } from './SkeletonAccountCardList';

export type PpAccountCardList = PpWOC & {
  isFetched?: boolean;
};

export function AccountCardList({
  children,
  isFetched = false,
}: PpAccountCardList): React.ReactElement {
  if (!isFetched) {
    return <SkeletonAccountCardList />;
  }

  return (
    <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
      {children}
    </List>
  );
}
