export const sardineErrorMessages = new Map<string, string>([
  [
    'unrecognizable_document',
    'Unfortunately your ID document could not be recognised, please try again and ensure you upload a picture of the required document.',
  ],
  [
    'requires_recapture',
    'Sorry, we weren’t able to process your ID document. Please retake the photo, ensuring it is clear and free from shadows.',
  ],
  [
    'document_bad_size_or_type',
    'The file size or the format of the document you provided for your ID is not supported, please try again.',
  ],
]);

export const processingError =
  'There has been an error while processing your ID. Please try again and ensure your ID is legible.';
