import { useCallback, useEffect, useRef } from 'react';
import { fetchOnboardingStatus, onboardingComplete } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { CircularSpinner } from '@noah-labs/core-web-ui/src/utility/CircularSpinner';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { KycDocumentVerificationStatus } from '@noah-labs/noah-schema';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useUserInit } from '../../user/data/useUserInit';
import {
  useSardineDocumentResultsMutation,
  useUserKycResetMutation,
  useUserKycRetryCountQuery,
} from '../data/kyc.generated';
import { processingError, sardineErrorMessages } from '../data/sardineErrors';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { CompleteFailedScene } from '../scenes/CompleteFailed';
import { TpKycSteps } from '../types';

const kycMaxRetries = webConfigBrowser.kyc.maxRetries;

export function EndDocKyc({ state, updateState }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();
  const { invalidate: userInitInvalidate } = useUserInit();
  const { handleSubmit: kycInputUpdate } = useSubmitKycInput();
  const {
    data: documentResultsData,
    error: documentResultsError,
    mutateAsync: setDocumentKycResult,
  } = useSardineDocumentResultsMutation();
  const { isLoading: isResetting, mutateAsync: userKycReset } = useUserKycResetMutation();
  const { data: kycRetryCountData } = useUserKycRetryCountQuery();
  const kycInputStateRef = useRef(state.userKycInput);

  const retryCount = kycRetryCountData?.userProfile.KycRetryCount;
  const retryCountData = !isUndefinedOrNull(retryCount);
  const allowTryAgain = retryCountData && retryCount < kycMaxRetries;

  const { finishAction, incodeApiBaseUrl, onboardingStatus, sardineToken } = state;

  useEffect(() => {
    async function getStatus(): Promise<void> {
      try {
        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        const { data } = await fetchOnboardingStatus({
          baseURL: incodeApiBaseUrl,
          token: sardineToken,
        });
        updateState({
          onboardingStatus: data.onboardingStatus,
        });
      } catch (err) {
        // TODO: add error handling
      }
    }

    void getStatus();
  }, [incodeApiBaseUrl, sardineToken, updateState]);

  useEffect(() => {
    async function getFinish(): Promise<void> {
      try {
        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc not initialised');
        }

        const { data } = await onboardingComplete({
          baseURL: incodeApiBaseUrl,
          token: sardineToken,
        });
        updateState({
          finishAction: data.action,
        });
      } catch (err) {
        // TODO: add error handling
      }
    }

    void getFinish();
  }, [incodeApiBaseUrl, sardineToken, updateState]);

  useEffect(() => {
    if (!onboardingStatus || !finishAction) {
      return;
    }

    async function setDocumentKycResultAndRedirect(): Promise<void> {
      try {
        const { sardineDocumentResults } = await setDocumentKycResult({});

        if (
          !sardineDocumentResults.Status ||
          sardineDocumentResults.Status === KycDocumentVerificationStatus.Failed
        ) {
          throw new Error('Sardine verification process failed');
        }

        await userInitInvalidate();

        await kycInputUpdate({
          errorKey: 'updateEndDocKycError',
          errorMessage: 'There was an error saving your details',
          shouldInvalidate: false,
          to: routes.confirm.path,
          values: {
            ...kycInputStateRef.current,
            LastStep: TpKycSteps.EndDocKyc,
          },
        });
      } catch (err) {
        // already handled in 'error'
      }
    }

    void setDocumentKycResultAndRedirect();
  }, [finishAction, onboardingStatus, kycInputUpdate, setDocumentKycResult, userInitInvalidate]);

  const handleTryAgain = useCallback(async () => {
    try {
      await userKycReset({});

      history.push(routes.base.path);
    } catch (err) {
      pushAlert({
        key: 'kyc-complete-try-again-error',
        message:
          'There was an error while trying to reset your verification status. Please try again later.',
        severity: 'error',
      });
    }
  }, [history, pushAlert, userKycReset]);

  const { ErrorCodes, Status } = documentResultsData?.sardineDocumentResults || {};

  if (Status === KycDocumentVerificationStatus.Failed) {
    let reason = processingError;

    if (Array.isArray(ErrorCodes)) {
      reason = sardineErrorMessages.get(ErrorCodes[0]) || reason;
    }

    return (
      <CompleteFailedScene
        allowTryAgain={allowTryAgain}
        isResetting={isResetting}
        reason={reason}
        onTryAgain={handleTryAgain}
      />
    );
  }

  if (documentResultsError) {
    return <ErrorPage message="Something went wrong" />;
  }

  return <LoadingPage Icon={<CircularSpinner />} message="Processing document data..." />;
}
