import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/noah-schema';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { useCurrencyAmounts } from './useCurrencyAmounts';
import type { PpCurrencyAmounts, TpCurrencyAmounts } from './useCurrencyAmounts';

export type TpCurrencyAmountsWithFee = {
  fee: TpCurrencyAmounts;
  net: TpCurrencyAmounts;
  total: TpCurrencyAmounts;
};

export type PpCurrencyAmountsWithFee = PpCurrencyAmounts & {
  cryptoUnit?: CurrencyUnit;
  feeCryptoAmount: TpAmount;
  feeFiatAmount: string;
  netCryptoAmount: string;
  netFiatAmount: string;
};

export function useCurrencyAmountsWithFee({
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit = CurrencyUnit.Default,
  feeCryptoAmount,
  feeFiatAmount,
  fiatAmount,
  fiatCurrency,
  netCryptoAmount,
  netFiatAmount,
  primaryCurrency,
  signDisplay,
  strikeThrough = false,
}: PpCurrencyAmountsWithFee): TpCurrencyAmountsWithFee {
  const total = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount,
    fiatCurrency,
    primaryCurrency,
    signDisplay,
    strikeThrough,
  });
  const fee = useCurrencyAmounts({
    cryptoAmount: feeCryptoAmount,
    cryptoCurrency,
    fiatAmount: feeFiatAmount,
    fiatCurrency,
    primaryCurrency: CurrencyDisplayType.Fiat,
    strikeThrough,
  });
  const net = useCurrencyAmounts({
    cryptoAmount: netCryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount: netFiatAmount,
    fiatCurrency,
    primaryCurrency,
    signDisplay,
    strikeThrough,
  });

  return { fee, net, total };
}
