import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { ChevronDownButton } from '@noah-labs/core-web-ui/src/buttons/ChevronDownButton';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppHeaderSubtitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderSubtitle';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/noah-schema';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useBackHijack } from '../../../../hooks';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { PaymentMethodsPicker } from '../../components';
import { CryptoPrice } from '../../components/atoms/CryptoPrice';
import { PaymentCurrencyDialog } from '../../components/dialogs/PaymentCurrencyDialog';
import { useCalculateFiatFromCrypto, useWalletParams } from '../../data';
import { routes } from '../../routes';
import type { TpAmountForm } from '../../scenes';
import { EnterAmountScene } from '../../scenes';
import type { TpPaymentMethodChange } from '../../types';
import { getCheckoutBuyAmountSchema } from '../../utils/validation';
import type { StBuyRouter } from './BuyRouter';

type PpEnterAmount = TpStateMachine<StBuyRouter>;

export function EnterAmount({ state, updateState }: PpEnterAmount): React.ReactElement {
  const { CurrencyCode, params } = useWalletParams();
  const history = useHistory();
  const { backTo, goBack } = useBackHijack(generatePath(routes.base.path, params));
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { fiatCurrency, fiatPaymentCurrency } = useUserFiatCurrency();
  const paymentCurrencyDialog = useRef<TpDialogToggle>(null);

  const amountPlaceholder = webConfigBrowser.checkout.minimumFiatAmount;

  const { data: cryptoData } = useCalculateFiatFromCrypto({
    cryptoAmount: '1',
    cryptoCurrency,
    fiatCurrency: fiatPaymentCurrency,
    priceProvider: 'buy',
  });

  const onPaymentMethodChange = useCallback(
    ({ card, type }: TpPaymentMethodChange): void => {
      updateState({
        paymentMethod: type,
        selectedPaymentCard: card,
      });
    },
    [updateState]
  );

  const onSubmit = useCallback(
    ({ cryptoAmount, fiatAmount }: TpAmountForm) => {
      updateState({
        cryptoAmount: truncateAmount({
          amount: cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: fiatAmount,
          decimalPlaces: fiatPaymentCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });

      history.push(generatePath(routes.buy.confirm.path, params));
    },
    [history, params, cryptoCurrency, fiatPaymentCurrency.decimals, updateState]
  );

  return (
    <EnterAmountScene
      amountRequired
      disableSwitch
      isCryptoAmountNet
      amountPlaceholder={amountPlaceholder}
      backButton={goBack}
      backTo={backTo}
      ContentSlot={
        <React.Fragment>
          <PaymentMethodsPicker
            paymentMethod={state.paymentMethod}
            selectedPaymentCard={state.selectedPaymentCard}
            onChange={onPaymentMethodChange}
            onSubmitCardDetailsRedirect={generatePath(routes.buy.enterAmount.path, params)}
          />
          <PaymentCurrencyDialog
            ref={paymentCurrencyDialog}
            fiatCurrency={fiatCurrency.code}
            fiatPaymentCurrency={fiatPaymentCurrency.code}
          />
        </React.Fragment>
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={CurrencyUnit.Default}
      ctaButtonDisabled={!state.paymentMethod}
      ctaButtonLabel="Preview buy"
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatPaymentCurrency}
      htmlHeadTitle={routes.buy.enterAmount.title}
      PageTitleSlot={
        <Stack>
          <AppHeaderTitle>Buy bitcoin</AppHeaderTitle>
          <AppHeaderSubtitle>
            <CryptoPrice
              cryptoCurrencyCode={cryptoCurrency.code}
              fiatCurrency={fiatPaymentCurrency}
              price={cryptoData?.fiatAmount}
            />
          </AppHeaderSubtitle>
        </Stack>
      }
      priceProvider="buy"
      primaryCurrency={CurrencyDisplayType.Fiat}
      SwitchCurrencySlot={
        <ChevronDownButton onClick={(): void => paymentCurrencyDialog.current?.toggle()} />
      }
      yupSchema={getCheckoutBuyAmountSchema(fiatPaymentCurrency)}
      onBlurValues={updateState}
      onSubmit={onSubmit}
    />
  );
}
