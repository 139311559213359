import { useEffect } from 'react';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import { RadioGroup } from '@noah-labs/core-web-ui/src/forms/RadioGroup';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { KycAppHeader } from '../../components/KycAppHeader';
import { PROGRESS } from '../../data/progress';
import { sourceOfFundsItems } from './data/radioForm';
import type { PpRadioForm } from './types';

const defaultValues = {
  SourceOfFunds: '',
};

type TpSourceOfFundsFormSchema = typeof defaultValues;

export type PpSourceOfFunds = PpRadioForm & {
  isLoading: boolean;
  sourceofFundsState?: string;
};

export function SourceOfFundsScene({
  backTo,
  isLoading,
  onSaveAndExit,
  onSubmit,
  sourceofFundsState,
}: PpSourceOfFunds): React.ReactElement {
  const methods = useForm<TpSourceOfFundsFormSchema>({
    defaultValues,
    mode: 'all',
  });
  const buttonDisabled = !methods.formState.isDirty;
  const isFormSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    if (!sourceofFundsState) {
      return;
    }
    methods.reset({ SourceOfFunds: sourceofFundsState }, { keepDefaultValues: true });
  }, [methods, sourceofFundsState]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled || isLoading}
            form="sourceOfFundsFormId"
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={(): void => {
              onSaveAndExit(methods.getValues());
            }}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.answers} />}
      dataQa="sourceOfFunds"
    >
      <Helmet>
        <title>Source of funds</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>What’s your main source of income?</SceneTitleMedium>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id="sourceOfFundsFormId" onSubmit={methods.handleSubmit(onSubmit)}>
            <RadioGroup label="Source of funds" name="SourceOfFunds">
              {sourceOfFundsItems.map((item) => (
                <RadioButton key={item.value} hideValue label={item.label} value={item.value} />
              ))}
            </RadioGroup>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
