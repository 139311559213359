import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { QRCodeWithImage } from '@noah-labs/core-web-ui/src/qr/QRCodeWithImage';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { assetPaths } from '@noah-labs/fe-assets';
import { Feature, CurrencyCode as TpCurrencyCode } from '@noah-labs/noah-schema';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { useUserInit } from '../../../user/data/useUserInit';
import { useIsFeatureEnabled } from '../../../user/hooks/useIsFeatureEnabled';
import { findDepositAddress, useAccountCreateMutation, useWalletParams } from '../../data';
import { useWalletError } from '../../hooks';
import { routes } from '../../routes';
import { ReceiveScene } from '../../scenes/Receive';
import type { StReceiveRouter } from './types';

export function Receive({ state }: TpStateMachine<StReceiveRouter>): ReactElement {
  const isOnChainEnabled = useIsFeatureEnabled(Feature.Deposit);
  const isLightningEnabled = useIsFeatureEnabled(Feature.LnInvoice);

  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    mutate: accountCreate,
  } = useAccountCreateMutation();

  const { AccountType, CurrencyCode, params } = useWalletParams();

  const { lnAddress } = useUserInit();

  const address = findDepositAddress(accountCreateData?.accountCreate);

  const network = state.network || cryptoCurrencyFromCode(CurrencyCode).networks?.[0];

  useEffect(() => {
    // If accountCreate is running or there is already an address, don't do anything
    if (!accountCreateIdle || address || !network) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({
      Input: {
        AccountType,
        CurrencyCode,
        DepositAddressRequest: {
          Create: true,
          Network: network,
        },
      },
    });
  }, [AccountType, CurrencyCode, accountCreate, accountCreateIdle, address, network]);

  const { ApiErrorScene } = useWalletError(accountCreateErr);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const sceneTitle = routes.deposit.base.title(cryptoCurrency.label);

  const showLightningSection =
    isLightningEnabled &&
    (CurrencyCode === TpCurrencyCode.BTC || CurrencyCode === TpCurrencyCode.BTC_TEST);

  return (
    <ReceiveScene
      address={address}
      cryptoCurrency={cryptoCurrencyFromCode(CurrencyCode)}
      lightningInvoiceHref={generatePath(routes.receive.lightning.enterAmount.path, params)}
      lnAddress={lnAddress}
      network={network}
      pageTitle={sceneTitle}
      qrCodeSlot={
        <QRCodeWithImage
          data={address}
          height={600}
          imageUrl={`${assetPaths.images}/logo-icon-black.svg`}
          width={600}
        />
      }
      showLightningSection={showLightningSection}
      showOnChainSection={isOnChainEnabled}
    />
  );
}
