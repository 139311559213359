import { useCallback } from 'react';
import type { TpRoute } from '@noah-labs/core-web-ui/src/types';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { CurrencyDisplayType } from '@noah-labs/noah-schema';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { getDefaults } from '../../../utils';
import { PreferredCryptoCurrencyForm } from '../../wallet/components/forms/PreferredCryptoCurrencyForm';
import { useOptimisticUserInitOptions } from '../data/useOptimisticUserInitOptions';
import { useUpdateUserPrimaryCurrencySettingMutation } from '../data/user.generated';
import { useUserInit } from '../data/useUserInit';
import { useUserError } from '../hooks/useUserError';
import { UserSettingOptionsScene } from '../scenes';

const { cryptoCurrency } = getDefaults();

type PpPrimaryCurrency = {
  route: TpRoute;
};

export function PrimaryCurrencySetting({ route }: PpPrimaryCurrency): React.ReactElement {
  const { data, error, isLoading } = useUserInit();
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } = useUpdateUserPrimaryCurrencySettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(error || mutateError);
  const { fiatCurrency } = useUserFiatCurrency();

  const handleChange = useCallback(
    (value: CurrencyDisplayType) => {
      mutate({ Input: { PrimaryCurrency: value } });
    },
    [mutate]
  );

  const items = [
    {
      label: cryptoCurrency.label,
      value: CurrencyDisplayType.Crypto,
    },
    {
      label: fiatCurrency.label,
      value: CurrencyDisplayType.Fiat,
    },
  ];

  const value = data?.userProfile.PrimaryCurrency || CurrencyDisplayType.Fiat;

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <UserSettingOptionsScene
      hideValues
      CurrencyForm={PreferredCryptoCurrencyForm}
      dataQa={route.id}
      handleChange={handleChange}
      items={items}
      pageTitle={route.title as string}
      value={value}
    />
  );
}
