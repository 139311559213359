import { useCallback, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { DialogSelectField } from '@noah-labs/core-web-ui/src/forms/DialogSelectField';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { CountryCode } from '@noah-labs/noah-schema';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UserCountrySelectField } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { CountryNotAllowedDialog } from '../components/dialogs/CountryNotAllowedDialog';
import { StateNotAllowedDialog } from '../components/dialogs/StateNotAllowedDialog';
import { KycAppHeader } from '../components/KycAppHeader';
import { PROGRESS } from '../data/progress';
import { usStates } from '../data/usStates';

export type PpLocation = {
  backTo: string;
  isLoading: boolean;
  locationFormState: TpLocationFormValues | undefined;
  onSaveAndExit: SubmitHandler<TpLocationFormValues>;
  onSubmit: SubmitHandler<TpLocationFormValues>;
};

const locationFormId = 'locationFormId';

type TpCountryInput = CountryCode | string;

export type TpLocationFormValues = {
  Country: TpCountryInput;
  CountryName: string;
  State?: string;
  StateName?: string;
};

const locationFormSchema = yup.object({
  Country: yup.string().required(),
  CountryName: yup.string(),
  State: yup.string().when('Country', {
    is: 'US',
    then: (schema) => schema.required(),
  }),
  // don't add validation here b/c it leads to an odd ux with the way the select field works
  StateName: yup.string(),
});

export function LocationScene({
  backTo,
  isLoading,
  locationFormState,
  onSaveAndExit,
  onSubmit,
}: PpLocation): React.ReactElement {
  const notAllowedCountryRef = useRef<TpDialogToggle>(null);
  const notAllowedStateRef = useRef<TpDialogToggle>(null);

  const defaultValues = useMemo(() => locationFormState, [locationFormState]);

  const methods = useForm<TpLocationFormValues>({
    defaultValues,
    mode: 'onBlur',
    // @ts-expect-error - complex to solve, doesn't affect functionality
    resolver: yupResolver(locationFormSchema),
  });

  const country = methods.watch('Country');
  const isUsCountry = compareStrings(country, 'US');

  const buttonDisabled = !methods.formState.isValid;
  const isFormSubmitting = methods.formState.isSubmitting;

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      const isCountryNotAvailable =
        webConfigBrowser.regions.ProhibitedCountries[value?.value || ''];

      if (isCountryNotAvailable) {
        notAllowedCountryRef.current?.toggle();
        return;
      }

      methods.setValue('Country', value?.value || '', { shouldValidate: true });
      methods.setValue('CountryName', value?.label || '');

      const hasState = methods.getValues().State;

      if (hasState) {
        methods.setValue('State', '');
        methods.setValue('StateName', '');
      }
    },
    [methods]
  );

  const handleStateFormValues = useCallback(
    (value: TpSelectOption | null): void => {
      if (isUsCountry && value) {
        const isUsStateNotAvailable =
          webConfigBrowser.regions.RestrictedRegions.US?.Kyc?.[value.value];

        if (isUsStateNotAvailable) {
          notAllowedStateRef.current?.toggle();
          return;
        }
      }

      methods.setValue('State', value?.value || '', { shouldValidate: true });
      methods.setValue('StateName', value?.label || '');
    },
    [methods, isUsCountry]
  );

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled || isLoading}
            form={locationFormId}
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={(): void => {
              onSaveAndExit(methods.getValues());
            }}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.location} />}
      dataQa="location"
    >
      <Helmet>
        <title>Location</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Where do you live?</SceneTitleMedium>
        <SceneParagraph>
          This is the country where you reside and have a permanent address.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={locationFormId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <UserCountrySelectField
                // no country filtering here because all countries are potentially allowed for ids - and we show an alert if not supported
                handleChange={handleCountryFormValues}
                label="Country/region"
                name="CountryName"
              />
              {isUsCountry && (
                <DialogSelectField
                  isFetched
                  dataQa="state"
                  label="State"
                  name="StateName"
                  options={usStates}
                  onChange={handleStateFormValues}
                />
              )}
            </Stack>
          </form>
        </FormProvider>
        <CountryNotAllowedDialog ref={notAllowedCountryRef} />
        <StateNotAllowedDialog ref={notAllowedStateRef} />
      </SceneMain>
    </AppContainer>
  );
}
