import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { ChevronRightIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import type { TpBackAction } from '../../../hooks';
import { PaymentMethod } from '../../wallet/components';
import type { TpPaymentCard } from '../../wallet/types';
import { TpPaymentMethod } from '../../wallet/types';
import { routes } from '../routes';

export type PpPaymentMethodsScene = {
  pageTitle: string;
  paymentCards: TpPaymentCard[];
} & TpBackAction;

export function PaymentMethodsScene({
  backTo,
  goBack,
  pageTitle,
  paymentCards,
}: PpPaymentMethodsScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    chevron: css`
      color: ${theme.palette.text.light};
    `,
    content: css`
      min-height: ${theme.spacing(7)};
      overflow: hidden;
    `,
  };
  const buttonSx = {
    ':hover': {
      backgroundColor: 'inherit',
    },
    padding: theme.spacing(0, 2, 0),
  };
  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData helpButton backButton={goBack} backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="payment-methods"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain dense>
        {paymentCards.length > 0 &&
          paymentCards.map((paymentCard) => (
            <ElevatedCardListItem
              key={paymentCard.id}
              buttonSx={buttonSx}
              href={generatePath(routes.settings.editPaymentMethod.path, { id: paymentCard.id })}
            >
              <PaymentMethod
                method={TpPaymentMethod.Card}
                paymentCard={paymentCard}
                ToggleSlot={<ChevronRightIcon css={styles.chevron} />}
              />
            </ElevatedCardListItem>
          ))}

        <ElevatedCardListItem buttonSx={buttonSx} href={routes.settings.newPaymentMethod.path}>
          <StructuredContent
            css={styles.content}
            dataQa="add-payment-method"
            endText={{
              primary: <ChevronRightIcon css={styles.chevron} />,
            }}
            mainText={{
              primary: 'Add a payment method',
              primaryTypographyProps: {
                color: 'text.link',
              },
            }}
          />
        </ElevatedCardListItem>
      </SceneMain>
    </AppContainer>
  );
}
