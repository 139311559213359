import type { KycUploadedFileInput } from '@noah-labs/noah-schema';
import type { TpActivityFormValues } from './scenes/Questionnaire/Activity';

export type TpConfirmedPersonalData = {
  City: string;
  Country: string;
  CountryName: string;
  DateOfBirth: string;
  FirstName: string;
  LastName: string;
  Nationality: string;
  PostalCode: string;
  State: string;
  Street: string;
};

export type TpUserKycInput = {
  Activity?: TpActivityFormValues;
  AnnualDeposit?: string;
  ConfirmedPersonalData?: TpConfirmedPersonalData;
  Country?: string;
  CountryName?: string;
  EmploymentStatus?: string;
  KycSourcesOfFunds?: KycUploadedFileInput[];
  LastStep: string;
  PrivacyConsent?: boolean;
  SourceOfFunds?: string;
  State?: string | null;
  StateName?: string;
  TransactionFrequency?: string;
  UsTaxpayer?: boolean;
  VerificationConsent?: boolean;
  WorkIndustry?: string;
};

export type TpCaptureResponse = {
  error?: string;
  redirectTo?: string;
  success: boolean;
};

export enum IdTypeOption {
  DriversLicense = 'DriversLicense',
  IdentificationCard = 'IdentificationCard',
  None = '',
  Passport = 'Passport',
}

export enum TpKycSteps {
  Activity = 'Activity',
  AnnualDeposit = 'AnnualDeposit',
  ConfirmPersonalData = 'ConfirmPersonalData',
  EmploymentStatus = 'EmploymentStatus',
  EndDocKyc = 'EndDocKyc',
  IdSelect = 'IdSelect',
  Location = 'Location',
  PrivacyConsent = 'PrivacyConsent',
  ProofOfSourcesOfFunds = 'ProofOfSourcesOfFunds',
  SourceOfFunds = 'SourceOfFunds',
  TransactionFrequency = 'TransactionFrequency',
  WorkIndustry = 'WorkIndustry',
}

export enum TpKycLevels {
  Basic = 'Basic',
  Full = 'Full',
  NonVerified = 'NonVerified',
}
