import { AccountCard } from '@noah-labs/core-web-ui/src/cards/AccountCard';
import type { PpElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import type { TpAcc } from '../../data';
import { useCalculateFiatFromCrypto } from '../../data';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';

type PpAccountCardData = {
  href: string;
} & Pick<TpAcc[0], 'Balance' | 'CurrencyCode' | 'ID'> &
  Pick<PpElevatedCardListItem, 'elevation' | 'buttonSx' | 'sx'>;

export function AccountCardData({
  Balance,
  CurrencyCode,
  href,
  ID,
  ...rest
}: PpAccountCardData): React.ReactElement {
  const { fiatCurrency } = useUserFiatCurrency();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const cryptoBalance = Balance?.Available;
  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  const Amounts = useCurrencyAmounts({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    fiatAmount: priceData?.fiatAmount,
    fiatCurrency,
    roundDown: true,
  });

  return (
    <AccountCard
      cryptoCurrency={cryptoCurrency}
      defaultAccount={ID.includes('Default')}
      href={href}
      {...Amounts}
      {...rest}
    />
  );
}
