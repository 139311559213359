import React from 'react';
import { css } from '@emotion/react';
import { LibraryAddCheck, MoreHoriz } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useClickToCopy } from '@noah-labs/core-web-ui/src/hooks/useClickToCopy';
import { useShare } from '@noah-labs/core-web-ui/src/hooks/useShare';
import { CopyIcon } from '@noah-labs/core-web-ui/src/icons';
import type { TpShareProps } from '@noah-labs/core-web-ui/src/types';
import { TpAnalyticsEvent, useAnalytics } from '../../../analytics';
import type { TpSocialSharePlatform } from '../../types';
import { SocialShareButton } from './SocialShareButton';

type PpSocialShareButtons = {
  buttons: TpSocialSharePlatform[];
  referralUrl: string;
  shareData: TpShareProps;
};

export function SocialShareButtons({
  buttons,
  referralUrl,
  shareData,
}: PpSocialShareButtons): React.ReactElement | null {
  const theme = useTheme();

  const styles = {
    muiButton: css`
      min-height: auto;
      min-width: auto;
      padding: 0;
    `,
    socialIcon: css`
      color: ${theme.palette.primaryLight.main};
    `,
  };

  const iconStyles = [styles.socialIcon];
  const ellipsisIconStyles = css`
    color: ${theme.palette.grayscale.white};
    border-radius: ${theme.borderRadius.xs};
  `;

  const { track } = useAnalytics();

  const { handleSharing, isSupported } = useShare({
    ...shareData,
    onShare: () => track(TpAnalyticsEvent.SocialReferralCardClicked),
  });

  const { copied, copyHandler } = useClickToCopy({
    onCopy: () => track(TpAnalyticsEvent.ReferralLinkCopied),
    text: referralUrl,
  });
  const ClickToCopyIcon = copied ? (
    <LibraryAddCheck css={iconStyles} />
  ) : (
    <CopyIcon css={iconStyles} />
  );

  if (!shareData.text) {
    return null;
  }

  const renderButtons = buttons.map((button) => (
    <SocialShareButton
      key={button}
      button={button}
      iconStyles={iconStyles}
      referralUrl={referralUrl}
      shareText={shareData.text || referralUrl}
      track={track}
    />
  ));

  return (
    <React.Fragment>
      {renderButtons}
      <Button aria-label="copy-button" css={styles.muiButton} variant="text" onClick={copyHandler}>
        {ClickToCopyIcon}
      </Button>
      {isSupported && (
        <Button
          aria-label="share-button"
          css={styles.muiButton}
          variant="text"
          onClick={handleSharing}
        >
          <MoreHoriz css={ellipsisIconStyles} />
        </Button>
      )}
    </React.Fragment>
  );
}
