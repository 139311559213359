import { useCallback } from 'react';
import { uploadFiles } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useQueryClient } from 'react-query';
import { useUserInit } from '../../user/data/useUserInit';
import {
  useKycHighRiskInfoSubmitMutation,
  useUserKycSourcesOfFundsUploadMutation,
} from '../data/kyc.generated';
import { kycInputQueryKey, useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import type { TpProofOfSourcesOfFundsFormSchema } from '../scenes/Questionnaire/ProofOfSourcesOfFunds';
import { ProofOfSourcesOfFundsScene } from '../scenes/Questionnaire/ProofOfSourcesOfFunds';
import { TpKycSteps } from '../types';

export function ProofOfSourcesOfFunds({ state }: TpStateMachine<StKycRouter>): React.ReactElement {
  const { handleSubmit, isKycInputQueryFetched } = useSubmitKycInput();
  const { invalidate: userInitInvalidate } = useUserInit();

  const { mutateAsync: kycHighRiskInfoSubmitMutation } = useKycHighRiskInfoSubmitMutation();
  const { mutateAsync: userKycSourcesOfFundsUploadMutation } =
    useUserKycSourcesOfFundsUploadMutation();

  const pushAlert = usePushAlert();
  const queryClient = useQueryClient();

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      const fileNames = files.map((file) => file.name);

      const {
        userKycSourcesOfFundsUpload: { KycUploadedFiles },
      } = await userKycSourcesOfFundsUploadMutation({
        Input: {
          Files: fileNames,
        },
      });

      if (!KycUploadedFiles.length) {
        throw new Error('Upload url failed to generate');
      }

      const hasUploaded = await uploadFiles(KycUploadedFiles, files);

      if (!hasUploaded) {
        throw new Error('Error uploading file');
      }

      return KycUploadedFiles;
    },
    [userKycSourcesOfFundsUploadMutation]
  );

  const onSubmit = useCallback(
    async (values: TpProofOfSourcesOfFundsFormSchema) => {
      if (!values.ProofOfSourcesOfFunds.length) {
        return;
      }

      try {
        if (!state.userKycInput?.WorkIndustry) {
          throw new Error('WorkIndustry data required');
        }

        const uploadedFiles = await handleUploadFiles(values.ProofOfSourcesOfFunds);

        await handleSubmit({
          errorKey: 'updateProofOfSourcesOfFundsError',
          errorMessage: 'There was an error saving your proof of source of funds',
          shouldInvalidate: false,
          values: {
            ...state.userKycInput,
            KycSourcesOfFunds: uploadedFiles,
            LastStep: TpKycSteps.ProofOfSourcesOfFunds,
          },
        });

        await kycHighRiskInfoSubmitMutation({
          Input: {
            KycSourcesOfFunds: uploadedFiles,
            WorkIndustry: state.userKycInput.WorkIndustry,
          },
        });

        await userInitInvalidate();
        await queryClient.invalidateQueries(kycInputQueryKey);

        // redirect is being controlled by the kyc router after query invalidations
      } catch (error) {
        // TODO: add error handling
        logger.error(error);
        pushAlert({
          autoHideDuration: 3000,
          key: 'uploadSourcesOfFundsError',
          message: 'There was an error saving your files',
          severity: 'error',
        });
      }
    },
    [
      handleUploadFiles,
      pushAlert,
      handleSubmit,
      kycHighRiskInfoSubmitMutation,
      userInitInvalidate,
      queryClient,
      state.userKycInput,
    ]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <ProofOfSourcesOfFundsScene
      backTo={routes.questionnaire.workIndustry.path}
      onSubmit={onSubmit}
    />
  );
}
