import { CurrencyCode as CryptoCurrencyCode, Network } from '@noah-labs/noah-schema';
import type { TpCryptoCurrency } from './types';

// TODO: replace with a DDB call
export const mockCryptoCurrenciesFromApi: Record<CryptoCurrencyCode, TpCryptoCurrency> = {
  [CryptoCurrencyCode.BTC]: {
    code: CryptoCurrencyCode.BTC,
    decimals: 8,
    label: 'Bitcoin',
    networks: [Network.Bitcoin],
    symbol: '₿',
  },
  [CryptoCurrencyCode.BTC_TEST]: {
    code: CryptoCurrencyCode.BTC_TEST,
    decimals: 8,
    label: 'Bitcoin',
    networks: [Network.BitcoinTest],
    symbol: '₿',
  },
  [CryptoCurrencyCode.ETH]: {
    code: CryptoCurrencyCode.ETH,
    decimals: 18,
    label: 'Ether',
    networks: [Network.Ethereum],
    symbol: 'Ξ',
  },
  [CryptoCurrencyCode.ETH_TEST_SEPOLIA]: {
    code: CryptoCurrencyCode.ETH_TEST_SEPOLIA,
    decimals: 18,
    label: 'Ether',
    networks: [Network.EthereumTestSepolia],
    symbol: 'Ξ',
  },
  [CryptoCurrencyCode.MATIC]: {
    code: CryptoCurrencyCode.MATIC,
    decimals: 18,
    label: 'Matic',
    networks: [Network.PolygonPos],
    symbol: '',
  },
  [CryptoCurrencyCode.MATIC_TEST]: {
    code: CryptoCurrencyCode.MATIC_TEST,
    decimals: 18,
    label: 'Matic',
    networks: [Network.PolygonTestMumbai],
    symbol: '',
  },
  [CryptoCurrencyCode.TRX]: {
    code: CryptoCurrencyCode.TRX,
    decimals: 6,
    label: 'Tron',
    networks: [Network.Tron],
    symbol: '',
  },
  [CryptoCurrencyCode.TRX_TEST]: {
    code: CryptoCurrencyCode.TRX_TEST,
    decimals: 6,
    label: 'Tron',
    networks: [Network.TronTestShasta],
    symbol: '',
  },
  [CryptoCurrencyCode.USDC]: {
    code: CryptoCurrencyCode.USDC,
    decimals: 6,
    label: 'USDC',
    networks: [Network.Ethereum, Network.PolygonPos, Network.Tron],
    symbol: '$',
  },
  [CryptoCurrencyCode.USDC_TEST]: {
    code: CryptoCurrencyCode.USDC_TEST,
    decimals: 6,
    label: 'USDC',
    networks: [Network.EthereumTestSepolia, Network.PolygonTestMumbai, Network.TronTestShasta],
    symbol: '$',
  },
  [CryptoCurrencyCode.USDT]: {
    code: CryptoCurrencyCode.USDT,
    decimals: 6,
    label: 'USDT',
    networks: [Network.Ethereum, Network.PolygonPos, Network.Tron],
    symbol: '$',
  },
  [CryptoCurrencyCode.USDT_TEST]: {
    code: CryptoCurrencyCode.USDT_TEST,
    decimals: 6,
    label: 'USDT',
    networks: [Network.EthereumTestSepolia, Network.PolygonTestMumbai, Network.TronTestShasta],
    symbol: '$',
  },
  [CryptoCurrencyCode.NOAH]: {
    code: CryptoCurrencyCode.NOAH,
    decimals: 0,
    label: 'NOAH',
    networks: null,
    symbol: 'N',
  },
};
