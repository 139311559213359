import type { StreetAddress } from '@noah-labs/noah-schema';
import * as yup from 'yup';

export type TpBillingAddressForm = Omit<StreetAddress, 'CountryCode'> & {
  CountryCode: string;
  CountryName: string;
};

export const fieldNames = {
  City: 'billingAddress.City',
  CountryCode: 'billingAddress.CountryCode',
  CountryName: 'billingAddress.CountryName',
  PostCode: 'billingAddress.PostCode',
  State: 'billingAddress.State',
  Street: 'billingAddress.Street',
  Street2: 'billingAddress.Street2',
};

export const emptyBillingAddress: TpBillingAddressForm = {
  City: '',
  CountryCode: '',
  CountryName: '',
  PostCode: '',
  State: '',
  Street: '',
  Street2: '',
};

export const billingAddressSchema = yup
  .object({
    City: yup.string().required('City is required'),
    CountryCode: yup.string().required('Country is required'),
    CountryName: yup.string().required('Country is required'),
    PostCode: yup.string().required('Post / Zip code is required'),
    State: yup.string().required('State is required'),
    Street: yup.string().required('Address Line 1 is required'),
    Street2: yup.string().nullable(),
  })
  .required('Billing address is required');
