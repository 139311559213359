import { useCallback } from 'react';
import type { TpApplePaySessionData } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { UseMutateAsyncFunction } from 'react-query';
import { useMutation } from 'react-query';
import { useUserInit } from '../../user/data/useUserInit';
import { useFiatPaymentAppleSessionMutation } from '../data';

const supportedNetworks = ['visa', 'masterCard', 'amex', 'discover', 'jcb'];
const merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[] = [
  'supports3DS',
  'supportsEMV',
  'supportsCredit',
  'supportsDebit',
];

const baseRequest = {
  merchantCapabilities,
  supportedNetworks,
};

type TpTransactionDetails = {
  amount: string;
  currencyCode: string;
};

export type TpApplePay = {
  isReady: boolean;
  paymentError: ApplePayError | null;
  startSession: UseMutateAsyncFunction<
    TpApplePaySessionData | undefined,
    ApplePayError,
    TpTransactionDetails,
    unknown
  >;
};

// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history/apple_pay_on_the_web_version_4_release_notes
const version = 4;

export function useApplePay(): TpApplePay {
  const { data: userData } = useUserInit();
  const countryCode = userData?.userProfile.HomeAddress?.CountryCode;
  const isReady = Boolean(
    countryCode &&
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(version) &&
      ApplePaySession.canMakePayments()
  );

  const { mutateAsync: validateSession } = useFiatPaymentAppleSessionMutation();

  const handleSession = useCallback(
    ({ amount, currencyCode }: TpTransactionDetails): Promise<TpApplePaySessionData | undefined> =>
      new Promise<TpApplePaySessionData | undefined>((resolve, reject) => {
        if (!countryCode) {
          reject(
            new ApplePayError(
              'unknown',
              undefined,
              'Apple pay session cancelled: missing country code'
            )
          );
          return;
        }

        const paymentRequest = {
          ...baseRequest,
          countryCode,
          currencyCode,
          total: {
            amount,
            label: 'Total',
          },
        };

        logger.debug('Apple Pay payment request', paymentRequest);
        const session = new ApplePaySession(version, paymentRequest);

        session.onvalidatemerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent): void => {
          async function completeValidation(): Promise<void> {
            const { validationURL } = event;
            const {
              fiatPaymentAppleSession: { MerchantSession },
            } = await validateSession({ Input: { ValidationURL: validationURL } });

            session.completeMerchantValidation(JSON.parse(MerchantSession));
          }

          void completeValidation();
        };

        session.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent): void => {
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          resolve(event.payment.token.paymentData as TpApplePaySessionData);
        };

        session.oncancel = (): void => {
          reject(new ApplePayError('unknown', undefined, 'Apple pay session cancelled'));
        };

        session.begin();
      }),
    [countryCode, validateSession]
  );

  /**
   * Starts the Apple Pay user session.
   * @param transactionDetails - transaction details
   * @returns payment data. Contains the token to be exchanged with Checkout
   */
  const { error: paymentError, mutateAsync: startSession } = useMutation<
    TpApplePaySessionData | undefined,
    ApplePayError,
    TpTransactionDetails,
    unknown
  >(['applepay/GetPaymentToken'], handleSession);

  return {
    isReady,
    paymentError,
    startSession,
  };
}
