import { useEffect, useRef } from 'react';
import type { TpGooglePaySessionData } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { UseMutateAsyncFunction } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import useScript from 'react-script-hook';
import { isProd, webConfigBrowser } from '../../../webConfigBrowser';
import { useUserInit } from '../../user/data/useUserInit';

const { googlePayGateway, googlePayMerchantId, publicKey: checkoutId } = webConfigBrowser.checkout;

const allowedCardNetworks: google.payments.api.CardNetwork[] = [
  'AMEX',
  'DISCOVER',
  'INTERAC',
  'JCB',
  'MASTERCARD',
  'VISA',
];

const allowedAuthMethods: google.payments.api.CardAuthMethod[] = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
  parameters: {
    gateway: googlePayGateway,
    gatewayMerchantId: checkoutId,
  },
  type: 'PAYMENT_GATEWAY',
};

const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
  parameters: {
    allowedAuthMethods,
    allowedCardNetworks,
  },
  type: 'CARD',
};

const cardPaymentMethod: google.payments.api.PaymentMethodSpecification = {
  tokenizationSpecification,
  ...baseCardPaymentMethod,
};

const isReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
  ...baseRequest,
  allowedPaymentMethods: [cardPaymentMethod],
};

type TpGooglePay = {
  isReady: boolean;
  paymentError: google.payments.api.PaymentsError | null;
  startSession: UseMutateAsyncFunction<
    TpGooglePaySessionData | undefined,
    google.payments.api.PaymentsError,
    TpTransactionDetails,
    unknown
  >;
};

type TpTransactionDetails = {
  currencyCode: string;
  totalPrice: string;
};

const scriptUrl = 'https://pay.google.com/gp/p/js/pay.js';

function instanceOfPaymentsError(error: unknown): error is google.payments.api.PaymentsError {
  if (typeof error !== 'object' || !error) {
    return false;
  }
  return 'statusCode' in error && 'statusMessage' in error;
}

export function useGooglePay(): TpGooglePay {
  const [loading, error] = useScript({ src: scriptUrl });
  const client = useRef<google.payments.api.PaymentsClient | null>(null);
  const { data: userData } = useUserInit();
  const countryCode = userData?.userProfile.HomeAddress?.CountryCode;

  useEffect(() => {
    if (loading || error || client.current) {
      return;
    }

    try {
      client.current = new google.payments.api.PaymentsClient({
        environment: isProd ? 'PRODUCTION' : 'TEST',
      });
    } catch (err) {
      logger.error('Google Pay client failed to initialize', err);
    }
  }, [loading, error]);

  const { data: isReady } = useQuery(
    ['googlepay/IsGooglePayReady'],
    async () => {
      const res = await client.current?.isReadyToPay(isReadyToPayRequest);
      return Boolean(res?.result) && Boolean(countryCode);
    },
    {
      enabled: Boolean(client.current),
    }
  );

  /**
   * Starts the Google Pay user session.
   * @param transactionDetails - transaction details
   * @returns payment data. Contains the token to be exchanged with Checkout
   */
  const { error: paymentError, mutateAsync: startSession } = useMutation<
    TpGooglePaySessionData | undefined,
    google.payments.api.PaymentsError,
    TpTransactionDetails,
    unknown
  >(
    ['googlepay/GetPaymentToken'],
    async ({
      currencyCode,
      totalPrice,
    }: TpTransactionDetails): Promise<TpGooglePaySessionData | undefined> => {
      if (!client.current) {
        return undefined;
      }

      const paymentRequest: google.payments.api.PaymentDataRequest = {
        ...baseRequest,
        allowedPaymentMethods: [cardPaymentMethod],
        merchantInfo: {
          merchantId: googlePayMerchantId,
        },
        transactionInfo: {
          countryCode,
          currencyCode,
          totalPrice,
          totalPriceLabel: 'Total',
          totalPriceStatus: 'FINAL',
        },
      };

      logger.debug('Google Pay payment request', paymentRequest);

      try {
        const paymentData = await client.current.loadPaymentData(paymentRequest);
        const { token } = paymentData.paymentMethodData.tokenizationData;
        return JSON.parse(token) as TpGooglePaySessionData;
      } catch (err) {
        if (instanceOfPaymentsError(err)) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw err;
        }
        logger.error('Google Pay session was interrupted', err);
        return undefined;
      }
    }
  );

  return {
    isReady: Boolean(isReady),
    paymentError,
    startSession,
  };
}
