import { useCallback } from 'react';
import { BitcoinIcon } from '@noah-labs/core-web-ui/src/icons';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { CurrencyCode, CurrencyUnit } from '@noah-labs/noah-schema';
import { PreferredCryptoCurrencyForm } from '../../wallet/components/forms/PreferredCryptoCurrencyForm';
import { useOptimisticUserInitOptions } from '../data/useOptimisticUserInitOptions';
import { useUpdateUserCurrencyUnitSettingMutation } from '../data/user.generated';
import { useUserInit } from '../data/useUserInit';
import { useUserError } from '../hooks/useUserError';
import { routes } from '../routes';
import { UserSettingOptionsScene } from '../scenes';

const items = [
  {
    label: 'Bitcoin',
    value: CurrencyCode.BTC,
  },
  {
    label: 'Satoshi',
    value: CurrencyUnit.SATS,
  },
];

export function CurrencyUnitSetting(): React.ReactElement {
  const { data, error, isLoading } = useUserInit();
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } = useUpdateUserCurrencyUnitSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(error || mutateError);

  const handleChange = useCallback(
    (value: CurrencyCode.BTC | CurrencyUnit) => {
      const unitValue = value === CurrencyCode.BTC ? CurrencyUnit.Default : value;
      mutate({ Input: { DisplayUnit: unitValue } });
    },
    [mutate]
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  const displayUnitValue =
    data?.userProfile.DisplayUnit === CurrencyUnit.Default
      ? CurrencyCode.BTC
      : data?.userProfile.DisplayUnit;

  return (
    <UserSettingOptionsScene
      CurrencyForm={PreferredCryptoCurrencyForm}
      dataQa={routes.settings.bitcoinUnit.id}
      handleChange={handleChange}
      HeaderIcon={BitcoinIcon}
      items={items}
      pageTitle={routes.settings.bitcoinUnit.title as string}
      value={displayUnitValue}
    />
  );
}
