import { useCallback, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Stack } from '@mui/material';
import { AlertBody } from '@noah-labs/core-web-ui/src/alerts/Typography';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/noah-schema';
import { Feature } from '@noah-labs/noah-schema';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppHeaderData, UserCountrySelectField } from '../../../components';
import { getFilterCountryForFeatureData } from '../../../utils/countries';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { NewPayoutMethodButton, SavedPayoutMethodsList } from '../components';
import { cardFee, getBankCurrencyFeeAndEta } from '../data';
import type { TpPaymentBank, TpPaymentCard } from '../types';
import { IsCountryAllowed } from '../utils/utils';

type TpCountryFormValues = {
  Country: string;
  CountryCurrency: string;
  // This is only used for display purposes
  CountryName: string | undefined;
};

const countryFormSchema = yup.object({
  Country: yup.string().required(),
  CountryCurrency: yup.string().required(),
  CountryName: yup.string(),
});

const emptyDefaultValues: TpCountryFormValues = {
  Country: '',
  CountryCurrency: '',
  CountryName: '',
};

export type PpSelectPayoutMethodScene = {
  defaultCountryValues: TpCountryFormValues | undefined;
  manualRampSellEnabled: boolean;
  onNewBankAccountClick: (countryCurrency: CountryCode) => void;
  onNewCardClick: () => void;
  onPayoutMethodClick: (payoutMethod: TpPaymentCard | TpPaymentBank) => void;
  pageTitle: string;
  savedPayoutMethods?: Array<TpPaymentCard | TpPaymentBank>;
};

export function SelectPayoutMethodScene({
  defaultCountryValues,
  manualRampSellEnabled,
  onNewBankAccountClick,
  onNewCardClick,
  onPayoutMethodClick,
  pageTitle,
  savedPayoutMethods,
}: PpSelectPayoutMethodScene): React.ReactElement {
  const [showCountryAlert, setShowCountryAlert] = useState(false);
  const defaultValues = useMemo(
    () => defaultCountryValues || emptyDefaultValues,
    [defaultCountryValues]
  );

  const methods = useForm<TpCountryFormValues>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(countryFormSchema),
    values: defaultValues,
  });

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      setShowCountryAlert(false);

      const isCountryNotAvailable =
        webConfigBrowser.regions.ProhibitedCountries[value?.value || ''];

      if (isCountryNotAvailable) {
        setShowCountryAlert(true);
      }

      methods.setValue('Country', value?.value || '', { shouldValidate: true });
      methods.setValue('CountryName', value?.label || '');
      methods.setValue('CountryCurrency', value?.currency || '');
    },
    [methods]
  );

  const countryCurrencies = methods.watch('CountryCurrency').split(',');
  const country = methods.watch('Country');
  const [mainCountryCurrency] = countryCurrencies;

  const showCardPayoutMethods = Boolean(country) && !showCountryAlert;
  const showBankPayoutMethod = IsCountryAllowed(country, countryCurrencies);
  const showPayoutMethodsList = showCardPayoutMethods || showBankPayoutMethod;

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="enter-amount"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneHeader dense>
        <SceneParagraph>
          Sell bitcoin and receive funds in your local currency. Select a recipient country and
          payout method.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <Stack spacing={2}>
          <FormProvider {...methods}>
            <form id="countryForm">
              <Stack spacing={2}>
                <UserCountrySelectField
                  // country needs to support checkout sell OR manualramp sell features
                  filterFunction={getFilterCountryForFeatureData([
                    [Feature.CheckoutSell, Feature.ManualRampSell],
                  ])}
                  handleChange={handleCountryFormValues}
                  label="Choose a country"
                  name="CountryName"
                />
                {showCountryAlert && (
                  <Alert severity="error">
                    <AlertTitle>Payouts are not yet supported here</AlertTitle>
                    <AlertBody>
                      Our goal is to make payouts available everywhere. Get in touch to see if we
                      can help.
                    </AlertBody>
                  </Alert>
                )}
              </Stack>
            </form>
          </FormProvider>
          <Stack>
            {showCardPayoutMethods && (
              <NewPayoutMethodButton feeAndEta={cardFee} type="card" onClick={onNewCardClick} />
            )}

            {showBankPayoutMethod && manualRampSellEnabled && (
              <NewPayoutMethodButton
                feeAndEta={getBankCurrencyFeeAndEta(mainCountryCurrency as FiatCurrencyCode)}
                type="bankAccount"
                onClick={(): void => {
                  onNewBankAccountClick(methods.getValues().Country as CountryCode);
                }}
              />
            )}
          </Stack>

          {showPayoutMethodsList && !isFalseyOrEmptyArray(savedPayoutMethods) && (
            <SavedPayoutMethodsList
              savedPayoutMethods={savedPayoutMethods}
              onPayoutMethodClick={onPayoutMethodClick}
            />
          )}
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
