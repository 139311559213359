import { CreditCardIcon } from '@noah-labs/core-web-ui/src/icons';
import { AmexLight, MastercardLight, VisaLight } from '@noah-labs/core-web-ui/src/images';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { CountryCode, CurrencyCode, FullName, StreetAddress } from '@noah-labs/noah-schema';
import {
  BankingSystem,
  CurrencyUnit,
  FiatPaymentCardScheme,
  TransferDestinationType,
} from '@noah-labs/noah-schema';
import { btcToSats } from '@noah-labs/shared-currencies/src/conversions';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import dayjs from 'dayjs';
import { allowedBankCurrencies } from '../data/currencies';
import { TpPaymentMethod } from '../types';

export function getAddressDisplay(
  address: Omit<StreetAddress, 'CountryCode'> | null | undefined
): string {
  if (!address) {
    return '';
  }

  const { City, PostCode, State, Street, Street2 } = address;

  return [Street, Street2, State, City, PostCode].filter(Boolean).join(', ');
}

type PpGetCryptoUnitData = {
  amount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit;
};

export type TpCryptoAmountUnit = {
  amountBtc: string;
  amountUnit: string;
  code: CurrencyCode;
  unit?: CurrencyUnit;
};

export function getCryptoUnitAmount({
  amount,
  cryptoCurrency,
  cryptoUnit,
}: PpGetCryptoUnitData): TpCryptoAmountUnit {
  let payload: TpCryptoAmountUnit;

  switch (cryptoUnit) {
    case CurrencyUnit.SATS: {
      payload = {
        amountBtc: amount,
        amountUnit: btcToSats(amount),
        code: cryptoCurrency.code,
        unit: CurrencyUnit.SATS,
      };
      break;
    }

    case CurrencyUnit.Default:
    default: {
      payload = {
        amountBtc: truncateAmount({
          amount,
          decimalPlaces: cryptoCurrency.decimals,
        }),
        amountUnit: truncateAmount({
          amount,
          decimalPlaces: cryptoCurrency.decimals,
        }),
        code: cryptoCurrency.code,
        unit: CurrencyUnit.Default,
      };
    }
  }

  return payload;
}

export function getPayoutEstimateTime(hours: string): string {
  return dayjs
    .duration({
      hours: Number(hours),
    })
    .humanize();
}

export function getCardSchemeIcon(scheme: string | undefined): React.FC {
  switch (scheme) {
    case FiatPaymentCardScheme.Visa:
      return VisaLight;
    case FiatPaymentCardScheme.Mastercard:
      return MastercardLight;
    case FiatPaymentCardScheme.Amex:
      return AmexLight;
    default:
      return CreditCardIcon;
  }
}

export function parseCardScheme(scheme: string): FiatPaymentCardScheme | undefined {
  switch (scheme.toLowerCase()) {
    case 'visa':
      return FiatPaymentCardScheme.Visa;
    case 'mastercard':
      return FiatPaymentCardScheme.Mastercard;
    case 'amex':
      return FiatPaymentCardScheme.Amex;
    default:
      return undefined;
  }
}

const US_COUNTRY_CODE = 'US';
const USD_CURRENCY_CODE = 'USD';

export function IsCountryAllowed(countryCode: string, currencies: string[]): boolean {
  const isAllowed = currencies.some((c) => allowedBankCurrencies[c]);

  if (!isAllowed) {
    return false;
  }

  if (currencies.includes(USD_CURRENCY_CODE) && countryCode !== US_COUNTRY_CODE) {
    return false;
  }

  return true;
}

export function getAccountHolderName(fullName: string): FullName | undefined {
  if (!fullName) {
    return undefined;
  }

  const [firstName, ...otherNames] = fullName.trim().split(/\s+/);
  const lastName = otherNames.pop() || '';

  const middleName = otherNames.join(' ');

  return {
    FirstName: firstName,
    LastName: lastName,
    MiddleName: middleName,
  };
}

export function getPayoutTansferDestinationType(pm?: TpPaymentMethod): TransferDestinationType {
  switch (pm) {
    case TpPaymentMethod.BankTransfer:
      return TransferDestinationType.ManualRamp;
    default:
      return TransferDestinationType.Checkout;
  }
}

export function getBankingSystem(country: CountryCode | undefined): BankingSystem {
  switch (country) {
    case 'GB':
      return BankingSystem.SortCode;
    default:
      return BankingSystem.SWIFT;
  }
}
