import type { HTMLAttributeAnchorTarget } from 'react';
import { css } from '@emotion/react';
import { ListItem, ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpStructuredContent } from '../lists/atoms/StructuredContent';
import { StructuredContent } from '../lists/atoms/StructuredContent';
import { ElevatedCard } from './ElevatedCard';

type PpTransactionCardListItem = PpStructuredContent & {
  dataQa?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
};

export function TransactionCardListItem(props: PpTransactionCardListItem): React.ReactElement {
  const theme = useTheme();

  const styles = css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `;

  const { dataQa, href, target, ...content } = props;

  const alignTop =
    (content.mainText.primary && content.mainText.secondary) ||
    (content.endText?.primary && content.endText.secondary);

  let Content = (
    <ElevatedCard sx={{ p: 2 }}>
      <StructuredContent
        dataQa={dataQa}
        {...content}
        alignItems={alignTop ? 'flex-start' : 'center'}
      />
    </ElevatedCard>
  );

  if (href) {
    Content = (
      <ListItemButton component="a" href={href} sx={{ p: 0 }} target={target}>
        {Content}
      </ListItemButton>
    );
  }

  return (
    <ListItem disableGutters disablePadding css={styles}>
      {Content}
    </ListItem>
  );
}
