import { useCallback, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DynamicCopyIcon } from '@noah-labs/core-web-ui/src/address/DynamicCopyIcon';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import { useClickToCopy } from '@noah-labs/core-web-ui/src/hooks/useClickToCopy';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { Network } from '@noah-labs/noah-schema';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { AddressCopiedAlert } from '../components/alerts';
import { LightningReceiveCard } from '../components/cards/LightningReceiveCard';
import { OnChainReceiveCard } from '../components/cards/OnChainReceiveCard';
import { ReceiveAddressInfoDialog } from '../components/dialogs/ReceiveAddressInfoDialog';
import { ReceiveInitialDialog } from '../components/dialogs/ReceiveInitialDialog';
import { getReceiveCryptoData } from '../utils/getReceiveCryptoData';

export type PpReceiveScene = {
  address?: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  initialDialog?: boolean;
  lightningInvoiceHref: string;
  lnAddress?: string;
  network?: Network;
  pageTitle: string;
  qrCodeSlot: React.ReactNode;
  showLightningSection: boolean;
  showOnChainSection: boolean;
};

export function ReceiveScene({
  address,
  cryptoCurrency,
  initialDialog = false,
  lightningInvoiceHref,
  lnAddress,
  network,
  pageTitle,
  qrCodeSlot,
  showLightningSection,
  showOnChainSection,
}: PpReceiveScene): React.ReactElement {
  const showInfoAddressDialog = useRef<TpDialogToggle>(null);
  const [copiedAddress, setCopiedAddress] = useState<'lightning' | 'onChain' | null>(null);

  const pushAlert = usePushAlert();

  const { copyHandler: lnCopyHandler } = useClickToCopy({
    onCopy: () => {
      setCopiedAddress('lightning');
      pushAlert(AddressCopiedAlert);
    },
    text: lnAddress,
  });
  const { copyHandler: onChainCopyHandler } = useClickToCopy({
    onCopy: () => {
      setCopiedAddress('onChain');
      pushAlert(AddressCopiedAlert);
    },
    text: address,
  });

  const handleInfoAddressDialogToggle = useCallback(() => {
    showInfoAddressDialog.current?.toggle();
  }, []);

  const { Icon } = getReceiveCryptoData(cryptoCurrency.code, network);

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton disableFade helpButton>
          <Stack alignItems="center">
            <Typography color="text.light" variant="paragraphBodyS">
              Receive
            </Typography>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <CurrencyAvatar Icon={Icon} size={3} />
              <Typography variant="subHeadingM">{cryptoCurrency.label}</Typography>
            </Stack>
          </Stack>
        </AppHeaderData>
      }
      backgroundColor="transparent"
      dataQa="receive"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain>
        <ReceiveInitialDialog initialOpen={initialDialog} />
        <ReceiveAddressInfoDialog ref={showInfoAddressDialog} />

        <Stack alignItems="center">
          <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
            Choose how you would like to receive {cryptoCurrency.label}.
          </Typography>
          <Typography color="text.light" variant="paragraphBodyS">
            <InfoTooltip onClick={handleInfoAddressDialogToggle}>
              Only send {cryptoCurrency.label} to these addresses
            </InfoTooltip>
          </Typography>
        </Stack>

        {showOnChainSection && (
          <OnChainReceiveCard
            address={address}
            cryptoCurrency={cryptoCurrency}
            network={network}
            OnChainCopyIcon={<DynamicCopyIcon copied={copiedAddress === 'onChain'} />}
            qrCodeSlot={qrCodeSlot}
            onChainCopyHandler={onChainCopyHandler}
          />
        )}

        {showLightningSection && (
          <LightningReceiveCard
            lightningInvoiceHref={lightningInvoiceHref}
            lnAddress={lnAddress}
            lnCopyHandler={lnCopyHandler}
            LnCopyIcon={<DynamicCopyIcon copied={copiedAddress === 'lightning'} />}
          />
        )}
      </SceneMain>
    </AppContainer>
  );
}
