import { SVGProps } from 'react';
const SvgVisaLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 24" {...props}>
    <rect width={48} height={24} fill="#FBFAF8" rx={2} />
    <path
      fill="#1A1F71"
      fillRule="evenodd"
      d="M32.167 7.448A7.546 7.546 0 0 0 29.555 7c-2.88 0-4.91 1.446-4.927 3.516-.015 1.533 1.449 2.386 2.555 2.896 1.134.522 1.515.855 1.51 1.321-.008.715-.906 1.041-1.744 1.041-1.166 0-1.786-.16-2.743-.559l-.376-.17-.41 2.386c.682.297 1.94.556 3.248.569 3.064 0 5.053-1.429 5.075-3.64.01-1.213-.765-2.136-2.446-2.896-1.02-.493-1.644-.822-1.637-1.321 0-.442.528-.917 1.668-.917a5.378 5.378 0 0 1 2.182.409l.262.122.395-2.309ZM18.683 17.842l1.822-10.656h2.914l-1.823 10.656h-2.913ZM37.389 7.196h2.252L42 17.845h-2.705s-.267-1.224-.354-1.597l-2.052-.002h-.012l-1.669-.002c-.113.288-.613 1.6-.613 1.6h-3.061l4.328-9.764c.307-.695.829-.884 1.527-.884Zm-1.343 6.868c.242-.614 1.162-2.979 1.162-2.979-.008.014.048-.133.126-.333.08-.21.185-.48.26-.684l.197.92.675 3.076h-2.42Zm-22.661.396 2.853-7.266h3.087l-4.588 10.635-3.084.004-2.609-9.32c1.85.923 3.506 2.768 4.037 4.47l.304 1.477Z"
      clipRule="evenodd"
    />
    <path
      fill="#F9A51A"
      fillRule="evenodd"
      d="M10.737 7.187h-4.7L6 7.408c3.657.883 6.076 3.014 7.08 5.576l-1.022-4.898c-.176-.675-.688-.876-1.32-.9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVisaLight;
