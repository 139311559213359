import { useCallback, useEffect, useRef } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { Checkbox } from '@noah-labs/core-web-ui/src/forms/Checkbox';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { IdCardIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import {
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/core-web-ui/src/lists/ListItem';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components';
import { UsTaxpayerDialog } from '../components/dialogs/UsTaxpayerDialog';

const defaultValues = {
  VerificationConsent: false,
};

export type TpAccountVerificationFormValues = typeof defaultValues;

export type UsTaxpayerFormValue = {
  UsTaxpayer: boolean;
};

export type TpAccountVerificationSubmitValues = TpAccountVerificationFormValues &
  UsTaxpayerFormValue;

export type PpAccountVerificationScene = {
  onSubmit: (values: TpAccountVerificationSubmitValues) => void;
  verificationConsentFormState: TpAccountVerificationFormValues | undefined;
};

export function AccountVerificationScene({
  onSubmit,
  verificationConsentFormState,
}: PpAccountVerificationScene): React.ReactElement {
  const showUsTaxpayerDialog = useRef<TpDialogToggle>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const pushAlert = usePushAlert();

  const methods = useForm<TpAccountVerificationFormValues>({
    defaultValues,
  });

  const handleFormState = useCallback(
    (usTaxpayer: UsTaxpayerFormValue) => {
      onSubmit({
        ...usTaxpayer,
        VerificationConsent: methods.getValues().VerificationConsent,
      });
    },
    [methods, onSubmit]
  );

  useEffect(() => {
    if (!verificationConsentFormState) {
      return;
    }
    methods.reset(verificationConsentFormState, { keepDefaultValues: true });
  }, [methods, verificationConsentFormState]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }
    pushAlert({
      autoHideDuration: null,
      dismissable: true,
      key: 'device-warning',
      message: `Verification is more likely to be successful when using a mobile phone camera due to
              quality of images. Please consider switching to mobile.`,
      preventDuplicate: true,
      severity: 'warning',
    });
  }, [isDesktop, pushAlert]);

  const buttonDisabled = !methods.formState.isDirty;

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <FormProvider {...methods}>
          <form>
            <Stack spacing={3}>
              <Checkbox
                label="I confirm that I am the owner of the account and the funds, and that I am acting on my own behalf."
                name="VerificationConsent"
                primaryTypographyProps={{
                  variant: 'paragraphBodyS',
                }}
              />

              <PrimaryButton
                color="primaryBrand"
                data-qa="continue-button"
                disabled={buttonDisabled}
                loading={methods.formState.isSubmitting}
                onClick={(): void => showUsTaxpayerDialog.current?.toggle()}
              >
                Continue
              </PrimaryButton>
            </Stack>
          </form>
        </FormProvider>
      }
      AppHeaderSlot={<AppHeaderData backButton disableFade exitButton helpButton />}
      dataQa="accountVerification"
    >
      <Helmet>
        <title>Account Verification</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Let’s get you verified</SceneTitleMedium>
        <SceneParagraph>
          This helps us keep your account safe from fraud.{' '}
          <LearnMoreButton href={webLinks.privacyPolicy.path} target="_blank">
            Learn more in our Privacy Policy.
          </LearnMoreButton>
        </SceneParagraph>
        <SceneParagraph>
          You have 10 minutes to complete the form, so please have the following documents ready.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <ListItem>
          <ListItemCard>
            <ListItemContent>
              <ListItemIcon>
                <IdCardIcon htmlColor={theme.palette.text.light} />
              </ListItemIcon>
              <ListItemStartContent>
                <ListItemPrimaryText>Identity document</ListItemPrimaryText>
                <ListItemSecondaryText>
                  Government-issued ID or drivers licence (except US)
                </ListItemSecondaryText>
              </ListItemStartContent>
            </ListItemContent>
          </ListItemCard>
        </ListItem>
      </SceneMain>
      <UsTaxpayerDialog ref={showUsTaxpayerDialog} handleFormState={handleFormState} />
    </AppContainer>
  );
}
