import { useHistory } from 'react-router-dom';
import { routes } from '../routes';
import { QuestionnaireScene } from '../scenes/Questionnaire/Questionnaire';

export function Questionnaire(): React.ReactElement {
  const history = useHistory();

  return (
    <QuestionnaireScene onSubmit={(): void => history.push(routes.questionnaire.activity.path)} />
  );
}
