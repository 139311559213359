import { getTruncatedAddress } from '@noah-labs/core-web-ui/src/address/getTruncatedAddress';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import type { TpCryptoCurrencyUI, TpIcon, TpSkeletonText } from '@noah-labs/core-web-ui/src/types';
import type { FiatCurrencyCode } from '@noah-labs/noah-schema';
import {
  Network as TpNetwork,
  TransactionDirection,
  TransactionStatus,
  TransferDestinationType,
  TransferSourceType,
} from '@noah-labs/noah-schema';
import { formatDate, formatTime } from '@noah-labs/shared-tools/src/browser/dates';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { calculateFee } from '../../../utils';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpCurrencyConverters, TpEnhancedTxData, TpTransactionItem } from '../types';
import { getTransactionType } from '../utils/getTransactionType';
import { getManualRampCurrencyConfig } from './fees';

const { feeMinimumFiatAmount, feePercentage } = webConfigBrowser.checkout;

function getNativeTitle(
  tx: TpTransactionItem,
  direction: TransactionDirection | undefined
): string {
  const fallback = 'Unknown';

  const inTitle = tx.SourceUsernameDisplay || getTruncatedAddress(tx.Source?.Address, fallback);
  const outTitle =
    tx.DestinationUsernameDisplay || getTruncatedAddress(tx.Destination?.Address, fallback);

  const nativeTitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: `From ${inTitle}`,
    [TransactionDirection.Out]: `To ${outTitle}`,
  };
  return direction ? nativeTitleMap[direction] : fallback;
}
export function getRampTitle(tx: TpTransactionItem, direction?: TransactionDirection): string {
  const checkoutTitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: `Bought ${tx.Currency}`,
    [TransactionDirection.Out]: `Sold ${tx.Currency}`,
  };
  return direction ? checkoutTitleMap[direction] : 'Unknown';
}
export function getL2Title(
  cryptoCurrency: TpCryptoCurrencyUI,
  sender: TpSkeletonText,
  receiver: TpSkeletonText,
  direction?: TransactionDirection
): string {
  const fallback = 'Unknown';
  const l2TitleMap: Record<TransactionDirection, string> = {
    [TransactionDirection.In]: [`Receive ${cryptoCurrency.code}`, sender]
      .filter(Boolean)
      .join(' from '),
    [TransactionDirection.Out]: [`Send ${cryptoCurrency.code}`, receiver]
      .filter(Boolean)
      .join(' to '),
  };
  return direction ? l2TitleMap[direction] : fallback;
}

type TpGetMainPrimaryContentWithIcon = {
  cryptoCurrency: TpCryptoCurrencyUI;
  direction?: TransactionDirection;
  isRamp?: boolean;
  tx: TpTransactionItem;
};

export function getMainPrimaryContentWithIcon({
  cryptoCurrency,
  direction,
  isRamp,
  tx,
}: TpGetMainPrimaryContentWithIcon): {
  Avatar: React.ReactElement | null;
  CurrencyIcon: TpIcon | null;
  mainPrimaryContent: string;
} {
  switch (tx.Network) {
    case TpNetwork.Bitcoin:
    case TpNetwork.BitcoinTest:
    case TpNetwork.Ethereum:
    case TpNetwork.EthereumTestSepolia:
    case TpNetwork.PolygonPos:
    case TpNetwork.PolygonTestMumbai:
    case TpNetwork.Tron:
    case TpNetwork.TronTestShasta:
    case TpNetwork.OffNetwork:
      return {
        Avatar: <CurrencyAvatar Icon={cryptoCurrency.Icon} size={4.75} />,
        CurrencyIcon: cryptoCurrency.Icon,
        mainPrimaryContent: isRamp ? getRampTitle(tx, direction) : getNativeTitle(tx, direction),
      };
    case TpNetwork.Lightning:
    case TpNetwork.LightningTest:
      return {
        Avatar: <CurrencyAvatar Icon={cryptoCurrency.Icon} size={4.75} />,
        CurrencyIcon: cryptoCurrency.NetworkIcons?.[tx.Network] || null,
        mainPrimaryContent: getL2Title(
          cryptoCurrency,
          tx.SourceUsernameDisplay,
          tx.DestinationUsernameDisplay,
          direction
        ),
      };

    default:
      return { Avatar: null, CurrencyIcon: null, mainPrimaryContent: 'Unknown transaction type' };
  }
}

export function getStatusText(
  tx: TpTransactionItem,
  hasExpired: boolean,
  direction?: TransactionDirection
): string {
  switch (tx.Status) {
    case TransactionStatus.Pending:
    case TransactionStatus.Failed:
      return hasExpired && direction === TransactionDirection.In ? 'Expired' : tx.Status;
    default:
      return tx.Status;
  }
}

export function getMainSecondaryContent(
  tx: TpTransactionItem,
  hasExpired: boolean,
  direction?: TransactionDirection
): string {
  return [formatTime(tx.Created), getStatusText(tx, hasExpired, direction)]
    .filter(Boolean)
    .join(' · ');
}

function positiveOrNegative(amount: string, direction?: TransactionDirection): string {
  return direction === TransactionDirection.In ? amount : safeBN(amount).negated().toString();
}

function getStatus(tx: TpTransactionItem, hasExpired: boolean): TransactionStatus {
  let status = tx.Status;
  if (hasExpired && status === TransactionStatus.Pending) {
    status = TransactionStatus.Failed;
  }
  return status;
}

type TpGetFeeFiatAmount = {
  fiatAmount: string | undefined;
  fiatCurrencyCode: FiatCurrencyCode | undefined;
  isManualRamp: boolean;
  tx: TpTransactionItem;
};
function getFeeFiatAmount({
  fiatAmount,
  fiatCurrencyCode,
  isManualRamp,
  tx,
}: TpGetFeeFiatAmount): string | undefined {
  if (tx.Status === TransactionStatus.Pending) {
    return undefined;
  }

  const hasFeeRecord = tx.SourceRecords?.find((item) => item.Amount);

  if (hasFeeRecord) {
    return hasFeeRecord.Amount;
  }

  if (isManualRamp && fiatCurrencyCode) {
    const manualRampCurrencyConfig = getManualRampCurrencyConfig(fiatCurrencyCode);
    return calculateFee({
      amount: fiatAmount,
      fee: manualRampCurrencyConfig?.FeePercentage,
      feeMin: manualRampCurrencyConfig?.FeeMinimumFiatAmount,
    }).toString();
  }

  return calculateFee({
    amount: fiatAmount,
    fee: feePercentage,
    feeMin: feeMinimumFiatAmount,
  }).toString();
}

type PpGetEnhancedTxData = TpCurrencyConverters & {
  tx: TpTransactionItem;
};
export function getEnhancedTxData({
  cryptoCurrencyFromCode,
  fiatCurrencyFromCode,
  tx,
}: PpGetEnhancedTxData): TpEnhancedTxData {
  const direction = tx.Direction || undefined;
  const hasExpired = tx.Expiry ? new Date(tx.Expiry) <= new Date() : false;

  const isCheckoutRamp =
    tx.SourceType === TransferSourceType.Checkout ||
    tx.DestinationType === TransferDestinationType.Checkout;
  const isManualRamp = tx.DestinationType === TransferDestinationType.ManualRamp;
  const isRamp = isCheckoutRamp || isManualRamp;

  const fiatAmountObj = tx.RequestedAmount || tx.MarketAmount;
  const marketfiatAmountObj = tx.MarketAmount;
  const fiatCurrency = fiatAmountObj?.FiatCurrency
    ? fiatCurrencyFromCode(fiatAmountObj.FiatCurrency)
    : undefined;

  const fiatAmount = isUndefinedOrNull(fiatAmountObj)
    ? undefined
    : positiveOrNegative(fiatAmountObj.Amount, direction);
  const fiatAmountWithFee = isUndefinedOrNull(marketfiatAmountObj)
    ? undefined
    : positiveOrNegative(marketfiatAmountObj.Amount, direction);

  const cryptoCurrency = cryptoCurrencyFromCode(tx.Currency);
  const status = getStatus(tx, hasExpired);

  const created = `${formatDate(tx.Created, {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  }).replace(',', '')}, ${formatTime(tx.Created)}`;

  const type = getTransactionType(isCheckoutRamp, direction);

  const feeAmount = getFeeFiatAmount({
    fiatAmount: fiatAmountObj?.Amount,
    fiatCurrencyCode: fiatCurrency?.code,
    isManualRamp,
    tx,
  });

  return {
    ...getMainPrimaryContentWithIcon({ cryptoCurrency, direction, isRamp, tx }),
    created: created.toLocaleString(),
    cryptoAmount: positiveOrNegative(tx.Amount, direction),
    cryptoCurrency,
    direction,
    feeAmount,
    fiatAmount,
    fiatAmountWithFee,
    fiatCurrency,
    hasExpired,
    isRamp,
    mainSecondaryContent: getMainSecondaryContent(tx, hasExpired, direction),
    status,
    statusText: getStatusText(tx, hasExpired, direction),
    type,
  };
}
