import { Fragment } from 'react';
import { Alert, List, Stack, Typography } from '@mui/material';
import { AddressWithIcon } from '@noah-labs/core-web-ui/src/address/AddressWithIcon';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { TransactionCardListItem } from '@noah-labs/core-web-ui/src/cards/TransactionCardListItem';
import { OpenInWindowIcon } from '@noah-labs/core-web-ui/src/icons';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import type {
  TpCryptoCurrencyUI,
  TpFiatCurrencyUI,
  TpIcon,
} from '@noah-labs/core-web-ui/src/types';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import {
  CurrencyDisplayType,
  TransactionDirection,
  TransactionStatus,
} from '@noah-labs/noah-schema';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { isNonZero } from '@noah-labs/shared-tools/src/browser/numbers';
import { GetInTouchLink } from '../../../../components';
import type { TpCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import type { TpTransactionDataQa, TpTransactionType } from '../../types';
import { AvatarWithTransactionStatus } from './AvatarWithTransactionStatus';
import { TransactionSummary } from './TransactionSummary';

type TpAmountsDisplay = {
  NetAmounts: TpCurrencyAmounts;
  RequestedAmounts: TpCurrencyAmounts;
  netAmountLabel: string;
};

function getNetAmountLabel(isRamp: boolean, direction?: TransactionDirection): string {
  let netAmountLabel = '';

  switch (direction) {
    case TransactionDirection.In:
      netAmountLabel = 'Amount received';
      break;

    case TransactionDirection.Out:
      netAmountLabel = 'Amount sent';

      if (isRamp) {
        netAmountLabel = 'Amount sold';
      }
      break;

    default:
      netAmountLabel = 'Amount';
      break;
  }

  return netAmountLabel;
}

type TpGetAmountsToDisplay = {
  NetAmounts: TpCurrencyAmounts;
  RequestedAmounts: TpCurrencyAmounts;
  direction?: TransactionDirection;
  isRamp: boolean;
  primaryCurrency?: CurrencyDisplayType;
};

function getAmountsToDisplay({
  direction,
  isRamp,
  NetAmounts,
  primaryCurrency,
  RequestedAmounts,
}: TpGetAmountsToDisplay): TpAmountsDisplay {
  let NetPrimary: React.ReactNode = NetAmounts.PrimaryAmountSlot;
  let NetSecondary: React.ReactNode = NetAmounts.SecondaryAmountSlot;
  let RequestedPrimary: React.ReactNode = RequestedAmounts.PrimaryAmountSlot;
  let RequestedSecondary: React.ReactNode = RequestedAmounts.SecondaryAmountSlot;

  const isPrimaryCrypto = primaryCurrency === CurrencyDisplayType.Crypto;
  const netAmountLabel = getNetAmountLabel(isRamp, direction);

  // Buys
  if (isRamp && direction === TransactionDirection.In) {
    if (isPrimaryCrypto) {
      NetPrimary = NetAmounts.SecondaryAmountSlot;
      NetSecondary = NetAmounts.PrimaryAmountSlot;
      RequestedPrimary = RequestedAmounts.SecondaryAmountSlot;
      RequestedSecondary = undefined;
    } else {
      NetPrimary = NetAmounts.PrimaryAmountSlot;
      NetSecondary = NetAmounts.SecondaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    }
  }

  // Sell
  if (isRamp && direction === TransactionDirection.Out) {
    if (isPrimaryCrypto) {
      NetPrimary = NetAmounts.PrimaryAmountSlot;
      NetSecondary = NetAmounts.SecondaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    } else {
      NetPrimary = NetAmounts.SecondaryAmountSlot;
      NetSecondary = NetAmounts.PrimaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    }
  }

  return {
    netAmountLabel,
    NetAmounts: {
      PrimaryAmountSlot: NetPrimary,
      SecondaryAmountSlot: NetSecondary,
    },
    RequestedAmounts: {
      PrimaryAmountSlot: RequestedPrimary,
      SecondaryAmountSlot: RequestedSecondary,
    },
  };
}

export type PpTransactionDetails = {
  NetworkIcon?: TpIcon | null;
  created?: string;
  cryptoAmount?: TpAmount;
  cryptoCurrency?: TpCryptoCurrencyUI;
  dataQa?: TpTransactionDataQa;
  description?: string | null | undefined;
  direction?: TransactionDirection;
  feeAmount?: string;
  fiatAmount?: TpAmount;
  fiatAmountWithFee?: TpAmount;
  fiatCurrency?: TpFiatCurrencyUI | undefined;
  hasExpired?: boolean;
  hasFee?: boolean;
  isRamp?: boolean;
  mainPrimaryContent?: string;
  primaryCurrency?: CurrencyDisplayType;
  publicUrl?: string;
  status?: TransactionStatus;
  statusText?: string;
  transactionId?: string | null | undefined;
  type?: TpTransactionType;
};

export function TransactionDetails({
  cryptoAmount,
  cryptoCurrency,
  dataQa,
  description,
  direction,
  feeAmount,
  fiatAmount,
  fiatAmountWithFee,
  fiatCurrency,
  hasExpired,
  hasFee,
  isRamp,
  mainPrimaryContent,
  NetworkIcon,
  primaryCurrency,
  publicUrl,
  status,
  statusText,
  type,
}: PpTransactionDetails): React.ReactElement {
  // transaction amounts without fees
  const RequestedAmounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
    roundDown: true,
    signDisplay: 'never',
    strikeThrough: status === TransactionStatus.Failed,
  });

  const totalFiatAmount = hasFee ? fiatAmountWithFee : fiatAmount;

  // sent or received amounts
  const NetAmounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount: totalFiatAmount,
    fiatCurrency,
    nonZeroCrypto: true,
    nonZeroFiat: true,
    roundDown: true,
    signDisplay: 'never',
    strikeThrough: status === TransactionStatus.Failed,
  });

  const {
    netAmountLabel,
    NetAmounts: NetAmountsDisplay,
    RequestedAmounts: RequestedAmountsDisplay,
  } = getAmountsToDisplay({
    direction,
    isRamp: Boolean(isRamp),
    NetAmounts,
    primaryCurrency,
    RequestedAmounts,
  });

  const Fee = (
    <FiatAmount
      roundDown
      amount={feeAmount}
      currency={fiatCurrency}
      fallback="Confirming Fee"
      fallbackCheck={(value): boolean => !isNonZero(value)}
      signDisplay="never"
    />
  );

  return (
    <Stack spacing={1}>
      <div>
        <TransactionSummary
          AvatarSlot={
            <AvatarWithTransactionStatus
              CurrencyIcon={cryptoCurrency?.Icon}
              transactionStatus={status}
              transactionType={type}
            />
          }
          dataQa={dataQa}
        >
          <Stack alignItems="center">
            <AddressWithIcon Icon={NetworkIcon}>{mainPrimaryContent}</AddressWithIcon>
          </Stack>
          <Balance {...RequestedAmountsDisplay} />
        </TransactionSummary>
      </div>
      <List disablePadding>
        {description && (
          <TransactionCardListItem
            dataQa="note"
            mainText={{
              primary: 'Note',
              primaryTypographyProps: { color: 'text.dark' },
              secondary: description,
            }}
          />
        )}

        <TransactionCardListItem
          dataQa="status"
          mainText={{
            primary: 'Status',
            primaryTypographyProps: { color: 'text.dark' },
            secondary: statusText
              ? `${
                  hasExpired && direction === TransactionDirection.In ? 'Invoice' : 'Payment'
                } ${statusText}`
              : undefined,
          }}
        />

        {status !== TransactionStatus.Failed && (
          <Fragment>
            <TransactionCardListItem
              dataQa="amount"
              endText={{
                primary: NetAmountsDisplay.PrimaryAmountSlot,
                secondary: NetAmountsDisplay.SecondaryAmountSlot,
              }}
              mainText={{
                primary: netAmountLabel,
                primaryTypographyProps: { color: 'text.dark' },
              }}
            />
            <TransactionCardListItem
              dataQa="fee"
              endText={
                hasFee
                  ? {
                      primary: Fee,
                    }
                  : {
                      secondary: 'No fee',
                    }
              }
              mainText={{
                primary: 'Fee',
                primaryTypographyProps: { color: 'text.dark' },
              }}
            />
          </Fragment>
        )}

        {status === TransactionStatus.Failed && (
          <Alert severity="error" sx={{ mb: 1 }}>
            Your transaction has failed, if you need help, please{' '}
            <GetInTouchLink color="inherit" underline="always" />.
          </Alert>
        )}

        {publicUrl && (
          <TransactionCardListItem
            dataQa="public-txn-link"
            endText={{
              primary: (
                <TextWithIcon sx={{ color: 'text.link', justifyContent: 'flex-end' }}>
                  <OpenInWindowIcon sx={{ fontSize: '1rem' }} />
                  <Typography textAlign="right" variant="paragraphBodyS">
                    View
                  </Typography>
                </TextWithIcon>
              ),
            }}
            href={publicUrl}
            mainText={{
              primary: 'Public transaction link',
              primaryTypographyProps: { color: 'text.dark' },
            }}
            target="_blank"
          />
        )}
      </List>
    </Stack>
  );
}
