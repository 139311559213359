import { useCallback } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { CountryCode } from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useQueryClient } from 'react-query';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useUserInit } from '../../user/data/useUserInit';
import { useKycMandatoryInfoSubmitMutation } from '../data/kyc.generated';
import { kycInputQueryKey, useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { TransactionFrequencyScene } from '../scenes/Questionnaire/TransactionFrequency';
import type { RadioFormSchema } from '../scenes/Questionnaire/types';
import { TpKycSteps } from '../types';
import { validateKycMandatoryQuestions } from '../utils/kycMandatoryQuestions';

export function TransactionFrequency({ state }: TpStateMachine<StKycRouter>): React.ReactElement {
  const { handleSubmit, isKycInputQueryFetched } = useSubmitKycInput();
  const { invalidate: userInitInvalidate } = useUserInit();

  const { mutateAsync: KycMandatoryInfoSubmitMutation } = useKycMandatoryInfoSubmitMutation();

  const pushAlert = usePushAlert();
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    async (values: RadioFormSchema) => {
      try {
        if (!state.userKycInput) {
          throw new Error('Invalid kyc input state');
        }

        const transactionFrequencyValue =
          'TransactionFrequency' in values
            ? values.TransactionFrequency
            : state.userKycInput.TransactionFrequency;

        const kycMandatoryValues = {
          Activity: state.userKycInput.Activity,
          AnnualDeposit: state.userKycInput.AnnualDeposit,
          ConfirmedPersonalData: state.userKycInput.ConfirmedPersonalData,
          EmploymentStatus: state.userKycInput.EmploymentStatus,
          PrivacyConsent: state.userKycInput.PrivacyConsent,
          SourceOfFunds: state.userKycInput.SourceOfFunds,
          TransactionFrequency: transactionFrequencyValue,
          UsTaxpayer: state.userKycInput.UsTaxpayer,
          VerificationConsent: state.userKycInput.VerificationConsent,
        };
        const isValid = validateKycMandatoryQuestions(kycMandatoryValues);

        if (!isValid) {
          throw new Error('Invalid kyc mandatory questions data');
        }

        await handleSubmit({
          errorKey: 'updateTransactionFrequencyError',
          errorMessage: 'There was an error saving your transaction frequency',
          shouldInvalidate: false,
          values: {
            ...state.userKycInput,
            ...values,
            LastStep: TpKycSteps.TransactionFrequency,
          },
        });

        const { ConfirmedPersonalData, ...kycValues } = kycMandatoryValues;

        await KycMandatoryInfoSubmitMutation({
          Input: {
            ...kycValues,
            CommitHash: webConfigBrowser.commitHash,
            ConfirmedPersonalData: {
              City: ConfirmedPersonalData.City,
              Country: ConfirmedPersonalData.Country as CountryCode,
              DateOfBirth: ConfirmedPersonalData.DateOfBirth,
              FirstName: ConfirmedPersonalData.FirstName,
              LastName: ConfirmedPersonalData.LastName,
              Nationality: ConfirmedPersonalData.Nationality as CountryCode,
              PostalCode: ConfirmedPersonalData.PostalCode,
              State: ConfirmedPersonalData.State,
              Street: ConfirmedPersonalData.Street,
            },
          },
        });

        await userInitInvalidate();
        await queryClient.invalidateQueries(kycInputQueryKey);

        // redirect is being controlled by the kyc router after query invalidations
      } catch (error) {
        logger.error(error);
        pushAlert({
          autoHideDuration: 3000,
          key: 'updateTransactionFrequencyError',
          message: 'There was an error saving your details',
          severity: 'error',
        });
      }
    },
    [
      handleSubmit,
      KycMandatoryInfoSubmitMutation,
      pushAlert,
      userInitInvalidate,
      queryClient,
      state.userKycInput,
    ]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <TransactionFrequencyScene
      backTo={routes.questionnaire.annualDeposit.path}
      transactionFrequencyState={state.userKycInput?.TransactionFrequency}
      onSubmit={onSubmit}
    />
  );
}
