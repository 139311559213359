import type { ReactElement } from 'react';
import { Divider, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { FormatInputField } from '@noah-labs/core-web-ui/src/forms/FormatInputField';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import { RadioGroup } from '@noah-labs/core-web-ui/src/forms/RadioGroup';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';
import { FiatCurrencyCode } from '@noah-labs/noah-schema';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

const StyledFormatInputField = styled(FormatInputField)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  width: '100%',
}));

const StyledInputContainer = styled('li')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  width: '100%',
}));

export type PpTransactionPinThresholdScene = {
  customLimitInputsAreDisabled?: boolean;
  onSubmit: () => void;
};

export function TransactionThresholdScene({
  customLimitInputsAreDisabled,
  onSubmit,
}: PpTransactionPinThresholdScene): ReactElement {
  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton color="primaryBrand" data-qa="save-btn" onClick={onSubmit}>
          Save
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>{routes.transactionPinThreshold.update.title}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa={routes.transactionPinThreshold.update.id}
    >
      <Helmet>
        <title>{routes.transactionPinThreshold.update.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneParagraph>Set the transaction amount limit for PIN protection.</SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <RadioGroup
          label="Set the transaction amount limit for PIN protection."
          name="transactionPinThreshold"
        >
          <List disablePadding>
            <RadioButton
              label="Always Require PIN"
              typographyProps={{
                variant: 'paragraphBodySBold',
              }}
              value="You'll be asked to verify all transactions"
              variant="card"
            />

            <ListItem disablePadding>
              <ElevatedCard>
                <List disablePadding>
                  <RadioButton
                    label="Set your own PIN threshold"
                    labelPlacement="start"
                    typographyProps={{
                      variant: 'paragraphBodySBold',
                    }}
                    value="You decide when we ask for your PIN"
                    variant="default"
                  />

                  <StyledInputContainer>
                    <Divider />

                    <StyledFormatInputField
                      InputFieldAtomProps={{
                        disabled: customLimitInputsAreDisabled,
                        endSlot: FiatCurrencyCode.EUR,
                        label: 'Max daily limit',
                        name: 'dailyLimit',
                        placeholder: 'Amount',
                      }}
                    />
                  </StyledInputContainer>

                  <StyledInputContainer>
                    <StyledFormatInputField
                      InputFieldAtomProps={{
                        disabled: customLimitInputsAreDisabled,
                        endSlot: FiatCurrencyCode.EUR,
                        label: 'Single Transaction Limit',
                        name: 'singleTransactionLimit',
                        placeholder: 'Amount',
                        sx: { paddingTop: (theme) => theme.spacing(0.5) },
                      }}
                    />
                  </StyledInputContainer>
                </List>
              </ElevatedCard>
            </ListItem>
          </List>
        </RadioGroup>
      </SceneMain>
    </AppContainer>
  );
}
