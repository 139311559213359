import { styled } from '@mui/material/styles';
import { LogoWithoutFullstop } from './generated';

const LogoWithoutFullstopStyled = styled(LogoWithoutFullstop)(({ theme }) => ({
  color: theme.palette.brand.light,
  display: 'block',
  height: '19px',
}));

export function AppLogoWithoutFullStop(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <LogoWithoutFullstopStyled {...props} />;
}
