import { Fragment, useEffect } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { CircularSpinner } from '@noah-labs/core-web-ui/src/utility/CircularSpinner';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { KycApprovalStatus } from '@noah-labs/noah-schema';
import { GetInTouchLink } from '../../../components';
import { useUserInit } from '../../user/data/useUserInit';
import type { StKycRouter } from '../Router';
import { CompleteFailedScene } from '../scenes/CompleteFailed';
import { CompletePendingScene } from '../scenes/CompletePending';
import { CompleteSuccessScene } from '../scenes/CompleteSuccess';

export function Complete({ resetState }: Partial<TpStateMachine<StKycRouter>>): React.ReactElement {
  const { data: userData, isLoading: userDataLoading } = useUserInit();
  const approvalStatus = userData?.userProfile.KycVerification.ApprovalStatus;

  /**
   * End of the flow so reset the flow state machine
   */
  useEffect(() => resetState, [resetState]);

  if (userDataLoading) {
    return (
      <LoadingPage Icon={<CircularSpinner />} message="Retrieving your verification result..." />
    );
  }

  switch (approvalStatus) {
    case KycApprovalStatus.Approved:
      return <CompleteSuccessScene />;
    case KycApprovalStatus.Declined:
      return (
        <CompleteFailedScene
          reason={
            <Fragment>
              Unfortunately, you did not succeed in passing document verification. Please{' '}
              <GetInTouchLink /> for futher information.
            </Fragment>
          }
        />
      );
    case KycApprovalStatus.Pending:
    default:
      return <CompletePendingScene />;
  }
}
