import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import type { AwsCaptchaApi, SardineFlows } from '@noah-labs/noah-schema';
import { Route } from 'react-router-dom';
import type { PpAuthRouteData } from './components';
import { AuthError, ForgottenPassword, SignedOut, SignIn, SignUp } from './controllers';
import { VerifyEmail } from './controllers/VerifyEmail';
import { routes } from './routes';
import { SignOut } from './scenes';

export type PpRouter = {
  AuthRouteData: (props: PpAuthRouteData) => React.ReactElement;
  authedPath: string;
  captcha: AwsCaptchaApi | undefined;
  forgottenPasswordReturnTo: string;
  helpButton?: React.ReactNode;
  referralCode: string | null;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
};

export function Router({
  authedPath,
  AuthRouteData,
  captcha,
  forgottenPasswordReturnTo,
  helpButton,
  referralCode,
  updateGuestSardineConfig,
}: PpRouter): React.ReactElement {
  return (
    <Switch404>
      <Route
        exact
        path={routes.signUp.path}
        render={(): React.ReactElement => (
          <AuthRouteData authRedirect={authedPath} route={routes.signUp}>
            <SignUp
              captcha={captcha}
              helpButton={helpButton}
              referralCode={referralCode}
              updateGuestSardineConfig={updateGuestSardineConfig}
            />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.signIn.path}
        render={(): React.ReactElement => (
          <AuthRouteData authRedirect={authedPath} route={routes.signIn}>
            <SignIn captcha={captcha} helpButton={helpButton} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.signOut.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.signOut}>
            <SignOut />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.signedOut.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.signedOut}>
            <SignedOut />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.verify.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.verify}>
            <VerifyEmail helpButton={helpButton} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.recovery.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.recovery}>
            <ForgottenPassword helpButton={helpButton} returnTo={forgottenPasswordReturnTo} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.error.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.error}>
            <AuthError />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
