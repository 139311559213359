import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { Checkbox } from '@noah-labs/core-web-ui/src/forms/Checkbox';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { KycAppHeader } from '../components/KycAppHeader';
import { PROGRESS } from '../data/progress';

const privacyConsentFormId = 'kycPrivacyConsent';

const defaultValues = {
  PrivacyConsent: false,
};

export type TpPrivacyConsentFormValues = {
  PrivacyConsent: boolean;
};

export type PpPrivacyConsentScene = {
  backTo: string;
  isLoading: boolean;
  onSaveAndExit: (values: TpPrivacyConsentFormValues) => void;
  onSubmit: (values: TpPrivacyConsentFormValues) => Promise<void> | void;
  privacyConsentDefaultValue: boolean;
};

export function PrivacyConsentScene({
  backTo,
  isLoading,
  onSaveAndExit,
  onSubmit,
  privacyConsentDefaultValue,
}: PpPrivacyConsentScene): React.ReactElement {
  const methods = useForm<TpPrivacyConsentFormValues>({
    defaultValues,
  });
  const buttonDisabled = !methods.formState.isDirty;
  const isFormSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    const shouldSetConsentValue = privacyConsentDefaultValue && !methods.formState.isSubmitting;
    if (shouldSetConsentValue) {
      methods.setValue('PrivacyConsent', privacyConsentDefaultValue, { shouldDirty: true });
    }
  }, [methods, privacyConsentDefaultValue]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <Stack spacing={2}>
          <FormProvider {...methods}>
            <form id={privacyConsentFormId} onSubmit={methods.handleSubmit(onSubmit)}>
              <Checkbox label="I confirm that I have read and accept." name="PrivacyConsent" />
            </form>
          </FormProvider>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              disabled={buttonDisabled || isLoading}
              form={privacyConsentFormId}
              loading={isFormSubmitting}
              type="submit"
            >
              Continue
            </PrimaryButton>
            <PrimaryButton
              disabled={buttonDisabled || isFormSubmitting}
              loading={isLoading}
              variant="text"
              onClick={(): void => {
                void onSaveAndExit(methods.getValues());
              }}
            >
              Save and finish later
            </PrimaryButton>
          </ButtonGroup>
        </Stack>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.privacy} />}
      dataQa="privacyConsent"
    >
      <Helmet>
        <title>Privacy consent</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Privacy consent</SceneTitleMedium>
        <SceneParagraph>
          To verify your identity without the requirement for you to go to a physical location, we
          will use the camera on your phone to capture images of your face and your ID documents.
        </SceneParagraph>
        <SceneParagraph>
          As such we will be needing your consent to access your camera to receive the images and to
          receive your location data for security purposes. This data will be collected by Incode
          Technologies and may be used to match against the records of a 3rd party ID documents
          service provider.
        </SceneParagraph>
        <SceneParagraph>
          Without your acceptance of this declaration, you will need to verify your identity through
          another way as accepted by Incode Technologies.
        </SceneParagraph>
        <SceneParagraph>
          Please check the box below and continue if you consent for Incode Technologies to perform
          this identity verification check.
        </SceneParagraph>
      </SceneHeader>
    </AppContainer>
  );
}
