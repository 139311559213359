import type { CurrencyCode, Network, OrderLimitPolicy } from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { getCryptoAmountForCurrency } from './currencies';

type TpCanSkipSignature = {
  amount: string;
  currency: CurrencyCode;
  network: Network | null | undefined;
  policies: OrderLimitPolicy[];
};

export function canSkipSignature({
  amount,
  currency,
  network,
  policies,
}: TpCanSkipSignature): boolean {
  /**
   * Checks all relevant policies if the requested amount is within the the Signing limits
   */
  return policies
    .filter((policy) => isUndefinedOrNull(policy.Network) || policy.Network === network)
    .every((policy) => {
      const vl = policy.VolumeLimit;
      if (vl) {
        const signingAmount = getCryptoAmountForCurrency(vl.Signing?.CryptoAmounts, currency);
        const usageAmount = getCryptoAmountForCurrency(vl.Usage?.CryptoAmounts, currency);
        const updatedUsage = safeBN(usageAmount).plus(safeBN(amount));

        // safety check in case we forget to request crypto amounts in the limits query
        if (vl.Signing && !signingAmount) {
          logger.error(`Missing signing crypto amounts for ${currency}`);
          return false;
        }

        return !(signingAmount && updatedUsage.gt(safeBN(signingAmount)));
      }
      return true;
    });
}
