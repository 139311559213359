import { ContentCard } from '../cards/ContentCard';
import { CurrencyAvatar } from '../images';
import type { TpCryptoCurrencyUI } from '../types';

export type PpAvailableBalance = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AvailableBalance({
  cryptoCurrency,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpAvailableBalance): React.ReactElement {
  const title = `${cryptoCurrency.label} Balance`;

  return (
    <ContentCard
      endText={{ primary: PrimaryAmountSlot, secondary: SecondaryAmountSlot }}
      Icon={<CurrencyAvatar Icon={cryptoCurrency.Icon} size={3} />}
      mainText={{ primary: title }}
    />
  );
}
