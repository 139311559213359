import { mockUSD } from '@noah-labs/core-web-ui/src/mock';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { AccountType, AppStages, CurrencyCode } from '@noah-labs/noah-schema';
import { webConfigBrowser } from '../webConfigBrowser';
import { cryptoCurrencyFromCode } from './currencies';

// TODO: add fiatCurrency to defaults
type TpDefaults = {
  accountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrencyUI;
};

export function getDefaults(): TpDefaults {
  switch (webConfigBrowser.appStage) {
    case AppStages.Prod:
      return {
        accountType: AccountType.Current,
        cryptoCurrency: cryptoCurrencyFromCode(CurrencyCode.BTC),
        fiatCurrency: mockUSD,
      };

    default:
      return {
        accountType: AccountType.Current,
        cryptoCurrency: cryptoCurrencyFromCode(CurrencyCode.BTC_TEST),
        fiatCurrency: mockUSD,
      };
  }
}
