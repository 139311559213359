import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Easing } from '../animation/easing';
import { Logo } from '../images';

type PpNoahSpinner = {
  color?: string;
  delayInSeconds?: number;
  isStatic?: boolean;
};

export function NoahSpinner({
  color,
  delayInSeconds = 0,
  isStatic = false,
}: PpNoahSpinner): React.ReactElement {
  const theme = useTheme();
  const [spin, setSpin] = useState(!isStatic && !delayInSeconds);

  useEffect(() => {
    if (isStatic) {
      return undefined;
    }
    const spinTimer = setTimeout(() => {
      setSpin(true);
    }, duration.seconds(delayInSeconds));

    return (): void => {
      clearTimeout(spinTimer);
    };
  }, [delayInSeconds, isStatic]);

  const styles = {
    logo: css`
      display: block;
      color: ${color || theme.palette.brand.light};
      width: 162px;
      height: 30px;
    `,
    logoWrapper: css`
      display: block;
      overflow: hidden;
      width: 162px;
      height: 30px;
    `,
  };

  const logoWidthRef = useSpringRef();
  const logoWidthStyles = useSpring({
    from: { width: delayInSeconds > 0 ? '162px' : '36px' },
    ref: logoWidthRef,
    to: {
      width: '36px',
    },
  });

  const logoSpinnerRef = useSpringRef();
  const logoSpinnerStyles = useSpring({
    config: {
      duration: 2000,
      easing: Easing.InOutQuad,
    },
    from: { transform: ' rotate(0deg)' },
    loop: true,
    ref: logoSpinnerRef,
    to: [{ transform: 'rotate(360deg)' }],
  });

  useChain(spin ? [logoWidthRef, logoSpinnerRef] : []);

  return (
    <animated.div
      css={styles.logoWrapper}
      style={{
        ...logoWidthStyles,
        ...logoSpinnerStyles,
      }}
    >
      <Logo css={styles.logo} />
    </animated.div>
  );
}
