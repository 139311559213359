import type { ReactElement } from 'react';
import { Fragment, useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { Button, IconButton, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getTruncatedAddress } from '@noah-labs/core-web-ui/src/address/getTruncatedAddress';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { useShare } from '@noah-labs/core-web-ui/src/hooks/useShare';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { ChevronRightIcon, InfoIcon, ShareIcon } from '@noah-labs/core-web-ui/src/icons';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import { ReceiveLightningDialog } from '../dialogs/ReceiveLightningDialog';

type PpLightningReceiveCard = {
  LnCopyIcon: ReactElement;
  lightningInvoiceHref: string;
  lnAddress?: string;
  lnCopyHandler: () => void;
};

export function LightningReceiveCard({
  lightningInvoiceHref,
  lnAddress,
  lnCopyHandler,
  LnCopyIcon,
}: PpLightningReceiveCard): ReactElement {
  const theme = useTheme();

  const showLnAddressDialog = useRef<TpDialogToggle>(null);

  const handleLightningDialogToggle = useCallback(() => {
    showLnAddressDialog.current?.toggle();
  }, []);

  const { handleSharing, isSupported } = useShare({ text: lnAddress });

  const styles = {
    card: css`
      padding: ${theme.spacing(2)};
      border-radius: ${theme.borderRadius.lg};
      margin-top: ${theme.spacing(1)};
    `,
  };

  return (
    <Fragment>
      <ElevatedCard css={styles.card} elevation={3}>
        <List disablePadding>
          <ListItem
            disablePadding
            divider
            alignItems="flex-start"
            component={Stack}
            spacing={1}
            sx={{ pb: 2 }}
          >
            <Button
              color="ghost"
              endIcon={<InfoIcon />}
              size="xs"
              variant="contained"
              onClick={handleLightningDialogToggle}
            >
              Lightning
            </Button>
            <StructuredContent
              endText={{
                primary: (
                  <Fragment>
                    <IconButton
                      color="primary"
                      data-qa="copy-ln-address"
                      disabled={!lnAddress}
                      onClick={lnCopyHandler}
                    >
                      {LnCopyIcon}
                    </IconButton>
                    {isSupported && (
                      <IconButton color="primary" onClick={handleSharing}>
                        <ShareIcon />
                      </IconButton>
                    )}
                  </Fragment>
                ),
              }}
              mainText={{
                primary: 'Your lightning address',
                primaryTypographyProps: {
                  color: 'text.light',
                  gutterBottom: true,
                  variant: 'paragraphBodyS',
                },
                secondary: (
                  <TextOrSkeleton>{getTruncatedAddress(lnAddress, undefined, 12)}</TextOrSkeleton>
                ),
                secondaryTypographyProps: {
                  color: 'text.primary',
                  variant: 'paragraphBodySBold',
                },
              }}
            />
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            data-qa="create-ln-invoice"
            href={lightningInvoiceHref}
            sx={{ pt: 2 }}
          >
            <StructuredContent
              endText={{
                primary: <ChevronRightIcon />,
              }}
              mainText={{
                primary: ' More options',
                primaryTypographyProps: {
                  color: 'text.light',
                  gutterBottom: true,
                  variant: 'paragraphBodyS',
                },
                secondary: (
                  <Fragment>
                    <Typography color="text.primary" variant="paragraphBodySBold">
                      Create a lightning invoice
                    </Typography>
                    <Typography color="text.primary" variant="paragraphBodySBold">
                      View your LNURL
                    </Typography>
                  </Fragment>
                ),
              }}
            />
          </ListItem>
        </List>
      </ElevatedCard>
      <ReceiveLightningDialog ref={showLnAddressDialog} />
    </Fragment>
  );
}
