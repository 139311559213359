import { useEffect, useMemo, useReducer } from 'react';
import { isLocalStorageAvailable } from '../modules/auth/utils';

type TpSlideName =
  | 'warpStone'
  | 'discord'
  | 'bitrefill'
  | 'buyBitcoin'
  | 'transactions'
  | 'zeroFees';

type TpLocalStorageState = {
  hiddenSlides: Record<TpSlideName, boolean>;
  isLoaded: boolean;
  lastSeenDepositSlide: string;
  lastSeenPinReminder: string;
};

type TpSetters = {
  setLastSeenDepositSlide: (value: string) => void;
  setLastSeenPinReminder: (value: string) => void;
  setSlideHidden: (slideName: TpSlideName, value: boolean) => void;
};

type TpUserLocalStorage = TpLocalStorageState & TpSetters;

const initialState: TpLocalStorageState = {
  hiddenSlides: {
    bitrefill: false,
    buyBitcoin: false,
    discord: false,
    transactions: false,
    warpStone: false,
    zeroFees: false,
  },
  isLoaded: false,
  lastSeenDepositSlide: '',
  lastSeenPinReminder: '',
};

enum TpType {
  SET_SLIDE_HIDDEN = 'SET_SLIDE_HIDDEN',
  UPDATE_FROM_LOCAL_STORAGE = 'UPDATE_FROM_LOCAL_STORAGE',
  UPDATE_VALUE = 'UPDATE_VALUE',
}

type TpAction =
  | { payload: TpLocalStorageState; type: TpType.UPDATE_FROM_LOCAL_STORAGE }
  | { payload: Partial<TpLocalStorageState>; type: TpType.UPDATE_VALUE }
  | { payload: { slideName: TpSlideName; value: boolean }; type: TpType.SET_SLIDE_HIDDEN };

function reducer(state: TpLocalStorageState, action: TpAction): TpLocalStorageState {
  switch (action.type) {
    case TpType.UPDATE_FROM_LOCAL_STORAGE:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
      };
    case TpType.UPDATE_VALUE:
      return {
        ...state,
        ...action.payload,
      };
    case TpType.SET_SLIDE_HIDDEN:
      return {
        ...state,
        hiddenSlides: {
          ...state.hiddenSlides,
          [action.payload.slideName]: action.payload.value,
        },
      };
    default:
      return state;
  }
}

export function useUserLocalStorage(userId: string | undefined): TpUserLocalStorage {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isAvailable = isLocalStorageAvailable();

  useEffect(() => {
    if (!isAvailable || !userId) {
      return;
    }

    const storedData: TpLocalStorageState = JSON.parse(
      localStorage.getItem(`NOAH-${userId}`) || '{}'
    );

    // If storage item doesn't exist, initiate with the initial state
    if (Object.keys(storedData).length === 0) {
      dispatch({ payload: initialState, type: TpType.UPDATE_FROM_LOCAL_STORAGE });
      return;
    }

    const parsedData: TpLocalStorageState = {} as TpLocalStorageState;

    Object.keys(initialState).forEach((key) => {
      if (!Object.hasOwn(storedData, key)) {
        return;
      }
      // @ts-expect-error we know key belongs to initialState
      parsedData[key] = storedData[key as keyof TpLocalStorageState];
    });

    if (Object.keys(parsedData).length > 0) {
      dispatch({ payload: parsedData, type: TpType.UPDATE_FROM_LOCAL_STORAGE });
    }
  }, [userId, isAvailable]);

  useEffect(() => {
    if (!isAvailable || !userId || !state.isLoaded) {
      return;
    }

    // isLoaded should not be stored
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isLoaded, ...stateWithoutIsLoaded } = state;
    localStorage.setItem(`NOAH-${userId}`, JSON.stringify(stateWithoutIsLoaded));
  }, [state, userId, isAvailable]);

  const setters = useMemo(
    (): TpSetters => ({
      setLastSeenDepositSlide: (value: string): void => {
        dispatch({
          payload: { lastSeenDepositSlide: value },
          type: TpType.UPDATE_VALUE,
        });
      },
      setLastSeenPinReminder: (value: string): void => {
        dispatch({
          payload: { lastSeenPinReminder: value },
          type: TpType.UPDATE_VALUE,
        });
      },
      setSlideHidden: (slideName: TpSlideName, value: boolean): void => {
        dispatch({
          payload: { slideName, value },
          type: TpType.SET_SLIDE_HIDDEN,
        });
      },
    }),
    []
  );

  return {
    ...state,
    ...setters,
  };
}
