import { useCallback } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { CountryCode, UserKyc, UserKycInput } from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useUserKycInputQuery, useUserKycInputUpdateMutation } from '../data/kyc.generated';
import type { TpUserKycInput } from '../types';

export type KycInputValues = Omit<UserKycInput, 'CommitHash' | 'Created' | 'UserID' | 'LastStep'>;

type TpSubmitParams = {
  errorKey: string;
  errorMessage: string;
  shouldInvalidate?: boolean;
  to?: string;
  values: TpUserKycInput;
};

type TpSubmitKycInputResponse = {
  handleSubmit: (payload: TpSubmitParams) => Promise<void>;
  isKycInputQueryFetched: boolean;
  isKycInputQueryFetching: boolean;
  isMutationLoading: boolean;
  kycInputData: UserKyc | undefined | null;
};

export const kycInputQueryKey = ['UserKycInput'];

export function useSubmitKycInput(): TpSubmitKycInputResponse {
  const history = useHistory();
  const queryClient = useQueryClient();
  const pushAlert = usePushAlert();
  const { isLoading: isMutationLoading, mutateAsync } = useUserKycInputUpdateMutation();
  const {
    data: kycInputData,
    isFetched: isKycInputQueryFetched,
    isFetching: isKycInputQueryFetching,
  } = useUserKycInputQuery(undefined, {
    select: (data) => data.userKycInput[0],
  });

  const handleSubmit = useCallback(
    async ({
      errorKey,
      errorMessage,
      shouldInvalidate = true,
      to,
      values,
    }: TpSubmitParams): Promise<void> => {
      let kycInput: KycInputValues = {};
      if (kycInputData) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { CommitHash, Created, UserID, ...newKycInputData } = kycInputData;
        kycInput = newKycInputData;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ConfirmedPersonalData, CountryName, StateName, ...newValues } = values;

      try {
        await mutateAsync({
          Input: {
            ...kycInput,
            ...newValues,
            CommitHash: webConfigBrowser.commitHash,
            ...(ConfirmedPersonalData && {
              ConfirmedPersonalData: {
                City: ConfirmedPersonalData.City,
                Country: ConfirmedPersonalData.Country as CountryCode,
                DateOfBirth: ConfirmedPersonalData.DateOfBirth,
                FirstName: ConfirmedPersonalData.FirstName,
                LastName: ConfirmedPersonalData.LastName,
                Nationality: ConfirmedPersonalData.Nationality as CountryCode,
                PostalCode: ConfirmedPersonalData.PostalCode,
                State: ConfirmedPersonalData.State,
                Street: ConfirmedPersonalData.Street,
              },
            }),
          },
        });

        if (shouldInvalidate) {
          await queryClient.invalidateQueries(kycInputQueryKey);
        }

        if (to) {
          history.push(to);
        }
      } catch (err) {
        // TODO: When built use Kyc Error handler
        logger.error(err);
        pushAlert({
          autoHideDuration: 3000,
          key: errorKey,
          message: errorMessage,
          severity: 'error',
        });
      }
    },
    [history, mutateAsync, pushAlert, kycInputData, queryClient]
  );

  return {
    handleSubmit,
    isKycInputQueryFetched,
    isKycInputQueryFetching,
    isMutationLoading,
    kycInputData,
  };
}
