import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useHistory } from 'react-router-dom';
import { routes as walletRoutes } from '../../wallet/routes';
import { nextBaseSteps } from '../data/steps';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import type { TpPrivacyConsentFormValues } from '../scenes/PrivacyConsent';
import { PrivacyConsentScene } from '../scenes/PrivacyConsent';
import { TpKycSteps } from '../types';

export function PrivacyConsent({
  state,
  updateState,
}: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const { handleSubmit, isKycInputQueryFetched, isMutationLoading } = useSubmitKycInput();

  const handleContinue = useCallback(
    (values: TpPrivacyConsentFormValues) => {
      updateState({
        userKycInput: {
          ...state.userKycInput,
          PrivacyConsent: values.PrivacyConsent,
        },
      });
      history.push(nextBaseSteps.PrivacyConsent);
    },
    [updateState, history, state.userKycInput]
  );

  const handleSave = useCallback(
    async (values: TpPrivacyConsentFormValues) => {
      await handleSubmit({
        errorKey: 'updatePrivacyConsentError',
        errorMessage: 'An error has occured when updating your privacy consent',
        to: walletRoutes.base.path,
        values: {
          ...state.userKycInput,
          LastStep: TpKycSteps.PrivacyConsent,
          PrivacyConsent: values.PrivacyConsent,
        },
      });
    },
    [handleSubmit, state.userKycInput]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  const privacyConsentFormState = state.userKycInput?.PrivacyConsent || false;

  return (
    <PrivacyConsentScene
      backTo={routes.location.path}
      isLoading={isMutationLoading}
      privacyConsentDefaultValue={privacyConsentFormState}
      onSaveAndExit={handleSave}
      onSubmit={handleContinue}
    />
  );
}
