import { useBackHijack } from '../../../hooks';
import { mapPaymentCards, useFiatPaymentMethodsQuery } from '../../wallet/data';
import { routes } from '../routes';
import { PaymentMethodsScene } from '../scenes';

export function PaymentMethods(): React.ReactElement {
  const { backTo, goBack } = useBackHijack(routes.settings.base.path);
  const { data: paymentCards } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentCards,
  });

  return (
    <PaymentMethodsScene
      backTo={backTo}
      goBack={goBack}
      pageTitle={routes.settings.paymentMethods.title as string}
      paymentCards={paymentCards || []}
    />
  );
}
