import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { StatusBadge } from '@noah-labs/core-web-ui/src/badges/StatusBadge';
import { BellCircleIcon, NoahCircleIcon } from '@noah-labs/core-web-ui/src/icons';
import { Avatar, AvatarBadge, AvatarIcon } from '@noah-labs/core-web-ui/src/images';

export function MarketingNotificationIcon(): ReactElement {
  const theme = useTheme();

  return (
    <Avatar>
      <AvatarIcon>
        <NoahCircleIcon />
      </AvatarIcon>

      <AvatarBadge>
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={2}
          Icon={<BellCircleIcon />}
        />
      </AvatarBadge>
    </Avatar>
  );
}
