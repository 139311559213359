import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LockIcon } from '@noah-labs/core-web-ui/src/icons';
import { MastercardLight, VisaLight } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppHeaderData } from '../../../components';
import { cardholderNameValidation } from '../../../utils/cardholderNameValidation';
import type { TpTokenizedCardForm } from '../components';
import { TokenizedCardForm } from '../components';
import type { TpBillingAddressForm } from '../components/forms/BillingAddresses/schema';
import {
  billingAddressSchema,
  emptyBillingAddress,
} from '../components/forms/BillingAddresses/schema';

export type PpEnterPaymentInfoScene = {
  ctaLabel: string;
  defaultAddress: TpBillingAddressForm | undefined | null;
  fullName: string | undefined;
  isLoading?: boolean;
  isVisible: boolean;
  onCardTokenized?: (cardTokenized: FrameCardTokenizedEvent, save: boolean) => Promise<void>;
  pageTitle: string;
  saveToggle: boolean;
  visiblePath: string;
};

const defaults: TpTokenizedCardForm = {
  billingAddress: emptyBillingAddress,
  cardholderName: '',
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  saveCard: true,
};

const addPaymentCardFormId = 'addPaymentCard';
const validCkoValue = 'validCkoValue';
const validCkoregex = new RegExp(validCkoValue, 'gm');

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function validation(fullName?: string) {
  return yup.object({
    billingAddress: billingAddressSchema,
    cardholderName: yup
      .string()
      .test('cardholderName', 'Cardholder name doesn’t match name on profile', (value) =>
        cardholderNameValidation(value, fullName)
      )
      .required('Cardholder name is required'),
    cardNumber: yup.string().matches(validCkoregex, 'Please enter a valid card number').required(),
    cvv: yup.string().matches(validCkoregex, 'Invalid CVV').required(),
    expiryDate: yup.string().matches(validCkoregex, 'Invalid Date').required(),
    saveCard: yup.boolean().required(),
  });
}

export function EnterPaymentInfoScene({
  ctaLabel,
  defaultAddress,
  fullName,
  isLoading = false,
  isVisible,
  onCardTokenized,
  pageTitle,
  saveToggle,
  visiblePath,
}: PpEnterPaymentInfoScene): React.ReactElement {
  const schema = useMemo(() => validation(fullName), [fullName]);
  const billingAddress = defaultAddress || defaults.billingAddress;
  const defaultValues = useMemo(() => ({ ...defaults, billingAddress }), [billingAddress]);
  const methods = useForm<TpTokenizedCardForm>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    resolver: yupResolver(schema),
    values: defaultValues,
  });

  const styles = {
    cardIcon: css`
      width: 48px;
      vertical-align: middle;
    `,
  };

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <Stack spacing={3}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Stack alignSelf="center" direction="row" ml={1} spacing={0.5}>
              <VisaLight css={styles.cardIcon} />
              <MastercardLight css={styles.cardIcon} />
            </Stack>
            <FooterContentText>We accept all major card providers</FooterContentText>
          </Stack>
          <PrimaryButton
            color="primaryBrand"
            data-qa="add-payment-card-button"
            disabled={!methods.formState.isValid}
            endIcon={<LockIcon />}
            form={addPaymentCardFormId}
            loading={methods.formState.isSubmitting || isLoading}
            type="submit"
          >
            {ctaLabel}
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="add-payment-card"
      isVisible={isVisible}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain maxWidth="xs">
        <TokenizedCardForm
          defaultAddress={defaultAddress}
          formId={addPaymentCardFormId}
          methods={methods}
          saveToggle={saveToggle}
          validValue={validCkoValue}
          visiblePath={visiblePath}
          onCardTokenized={onCardTokenized}
        />
      </SceneMain>
    </AppContainer>
  );
}
