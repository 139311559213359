/* eslint-disable react/no-multi-comp */
import { useEffect } from 'react';
import type { IncodeCaptureType } from '@noah-labs/core-services';
import { useCountriesForSelect } from '@noah-labs/core-services';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import type { CountryCode } from '@noah-labs/noah-schema';
import { KycActionRequired, SardineFlows } from '@noah-labs/noah-schema';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { Route, useHistory } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { useSardineFlow } from '../sardine';
import { useUserInit } from '../user/data/useUserInit';
import { routes as userRoutes } from '../user/routes';
import { AccountVerification } from './controllers/AccountVerification';
import { Activity } from './controllers/Activity';
import { AnnualDeposit } from './controllers/AnnualDeposit';
import { BackIdCapture } from './controllers/BackIdCapture';
import { Complete } from './controllers/Complete';
import { Confirm } from './controllers/Confirm';
import { EmploymentStatus } from './controllers/EmploymentStatus';
import { EndDocKyc } from './controllers/EndDocKyc';
import { FaceCapture } from './controllers/FaceCapture';
import { FrontIdCapture } from './controllers/FrontIdCapture';
import { IdSelect } from './controllers/IdSelect';
import { Location } from './controllers/Location';
import { PrivacyConsent } from './controllers/PrivacyConsent';
import { ProcessFace } from './controllers/ProcessFace';
import { ProcessId } from './controllers/ProcessId';
import { ProofOfSourcesOfFunds } from './controllers/ProofOfSourcesOfFunds';
import { Questionnaire } from './controllers/Questionnaire';
import { SourceOfFunds } from './controllers/SourceOfFunds';
import { Sumsub } from './controllers/Sumsub';
import { TransactionFrequency } from './controllers/TransactionFrequency';
import { WorkIndustry } from './controllers/WorkIndustry';
import { useUserKycInputQuery } from './data/kyc.generated';
import { nextBaseSteps } from './data/steps';
import { usStates } from './data/usStates';
import { routes } from './routes';
import type { IdTypeOption, TpUserKycInput } from './types';
import { TpKycSteps } from './types';

const { base, confirm, front, location, privacy, questionnaire, select } = routes;
const {
  activity,
  annualDeposit,
  employmentStatus,
  sourceOfFunds,
  transactionFrequency,
  workIndustry,
} = questionnaire;

export type StKycRouter = {
  captureTypes?: IncodeCaptureType[];
  country?: CountryCode;
  countryName?: string;
  finishAction?: string;
  idType?: IdTypeOption;
  incodeApiBaseUrl?: string;
  liveness?: boolean;
  onboardingStatus?: string;
  sardineToken?: string;
  sessionExpiry?: number;
  userExists?: boolean;
  userKycInput?: Omit<TpUserKycInput, 'LastStep'>;
  verificationId?: string | null;
};

const emptyState = {};

export function InhouseRouter(): React.ReactElement {
  const { data: userData } = useUserInit();
  useSardineFlow({ flow: SardineFlows.id_verification });

  const sm = useStateMachine<StKycRouter>({
    emptyState,
    name: 'KycRouter',
  });
  const history = useHistory();
  const { data: kycInputData } = useUserKycInputQuery(undefined, {
    // we need this, otherwise the query will be canceled when the Router
    // is being mounted after page reload
    enabled: !!userData,
    select: (data) => data.userKycInput[0],
  });
  const { data: allCountriesOptions } = useCountriesForSelect();

  const { state, updateState } = sm;

  const kycAction = userData?.userProfile.KycVerification.ActionRequired;
  const kycApprovalStatus = userData?.userProfile.KycVerification.ApprovalStatus;

  // Invalid redirects
  const locationInvalidRedirect = !state.userKycInput?.VerificationConsent && base.path;
  const privacyInvalidRedirect = !state.userKycInput?.Country && location.path;
  const idSelectInvalidRedirect = !state.userKycInput?.PrivacyConsent && privacy.path;
  const frontIdCaptureInvalidRedirect = !state.idType && select.path;
  const incodeInvalidRedirect = !state.incodeApiBaseUrl && front.path;
  const confirmInvalidRedirect = kycAction !== KycActionRequired.MandatoryDataSubmit && select.path;
  const personalDataInvalidRedirect = !state.userKycInput?.ConfirmedPersonalData && confirm.path;
  const sofInvalidRedirect = !state.userKycInput?.Activity && activity.path;
  const empStatusInvalidRedirect = !state.userKycInput?.SourceOfFunds && sourceOfFunds.path;
  const annualDepInvalidRedirect = !state.userKycInput?.EmploymentStatus && employmentStatus.path;
  const transactionFreqInvalidRedirect = !state.userKycInput?.AnnualDeposit && annualDeposit.path;
  const completeInvalidRedirect = !kycApprovalStatus && transactionFrequency.path;

  // High risk invalid redirects
  const workIndustryInvalidRedirect =
    kycAction !== KycActionRequired.HighRiskDataSubmit && userRoutes.settings.base.path;
  const proofSofInvalidRedirect = !state.userKycInput?.WorkIndustry && workIndustry.path;

  useEffect(() => {
    if (!kycInputData) {
      return;
    }

    // Redirect to high risk flow
    const isHighRiskDataAction = kycAction === KycActionRequired.HighRiskDataSubmit;
    if (isHighRiskDataAction) {
      history.replace(routes.questionnaire.workIndustry.path);
      return;
    }

    const isMandatoryDataAction = kycAction === KycActionRequired.MandatoryDataSubmit;

    const lastStep = kycInputData.LastStep as TpKycSteps;
    const nextStep = nextBaseSteps[lastStep];

    // We need to redirect to this page in case kycMandatoryInfoSubmit fails
    if (isMandatoryDataAction && lastStep === TpKycSteps.TransactionFrequency) {
      history.replace(routes.questionnaire.transactionFrequency.path);
      return;
    }

    if (nextStep) {
      history.replace(nextStep);
    }
  }, [history, kycInputData, kycAction]);

  useEffect(() => {
    if (!kycInputData) {
      return;
    }

    const kycValidData = Object.entries(kycInputData).filter(([_, value]) => value !== null);
    const kycData = Object.fromEntries(kycValidData);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { CommitHash, Created, LastStep, UserID, ...kycInput } = kycData;

    const { Country, State } = kycInput;

    let countryValue: TpSelectOption | undefined;
    let stateValue: TpSelectOption | undefined;

    if (Country) {
      countryValue = allCountriesOptions?.find((value) => compareStrings(value.value, Country));

      if (State) {
        stateValue = usStates.find((value) => compareStrings(value.value, State));
      }
    }

    updateState({
      userKycInput: {
        ...kycInput,
        ...(countryValue && {
          CountryName: countryValue.label,
        }),
        ...(stateValue && {
          StateName: stateValue.label,
        }),
      },
      ...(countryValue && {
        country: countryValue.value as CountryCode,
        countryName: countryValue.label,
      }),
    });
  }, [kycInputData, updateState, allCountriesOptions]);

  return (
    <Switch404>
      <Route
        exact
        path={routes.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.base}>
            <AccountVerification {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.location.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={locationInvalidRedirect} route={routes.location}>
            <Location {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.privacy.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={privacyInvalidRedirect} route={routes.privacy}>
            <PrivacyConsent {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.select.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={idSelectInvalidRedirect} route={routes.select}>
            <IdSelect {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.front.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={frontIdCaptureInvalidRedirect} route={routes.front}>
            <FrontIdCapture {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.back.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={incodeInvalidRedirect} route={routes.back}>
            <BackIdCapture {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.processid.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={incodeInvalidRedirect} route={routes.processid}>
            <ProcessId {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.face.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={incodeInvalidRedirect} route={routes.face}>
            <FaceCapture {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.processface.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={incodeInvalidRedirect} route={routes.processface}>
            <ProcessFace {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.enddockyc.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={incodeInvalidRedirect} route={routes.enddockyc}>
            <EndDocKyc {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={confirmInvalidRedirect} route={routes.confirm}>
            <Confirm {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={personalDataInvalidRedirect}
            route={routes.questionnaire.base}
          >
            <Questionnaire />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.activity.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={personalDataInvalidRedirect}
            route={routes.questionnaire.activity}
          >
            <Activity {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.sourceOfFunds.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={sofInvalidRedirect}
            route={routes.questionnaire.sourceOfFunds}
          >
            <SourceOfFunds {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.employmentStatus.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={empStatusInvalidRedirect}
            route={routes.questionnaire.employmentStatus}
          >
            <EmploymentStatus {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.annualDeposit.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={annualDepInvalidRedirect}
            route={routes.questionnaire.annualDeposit}
          >
            <AnnualDeposit {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.transactionFrequency.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={transactionFreqInvalidRedirect}
            route={routes.questionnaire.transactionFrequency}
          >
            <TransactionFrequency {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData invalidRedirect={completeInvalidRedirect} route={routes.complete}>
            <Complete {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.workIndustry.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={workIndustryInvalidRedirect}
            route={routes.questionnaire.workIndustry}
          >
            <WorkIndustry {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.questionnaire.proofSourcesOfFunds.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={proofSofInvalidRedirect}
            route={routes.questionnaire.proofSourcesOfFunds}
          >
            <ProofOfSourcesOfFunds {...sm} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}

export function SumsubRouter(): React.ReactElement {
  const { data: userData } = useUserInit();
  const kycApprovalStatus = userData?.userProfile.KycVerification.ApprovalStatus;

  return (
    <Switch404>
      <Route
        exact
        path={routes.sumsub.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={!!kycApprovalStatus && routes.sumsub.complete.path}
            route={routes.sumsub.base}
          >
            <Sumsub />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.sumsub.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sumsub.complete}>
            <Complete />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
