import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import { BitcoinIcon, EthereumIcon, PolygonIcon, TronIcon } from '@noah-labs/core-web-ui/src/icons';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import type { TpIcon } from '@noah-labs/core-web-ui/src/types';
import { assetPaths } from '@noah-labs/fe-assets';
import { CurrencyCode, Network } from '@noah-labs/noah-schema';

export type TpCardStyles = {
  borderCss: SerializedStyles;
  cardCss: SerializedStyles;
};

type TpGetReceiveCryptoData = {
  Icon: TpIcon;
} & TpCardStyles;

export function getReceiveCryptoData(
  currency: CurrencyCode,
  network?: Network
): TpGetReceiveCryptoData {
  switch (currency) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      switch (network) {
        case Network.PolygonPos:
        case Network.PolygonTestMumbai:
          return {
            borderCss: css`
              background-image: linear-gradient(
                90deg,
                rgba(237, 212, 243, 1) 0%,
                rgba(140, 84, 231, 1) 100%
              );
            `,
            cardCss: css`
              background-image: linear-gradient(66deg, #a628c4 4.57%, #7f40e4 59.4%);
            `,
            Icon: PolygonIcon,
          };
        case Network.Tron:
        case Network.TronTestShasta:
          return {
            borderCss: css`
              background-image: linear-gradient(
                90deg,
                rgba(255, 6, 10, 0.75) 22%,
                rgba(255, 6, 10, 1) 100%
              );
            `,
            cardCss: css`
              background-color: #ff060a;
            `,
            Icon: TronIcon,
          };
        default:
          return {
            borderCss: css`
              background-image: linear-gradient(
                90deg,
                rgba(226, 237, 250, 1) 0%,
                rgba(62, 131, 207, 1) 100%
              );
            `,
            cardCss: css`
              background-image: linear-gradient(
                66deg,
                #75aeec 4.57%,
                #629ee0 32.51%,
                #2775ca 59.4%
              );
            `,
            Icon: EthereumIcon,
          };
      }
    case CurrencyCode.ETH:
    case CurrencyCode.ETH_TEST_SEPOLIA:
      return {
        borderCss: css`
          background-image: linear-gradient(
            90deg,
            rgba(223, 229, 251, 1) 0%,
            rgba(156, 174, 245, 0.8) 100%
          );
        `,
        cardCss: css`
          background-image: linear-gradient(66deg, #627eea 4.57%, #859dfd 37.88%, #91a5f4 59.4%);
        `,
        Icon: EthereumIcon,
      };
    default:
      return {
        borderCss: css`
          background-image: linear-gradient(
            90deg,
            rgba(243, 208, 207, 0.75) 22%,
            rgba(229, 14, 14, 1) 100%
          );
        `,
        cardCss: css`
          ${cssMixins.bgImageCoverNoRepeat(`${assetPaths.images}/bg-images/gradient-small.png`)};
        `,
        Icon: BitcoinIcon,
      };
  }
}
