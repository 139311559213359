import { useEffect } from 'react';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { Checkbox } from '@noah-labs/core-web-ui/src/forms/Checkbox';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { KycAppHeader } from '../../components/KycAppHeader';
import { PROGRESS } from '../../data/progress';
import { TpActivityValues } from './types';
import type { PpActivity } from './types';

const defaultValues = {
  currencyConversion: false,
  depositOrWithdraw: false,
  purchaseCrypto: false,
  purchaseCryptoRegularly: false,
  transfers: false,
};

const items = [
  {
    label: 'Currency conversion',
    name: TpActivityValues.CurrencyConversion,
  },
  {
    label: 'Purchasing crypto',
    name: TpActivityValues.PurchaseCrypto,
  },
  {
    label: 'Purchasing crypto regularly',
    name: TpActivityValues.PurchaseCryptoRegularly,
  },
  {
    label: 'Transfers between users',
    name: TpActivityValues.Transfers,
  },
  {
    label: 'Depositing or Withdrawing Cryptocurrencies',
    name: TpActivityValues.DepositOrWithdraw,
  },
];

export type TpActivityFormValues = typeof defaultValues;

const activityFormId = 'activityFormId';

export function ActivityScene({
  activityFormState,
  backTo,
  isLoading,
  onSaveAndExit,
  onSubmit,
}: PpActivity): React.ReactElement {
  const methods = useForm<TpActivityFormValues>({
    defaultValues,
  });
  const buttonDisabled = !methods.formState.isDirty;
  const isFormSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    if (!activityFormState) {
      return;
    }
    methods.reset({ ...activityFormState }, { keepDefaultValues: true });
  }, [methods, activityFormState]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled || isLoading}
            form={activityFormId}
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={(): void => {
              onSaveAndExit(methods.getValues());
            }}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.activity} />}
      dataQa="activity"
    >
      <Helmet>
        <title>Activity</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>What will you mainly use NOAH for?</SceneTitleMedium>
        <SceneParagraph>Choose up to 3</SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={activityFormId} onSubmit={methods.handleSubmit(onSubmit)}>
            {items.map((item) => (
              <Checkbox
                key={item.name}
                label={item.label}
                name={item.name}
                primaryTypographyProps={{
                  variant: 'paragraphBodyM',
                }}
              />
            ))}
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
