import type { KycMandatoryInfoSubmitInput } from '@noah-labs/noah-schema';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import type { TpUserKycInput } from '../types';

type KycRequiredInfo = Omit<KycMandatoryInfoSubmitInput, 'CommitHash'>;
export type KycRequiredKeys = Record<keyof KycRequiredInfo, undefined>;

type KycState = Pick<
  TpUserKycInput,
  | 'Activity'
  | 'AnnualDeposit'
  | 'ConfirmedPersonalData'
  | 'EmploymentStatus'
  | 'PrivacyConsent'
  | 'SourceOfFunds'
  | 'TransactionFrequency'
  | 'UsTaxpayer'
  | 'VerificationConsent'
>;

const kycRequiredInfoKeys: KycRequiredKeys = {
  Activity: undefined,
  AnnualDeposit: undefined,
  ConfirmedPersonalData: undefined,
  EmploymentStatus: undefined,
  PrivacyConsent: undefined,
  SourceOfFunds: undefined,
  TransactionFrequency: undefined,
  UsTaxpayer: undefined,
  VerificationConsent: undefined,
};

export function validateKycMandatoryQuestions(kycState: KycState): kycState is Required<KycState> {
  const isValid = Object.keys(kycRequiredInfoKeys).every((key) => {
    const kycData = kycState[key as keyof KycState];

    return kycData !== '' && !isUndefinedOrNull(kycData);
  });

  return isValid;
}
