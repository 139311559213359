import { Stack, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DynamicCopyIcon } from '@noah-labs/core-web-ui/src/address/DynamicCopyIcon';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useClickToCopy } from '@noah-labs/core-web-ui/src/hooks/useClickToCopy';
import { useShare } from '@noah-labs/core-web-ui/src/hooks/useShare';
import { ShareIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import type { Network } from '@noah-labs/noah-schema';
import { AddressCopiedAlert } from '../alerts';
import { QRCodeArea } from '../cards/QRCodeArea';

export type PpOnChainQRCodeDialog = {
  address?: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  network?: Network;
  onClose: () => void;
  open: boolean;
  qrCodeSlot: React.ReactNode;
};

const StyledQRCodeWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grayscale.white,
  borderRadius: theme.borderRadius.md,
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

const dialogMaxWidth = '327px';

const dialogWidthStyles = {
  maxWidth: dialogMaxWidth,
  width: '100%',
};

export function OnChainQRCodeDialog({
  address,
  cryptoCurrency,
  network,
  onClose,
  open,
  qrCodeSlot,
}: PpOnChainQRCodeDialog): React.ReactElement {
  const theme = useTheme();
  const pushAlert = usePushAlert();
  const { handleSharing, isSupported } = useShare({ text: address });

  const { copied, copyHandler } = useClickToCopy({
    onCopy: () => pushAlert(AddressCopiedAlert),
    text: address,
  });

  const backdropProps = {
    backdropFilter: 'blur(10px)',
    background: alpha(theme.palette.grayscale[600], 0.5),
  };
  const paperProps = {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    padding: theme.spacing(3),
  };

  return (
    <LowDialog
      fullScreen
      keepMounted
      backgroundColor="transparent"
      dataQa="qrcode"
      open={open}
      PaperProps={{ sx: paperProps }}
      slotProps={{
        backdrop: {
          sx: backdropProps,
        },
      }}
      onClose={onClose}
    >
      <QRCodeArea cryptoCurrency={cryptoCurrency} network={network} onClose={onClose}>
        <Stack alignItems="flex-start" p={2} spacing={3} sx={dialogWidthStyles}>
          <Stack spacing={0.5}>
            <AppLogo color={theme.palette.grayscale[100]} />
            <Typography variant="paragraphBodyS">{cryptoCurrency.label}</Typography>
          </Stack>
          <StyledQRCodeWrapper>{qrCodeSlot}</StyledQRCodeWrapper>
          <Stack spacing={0.5} width="100%">
            <Typography sx={{ opacity: 0.8 }} variant="paragraphBodyS">
              Your {network} address
            </Typography>
            <Typography noWrap variant="paragraphBodySBold">
              <TextOrSkeleton>{address}</TextOrSkeleton>
            </Typography>
          </Stack>
        </Stack>
      </QRCodeArea>
      <Stack width="100%">
        <ButtonGroup mt={2} sx={dialogWidthStyles}>
          <PrimaryButton
            color="ghost"
            data-qa="copy-button"
            data-qa-address={address}
            startIcon={<DynamicCopyIcon copied={copied} />}
            onClick={copyHandler}
          >
            Copy
          </PrimaryButton>
          {isSupported && (
            <PrimaryButton color="ghost" startIcon={<ShareIcon />} onClick={handleSharing}>
              Share
            </PrimaryButton>
          )}
        </ButtonGroup>
      </Stack>
    </LowDialog>
  );
}
