import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { MinusIcon, PlusIcon, SendIcon, WalletReceiveIcon } from '@noah-labs/core-web-ui/src/icons';
import { ActionsMenu } from '@noah-labs/core-web-ui/src/menus/ActionsMenu';
import { ActionsMenuItem } from '@noah-labs/core-web-ui/src/menus/ActionsMenuItem';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { useLocation } from 'react-router-dom';
import { TpAnalyticsEvent, useAnalytics } from '../../../analytics';
import { defaultAccount, useWalletParams } from '../../data';
import type { TpUseAvailableCurrencyActions } from '../../hooks';
import { routes } from '../../routes';

type TpActionsMenuData = {
  availableCurrencyActions: TpUseAvailableCurrencyActions;
  customRoutes?: {
    receive: string;
    send: string;
  };
};

export function ActionsMenuData({
  availableCurrencyActions,
  customRoutes,
}: TpActionsMenuData): React.ReactElement {
  const { params } = useWalletParams({
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  });

  const { pathname } = useLocation();
  const { updateExitTo } = useNavigation();
  const { track } = useAnalytics();

  return (
    <ActionsMenu>
      <ActionsMenuItem
        dataQa="buy-button"
        disabled={!availableCurrencyActions.Buy}
        // @ts-expect-error false positive
        href={
          availableCurrencyActions.Buy
            ? { pathname: generatePath(routes.buy.base.path, params), state: { from: pathname } }
            : undefined
        }
        Icon={PlusIcon}
        label="Buy"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.BuyClicked);
        }}
      />

      <ActionsMenuItem
        dataQa="sell-button"
        disabled={!availableCurrencyActions.Sell}
        href={
          availableCurrencyActions.Sell ? generatePath(routes.sell.base.path, params) : undefined
        }
        Icon={MinusIcon}
        label="Sell"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.SellClicked);
        }}
      />

      <ActionsMenuItem
        dataQa="receive-button"
        disabled={!availableCurrencyActions.Deposit}
        href={
          availableCurrencyActions.Deposit
            ? generatePath(customRoutes?.receive || routes.selectCrypto.receive.path)
            : undefined
        }
        Icon={WalletReceiveIcon}
        label="Receive"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.ReceiveClicked);
        }}
      />

      <ActionsMenuItem
        dataQa="send-button"
        disabled={!availableCurrencyActions.Withdraw}
        // @ts-expect-error false positive
        href={
          availableCurrencyActions.Withdraw
            ? {
                pathname: generatePath(customRoutes?.send || routes.selectCrypto.send.path),
                state: { from: pathname },
              }
            : undefined
        }
        Icon={SendIcon}
        label="Send"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.SendClicked);
        }}
      />
    </ActionsMenu>
  );
}
