import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import type { TpRoute } from '@noah-labs/core-web-ui/src/types';
import type { FiatCurrencyCode } from '@noah-labs/noah-schema';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { PreferredFiatCurrencyForm } from '../../wallet/components/forms/PreferredFiatCurrencyForm';
import { fiatCurrencyItems } from '../../wallet/data';
import { useOptimisticUserInitOptions } from '../data/useOptimisticUserInitOptions';
import { useUpdateUserFiatPaymentCurrencyCodeSettingMutation } from '../data/user.generated';
import { useUserError } from '../hooks/useUserError';
import { UserSettingOptionsScene } from '../scenes';

type PpPaymentCurrency = {
  route: TpRoute;
};

const formTitle =
  'Selected currency will be used for buying & selling with your credit/debit card.';

export function PaymentCurrencySetting({ route }: PpPaymentCurrency): React.ReactElement {
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } =
    useUpdateUserFiatPaymentCurrencyCodeSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(mutateError);
  const { fiatCurrency, fiatPaymentCurrency } = useUserFiatCurrency();
  const history = useHistory();

  const handleChange = useCallback(
    (value: FiatCurrencyCode) => {
      mutate({
        Input: {
          FiatCurrencyCode: fiatCurrency.code,
          FiatPaymentCurrencyCode: value,
        },
      });
      history.goBack();
    },
    [mutate, fiatCurrency.code, history]
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UserSettingOptionsScene
      hideValues
      ContentSlot={
        <Typography color="text.light" marginTop={3} textAlign="center" variant="paragraphBodyS">
          More currencies coming soon
        </Typography>
      }
      CurrencyForm={PreferredFiatCurrencyForm}
      dataQa={route.id}
      formTitle={formTitle}
      handleChange={handleChange}
      items={fiatCurrencyItems}
      pageTitle={route.title as string}
      value={fiatPaymentCurrency.code}
    />
  );
}
