import type { Country, KycAddress } from '@noah-labs/noah-schema';
import type { TpBillingAddressForm } from '../../wallet/components/forms/BillingAddresses/schema';

export function BillingDefaults(
  homeAddress: KycAddress,
  country: Country
): TpBillingAddressForm | undefined {
  return {
    ...homeAddress,
    City: homeAddress.City || '',
    CountryCode: homeAddress.CountryCode as string,
    CountryName: country.name,
    PostCode: homeAddress.PostCode || '',
    State: homeAddress.State || '',
    Street: homeAddress.Street || '',
    Street2: homeAddress.Street2 || '',
  };
}
