import { useEffect } from 'react';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import { RadioGroup } from '@noah-labs/core-web-ui/src/forms/RadioGroup';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { KycAppHeader } from '../../components/KycAppHeader';
import { PROGRESS } from '../../data/progress';
import { workIndustryItems } from './data/radioForm';
import type { PpRadioForm } from './types';

const defaultValues = {
  WorkIndustry: '',
};

const formId = 'workIndustryFormId';

type TpWorkIndustryFormSchema = typeof defaultValues;

export type PpWorkIndustry = Omit<PpRadioForm, 'backTo'> & {
  isLoading: boolean;
  workIndustryState?: string;
};

export function WorkIndustryScene({
  isLoading,
  onSaveAndExit,
  onSubmit,
  workIndustryState,
}: PpWorkIndustry): React.ReactElement {
  const methods = useForm<TpWorkIndustryFormSchema>({
    defaultValues,
    mode: 'all',
  });
  const buttonDisabled = !methods.formState.isDirty;
  const isFormSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    if (!workIndustryState) {
      return;
    }
    methods.reset({ WorkIndustry: workIndustryState }, { keepDefaultValues: true });
  }, [methods, workIndustryState]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled || isLoading}
            form={formId}
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={(): void => {
              onSaveAndExit(methods.getValues());
            }}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader progress={PROGRESS.answers} />}
      dataQa="workIndustry"
    >
      <Helmet>
        <title>Work industry</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>What industry do you work in?</SceneTitleMedium>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
            <RadioGroup label="Work industry" name="WorkIndustry">
              {workIndustryItems.map((item) => (
                <RadioButton key={item.value} hideValue label={item.label} value={item.value} />
              ))}
            </RadioGroup>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
