import { Alert, List } from '@mui/material';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData, GetInTouchLink } from '../../../components';

export type PpSelectNetwork = {
  NetworksSlot: React.ReactNode;
  subtitle: string;
  title: string;
};

export function SelectNetworkScene({
  NetworksSlot,
  subtitle,
  title,
}: PpSelectNetwork): React.ReactElement {
  return (
    <AppContainer
      AppHeaderSlot={<AppHeaderData backButton exitButton helpButton />}
      dataQa="select-network"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>{title}</SceneTitleMedium>
        <SceneParagraph>{subtitle}</SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1.5 }}>
          {NetworksSlot}
        </List>
        <Alert severity="warning">
          Please ensure you are using the correct network. It is important and using the wrong one
          may result in the loss of funds. Please <GetInTouchLink text="get help" /> if you are
          unsure.
        </Alert>
      </SceneMain>
    </AppContainer>
  );
}
