import { useEffect } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import dayjs from 'dayjs';
import { useSardineKycTokensMutation } from '../data/kyc.generated';
import type { StKycRouter } from '../Router';

export function useIncodeClient({ state, updateState }: TpStateMachine<StKycRouter>): void {
  const { mutateAsync } = useSardineKycTokensMutation();
  const pushAlert = usePushAlert();

  useEffect(() => {
    async function createIncodeClient(): Promise<void> {
      try {
        const data = await mutateAsync({});

        if (!data.sardineKycTokens.AccessToken) {
          throw new Error('No incode access token');
        }

        if (!data.sardineKycTokens.IncodeSdkBaseUrl) {
          throw new Error('No incode api url');
        }

        updateState({
          incodeApiBaseUrl: data.sardineKycTokens.IncodeSdkBaseUrl,
          sardineToken: data.sardineKycTokens.AccessToken,
          sessionExpiry: data.sardineKycTokens.ExpiresIn
            ? parseInt(data.sardineKycTokens.ExpiresIn, 10)
            : undefined,
          verificationId: data.sardineKycTokens.VerificationId,
        });
      } catch (err) {
        // Error will be handled by Kyc error handler hook
        logger.error(err);
        pushAlert({
          autoHideDuration: 3000,
          key: 'kycInitError',
          message: 'An error has occured when trying to initiate your verification session.',
          severity: 'error',
        });
      }
    }

    if (
      state.sessionExpiry &&
      dayjs().isBefore(state.sessionExpiry) &&
      state.incodeApiBaseUrl &&
      state.sardineToken
    ) {
      return;
    }

    void createIncodeClient();
  }, [
    mutateAsync,
    pushAlert,
    state,
    state.incodeApiBaseUrl,
    state.sardineToken,
    state.sessionExpiry,
    updateState,
  ]);
}
