import type { IncodeCaptureType } from '@noah-labs/core-services';

enum AspecRatio {
  CreditCard = 1.59,
  Portrait = 0.85,
  A4 = 0.71,
}

const WIDTH_4K = 3840;

type TpVideoConstraints = {
  aspectRatio: number;
  maxWidth: number;
};

export function getVideoConstraints(captureType: IncodeCaptureType): TpVideoConstraints {
  let aspectRatio = AspecRatio.CreditCard;

  switch (captureType) {
    case 'selfie':
      aspectRatio = AspecRatio.Portrait;
      break;
    case 'document':
      aspectRatio = AspecRatio.A4;
      break;
    default:
      break;
  }

  return {
    aspectRatio,
    maxWidth: WIDTH_4K,
  };
}
