import { AuthGroups, Feature } from '@noah-labs/noah-schema';

const base = '/identity-verification';
const sumSubBase = '/sumsub';
const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  address: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/capture/address`,
    title: 'Capture proof of address',
  },
  back: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/capture/back`,
    title: 'Capture back of your ID',
  },
  base: {
    feature: Feature.Kyc,
    groups,
    path: base,
    title: 'Identity Verification',
  },
  complete: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/complete`,
    title: 'Verifying your ID',
  },
  confirm: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/confirm`,
    title: 'Confirm your data',
  },
  enddockyc: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/process-documents`,
    title: 'Processing documents',
  },
  face: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/capture/face`,
    title: 'Take a selfie',
  },
  front: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/capture/front`,
    title: 'Capture front of your ID',
  },
  location: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/location`,
    title: 'Where do you live?',
  },
  privacy: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/privacy`,
    title: 'Privacy consent',
  },
  processaddress: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/process-address`,
    title: 'Processing your address',
  },
  processface: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/process-face`,
    title: 'Processing your selfie',
  },
  processid: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/process-id`,
    title: 'Processing your ID',
  },
  questionnaire: {
    activity: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/activity`,
      title: 'Activity',
    },
    annualDeposit: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/annual-deposit`,
      title: 'Annual deposit',
    },
    base: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire`,
      title: 'Questionnaire',
    },
    employmentStatus: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/employment-status`,
      title: 'Employment status',
    },
    proofSourcesOfFunds: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/proof-sources-of-funds`,
      title: 'Proof of sources of funds',
    },
    sourceOfFunds: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/source-of-funds`,
      title: 'Source of funds',
    },
    transactionFrequency: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/transaction-frequency`,
      title: 'Transaction frequency',
    },
    workIndustry: {
      feature: Feature.Kyc,
      groups,
      path: `${base}/questionnaire/work-industry`,
      title: 'Work industry',
    },
  },
  retry: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/retry`,
    title: 'Retry steps',
  },
  select: {
    feature: Feature.Kyc,
    groups,
    path: `${base}/id-select`,
    title: 'Select your ID type',
  },
  sumsub: {
    base: {
      feature: Feature.Sumsub,
      groups,
      path: sumSubBase,
      title: 'Verification',
    },
    complete: {
      feature: Feature.Sumsub,
      groups,
      path: `${sumSubBase}/complete`,
      title: 'Verifying your ID',
    },
  },
};
