import { Fragment } from 'react';
import { DialogContentText } from '@mui/material';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { BulletedList } from '@noah-labs/core-web-ui/src/lists/BulletedList';

export function ReceiveUSDCDialogContent(): React.ReactElement {
  return (
    <Fragment>
      <DialogContentText paragraph>
        USDC is a stablecoin aiming to bridge the gap between fiat currencies and cryptocurrencies.
        Each USDC token is backed 1:1 by a US dollar reserve.
      </DialogContentText>
      <DialogContentText paragraph>
        Key features & Stability measures
        <BulletedList>
          <li>Instant transfers: Faster than traditional banking.</li>
          <li>Lower fees: Cost-effective, especially for cross-border transactions.</li>
          <li>Global access: Decentralized and available worldwide.</li>
          <li>Backed by USD: Each token is collateralized with an actual dollar.</li>
          <li>Regular Audits: Audits confirm the backing reserves.</li>
        </BulletedList>
      </DialogContentText>
      <DialogContentText paragraph>
        Note: While USDC aims for stability, it is still a cryptocurrency with associated risks,
        please stay informed.
      </DialogContentText>
      <LearnMoreButton href={webLinks.blog.usdcStablecoin} target="_blank" variant="paragraphBodyM">
        Learn more about USDC.
      </LearnMoreButton>
    </Fragment>
  );
}
