import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useHistory } from 'react-router-dom';
import { routes as walletRoutes } from '../../wallet/routes';
import { nextBaseSteps } from '../data/steps';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import type { TpActivityFormValues } from '../scenes/Questionnaire/Activity';
import { ActivityScene } from '../scenes/Questionnaire/Activity';
import { TpKycSteps } from '../types';

export function Activity({ state, updateState }: TpStateMachine<StKycRouter>): React.ReactElement {
  const { handleSubmit, isKycInputQueryFetched, isMutationLoading } = useSubmitKycInput();
  const history = useHistory();

  const handleContinue = useCallback(
    (activityValues: TpActivityFormValues) => {
      updateState({
        userKycInput: {
          ...state.userKycInput,
          Activity: activityValues,
        },
      });
      history.push(nextBaseSteps.Activity);
    },
    [updateState, history, state.userKycInput]
  );

  const handleSave = useCallback(
    async (activityValues: TpActivityFormValues) => {
      await handleSubmit({
        errorKey: 'updateActivityError',
        errorMessage: 'There was an error saving your activity',
        to: walletRoutes.base.path,
        values: {
          ...state.userKycInput,
          Activity: activityValues,
          LastStep: TpKycSteps.Activity,
        },
      });
    },
    [handleSubmit, state.userKycInput]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <ActivityScene
      activityFormState={state.userKycInput?.Activity}
      backTo={routes.questionnaire.base.path}
      isLoading={isMutationLoading}
      onSaveAndExit={handleSave}
      onSubmit={handleContinue}
    />
  );
}
