import { Fragment } from 'react';
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { SkeletonListItems } from '@noah-labs/core-web-ui/src/lists/SkeletonListItems';
import { TransactionDate } from './atoms/TransactionDate';

export function TransactionPlaceholder(): React.ReactElement {
  const theme = useTheme();
  return (
    <Fragment>
      <TransactionDate />
      <ElevatedCard sx={{ marginBottom: theme.spacing(3), padding: theme.spacing(2) }}>
        <List disablePadding>
          <SkeletonListItems rows={1} />
        </List>
      </ElevatedCard>
    </Fragment>
  );
}
