import React, { Fragment } from 'react';
import { Stack } from '@mui/material';
import { DefaultListItemText } from '@noah-labs/core-web-ui/src/lists/atoms/DefaultListItemText';
import { FiatAmount as FiatAmountComponent } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import { ProgressBar } from '@noah-labs/core-web-ui/src/utility/ProgressBar';
import type { FiatAmount, FiatCurrencyCode } from '@noah-labs/noah-schema';
import { toInt } from '@noah-labs/shared-tools/src/browser/numbers';
import { fiatCurrencyFromString } from '../../../utils';

type PpAccountLimit = {
  fiatCurrency?: FiatCurrencyCode | undefined;
  limit?: FiatAmount | undefined | null;
  secondaryContent?: string;
  title: string;
  unlimited: boolean;
  usage?: FiatAmount | null;
};

export function AccountLimit({
  fiatCurrency,
  limit,
  secondaryContent,
  title,
  unlimited,
  usage,
}: PpAccountLimit): React.ReactElement {
  const hasUsage = usage !== undefined;

  // if usage is null we need to show 0
  const usageAmount = usage === null ? '0' : usage?.Amount;
  const displayProgress = toInt(usageAmount) / toInt(limit?.Amount, 1);
  const fiatCurrencyCode = fiatCurrencyFromString(fiatCurrency);

  const ProgressText = hasUsage ? (
    <Fragment>
      <FiatAmountComponent amount={usageAmount} currency={fiatCurrencyCode} /> of{' '}
      <FiatAmountComponent amount={limit?.Amount} currency={fiatCurrencyCode} />
    </Fragment>
  ) : (
    <FiatAmountComponent amount={limit?.Amount} currency={fiatCurrencyCode} />
  );

  const limitContent = unlimited ? 'Unlimited' : ProgressText;

  return (
    <Stack spacing={2}>
      <DefaultListItemText
        primary={title}
        primaryTypographyProps={{ color: 'text.light' }}
        secondary={secondaryContent || limitContent}
        secondaryTypographyProps={{ color: 'text.light' }}
      />
      {!unlimited && hasUsage && <ProgressBar progress={displayProgress} />}
    </Stack>
  );
}
