import type { SubmitHandler } from 'react-hook-form';
import type { TpActivityFormValues } from './Activity';

export enum TpActivityValues {
  CurrencyConversion = 'currencyConversion',
  DepositOrWithdraw = 'depositOrWithdraw',
  PurchaseCrypto = 'purchaseCrypto',
  PurchaseCryptoRegularly = 'purchaseCryptoRegularly',
  Transfers = 'transfers',
}

export enum TpSourceOfFundsValues {
  Benefits = 'benefits',
  FriendsAndFamily = 'friendsAndFamily',
  Inheritance = 'inheritance',
  PensionInvestment = 'pensionInvestment',
  Property = 'property',
  Salary = 'salary',
}

export enum TpEmploymentStatusValues {
  Employed = 'employed',
  Retired = 'retired',
  SelfEmployed = 'selfEmployed',
  Student = 'student',
  Unemployed = 'unemployed',
}

export enum TpWorkIndustryValues {
  AccountingAndAuditing = 'AccountingAndAuditing',
  BankingAndFinancial = 'BankingAndFinancial',
  GamingAndGambling = 'GamingAndGambling',
  GovernmentAndPublicSector = 'GovernmentAndPublicSector',
  HealthcareAndPharmaceuticals = 'HealthcareAndPharmaceuticals',
  HospitalityAndTourism = 'HospitalityAndTourism',
  Insurance = 'Insurance',
  InvestmentAndSecurities = 'InvestmentAndSecurities',
  LegalServices = 'LegalServices',
  MiningAndEnergy = 'MiningAndEnergy',
  NonProfitAndCharity = 'NonProfitAndCharity',
  Other = 'Other',
  RealEstate = 'RealEstate',
  RetailAndEcommerce = 'RetailAndEcommerce',
  TechnologyAndSoftware = 'TechnologyAndSoftware',
  TransportationAndLogistics = 'TransportationAndLogistics',
}

export enum TpAnnualDepositValues {
  Between5000And50000 = 'between5000And50000',
  Over150000 = 'over150000',
  Over50000 = 'over50000',
  Under5000 = 'under5000',
}

export enum TpTransactionFrequencyValues {
  FewPerMonth = 'fewPerMonth',
  MoreThanOncePerWeek = 'moreThanOncePerWeek',
  OnceAYear = 'onceAYear',
  OnceEveryFewMonths = 'onceEveryFewMonths',
  OncePerWeek = 'oncePerWeek',
}

export enum TpQuestionnaireScreens {
  AnnualDeposit = 'annual-deposit',
  SourceOfFunds = 'source-of-funds',
  TransactionFrequency = 'transaction-frequency',
}

export type RadioFormSchema =
  | {
      AnnualDeposit: string;
    }
  | {
      EmploymentStatus: string;
    }
  | {
      SourceOfFunds: string;
    }
  | {
      TransactionFrequency: string;
    }
  | {
      WorkIndustry: string;
    };

export type PpRadioForm = {
  backTo: string;
  onSaveAndExit: SubmitHandler<RadioFormSchema>;
  onSubmit: SubmitHandler<RadioFormSchema>;
};

export type PpActivity = {
  activityFormState: TpActivityFormValues | undefined;
  backTo: string;
  isLoading: boolean;
  onSaveAndExit: SubmitHandler<TpActivityFormValues>;
  onSubmit: SubmitHandler<TpActivityFormValues>;
};
