import React, { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { AvailableBalanceData } from '../../components';
import { TransactionsLimitsIndicator } from '../../components/limits/TransactionsLimitsIndicator';
import { useWalletParams } from '../../data';
import { useCryptoUnit } from '../../hooks/useCryptoUnit';
import { usePrimaryCurrency } from '../../hooks/usePrimaryCurrency';
import { useWithdrawalAllowance } from '../../hooks/useWithdrawalAllowance';
import { routes } from '../../routes';
import type { TpAmountForm } from '../../scenes';
import { EnterAmountScene } from '../../scenes';
import { getCryptoUnitAmount } from '../../utils/utils';
import { getOnchainWithdrawSchema } from '../../utils/validation';
import type { StWithdrawRouter } from './WithdrawRouter';

export function EnterAmount({
  state,
  updateState,
}: TpStateMachine<StWithdrawRouter>): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { fiatCurrency } = useUserFiatCurrency();
  const { primaryCurrency } = usePrimaryCurrency();
  const allowance = useWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    network: state.payeeData?.network,
  });
  const cryptoUnit = useCryptoUnit();

  const minAmounts =
    allowance?.withdrawMinimumSingleCrypto &&
    getCryptoUnitAmount({
      amount: allowance.withdrawMinimumSingleCrypto.toString(),
      cryptoCurrency,
      cryptoUnit,
    });

  const schema = getOnchainWithdrawSchema({
    allowance,
    fiatCurrency,
    minAmounts,
  });

  const history = useHistory();
  const onSubmit = useCallback(
    (values: TpAmountForm) => {
      updateState({
        ...values,
        cryptoAmount: truncateAmount({
          amount: values.cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: values.fiatAmount,
          decimalPlaces: fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });
      history.push(generatePath(routes.withdraw.confirm.path, params));
    },
    [history, params, cryptoCurrency.decimals, fiatCurrency.decimals, updateState]
  );

  const dailySendLimit = allowance?.accountDayCountLimitCrypto?.toNumber();
  const remainingTransactions = allowance?.withdrawalRemainingTxs?.toNumber();
  const showLimitsIndicator =
    !isUndefinedOrNull(dailySendLimit) && !isUndefinedOrNull(remainingTransactions);

  return (
    <EnterAmountScene
      amountRequired
      backButton
      ContentSlot={
        <AvailableBalanceData
          AccountType={AccountType}
          cryptoCurrency={cryptoCurrency}
          fiatCurrency={fiatCurrency}
        />
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={cryptoUnit}
      ctaButtonDisabled={!schema}
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatCurrency}
      FooterContentSlot={
        showLimitsIndicator ? (
          <TransactionsLimitsIndicator
            dailyLimit={dailySendLimit}
            remainingTransactions={remainingTransactions}
          />
        ) : undefined
      }
      htmlHeadTitle={routes.withdraw.enterAmount.title}
      PageTitleSlot={<AppHeaderTitle>{routes.withdraw.enterAmount.title}</AppHeaderTitle>}
      priceProvider="market"
      primaryCurrency={primaryCurrency}
      yupSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
