import { useEffect } from 'react';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { parseAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { isProd } from '../../../../webConfigBrowser';
import { useWalletParams } from '../../data';
import { routes } from '../../routes';
import { QRDisplayScene } from '../../scenes';
import type { StReceiveRouter } from '../receive';

export function ViewInvoice({
  resetState,
  state,
}: TpStateMachine<StReceiveRouter>): React.ReactElement {
  const { CurrencyCode } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { fiatCurrency } = useUserFiatCurrency();

  /**
   * End of the flow so reset the flow state machine
   */
  useEffect(() => (): void => resetState(), [resetState]);

  const paymentRequestData = parseAddressData(state.paymentRequest, isProd);

  return (
    <QRDisplayScene
      addressData={paymentRequestData}
      cryptoAmount={paymentRequestData.amount}
      cryptoCurrency={cryptoCurrency}
      faqLink={webLinks.faq.transactionTimes}
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatCurrency}
      sceneTitle={routes.receive.lightning.viewInvoice.title}
      transactionId={state.publicID}
    />
  );
}
