import { Container } from '@mui/material';
import { ProgressBar } from '@noah-labs/core-web-ui/src/utility/ProgressBar';
import type { PPAppHeaderData } from '../../../components';
import { AppHeaderData } from '../../../components';

type PpKycAppHeader = PPAppHeaderData & {
  progress: number;
};

export function KycAppHeader({ progress, ...rest }: PpKycAppHeader): React.ReactElement {
  return (
    <Container css={{ padding: 0 }} maxWidth="sm">
      <ProgressBar progress={progress} variant="gradient" />
      <AppHeaderData disableFade exitButton helpButton {...rest} />
    </Container>
  );
}
