import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { AccountType as TpAccountType } from '@noah-labs/noah-schema';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import {
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
  useCalculateFiatFromCrypto,
} from '../data';

type PpUseAvailableBalance = {
  AccountType: TpAccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrency;
};

type TpUseAvailableBalance = {
  cryptoAmount: string | undefined;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrency;
  roundDown: boolean;
};

export function useAvailableBalance({
  AccountType,
  cryptoCurrency,
  fiatCurrency,
}: PpUseAvailableBalance): TpUseAvailableBalance {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) =>
      selectFromFilterWithFallback({
        AccountType,
        CurrencyCode: cryptoCurrency.code,
        data,
      }),
  });
  const cryptoAmount = account?.Balance?.Available;
  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  return {
    cryptoAmount,
    cryptoCurrency,
    fiatAmount: priceData?.fiatAmount,
    fiatCurrency,
    roundDown: true,
  };
}
