import React, { useRef, useState } from 'react';
import type { PpHeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { NotificationCenterButton } from '@noah-labs/core-web-ui/src/buttons/NotificationCenterButton';
import { Feature } from '@noah-labs/noah-schema';
import { useNotifications } from '@novu/notification-center';
import { NotificationsCenterController } from '../../modules/notifications/controllers/NotificationsCenter';
import { useIsFeatureEnabled } from '../../modules/user/hooks/useIsFeatureEnabled';

type PpNotificationCenterButton = Pick<PpHeaderIconButton, 'edge'>;

export function NotificationCenterButtonData({
  edge,
}: PpNotificationCenterButton): React.ReactElement | null {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(false);

  const { unseenCount } = useNotifications();

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    anchorEl.current = e.currentTarget;

    setIsNotificationCenterOpen(true);
  }

  const notificationsEnabled = useIsFeatureEnabled(Feature.Notifications);

  if (notificationsEnabled) {
    return (
      <React.Fragment>
        <NotificationCenterButton
          edge={edge}
          hasUnseenNotifications={unseenCount > 0}
          onClick={(e): void => handleClick(e)}
        />

        <NotificationsCenterController
          ref={anchorEl}
          isNotificationCenterOpen={isNotificationCenterOpen}
          setIsNotificationCenterOpen={setIsNotificationCenterOpen}
        />
      </React.Fragment>
    );
  }

  return null;
}
