import { useTheme } from '@mui/material/styles';
import { moonpayApi } from '@noah-labs/core-services';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { SignMoonpayUrlInputAction } from '@noah-labs/noah-schema';
import { isBrowser } from '@noah-labs/shared-tools/src/browser/utils';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../../components';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { useUserInit } from '../../../user/data/useUserInit';
import { MoonpayBuy } from '../../components';
import { useMoonpaySignatureQuery, useWalletParams } from '../../data';
import { routes } from '../../routes';

type PpBuyWithMoonpay = {
  walletAddress?: string;
};

export function BuyWithMoonpay({ walletAddress = '' }: PpBuyWithMoonpay): React.ReactElement {
  const theme = useTheme();
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const { data } = useUserInit();
  const { fiatCurrency } = useUserFiatCurrency();

  const origin = isBrowser ? window.location.origin : '';

  const redirectUrl = generatePath(routes.buy.moonpayComplete.path, params);

  const moonPayUrlParams = {
    AccountType,
    Action: SignMoonpayUrlInputAction.Buy,
    ApiKey: moonpayApi.getPublishableKey(),
    ColorCode: theme.palette.primary.main,
    CryptoCurrencyCode: CurrencyCode,
    Domain: btoa(origin), // WAF does not like the url param
    FiatCurrencyCode: fiatCurrency.code,
    Url: redirectUrl,
    WalletAddress: walletAddress,
  };

  const { data: signatureData } = useMoonpaySignatureQuery(
    {
      Input: moonPayUrlParams,
    },
    {
      enabled: Boolean(walletAddress && data?.userProfile.Email),
    }
  );

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeader>
          <AppLogo />
        </AppHeader>
      }
      dataQa="buy"
    >
      <Helmet>
        <title>{routes.buy.moonpay.title}</title>
      </Helmet>
      <SceneMain>
        <LowDialog
          open
          PaperProps={{
            // Has a max-height property which allows space for the app header
            sx: { height: 1 },
          }}
        >
          <AppHeaderData disableFade exitButton>
            <AppHeaderTitle>{routes.buy.moonpay.title}</AppHeaderTitle>
          </AppHeaderData>
          <MoonpayBuy queryParams={signatureData?.signMoonpayUrl.Url} />
        </LowDialog>
      </SceneMain>
    </AppContainer>
  );
}
