import { AuthGroups, Feature } from '@noah-labs/noah-schema';
import { createRoute } from '../auth/utils/createRoute';

const base = 'user';
const profileBase = 'profile';
const forgotPinBase = 'forgot-pin';
const newPasswordBase = 'new-password';
const setupPinBase = 'setup-pin';
const updatePinBase = 'update-pin';
const transactionPinThreshold = 'transaction-pin-threshold';
const settingsBase = 'settings';
const pbGroup = new Set([AuthGroups.personal_basic]);
const guestGroup = new Set([AuthGroups.guest]);

export const routes = {
  acceptUpdates: createRoute({
    base,
    groups: pbGroup,
    segments: ['accept-updates'],
    title: "Don't miss out on updates",
  }),
  accounts: createRoute({
    base,
    groups: pbGroup,
    segments: ['accounts'],
    title: 'Linked Accounts',
  }),
  base: createRoute({
    base,
    groups: new Set([AuthGroups.guest]),
    segments: null,
    title: 'User Home',
  }),
  consent: createRoute({
    base,
    groups: pbGroup,
    segments: ['consent'],
    title: 'We value your privacy',
  }),
  forgotPin: {
    createPin: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [forgotPinBase, 'create-pin'],
      title: 'Create PIN',
    }),
    enterPhrase: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [forgotPinBase, 'enter-phrase'],
      title: 'Forgot PIN',
    }),
    success: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [forgotPinBase, 'success'],
      title: 'PIN Updated',
    }),
  },
  newPassword: {
    base: createRoute({
      base,
      groups: pbGroup,
      segments: [newPasswordBase],
      title: 'New password',
    }),
    complete: createRoute({
      base,
      groups: pbGroup,
      segments: [newPasswordBase, 'complete'],
      title: 'Success',
    }),
  },
  pinRevocation: {
    revoke: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: ['revoke'],
      title: 'Revoke',
    }),
    unrevoke: createRoute({
      base,
      groups: guestGroup,
      segments: ['unrevoke'],
      title: 'Unrevoke',
    }),
  },
  profile: {
    base: createRoute({
      base,
      groups: pbGroup,
      segments: [profileBase],
      title: 'Personal Details',
    }),
    fullName: createRoute({
      base,
      groups: pbGroup,
      segments: [profileBase, 'full-name'],
      title: 'Name',
    }),
  },
  settings: {
    accountLimits: createRoute({
      base,
      groups: pbGroup,
      segments: ['limits'],
      title: 'Account Limits',
    }),
    base: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase],
      title: 'Settings',
    }),
    bitcoinUnit: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'bitcoin-unit'],
      title: 'Bitcoin unit',
    }),
    consent: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'consent'],
      title: 'We value your privacy',
    }),
    displayCurrency: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'display-currency'],
      title: 'Display currency',
    }),
    editPaymentMethod: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'payment-methods', ':id'],
      title: 'Edit Payment Method',
    }),
    newPaymentMethod: createRoute({
      base,
      // TODO (CS): ideally would be CheckoutBuyRoutes | CheckoutSellRoutes | ManualOffRampRoutes - will update route/feature logic in another PR
      // feature: Feature.CheckoutBuy,
      groups: pbGroup,
      segments: [settingsBase, 'new-payment-method'],
      title: 'New Payment Method',
    }),
    paymentCurrency: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'payment-currency'],
      title: 'Payment currency',
    }),
    paymentMethods: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'payment-methods'],
      title: 'Saved Payment Methods',
    }),
    primaryCurrency: createRoute({
      base,
      groups: pbGroup,
      segments: [settingsBase, 'primary-currency'],
      title: 'Primary currency',
    }),
    securityPin: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [settingsBase, 'security-pin'],
      title: 'Security PIN',
    }),
  },
  setupPin: {
    createPin: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [setupPinBase, 'create-pin'],
      title: 'Choose your transaction PIN',
    }),
    secretPhrase: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [setupPinBase, 'secret-phrase'],
      title: 'PIN Recovery',
    }),
    success: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [setupPinBase, 'success'],
      title: 'PIN Created',
    }),
  },
  transactionPinThreshold: {
    update: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [transactionPinThreshold],
      title: 'Transaction PIN Threshold',
    }),
  },
  updatePin: {
    createPin: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [updatePinBase, 'create-pin'],
      title: 'Update PIN',
    }),
    enterCurrentPin: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [updatePinBase, 'enter-current-pin'],
      title: 'Update PIN',
    }),
    success: createRoute({
      base,
      feature: Feature.Pin,
      groups: pbGroup,
      segments: [updatePinBase, 'success'],
      title: 'PIN Updated',
    }),
  },
  username: createRoute({
    base,
    groups: pbGroup,
    segments: ['username'],
    title: 'Choose a username',
  }),
};
