import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

const textSx = {
  ':before': {
    content: '"• "',
  },
};

export type PpSupportedCryptoCurrenciesDialog = {
  initialOpen?: boolean;
};

export const SupportedCryptoCurrenciesDialog = forwardRef<
  TpDialogToggle,
  PpSupportedCryptoCurrenciesDialog
>(({ initialOpen }, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ initialOpen, ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Supported cryptocurrencies and networks</DialogTitle>
      <DialogContent>
        <Stack sx={{ pb: 2, pl: 1, pt: 1 }}>
          <Typography sx={textSx} variant="paragraphBodyMBold">
            Bitcoin:{' '}
            <Typography component="span" variant="paragraphBodyM">
              We support transactions on both the On-chain and Lightning networks.
            </Typography>
          </Typography>
          <Typography sx={textSx} variant="paragraphBodyMBold">
            USDC:{' '}
            <Typography component="span" variant="paragraphBodyM">
              Transactions are supported on the Ethereum network.
            </Typography>
          </Typography>
        </Stack>
        <Typography variant="paragraphBodyM">
          Still have questions?{' '}
          <LearnMoreButton href={webLinks.blog.supportedNetworks} target="_blank">
            Learn more about our supported addresses & networks.
          </LearnMoreButton>
        </Typography>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

SupportedCryptoCurrenciesDialog.displayName = SupportedCryptoCurrenciesDialog.name;
