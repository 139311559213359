import React, { useCallback, useMemo } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/noah-schema';
import { useHistory, useLocation } from 'react-router-dom';
import type * as yup from 'yup';
import { useCountryFromCode } from '../../../user/data/useCountryFromCode';
import { useUserInit } from '../../../user/data/useUserInit';
import { BillingDefaults } from '../../../user/hooks/useBillingDefaults';
import { DefaultBankFormFields, SortCodeFormFields, UsBankFormFields } from '../../data/forms';
import { routes } from '../../routes';
import type { TpAccountDetailsFormValues } from '../../scenes';
import { EnterAccountDetailsScene } from '../../scenes';
import { TpPaymentMethod } from '../../types';
import { getAccountHolderName, getBankingSystem } from '../../utils/utils';
import {
  defaultBankFormSchema,
  SortCodeFormSchema,
  USBankFormSchema,
} from '../../utils/validation';
import type { StSellRouter } from './SellRouter';

type PpEnterAccountDetails = TpStateMachine<StSellRouter>;

export function EnterAccountDetails({
  state,
  updateState,
}: PpEnterAccountDetails): React.ReactElement {
  const { data: userData } = useUserInit();
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const history = useHistory();
  const { state: locationState } = useLocation<{ successTo: string }>();

  const defaultAddress = useMemo(() => {
    if (!userData?.userProfile.HomeAddress || !country) {
      return undefined;
    }

    return BillingDefaults(userData.userProfile.HomeAddress, country);
  }, [country, userData?.userProfile.HomeAddress]);

  const onSubmit = useCallback(
    (accountDetails: TpAccountDetailsFormValues) => {
      updateState({
        fiatCurrencyCode: accountDetails.accountCurrency as FiatCurrencyCode,
        payoutMethod: TpPaymentMethod.BankTransfer,
        savePayoutBank: accountDetails.saveAccount,
        selectedPayoutBank: {
          accountCurrency: accountDetails.accountCurrency as FiatCurrencyCode,
          accountHolderAddress: {
            City: accountDetails.billingAddress.City,
            CountryCode: accountDetails.billingAddress.CountryCode as CountryCode,
            PostCode: accountDetails.billingAddress.PostCode,
            State: accountDetails.billingAddress.State,
            Street: accountDetails.billingAddress.Street,
            Street2: accountDetails.billingAddress.Street2,
          },
          accountHolderName: getAccountHolderName(accountDetails.accountHolderName),
          accountNumber: accountDetails.accountNumber,
          bankCode: accountDetails.bankCode,
          system: getBankingSystem(state.selectedCountry),
          type: 'new',
        },
      });

      history.push(locationState.successTo);
    },
    [locationState.successTo, state.selectedCountry, updateState, history]
  );

  let schema: yup.ObjectSchema<TpAccountDetailsFormValues>;
  let FormSlot: React.ReactElement;

  switch (state.selectedCountry) {
    case 'US': {
      schema = USBankFormSchema;
      FormSlot = UsBankFormFields;

      break;
    }

    case 'GB': {
      schema = SortCodeFormSchema;
      FormSlot = SortCodeFormFields;
      break;
    }

    default:
      schema = defaultBankFormSchema;
      FormSlot = DefaultBankFormFields;
      break;
  }

  return (
    <EnterAccountDetailsScene
      defaultAddress={defaultAddress}
      FormSlot={FormSlot}
      pageTitle={routes.sell.enterAccountDetails.title}
      schema={schema}
      selectedCountry={state.selectedCountry}
      onSubmit={onSubmit}
    />
  );
}
