import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';
import { CloseIcon } from '../icons';

export function CloseButton(props: IconButtonProps): React.ReactElement {
  // eslint-disable-next-line react/destructuring-assignment
  const sx = props.size === 'small' ? { fontSize: '1rem' } : {};

  return (
    <IconButton sx={{ alignSelf: 'flex-end', mr: 0.5, mt: 0.5 }} {...props}>
      <CloseIcon sx={sx} />
    </IconButton>
  );
}
