import type { KycUploadedFile } from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import axios from 'axios';

export async function uploadImage(
  presignedUrl: string,
  imageBase64: string,
  mimeType: string
): Promise<boolean> {
  try {
    const buffer = Buffer.from(imageBase64, 'base64');

    await axios.put(presignedUrl, buffer, {
      headers: {
        'Content-Type': mimeType,
      },
    });

    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
}

export async function uploadFiles(
  presignedUrls: KycUploadedFile[],
  files: File[]
): Promise<boolean> {
  try {
    const uploads = presignedUrls.map(async (fileUrl) => {
      const file = files.find(({ name }) => name === fileUrl.FileName);

      if (!file) {
        throw new Error('PresignedUrl not found');
      }

      const fileBuffer = await file.arrayBuffer();
      const buffer = Buffer.from(fileBuffer);

      return axios.put(fileUrl.PresignedUrl, buffer, {
        headers: {
          'Content-Type': file.type,
        },
      });
    });

    await Promise.all(uploads);

    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
}
