import { useCallback } from 'react';
import type { TpAddIdRequest, TpIncodeError } from '@noah-labs/core-services';
import { addBackId } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory } from 'react-router-dom';
import { getErrorMessageByCode, getIdCaptureErrorPrompt } from '../data/incodeErrorMessages';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { IdCaptureScene } from '../scenes/IdCapture';
import type { TpCaptureResponse } from '../types';

export function BackIdCapture({ state }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();

  const captureType =
    state.captureTypes && state.captureTypes.length > 1 ? state.captureTypes[1] : 'back';

  const handleCapture = useCallback(
    async ({ base64Image }: TpAddIdRequest): Promise<TpCaptureResponse> => {
      try {
        const { incodeApiBaseUrl, sardineToken } = state;

        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        const res = await addBackId(
          { baseURL: incodeApiBaseUrl, token: sardineToken },
          { base64Image }
        );

        const errorPrompt = getIdCaptureErrorPrompt(res.data);
        if (errorPrompt) {
          return {
            error: errorPrompt,
            success: false,
          };
        }

        if (res.data.typeOfId !== state.idType) {
          return {
            error: 'ID type does not match the one selected.',
            success: false,
          };
        }

        return { success: true };
      } catch (err) {
        logger.error(err);

        const incodeError = err as TpIncodeError;

        if (!incodeError.response?.data.status) {
          pushAlert({
            autoHideDuration: 3000,
            key: 'incodeBackCaptureError',
            message: 'An error has occured when capturing the back your ID',
            preventDuplicate: true,
            severity: 'error',
          });
          return {
            success: false,
          };
        }

        if (incodeError.response.data.error) {
          return {
            error: incodeError.response.data.error,
            success: false,
          };
        }

        const message = getErrorMessageByCode(incodeError.response.data.status);

        return { error: message, success: false };
      }
    },
    [pushAlert, state]
  );

  const handleSuccess = useCallback(() => {
    history.push(routes.processid.path);
  }, [history]);

  return (
    <IdCaptureScene
      backTo={routes.front.path}
      captureType={captureType}
      description="Hold up your ID and take a picture. Your entire ID must be in the frame."
      processingLabel="Reading ID image..."
      reviewDescription="Check that you can see the entire ID in the frame below and continue."
      reviewTitle="Review your photo"
      {...state}
      title="Back of your ID"
      onCapture={handleCapture}
      onSuccess={handleSuccess}
    />
  );
}
