import { useCallback } from 'react';
import type { TpAddIdRequest, TpIncodeError } from '@noah-labs/core-services';
import { addFrontId } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory } from 'react-router-dom';
import { getErrorMessageByCode, getIdCaptureErrorPrompt } from '../data/incodeErrorMessages';
import { useIncodeClient } from '../hooks/useIncodeClient';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { IdCaptureScene } from '../scenes/IdCapture';
import type { TpCaptureResponse } from '../types';

export function FrontIdCapture(props: TpStateMachine<StKycRouter>): React.ReactElement {
  const { state } = props;

  const captureType =
    state.captureTypes && state.captureTypes.length > 0 ? state.captureTypes[0] : 'front';

  const isPassport = captureType === 'passport';

  const history = useHistory();
  const pushAlert = usePushAlert();

  // Only gets initialised here as it's the first screen that needs it
  // and the Sardine token has a 10min expiry in production
  useIncodeClient(props);

  // TODO: refactor this to use the same handler as BackIdCapture and FaceCapture
  const handleCapture = useCallback(
    async ({ base64Image }: TpAddIdRequest): Promise<TpCaptureResponse> => {
      try {
        const { incodeApiBaseUrl, sardineToken } = state;

        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        const res = await addFrontId(
          { baseURL: incodeApiBaseUrl, token: sardineToken },
          { base64Image },
          isPassport
        );

        const errorPrompt = getIdCaptureErrorPrompt(res.data);
        if (errorPrompt) {
          return {
            error: errorPrompt,
            success: false,
          };
        }

        if (res.data.typeOfId !== state.idType) {
          return {
            error: 'ID type does not match the one selected.',
            success: false,
          };
        }

        return { success: true };
      } catch (err) {
        logger.error(err);

        const incodeError = err as TpIncodeError;

        if (!incodeError.response?.data.status) {
          pushAlert({
            autoHideDuration: 3000,
            key: 'incodeFrontCaptureError',
            message: 'An error has occured when capturing your ID',
            preventDuplicate: true,
            severity: 'error',
          });
          return {
            success: false,
          };
        }

        if (incodeError.response.data.error) {
          return {
            error: incodeError.response.data.error,
            success: false,
          };
        }

        const message = getErrorMessageByCode(incodeError.response.data.status);

        return { error: message, success: false };
      }
    },
    [isPassport, pushAlert, state]
  );

  const handleSuccess = useCallback(() => {
    const { captureTypes } = state;

    if (captureTypes?.includes('back')) {
      history.push(routes.back.path);

      return;
    }

    history.push(routes.processid.path);
  }, [history, state]);

  return (
    <IdCaptureScene
      backTo={routes.select.path}
      captureType={captureType}
      description="Hold up your ID and take a picture. Your entire ID must be in the frame."
      processingLabel="Reading ID image..."
      reviewDescription="Check that you can see the entire ID in the frame below and continue."
      reviewTitle={isPassport ? 'Review passport photo' : 'Review your photo'}
      {...state}
      title={isPassport ? 'Your passport' : 'Front of your ID'}
      onCapture={handleCapture}
      onSuccess={handleSuccess}
    />
  );
}
