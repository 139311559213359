import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/noah-schema';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { fiatCurrencyFromCode, getDefaults, getReferralUrl } from '../../../utils';
import { routes as walletRoutes } from '../../wallet/routes';
import { useKycApprovalStatusData } from '../data/useKycApprovalStatusData';
import { useUserInit } from '../data/useUserInit';
import { useKycRoute } from '../hooks/useKycRoute';
import { useUserInitials } from '../hooks/useUserInitial';
import { routes } from '../routes';
import { SettingsScene } from '../scenes';

const { cryptoCurrency } = getDefaults();

export function Settings(): React.ReactElement {
  const { data: userData, lnAddress: userLnAddress } = useUserInit();
  const { fiatCurrency, fiatPaymentCurrency } = useUserFiatCurrency();
  const usernameDisplay = userData?.userProfile.UsernameDisplay;

  const userInitials = useUserInitials();
  const kycRoute = useKycRoute();

  const kycApprovalStatus = userData?.userProfile.KycVerification.ApprovalStatus;
  const kycApprovalStatusData = useKycApprovalStatusData(kycApprovalStatus);

  const paymentCurrency = fiatCurrencyFromCode(fiatPaymentCurrency.code);
  const displayCurrency = fiatCurrencyFromCode(fiatCurrency.code);

  let btcUnit;
  switch (userData?.userProfile.DisplayUnit) {
    case CurrencyUnit.SATS:
      btcUnit = 'Satoshi';
      break;

    case undefined:
      break;

    default:
      btcUnit = cryptoCurrency.label;
  }

  let primaryCurrency;
  switch (userData?.userProfile.PrimaryCurrency) {
    case CurrencyDisplayType.Crypto:
      primaryCurrency = cryptoCurrency.label;
      break;

    case undefined:
      break;

    default:
      primaryCurrency = fiatCurrency.label;
  }

  return (
    <SettingsScene
      avatarAlt={userData?.userProfile.Email}
      avatarContent={userInitials}
      backTo={walletRoutes.base.path}
      btcUnit={btcUnit}
      displayCurrency={displayCurrency}
      email={userData?.userProfile.Email}
      kycApprovalStatusData={kycApprovalStatusData}
      kycRoute={kycRoute}
      lnAddress={userLnAddress}
      pageTitle={routes.settings.base.title as string}
      paymentCurrency={paymentCurrency}
      primaryCurrency={primaryCurrency}
      referralUrl={getReferralUrl(userData?.userProfile.UsernameDisplay)}
      usernameDisplay={usernameDisplay}
    />
  );
}
