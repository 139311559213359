import { useCallback, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import { RadioGroup } from '@noah-labs/core-web-ui/src/forms/RadioGroup';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UserCountrySelectField } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { KycAppHeader } from '../components/KycAppHeader';
import { PROGRESS } from '../data/progress';
import { IdTypeOption } from '../types';

const idSelectFormId = 'kycIdSelect';

const idTypes = [
  {
    label: 'Driving License',
    value: IdTypeOption.DriversLicense,
  },
  {
    label: 'Passport',
    value: IdTypeOption.Passport,
  },
  {
    label: 'Identity Card',
    value: IdTypeOption.IdentificationCard,
  },
];

type TpIdSelectFormValues = {
  Country: string;
  // This is only used for display purposes
  CountryName: string;
  IdType: IdTypeOption;
};

const idSelectFormSchema = yup.object({
  Country: yup.string().required(),
  CountryName: yup.string().required(),
  IdType: yup.string().oneOf(Object.values(IdTypeOption)).required(),
});

export type PpIdSelectScene = {
  backTo: string;
  isLoading: boolean;
  onSaveAndExit: () => void;
  onSubmit: (values: TpIdSelectFormValues) => Promise<void> | void;
  stateFormValues?: TpIdSelectFormValues;
};

export function IdSelectScene({
  backTo,
  isLoading,
  onSaveAndExit,
  onSubmit,
  stateFormValues,
}: PpIdSelectScene): React.ReactElement {
  const showNotAllowed = useRef<TpDialogToggle>(null);
  const defaultValues = useMemo(() => stateFormValues, [stateFormValues]);
  const { open, toggle } = useToggleDialog({ ref: showNotAllowed });
  const methods = useForm<TpIdSelectFormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(idSelectFormSchema),
  });

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      const isCountryNotAvailable =
        webConfigBrowser.regions.ProhibitedCountries[value?.value || ''];

      if (isCountryNotAvailable) {
        showNotAllowed.current?.toggle();
        return;
      }

      methods.setValue('Country', value?.value || '', { shouldValidate: true });
      methods.setValue('CountryName', value?.label || '');
    },
    [methods]
  );

  const buttonDisabled = !methods.formState.isValid;
  const isFormSubmitting = methods.formState.isSubmitting;

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled || isLoading}
            form={idSelectFormId}
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={onSaveAndExit}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.select} />}
      dataQa="idSelect"
    >
      <Helmet>
        <title>Choose an ID type</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Choose an ID type</SceneTitleMedium>
        <SceneParagraph>
          Financial regulations require us to verify your ID. This helps prevent someone else from
          creating a NOAH account in your name.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <FormProvider {...methods}>
          <form id={idSelectFormId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <UserCountrySelectField
                // no country filtering here because all countries are potentially allowed for ids - and we show an alert if not supported
                handleChange={handleCountryFormValues}
                label="Issuing country/region"
                name="CountryName"
              />
              <RadioGroup label="ID type" name="IdType">
                {idTypes.map(({ label, value }) => (
                  <RadioButton
                    key={value}
                    hideValue
                    label={label}
                    value={value.toString()}
                    variant="card"
                  />
                ))}
              </RadioGroup>
            </Stack>
          </form>
        </FormProvider>
        <LowDialog data-qa="not-allowed-dialog" open={open} onClose={toggle}>
          <DialogTitle>Sorry, we haven’t launched in your country yet</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We are working hard to enable support for your country.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton color="primaryBrand" onClick={toggle}>
              Dismiss
            </PrimaryButton>
          </DialogActions>
        </LowDialog>
      </SceneMain>
    </AppContainer>
  );
}
