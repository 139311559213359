import { useMemo } from 'react';
import { AccountCardList } from '@noah-labs/core-web-ui/src/lists/AccountCardList';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Feature } from '@noah-labs/noah-schema';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { useFeature } from '../../../user/hooks/useFeature';
import { AccountCardData } from '../../components';
import { sortFilterWithFallback, useAccountsQueryPoll } from '../../data';
import { routes } from '../../routes';
import { SelectCryptoScene } from '../../scenes';
import { getFeatureCurrencies } from '../../utils/getFeatureCurrencies';
import { getReceiveCryptoRoute } from '../../utils/getReceiveCryptoRoute';

export function Receive(): React.ReactElement {
  const depositFF = useFeature(Feature.Deposit);
  const { data: accountsData, isFetched } = useAccountsQueryPoll();

  const sortedAccounts = useMemo(
    () =>
      sortFilterWithFallback(accountsData?.accounts.items, {
        currencies: getFeatureCurrencies([depositFF]),
        isFeatureEnabled: !!depositFF?.Enabled,
      }),
    [accountsData?.accounts.items, depositFF]
  );
  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(sortedAccounts) &&
        sortedAccounts.map((account) => {
          const { networks } = cryptoCurrencyFromCode(account.CurrencyCode);
          const receivePath = getReceiveCryptoRoute(networks);
          return (
            <AccountCardData
              key={account.ID}
              buttonSx={{ ':hover': { backgroundColor: 'inherit' } }}
              href={generatePath(receivePath, {
                accountType: account.AccountType,
                currencyCode: account.CurrencyCode,
              })}
              {...account}
            />
          );
        })}
    </AccountCardList>
  );

  return (
    <SelectCryptoScene
      AccountsSlot={AccountsSlot}
      backTo={routes.base.path}
      subTitle="Choose the currency you want to receive to your NOAH wallet."
      title={routes.selectCrypto.receive.title}
    />
  );
}
