import {
  TpAnnualDepositValues,
  TpEmploymentStatusValues,
  TpSourceOfFundsValues,
  TpTransactionFrequencyValues,
  TpWorkIndustryValues,
} from '../types';

export const sourceOfFundsItems = [
  {
    label: 'Salary',
    value: TpSourceOfFundsValues.Salary,
  },
  {
    label: 'Pension investment',
    value: TpSourceOfFundsValues.PensionInvestment,
  },
  {
    label: 'Property',
    value: TpSourceOfFundsValues.Property,
  },
  {
    label: 'Friends & family',
    value: TpSourceOfFundsValues.FriendsAndFamily,
  },
  {
    label: 'Inheritance',
    value: TpSourceOfFundsValues.Inheritance,
  },
  {
    label: 'Benefits (e.g. Universal Credit)',
    value: TpSourceOfFundsValues.Benefits,
  },
];

export const employmentStatusItems = [
  {
    label: 'Employed',
    value: TpEmploymentStatusValues.Employed,
  },
  {
    label: 'Unemployed',
    value: TpEmploymentStatusValues.Unemployed,
  },
  {
    label: 'Retired',
    value: TpEmploymentStatusValues.Retired,
  },
  {
    label: 'Student',
    value: TpEmploymentStatusValues.Student,
  },
  {
    label: 'Self-employed',
    value: TpEmploymentStatusValues.SelfEmployed,
  },
];

export const workIndustryItems = [
  {
    label: 'Banking and financial services',
    value: TpWorkIndustryValues.BankingAndFinancial,
  },
  {
    label: 'Investment and securities',
    value: TpWorkIndustryValues.InvestmentAndSecurities,
  },
  {
    label: 'Insurance',
    value: TpWorkIndustryValues.Insurance,
  },
  {
    label: 'Real estate',
    value: TpWorkIndustryValues.RealEstate,
  },
  {
    label: 'Legal services',
    value: TpWorkIndustryValues.LegalServices,
  },
  {
    label: 'Accounting and auditing',
    value: TpWorkIndustryValues.AccountingAndAuditing,
  },
  {
    label: 'Gaming and gambling',
    value: TpWorkIndustryValues.GamingAndGambling,
  },
  {
    label: 'Mining and energy',
    value: TpWorkIndustryValues.MiningAndEnergy,
  },
  {
    label: 'Retail and e-commerce',
    value: TpWorkIndustryValues.RetailAndEcommerce,
  },
  {
    label: 'Healthcare and pharmaceuticals',
    value: TpWorkIndustryValues.HealthcareAndPharmaceuticals,
  },
  {
    label: 'Government and public sector',
    value: TpWorkIndustryValues.GovernmentAndPublicSector,
  },
  {
    label: 'Non-profit and charity',
    value: TpWorkIndustryValues.NonProfitAndCharity,
  },
  {
    label: 'Technology and software development',
    value: TpWorkIndustryValues.TechnologyAndSoftware,
  },
  {
    label: 'Transportation and logistics',
    value: TpWorkIndustryValues.TransportationAndLogistics,
  },
  {
    label: 'Hospitality and tourism',
    value: TpWorkIndustryValues.HospitalityAndTourism,
  },
  {
    label: 'Other',
    value: TpWorkIndustryValues.Other,
  },
];

export const annualDepositItems = [
  {
    label: 'Under £5000',
    value: TpAnnualDepositValues.Under5000,
  },
  {
    label: '£5000 - £50,000',
    value: TpAnnualDepositValues.Between5000And50000,
  },
  {
    label: 'Over £50,000',
    value: TpAnnualDepositValues.Over50000,
  },
  {
    label: 'Over £150,000',
    value: TpAnnualDepositValues.Over150000,
  },
];

export const transactionFrequencyItems = [
  {
    label: 'More than once per week',
    value: TpTransactionFrequencyValues.MoreThanOncePerWeek,
  },
  {
    label: 'Once per week',
    value: TpTransactionFrequencyValues.OncePerWeek,
  },
  {
    label: '1-2 times per month',
    value: TpTransactionFrequencyValues.FewPerMonth,
  },
  {
    label: 'Once every few months',
    value: TpTransactionFrequencyValues.OnceEveryFewMonths,
  },
  {
    label: 'Once a year',
    value: TpTransactionFrequencyValues.OnceAYear,
  },
];
