import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { routes } from '../../routes';
import { Receive } from './Receive';
import { Send } from './Send';

export function SelectCryptoRouter(): React.ReactElement {
  return (
    <Switch404>
      <Route
        exact
        path={routes.selectCrypto.receive.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.selectCrypto.receive}>
            <Receive />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.selectCrypto.send.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.selectCrypto.send}>
            <Send />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
