import { Fragment } from 'react';
import { DialogContentText } from '@mui/material';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { BulletedList } from '@noah-labs/core-web-ui/src/lists/BulletedList';

export function ReceiveUSDTDialogContent(): React.ReactElement {
  return (
    <Fragment>
      <DialogContentText paragraph>
        Tether (USDT) is a stablecoin designed to offer a stable medium of exchange in the volatile
        crypto market. Each USDT token aims to maintain a 1:1 value ratio with the US dollar.
      </DialogContentText>
      <DialogContentText>
        Key Features & Stability Measures
        <BulletedList>
          <li>
            Global Access: Decentralized and available worldwide, offering global reach for
            financial activities.
          </li>
          <li>Instant Transfers: Faster than traditional banking.</li>
          <li>Low-Cost Remittances: Offers affordable, fast cross-border payments.</li>
          <li>Global Access: Decentralized and available worldwide.</li>
          <li>Backed by USD: Each token is collateralized with an actual dollar.</li>
          <li>
            Quarterly Reserve Reports: Efforts to improve transparency through periodic reporting.
          </li>
        </BulletedList>
      </DialogContentText>
      <DialogContentText paragraph>
        Note: While Ether aims to offer a range of functionalities within the Ethereum ecosystem, it
        is still a cryptocurrency with associated risks.{' '}
      </DialogContentText>
      <LearnMoreButton href={webLinks.blog.usdtStablecoin} target="_blank" variant="paragraphBodyM">
        Learn more about USDT.
      </LearnMoreButton>
    </Fragment>
  );
}
