import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

export const ReceiveLightningDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });
  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>The Lightning Network and Your NOAH Lightning Address</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          The{' '}
          <LearnMoreButton href={webLinks.blog.lightningNetwork} target="_blank">
            Lightning Network
          </LearnMoreButton>{' '}
          is like the fast lane for Bitcoin transactions. It lets you send and receive Bitcoin super
          quickly without getting bogged down by high fees. Think of it as bypassing the usual
          traffic on the Bitcoin network for a speedier route.
        </DialogContentText>
        <DialogContentText paragraph>
          Your{' '}
          <LearnMoreButton href={webLinks.blog.lightningAddress} target="_blank">
            NOAH Lightning Address
          </LearnMoreButton>
          , on the other hand, works like an email address for Bitcoin. You can instantly send or
          receive Bitcoin over the Lightning Network just by using this address.
        </DialogContentText>
        <DialogContentText>
          With the NOAH app, you can zap Bitcoin to your friends instantly and without any fees. So
          invite your friends to use NOAH and experience the fast lane for Bitcoin transactions!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got It
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});
ReceiveLightningDialog.displayName = ReceiveLightningDialog.name;
