import type { QuestTaskRequirement } from '@noah-labs/noah-schema';

export enum TpRewardsTabs {
  Badges = 'badges',
  Quests = 'quests',
}

export type TpRewardsTabProps = {
  header: string;
  panel: React.ReactElement;
  value: string;
};

export type TpQuestTaskCopy = {
  description: string;
  reward: string;
  title: string;
};

export type TpTaskAction = {
  cta: string;
  externalLink?: boolean;
  href?: string;
  requirement: QuestTaskRequirement;
};

export type TpQuestListItem = {
  activeTaskDescription: string | undefined;
  badgeId?: string;
  completed: boolean;
  ends?: string;
  href?: string;
  np: number;
  progress: number;
  questId: string;
  requiredProgress: number;
  title: string;
};

export type TpBadgeGridItem = {
  badgeId: string;
  earned: boolean;
  questId?: string;
  stars: number;
};

export enum TpSocialSharePlatform {
  Twitter,
  Facebook,
  Whatsapp,
  Telegram,
}
