import type { TpAddFaceResponse, TpAddIdResponse } from '@noah-labs/core-services';

export type TpIdCaptureErrorCode =
  | 'UNKNOWN_DOCUMENT_TYPE'
  | 'WRONG_DOCUMENT_SIDE'
  | 'WRONG_ONE_SIDED_DOCUMENT'
  | 'DOCUMENT_NOT_READABLE'
  | 'UNABLE_TO_ALIGN_DOCUMENT'
  | 'ID_TYPE_UNACCEPTABLE'
  | 'UNEXPECTED_ERROR_OCCURRED';

export function getIdCaptureFailReasonErrorMessage(errorCode: TpIdCaptureErrorCode): string {
  switch (errorCode) {
    case 'UNKNOWN_DOCUMENT_TYPE':
      return 'ID not recognised.';
    case 'WRONG_DOCUMENT_SIDE':
      return 'Wrong side of the ID, turn around.';
    case 'DOCUMENT_NOT_READABLE':
      return 'ID not readable.';
    case 'UNABLE_TO_ALIGN_DOCUMENT':
      return 'Unable to detect ID.';
    case 'ID_TYPE_UNACCEPTABLE':
      return 'ID type not accepted.';
    case 'UNEXPECTED_ERROR_OCCURRED':
    default:
      return 'An unexpected error occured.';
  }
}

export function getIdCaptureErrorPrompt(response: TpAddIdResponse): string | undefined {
  if (response.failReason) {
    const failMessage = getIdCaptureFailReasonErrorMessage(
      response.failReason as TpIdCaptureErrorCode
    );

    return `${failMessage} Try again.`;
  }

  if (!response.readability) {
    return 'Unable to read ID. Hold ID/camera still and retake photo.';
  }

  if (!response.classification) {
    return 'ID not detected. Bring the ID into view and retake photo.';
  }

  if (response.glare && response.glare < 50) {
    return 'Too much glare. Move to a better location and retake photo.';
  }

  if (response.sharpness && response.sharpness < 50) {
    return 'ID not clear. Please retake photo.';
  }

  if (response.horizontalResolution && response.horizontalResolution < 200) {
    return 'Photo resolution too low. Move ID closer and retake photo.';
  }

  return undefined;
}

export function getSelfieErrorPrompt(response: TpAddFaceResponse): string | undefined {
  if (response.confidence && response.confidence > 0) {
    return 'Liveness failed, please retake photo.';
  }

  if (response.hasLenses) {
    return 'Lenses detected, please retake photo.';
  }

  if (response.hasFaceMask) {
    return 'Face mask detected, please retake photo.';
  }

  if (!response.isBright) {
    return 'Move to better lighting conditions and retake photo.';
  }

  return undefined;
}

export function getErrorMessageByCode(errorCode: number): string {
  switch (errorCode) {
    case 1001:
    case 1003:
    case 3002:
    case 3004:
      return 'Failed to crop your selfie';
    case 3005:
      return 'Move closer';
    case 3006:
      return 'Too blurry';
    case 3007:
      return 'Too dark';
    case 3008:
      return 'Photo size too small';
    case 3009:
      return 'Wrong photo orientation';
    case 3010:
      return 'Blurry photo';
    case 4004:
      return 'Could not find user';
    case 4019:
      return 'Face not found';
    case 5003:
      return 'Image size is too small';
    case 4010:
      return 'More than one face detected';
    default:
      return 'An unexpected error occured';
  }
}
