import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

export const CountryNotAllowedDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Sorry, we haven’t launched in your country yet.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We are working hard to enable support for your country.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Dismiss
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

CountryNotAllowedDialog.displayName = CountryNotAllowedDialog.name;
