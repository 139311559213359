import { useEffect } from 'react';
import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type {
  TpBitcoinAddressData,
  TpEthAddressData,
  TpUsdcAddressData,
  TpUsdtAddressData,
} from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { SardineFlows } from '@noah-labs/noah-schema';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { useSardineFlow } from '../../../sardine';
import { useWalletParams } from '../../data';
import { useAddress, useSendAmounts } from '../../hooks';
import { routes } from '../../routes';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAmount } from './EnterAmount';
import { SelectNetwork } from './SelectNetwork';

export type TpPayeeData =
  | TpBitcoinAddressData
  | TpUsdcAddressData
  | TpUsdtAddressData
  | TpEthAddressData;

export type StWithdrawRouter = {
  cryptoAmount: string;
  fetchedAt: string;
  fiatAmount: string;
  payeeData: TpPayeeData | undefined;
  price: string;
  withdrawOrderId: string;
};

const emptyState = {
  cryptoAmount: '',
  fetchedAt: '',
  fiatAmount: '',
  payeeData: undefined,
  price: '',
  withdrawOrderId: '',
};

export function WithdrawRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CheckoutBuy });
  const { data, setData } = useAddress();
  /**
   * Remove destination address from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      payeeData: data as TpPayeeData,
    };
  }

  const sm = useStateMachine<StWithdrawRouter>({
    emptyState,
    initialState,
    name: 'WithdrawRouter',
  });

  const { params } = useWalletParams();

  const { fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: sm.state.cryptoAmount,
    fiatAmount: sm.state.fiatAmount,
  });

  const { updateState } = sm;
  useEffect(() => {
    updateState({
      fetchedAt,
      fiatAmount,
      price,
    });
  }, [fetchedAt, fiatAmount, price, updateState]);

  // if user hasn't scanned or enter an address, we have to go back to the scanner
  const addressInvalidRedirect = !sm.state.payeeData?.address;

  // if any data is missing, we can't show confirm or complete screens
  const confirmInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;
  const completeInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;

  return (
    <Switch404>
      <Route
        exact
        path={routes.withdraw.network.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={
              addressInvalidRedirect && generatePath(routes.address.text.path, params)
            }
            route={routes.withdraw.network}
          >
            <SelectNetwork {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.withdraw.enterAmount.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={
              addressInvalidRedirect && generatePath(routes.address.text.path, params)
            }
            route={routes.withdraw.enterAmount}
          >
            <EnterAmount {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.withdraw.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={
              confirmInvalid && generatePath(routes.withdraw.enterAmount.path, params)
            }
            route={routes.withdraw.confirm}
          >
            <Confirm {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.withdraw.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={
              completeInvalid && generatePath(routes.withdraw.enterAmount.path, params)
            }
            route={routes.withdraw.complete}
          >
            <Complete {...sm} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
