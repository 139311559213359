import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { QuestStatus } from '@noah-labs/noah-schema';
import { getActiveTask, getQuestTotalReward } from '../data';
import { routes } from '../routes';
import type { TpQuestListItem } from '../types';
import { questNames } from './questCopy';
import { getQuestTaskCopy } from './questTaskCopy';

export function getNp(questStatus: QuestStatus): number {
  switch (questStatus.Quest.QuestID) {
    case 'FReferral20230203':
      return 1000;
    case 'FValentineReferral2023':
      return 2000;
    case 'FReferral20230927':
      return 100;
    default:
      return getQuestTotalReward(questStatus.Quest);
  }
}

function getHref(questStatus: QuestStatus, isCompleted: boolean): string | undefined {
  if (isCompleted && questStatus.Quest.BadgeTypeID) {
    return generatePath(routes.badge.path, { id: questStatus.Quest.BadgeTypeID });
  }

  // if quest is completed and without badge, no redirect
  if (isCompleted) {
    return undefined;
  }

  if (questStatus.Quest.QuestID === 'FValentineReferral2023') {
    return generatePath(routes.inviteFriends.path);
  }

  return generatePath(routes.quest.path, { id: questStatus.Quest.QuestID });
}

export function getQuestListItem(qs: QuestStatus): TpQuestListItem | null {
  const activeTask = getActiveTask(qs);

  if (!questNames[qs.Quest.QuestID]) {
    return null;
  }

  try {
    return {
      activeTaskDescription: activeTask && getQuestTaskCopy(activeTask.Task)?.description,
      badgeId: qs.Quest.BadgeTypeID || undefined,
      completed: !activeTask,
      ends: qs.Quest.Ends || undefined,
      href: getHref(qs, !activeTask),
      np: getNp(qs),
      progress: qs.Progress,
      questId: qs.Quest.QuestID,
      requiredProgress: qs.RequiredProgress,
      title: questNames[qs.Quest.QuestID],
    };
  } catch {
    return null;
  }
}
