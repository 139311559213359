import { useCallback } from 'react';
import type { IncodeCaptureType } from '@noah-labs/core-services';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { CountryCode } from '@noah-labs/noah-schema';
import { useHistory } from 'react-router-dom';
import { routes as walletRoutes } from '../../wallet/routes';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { IdSelectScene } from '../scenes/IdSelect';
import { IdTypeOption, TpKycSteps } from '../types';

function getCaptureTypes(idType: IdTypeOption): IncodeCaptureType[] | undefined {
  if (idType === IdTypeOption.None) {
    return undefined;
  }

  switch (idType) {
    case IdTypeOption.IdentificationCard:
    case IdTypeOption.DriversLicense:
      return ['front', 'back', 'selfie', 'document'];
    case IdTypeOption.Passport:
    default:
      return ['passport', 'selfie', 'document'];
  }
}

export function IdSelect({ state, updateState }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const { handleSubmit, isKycInputQueryFetched, isMutationLoading } = useSubmitKycInput();

  const { country, countryName, idType } = state;

  const handleSave = useCallback(async () => {
    await handleSubmit({
      errorKey: 'updateIdSelectError',
      errorMessage: 'There was an error saving your information',
      to: walletRoutes.base.path,
      values: {
        ...state.userKycInput,
        LastStep: TpKycSteps.IdSelect,
      },
    });
  }, [handleSubmit, state.userKycInput]);

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <IdSelectScene
      backTo={routes.privacy.path}
      isLoading={isMutationLoading}
      stateFormValues={{
        Country: country || '',
        CountryName: countryName || '',
        IdType: idType || IdTypeOption.None,
      }}
      onSaveAndExit={handleSave}
      onSubmit={(values): void => {
        const captureTypes = getCaptureTypes(values.IdType);

        updateState({
          captureTypes,
          country: values.Country as CountryCode,
          countryName: values.CountryName,
          idType: values.IdType,
        });

        let nextRoute = routes.base.path;

        if (captureTypes && captureTypes.length >= 1) {
          nextRoute = routes.front.path;
        }

        history.push(nextRoute);
      }}
    />
  );
}
