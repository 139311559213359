import { useEffect } from 'react';
import { processFace } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { CircularSpinner } from '@noah-labs/core-web-ui/src/utility/CircularSpinner';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory } from 'react-router-dom';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';

export function ProcessFace({ state }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();

  const { incodeApiBaseUrl, sardineToken } = state;

  useEffect(() => {
    async function process(): Promise<void> {
      try {
        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        await processFace({ baseURL: incodeApiBaseUrl, token: sardineToken });

        // skips poa and goes directly to end of kyc flow
        history.push(routes.enddockyc.path);
      } catch (err) {
        logger.error(err);
        pushAlert({
          autoHideDuration: 3000,
          key: 'incodeProcessFaceError',
          message: 'An error has occured when processing your selfie',
          severity: 'error',
        });
      }
    }

    void process();
  }, [history, pushAlert, incodeApiBaseUrl, sardineToken]);

  return <LoadingPage Icon={<CircularSpinner />} message="Your selfie is being processed..." />;
}
