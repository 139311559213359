import { AccountType, CurrencyCode, FiatCurrencyCode, Network } from '@noah-labs/noah-schema';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import {
  BitcoinIcon,
  EthereumIcon,
  LightningCircleIcon,
  PolygonIcon,
  UsdcoinIcon,
  UsdtIcon,
} from './icons';
import type {
  TpBitcoinAddressData,
  TpLightningAddressData,
  TpUsdcAddressData,
  TpUsdtAddressData,
} from './tools/parseAddressData';
import type { TpCryptoCurrencyUI } from './types';

// only used in stories
export const mockBTC: TpCryptoCurrencyUI = {
  code: CurrencyCode.BTC,
  decimals: 8,
  Icon: BitcoinIcon,
  label: 'Bitcoin',
  NetworkIcons: { OffNetwork: LightningCircleIcon },
  networks: [Network.OffNetwork],
  symbol: '₿',
};
export const mockUSDC: TpCryptoCurrencyUI = {
  code: CurrencyCode.USDC,
  decimals: 6,
  Icon: UsdcoinIcon,
  label: 'USDC',
  NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon },
  networks: [Network.Ethereum, Network.PolygonPos],
  symbol: '$',
};
export const mockUSDT: TpCryptoCurrencyUI = {
  code: CurrencyCode.USDT,
  decimals: 6,
  Icon: UsdtIcon,
  label: 'USDC',
  NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon },
  networks: [Network.Ethereum, Network.PolygonPos],
  symbol: '$',
};
export const mockUSD = {
  code: FiatCurrencyCode.USD,
  decimals: 2,
  Icon: null,
  label: 'United States Dollar',
  symbol: '$',
};

export const mockAccounts = [
  {
    AccountType: AccountType.Current,
    Balance: { Available: '123' },
    CurrencyCode: CurrencyCode.BTC,
    ID: '1',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '891' },
    CurrencyCode: CurrencyCode.ETH,
    ID: '2',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '245' },
    CurrencyCode: CurrencyCode.USDC,
    ID: '3',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '90' },
    CurrencyCode: CurrencyCode.USDT,
    ID: '4',
  },
];

export const mockBitrefillLNInvoiceData: TpLightningAddressData = {
  address:
    'lnbc58080n1p3u00zmpp5nrcp3rgxt6vlal6m5jsu2ggqlu085d036uefj6kkyz8gt57jggxsdz2gf5hgun9ve5kcmpq8yekydpcvdjngttyxccnxtf58qcrxtfevc6rvttyxucxxvny8ymxyetyvycqzx7xqrp9ssp5gertnzw9pxx8y3q6f20agt8kzaqvzspwl5ha2u9hf9q0flneha6q9qyyssquzzhsdsukreckj8qutjqgyt5s89mespqav9u96526s54h6tcghv8uekvty5upmgqk58ttgmcgjugl0px4j3mjujusseqrmwxkpp5cysq5emg8f',
  addressType: 'lnbc',
  amount: '0.01',
  currencyCode: CurrencyCode.BTC,
  description: 'Bitrefill 93b48ce4-d613-4803-9f46-d70c2d96beda',
  expiryTime: Date.now() + duration.minutes(20),
  Icon: LightningCircleIcon,
  network: Network.Lightning,
  processingTime: duration.seconds(30),
};

export const mockLNAddressData: TpLightningAddressData = {
  address:
    'lntb67180n1p3gav6khp58yjmdan79s6qqdhdzgynm4zwqd5d7xmw5fk98klysy043l2ahrqspp5huncfk5aamvpmhw2uhwssjtv6gp60c6m40ead3hl98g26n0n2awqxqrrsssp5m6kmam774klwlh4dhmhaatd7al02m0h0m6kmam774klwlh4dhmhs9qypqsqcqpfcey7c28qvzlx0mhuf6wfal3257qxtwpw7vupqw9lmaufz29zhf7xavzh3xs0yjlvc3yqs0fgwvfek3h8kptsp0rvkw24y5f3k32x2qqpu9h3yz',
  addressType: 'lnbc',
  amount: '0.02',
  currencyCode: CurrencyCode.BTC,
  description: 'Starbucks matcha latté',
  expiryTime: Date.now() + duration.minutes(10),
  Icon: LightningCircleIcon,
  network: Network.Lightning,
  processingTime: duration.seconds(30),
};

export const mockBTCAddressData: TpBitcoinAddressData = {
  address: '3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5',
  currencyCode: CurrencyCode.BTC,
  Icon: BitcoinIcon,
  network: Network.Bitcoin,
  processingTime: duration.minutes(30),
};

export const mockUSDCAddressData: TpUsdcAddressData = {
  address: '0xE3Cc8Aff345527b48Db9f398eA2a7FBbC90CB37b',
  currencyCode: CurrencyCode.USDC,
  Icon: UsdcoinIcon,
  network: Network.Ethereum,
  processingTime: duration.minutes(30),
};

export const mockUSDTAddressData: TpUsdtAddressData = {
  address: '0x7C4E1A846Ac3fF85d2c13c85eF9f67b9AF272221',
  currencyCode: CurrencyCode.USDT,
  Icon: UsdtIcon,
  network: Network.Ethereum,
  processingTime: duration.minutes(30),
};

export const mockUserProfile = {
  Email: 'test@test.com',
  HomeAddress: {
    Country: 'GB',
  },
  PersonalName: {
    FirstName: 'John',
    LastName: 'Doe',
    MiddleName: 'James',
  },
  PhoneNumber: '+23423984575',
  UsernameDisplay: 'DisplayUsername',
};
