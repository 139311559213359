import { useMemo } from 'react';
import { AccountCardList } from '@noah-labs/core-web-ui/src/lists/AccountCardList';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Feature } from '@noah-labs/noah-schema';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { useFeature } from '../../../user/hooks/useFeature';
import { AccountCardData } from '../../components';
import { sortFilterWithFallback, useAccountsQueryPoll } from '../../data';
import { routes } from '../../routes';
import { SelectCryptoScene } from '../../scenes';
import { getFeatureCurrencies } from '../../utils/getFeatureCurrencies';

export function Send(): React.ReactElement {
  const withdrawFF = useFeature(Feature.Withdraw);
  const { data: accountsData, isFetched } = useAccountsQueryPoll();

  const sortedAccounts = useMemo(
    () =>
      sortFilterWithFallback(accountsData?.accounts.items, {
        currencies: getFeatureCurrencies([withdrawFF]),
        isFeatureEnabled: !!withdrawFF?.Enabled,
      }),
    [accountsData?.accounts.items, withdrawFF]
  );

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(sortedAccounts) &&
        sortedAccounts.map((account) => (
          <AccountCardData
            key={account.ID}
            buttonSx={{ ':hover': { backgroundColor: 'inherit' } }}
            href={generatePath(routes.address.text.path, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            {...account}
          />
        ))}
    </AccountCardList>
  );

  return (
    <SelectCryptoScene
      AccountsSlot={AccountsSlot}
      backTo={routes.base.path}
      subTitle="Choose the currency you want to send to another crypto wallet or exchange."
      title={routes.selectCrypto.send.title}
    />
  );
}
