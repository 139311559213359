import { useEffect } from 'react';
import { useDialogs } from '@noah-labs/core-web-ui/src/dialogs/useDialogs';
import dayjs from 'dayjs';
import { useRouteMatch } from 'react-router-dom';
import { useSigning } from '../modules/signing/context';
import { CreatePinReminderDialog } from '../modules/signing/dialogs/CreatePinReminder';
import { useUserInit } from '../modules/user/data/useUserInit';
import { routes as userRoutes } from '../modules/user/routes';
import { routes as walletRoutes } from '../modules/wallet';
import { useUserLocalStorage } from './useUserLocalStorage';

const REMINDER_DAYS = 1;
const DIALOG_KEY = 'create-pin-reminder';

export function useCreatePinReminder(): void {
  const userInit = useUserInit();
  const {
    isLoaded: isStorageLoaded,
    lastSeenPinReminder,
    setLastSeenPinReminder,
  } = useUserLocalStorage(userInit.data?.userProfile.UserID);
  const { closeDialog, pushDialog } = useDialogs();
  const { sdStatus } = useSigning();
  const isWalletPath = useRouteMatch(walletRoutes.base.path);
  const shouldCheckLastSeen =
    isWalletPath?.isExact && sdStatus?.pinSetupRequired && isStorageLoaded;

  useEffect(() => {
    if (!shouldCheckLastSeen) {
      return;
    }

    const lpr = parseInt(lastSeenPinReminder, 10);
    if (!Number.isNaN(lpr) && dayjs().diff(dayjs.unix(lpr), 'day') < REMINDER_DAYS) {
      return;
    }

    setLastSeenPinReminder(dayjs().unix().toString());
    pushDialog({
      Content: (
        <CreatePinReminderDialog
          setupTo={userRoutes.setupPin.createPin.path}
          onSkip={(): void => closeDialog(DIALOG_KEY)}
        />
      ),
      key: DIALOG_KEY,
      low: true,
    });
  }, [closeDialog, lastSeenPinReminder, pushDialog, setLastSeenPinReminder, shouldCheckLastSeen]);
}
