import { useCallback } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { coreThemeConstants } from '@noah-labs/core-web-ui/src/theme/coreThemeConstants';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import type { QuestTaskWithStatus } from '@noah-labs/noah-schema';
import { useHistory, useLocation } from 'react-router-dom';
import type { TpBackHijackState } from '../../../../../hooks';
import { TpAnalyticsEvent, useAnalytics } from '../../../../analytics';
import { isQuestTaskComplete } from '../../../data';
import { getQuestTaskAction, getQuestTaskCopy } from '../../../mappings';

export type PpQuestTaskCard = {
  task: QuestTaskWithStatus;
};

const pointsStyle = css`
  /* TODO Font variant missing */
  font-family: ${coreThemeConstants.fontFamily.extended};
  font-style: normal;
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 12px;
`;

export function QuestTaskCard({ task }: PpQuestTaskCard): React.ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      position: relative;
      padding: ${theme.spacing(2)};
    `,
    completed: css`
      ::after {
        content: '';
        position: absolute;
        ${cssMixins.stretchAll}
        background: ${theme.palette.grayscale.black40};
      }
    `,
  };
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const history = useHistory<TpBackHijackState>();

  const taskAction = getQuestTaskAction(task.Task);
  const taskCopy = getQuestTaskCopy(task.Task);
  const isComplete = isQuestTaskComplete(task);

  const cardStyles = [styles.card];
  if (isComplete) {
    cardStyles.push(styles.completed);
  }

  const handleClick = useCallback(() => {
    if (!taskAction) {
      return;
    }

    track(TpAnalyticsEvent.TaskClick, {
      requirement: taskAction.requirement,
    });

    if (!taskAction.href) {
      return;
    }

    if (taskAction.externalLink) {
      window.open(taskAction.href, '_blank');
      return;
    }

    history.push(taskAction.href, { from: pathname });
  }, [history, pathname, taskAction, track]);

  return (
    <ElevatedCard css={cardStyles}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="paragraphBodyMBold">
            <TextOrSkeleton>{taskCopy?.title}</TextOrSkeleton>
          </Typography>
          <Typography color="text.light" variant="paragraphBodyS">
            <TextOrSkeleton>{taskCopy?.description}</TextOrSkeleton>
          </Typography>
          <Typography color="brand.light" css={pointsStyle}>
            <TextOrSkeleton>{taskCopy?.reward}</TextOrSkeleton>
          </Typography>
        </Stack>
        {!isComplete && !!taskAction?.cta && (
          <PrimaryButton
            color="primaryBrand"
            loading={!taskAction}
            size="medium"
            onClick={handleClick}
          >
            {taskAction.cta}
          </PrimaryButton>
        )}
      </Stack>
    </ElevatedCard>
  );
}
