import React, { useRef } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getTruncatedAddress } from '@noah-labs/core-web-ui/src/address/getTruncatedAddress';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { CurrencyCode } from '@noah-labs/noah-schema';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import {
  AddressSummaryCard,
  BitcoinTransactionFeesDialog,
  UsdStablecoinsFeeDialog,
} from '../components';
import { useCurrencyAmounts } from '../hooks/useCurrencyAmounts';

export type PpConfirmScene = {
  addressData?: TpAddressData;
  backButton?: boolean;
  backTo?: string;
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI;
  ctaButtonLabel?: string;
  faqLink?: string;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrencyUI;
  isCtaDisabled?: boolean;
  isLoading: boolean;
  onConfirm: () => Promise<void>;
  pageTitle: string;
};

export function ConfirmScene({
  addressData,
  backButton,
  backTo,
  cryptoAmount,
  cryptoCurrency,
  ctaButtonLabel = 'Send Now',
  fiatAmount,
  fiatCurrency,
  isCtaDisabled = false,
  isLoading,
  onConfirm,
  pageTitle,
}: PpConfirmScene): React.ReactElement {
  const theme = useTheme();

  const feeInfoDialog = useRef<TpDialogToggle>(null);

  let FeeDialog: React.ReactElement;

  switch (cryptoCurrency.code) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      FeeDialog = (
        <UsdStablecoinsFeeDialog ref={feeInfoDialog} currencyCode={cryptoCurrency.code} />
      );
      break;

    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
    default:
      FeeDialog = <BitcoinTransactionFeesDialog ref={feeInfoDialog} />;
  }

  const Fees = useCurrencyAmounts({
    cryptoAmount: 0,
    cryptoCurrency,
    fiatAmount: 0,
    fiatCurrency,
  });
  const Amounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
    roundDown: true,
  });
  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="confirm-button"
          disabled={isCtaDisabled}
          loading={isLoading}
          onClick={onConfirm}
        >
          {ctaButtonLabel}
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData exitButton helpButton backButton={backButton} backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="confirm"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain maxWidth="xs">
        <Stack spacing={6}>
          <Balance {...Amounts} />

          {addressData && (
            <AddressSummaryCard
              address={getTruncatedAddress(addressData.address, '', 12)}
              cryptoCurrency={cryptoCurrency}
              FeeSlot={
                <StructuredContent
                  endText={{
                    primary: Fees.PrimaryAmountSlot,
                    primaryTypographyProps: {
                      variant: 'paragraphBodySBold',
                    },
                    secondary: Fees.SecondaryAmountSlot,
                  }}
                  mainText={{
                    disableTypography: true,
                    primary: (
                      <InfoTooltip
                        color="text.light"
                        variant="paragraphBodyS"
                        onClick={(): void => feeInfoDialog.current?.toggle()}
                      >
                        Fee
                      </InfoTooltip>
                    ),
                  }}
                  sx={{ minHeight: theme.spacing(4.5), wordBreak: 'break-word' }}
                />
              }
              network={addressData.network}
              TotalAmountSlot={Amounts}
            />
          )}
        </Stack>
        {FeeDialog}
      </SceneMain>
    </AppContainer>
  );
}
