import { CurrencyCode as TpCurrencyCode } from '@noah-labs/noah-schema';
import type { AccountType } from '@noah-labs/noah-schema';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpDefaultWalletAccount } from './defaultAccount';
import {
  defaultAccount,
  defaultAccountEth,
  defaultAccountUsdc,
  defaultAccountUsdt,
} from './defaultAccount';
import type { AccountCreateMutation, AccountsQuery } from './wallet.generated';

type TpFindAccount = {
  AccountType: AccountType;
  CurrencyCode: TpCurrencyCode;
  data: AccountsQuery;
};

export type TpAcc = NonNullable<AccountsQuery['accounts']['items']>;

type TpFeatureData = { currencies: TpCurrencyCode[]; isFeatureEnabled: boolean };

const currencySettings = webConfigBrowser.currencies.settings;

export const defaultAccounts = [
  defaultAccount,
  defaultAccountEth,
  defaultAccountUsdc,
  defaultAccountUsdt,
];

const accountSortOrder = [
  TpCurrencyCode.BTC,
  TpCurrencyCode.BTC_TEST,
  TpCurrencyCode.ETH,
  TpCurrencyCode.ETH_TEST_SEPOLIA,
  TpCurrencyCode.USDC,
  TpCurrencyCode.USDC_TEST,
  TpCurrencyCode.USDT,
  TpCurrencyCode.USDT_TEST,
];

export function filterPlaceholderAccounts(
  accounts: AccountsQuery['accounts']['items'],
  placeholderAccounts: TpDefaultWalletAccount[]
): TpAcc {
  const existingAccounts = new Set(accounts?.map((c) => c.CurrencyCode));

  const filteredPlaceholderAccounts = placeholderAccounts.filter(
    (c) => !existingAccounts.has(c.CurrencyCode)
  );
  return [...(accounts || []), ...filteredPlaceholderAccounts];
}

/**
 * sortFilterWithFallback can be used to display accounts in the UI,
 * controls users accounts, placeholder accounts and also filters
 * accounts based on currencies settings and feature data
 */
export function sortFilterWithFallback(
  userAccounts: AccountsQuery['accounts']['items'],
  { currencies, isFeatureEnabled }: TpFeatureData
): TpAcc | undefined {
  if (!userAccounts) {
    return undefined;
  }

  const filteredDefaultAccounts = defaultAccounts
    .filter((acc) => currencies.includes(acc.CurrencyCode))
    .filter((a) => !!currencySettings.find((c) => c.currency === a.CurrencyCode));

  const placeholderAccounts = isFeatureEnabled ? filteredDefaultAccounts : [defaultAccount];
  const accounts = filterPlaceholderAccounts(userAccounts, placeholderAccounts);

  const sortedAccounts = accounts
    .filter((acc) => acc.CurrencyCode !== TpCurrencyCode.NOAH)
    .sort(
      (a, b) => accountSortOrder.indexOf(a.CurrencyCode) - accountSortOrder.indexOf(b.CurrencyCode)
    );

  return sortedAccounts;
}

/**
 * filterWithFallback returns users accounts and placeholder accounts without
 * settings filter or restricted currencies filter, it should be used
 * for select cases and not to display accounts
 */
export function filterWithFallback(data: AccountsQuery): AccountsQuery {
  const accounts = filterPlaceholderAccounts(data.accounts.items, defaultAccounts);

  return {
    ...data,
    accounts: {
      ...data.accounts,
      items: accounts,
    },
  };
}

export function selectAccount({
  AccountType,
  CurrencyCode,
  data,
}: TpFindAccount): TpAcc[0] | undefined {
  if (!Array.isArray(data.accounts.items)) {
    return undefined;
  }

  return data.accounts.items.find(
    (acc) => acc.AccountType === AccountType && acc.CurrencyCode === CurrencyCode
  );
}

type PpGetAccount = Omit<TpFindAccount, 'data'> & { data?: AccountsQuery };
export function getAccount({
  AccountType,
  CurrencyCode,
  data,
}: PpGetAccount): TpAcc[0] | undefined {
  if (!data) {
    return undefined;
  }

  return selectAccount({ AccountType, CurrencyCode, data });
}

export function selectFromFilterWithFallback({
  AccountType,
  CurrencyCode,
  data,
}: TpFindAccount): TpAcc[0] | undefined {
  return selectAccount({ AccountType, CurrencyCode, data: filterWithFallback(data) });
}

export function findDepositAddress(
  account: AccountCreateMutation['accountCreate'] | undefined
): string | undefined {
  // TODO: Also need to think about cases with multiple deposit addresses
  if (!account || !Array.isArray(account.DepositAddresses)) {
    return undefined;
  }

  return account.DepositAddresses[0].DestinationAddress.Address;
}
