import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { CircleTickIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneIcon } from '@noah-labs/core-web-ui/src/scene/SceneIcon';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import ReactLottie from 'lottie-react';
import { Helmet } from 'react-helmet';
import { AppHeaderData, GetInTouchLink } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { routes as walletRoutes } from '../../wallet/routes';
import confettiEffect from '../lotties/confetti.json';

export type PpCompleteSuccessScene = {
  enableAnimation?: boolean;
};

export function CompleteSuccessScene({
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
}: PpCompleteSuccessScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    animation: css`
      position: absolute;
      ${cssMixins.stretchTop};
      height: 100%;
      width: 100%;
      overflow: hidden;
      z-index: ${theme.zIndex.floatingBar};
      pointer-events: none;
    `,
  };

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={<PrimaryButton href={walletRoutes.base.path}>Close</PrimaryButton>}
      AppHeaderSlot={<AppHeaderData disableFade exitButton helpButton />}
      dataQa="completeSuccess"
    >
      <Helmet>
        <title>Account verification successful</title>
      </Helmet>
      <SceneMain>
        <SceneIcon>
          <CircleTickIcon color="success" />
        </SceneIcon>
        <SceneTitleLarge>Account verification successful</SceneTitleLarge>
        <SceneParagraph>
          Your account has been successfully verified and you now have access to all features.
        </SceneParagraph>
        <SceneParagraph>
          If you need to change your KYC information, please <GetInTouchLink />.
        </SceneParagraph>
        {enableAnimation && (
          <ReactLottie
            animationData={confettiEffect}
            css={styles.animation}
            loop={false}
            open={false}
            rendererSettings={{
              // makes the lottie to cover the entire viewport
              // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
              preserveAspectRatio: 'xMidYMid slice',
            }}
          />
        )}
      </SceneMain>
    </AppContainer>
  );
}
