import { Feature } from '@noah-labs/noah-schema';
import { routes as kycRoutes } from '../../kyc';
import { useFeature } from './useFeature';

export function useKycRoute(): string {
  const sumSubFF = useFeature(Feature.Sumsub);
  const kycRoute = sumSubFF?.Enabled ? kycRoutes.sumsub.base.path : kycRoutes.base.path;

  return kycRoute;
}
