import type { TpSkeletonText } from '../types';

export function getTruncatedAddress<T = TpSkeletonText>(
  address: TpSkeletonText,
  fallback: T,
  prefix = 5,
  suffix = prefix
): T | string {
  if (typeof address !== 'string') {
    return fallback;
  }
  if (address.length <= prefix + suffix) {
    return address;
  }
  const partOne = address.slice(0, prefix);
  const partTwo = address.slice(address.length - suffix);
  return [partOne, partTwo].filter(Boolean).join('\u2026'); // ellipsis char
}
