import React from 'react';
import { Container, Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CtaCardFull } from '@noah-labs/core-web-ui/src/cards/CtaCardFull';
import { ChevronRightIcon } from '@noah-labs/core-web-ui/src/icons';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { assetPaths } from '@noah-labs/fe-assets';
import type { UserBadge } from '@noah-labs/noah-schema';
import { routes as integrationRoutes } from '../../../../integrations';
import { getBadgeGridItem } from '../../../mappings/badgeGridItem';
import { BadgeGridItem } from './BadgeGridItem';

type PpBadgesTabPanel = {
  badges: UserBadge[] | undefined;
};

export function BadgesTabPanel({ badges }: PpBadgesTabPanel): React.ReactElement {
  const theme = useTheme();
  const items = badges?.map((badge) => getBadgeGridItem(badge)).filter(Boolean);
  return (
    <React.Fragment>
      <CtaCardFull
        backgroundImage={`${assetPaths.images}/bg-images/cta-shop.png`}
        href={generatePath(integrationRoutes.bitrefill.path)}
        IconSlot={
          <IconButton edge="end">
            <ChevronRightIcon css={{ color: theme.palette.ctaCards.white.contrastText }} />
          </IconButton>
        }
        subtitle="Purchase gift cards, phone top-ups, eSIMs and more with Bitcoin through our partner Bitrefill!"
        title="Shop with Bitcoin"
      />
      <SceneHeader>
        <SceneTitleMedium>Achievements</SceneTitleMedium>
        <SceneParagraph>
          Earn badges as you go! They’re a fun way to track your progress on your NOAH journey.
        </SceneParagraph>
      </SceneHeader>
      <Container maxWidth="sm">
        <Grid container columnSpacing={2} mt={2} rowSpacing={2}>
          {items?.map((item) => (
            <Grid key={item?.badgeId} item sm={4} xs={6}>
              <BadgeGridItem badge={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
