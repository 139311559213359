import React, { useCallback, useEffect, useMemo } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { CountryCode } from '@noah-labs/noah-schema';
import { Feature } from '@noah-labs/noah-schema';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { useCountryFromCode } from '../../../user/data/useCountryFromCode';
import { useUserInit } from '../../../user/data/useUserInit';
import { useIsFeatureEnabled } from '../../../user/hooks/useIsFeatureEnabled';
import { mapPaymentMethods, useFiatPaymentMethodsQuery, useWalletParams } from '../../data';
import { routes } from '../../routes';
import { SelectPayoutMethodScene } from '../../scenes';
import type { TpPaymentBank, TpPaymentCard } from '../../types';
import { TpPaymentMethod } from '../../types';
import type { StSellRouter } from './SellRouter';

export function SelectPayoutMethod({
  state,
  updateState,
}: TpStateMachine<StSellRouter>): React.ReactElement {
  const { state: locationState } = useLocation<{ successTo: string } | undefined>();
  const history = useHistory();
  const { params } = useWalletParams();
  const { data: userData } = useUserInit();
  const { fiatPaymentCurrency } = useUserFiatCurrency();
  const manualRampSellEnabled = useIsFeatureEnabled(Feature.ManualRampSell);

  const userLatestCountry = userData?.userProfile.LatestCountry;
  const country = useCountryFromCode(userLatestCountry);

  const { data: savedMethods } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentMethods,
  });

  const defaultCountryValues = useMemo(() => {
    if (!country || !userLatestCountry) {
      return undefined;
    }

    return {
      Country: userLatestCountry,
      CountryCurrency: country.currency,
      CountryName: country.name,
    };
  }, [country, userLatestCountry]);

  const onNewCardClick = useCallback(() => {
    // Forward traffic to successTo if it exists, otherwise to enter amount
    history.push(generatePath(routes.sell.enterPaymentInfo.path, params), {
      successTo: locationState?.successTo ?? generatePath(routes.sell.enterAmount.path, params),
    });
  }, [history, locationState?.successTo, params]);

  const onNewBankAccountClick = useCallback(
    (selectedCountry: CountryCode) => {
      updateState({
        selectedCountry,
      });

      // Forward traffic to successTo if it exists, otherwise to enter amount
      history.push(generatePath(routes.sell.enterAccountDetails.path, params), {
        successTo: locationState?.successTo ?? generatePath(routes.sell.enterAmount.path, params),
      });
    },
    [history, locationState?.successTo, updateState, params]
  );

  const updateSelectedPayoutMethod = useCallback(
    (method: TpPaymentCard | TpPaymentBank) => {
      switch (method.payoutMethod) {
        case TpPaymentMethod.Card: {
          const cardPaymentMethod = method as TpPaymentCard;

          updateState({
            fiatCurrencyCode: fiatPaymentCurrency.code,
            payoutMethod: cardPaymentMethod.payoutMethod,
            selectedPayoutBank: undefined,
            selectedPayoutCard: cardPaymentMethod,
          });
          break;
        }

        case TpPaymentMethod.BankTransfer: {
          const bankPaymentMethod = method as TpPaymentBank;

          updateState({
            fiatCurrencyCode: bankPaymentMethod.accountCurrency,
            payoutMethod: bankPaymentMethod.payoutMethod,
            selectedPayoutBank: bankPaymentMethod,
            selectedPayoutCard: undefined,
          });
          break;
        }

        default:
          break;
      }
    },
    [updateState, fiatPaymentCurrency.code]
  );

  const onPayoutMethodClick = useCallback(
    (method: TpPaymentCard | TpPaymentBank) => {
      updateSelectedPayoutMethod(method);

      if (!locationState?.successTo) {
        history.push(generatePath(routes.sell.enterAmount.path, params));
        return;
      }
      history.push(generatePath(locationState.successTo));
    },
    [history, locationState?.successTo, params, updateSelectedPayoutMethod]
  );

  /**
   * If a payout method exists and no payout method is selected, preselect it and redirect to enter amount screen
   */
  useEffect(() => {
    if (
      isFalseyOrEmptyArray(savedMethods) ||
      state.selectedPayoutCard ||
      state.selectedPayoutBank
    ) {
      return;
    }

    const savedMethod = savedMethods.find((method) => method.payoutSupported);
    if (!savedMethod) {
      return;
    }

    updateSelectedPayoutMethod(savedMethod);
    history.push(generatePath(routes.sell.enterAmount.path, params));
  }, [
    state.selectedPayoutCard,
    state.selectedPayoutBank,
    history,
    savedMethods,
    params,
    updateSelectedPayoutMethod,
  ]);

  if (!savedMethods) {
    return <LoadingPage />;
  }

  return (
    <SelectPayoutMethodScene
      defaultCountryValues={defaultCountryValues}
      manualRampSellEnabled={manualRampSellEnabled}
      pageTitle={routes.sell.payoutMethod.title}
      savedPayoutMethods={savedMethods}
      onNewBankAccountClick={onNewBankAccountClick}
      onNewCardClick={onNewCardClick}
      onPayoutMethodClick={onPayoutMethodClick}
    />
  );
}
