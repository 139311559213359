import { useMemo } from 'react';
import type { CurrencyCode as TpCurrencyCode } from '@noah-labs/noah-schema';
import { Feature } from '@noah-labs/noah-schema';
import { useFeature } from '../../user/hooks/useFeature';
import { TpTransactionType } from '../types';

export type TpUseAvailableCurrencyActions = {
  [key in TpTransactionType]: boolean | undefined;
};

export function useAvailableCurrencyActions(
  currencyCode: TpCurrencyCode
): TpUseAvailableCurrencyActions {
  const checkoutBuyFeature = useFeature(Feature.CheckoutBuy);
  const moonpayBuyFeature = useFeature(Feature.MoonpayBuy);
  const checkoutSellFeature = useFeature(Feature.CheckoutSell);
  const moonpaySellFeature = useFeature(Feature.MoonpaySell);
  const withdrawFeature = useFeature(Feature.Withdraw);
  const depositFeature = useFeature(Feature.Deposit);

  const availableCurrencyActions = useMemo(
    () => ({
      [TpTransactionType.Buy]:
        (checkoutBuyFeature?.Enabled && checkoutBuyFeature.Currencies?.includes(currencyCode)) ||
        (moonpayBuyFeature?.Enabled && moonpayBuyFeature.Currencies?.includes(currencyCode)),
      [TpTransactionType.Deposit]:
        depositFeature?.Enabled && depositFeature.Currencies?.includes(currencyCode),
      [TpTransactionType.Sell]:
        (checkoutSellFeature?.Enabled && checkoutSellFeature.Currencies?.includes(currencyCode)) ||
        (moonpaySellFeature?.Enabled && moonpaySellFeature.Currencies?.includes(currencyCode)),
      [TpTransactionType.Withdraw]:
        withdrawFeature?.Enabled && withdrawFeature.Currencies?.includes(currencyCode),
    }),
    [
      moonpaySellFeature,
      moonpayBuyFeature,
      checkoutBuyFeature,
      checkoutSellFeature,
      withdrawFeature,
      depositFeature,
      currencyCode,
    ]
  );

  return availableCurrencyActions;
}
