import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { CurrencyCode as CryptoCurrencyCode } from '@noah-labs/noah-schema';
import type { CryptoAmount, FiatAmount, FiatCurrencyCode } from '@noah-labs/noah-schema';
import { mockCryptoCurrenciesFromApi } from '@noah-labs/shared-currencies/src/crypto-currencies';
import { mockFiatCurrenciesFromApi } from '@noah-labs/shared-currencies/src/fiat-currencies';
import { isProd } from '../webConfigBrowser';
import { addIconsToCryptoCurrencies, addIconsToFiatCurrencies } from './addIconsToCurrencies';

export const cryptoCurrenciesWithIcons = addIconsToCryptoCurrencies(mockCryptoCurrenciesFromApi);
export const fiatCurrenciesWithIcons = addIconsToFiatCurrencies(mockFiatCurrenciesFromApi);

export function cryptoCurrencyFromCode(code: CryptoCurrencyCode): TpCryptoCurrencyUI {
  return cryptoCurrenciesWithIcons[code];
}

export function cryptoCurrencyFromString(code: string | undefined): TpCryptoCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return cryptoCurrenciesWithIcons[codeUpper as CryptoCurrencyCode];
}

export function fiatCurrencyFromString(code: string | undefined): TpFiatCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return fiatCurrenciesWithIcons[codeUpper as FiatCurrencyCode];
}

export function fiatCurrencyFromCode(code: FiatCurrencyCode): TpFiatCurrencyUI {
  return fiatCurrenciesWithIcons[code];
}

// TODO: (CS) in next PR we'll be updating the currency objects so we shouldn't need these functions
export function toCryptoTestCode(cryptoCode: string | undefined): string | undefined {
  const isTestNet = !isProd;

  if (!isTestNet) {
    return cryptoCode;
  }

  switch (cryptoCode) {
    case 'btc':
      return 'btc_test';

    default:
      return cryptoCode;
  }
}

export function fromCryptoTestCode(cryptoCode: CryptoCurrencyCode): CryptoCurrencyCode {
  const isTestNet = !isProd;

  if (!isTestNet) {
    return cryptoCode;
  }

  switch (cryptoCode) {
    case CryptoCurrencyCode.BTC_TEST:
      return CryptoCurrencyCode.BTC;

    case CryptoCurrencyCode.ETH_TEST_SEPOLIA:
      return CryptoCurrencyCode.ETH;

    case CryptoCurrencyCode.USDC_TEST:
      return CryptoCurrencyCode.USDC;

    case CryptoCurrencyCode.USDT_TEST:
      return CryptoCurrencyCode.USDT;

    default:
      return cryptoCode;
  }
}

export function getCryptoTestCode(code: CryptoCurrencyCode): CryptoCurrencyCode {
  const isMainNet = isProd;

  if (isMainNet) {
    return code;
  }

  switch (code) {
    case CryptoCurrencyCode.BTC:
      return CryptoCurrencyCode.BTC_TEST;

    case CryptoCurrencyCode.ETH:
      return CryptoCurrencyCode.ETH_TEST_SEPOLIA;

    case CryptoCurrencyCode.USDC:
      return CryptoCurrencyCode.USDC_TEST;

    case CryptoCurrencyCode.USDT:
      return CryptoCurrencyCode.USDT_TEST;

    default:
      return code;
  }
}

export function getCryptoAmountForCurrency(
  amounts: CryptoAmount[] | undefined | null,
  currency: CryptoCurrencyCode
): string | undefined {
  return amounts?.find((amount) => amount.Currency === currency)?.Amount;
}

/**
 * Returns the fiat amount for the given currency code, or the first one if it doesn't exist
 */
export function fiatAmountForCurrency(
  amounts: FiatAmount[],
  currencyCode: FiatCurrencyCode
): FiatAmount {
  const amount = amounts.find((am) => am.FiatCurrency === currencyCode);
  return amount || amounts[0];
}
