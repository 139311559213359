import type { ChangeEvent } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Delete, Upload } from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { ButtonBase, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';
import { CircleTickIcon } from '../icons';
import { SceneParagraph } from '../scene/Typography';
import { InputField } from './InputField';

export type PpFileInput = Omit<TextFieldProps, 'error' | 'id' | 'name'> & {
  initialState?: string[];
  name: string;
};

const initialEmptyState: string[] = [];

export function FileInput({
  initialState = initialEmptyState,
  name,
  ...rest
}: PpFileInput): React.ReactElement {
  const [selectedFiles, setSelectedFiles] = useState<string[]>(initialState);
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { getValues, setValue } = useFormContext();

  const styles = {
    fileContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      align-items: center;
      padding: ${theme.spacing(3, 4)};
      border-radius: ${theme.borderRadius.lg};
      border: 1px dashed ${theme.palette.grayscale[300]};
      cursor: pointer;
      margin-bottom: ${theme.spacing(4)};
    `,
    hiddenInput: css`
      position: absolute;
      bottom: -${theme.spacing(3)};
      margin: 0;
      pointer-events: none;
      left: 0;

      .MuiInputBase-root {
        opacity: 0;
      }

      .MuiFormHelperText-root {
        margin: ${theme.spacing(1, 0, 0)};
      }
    `,
    selectedFile: css`
      flex-direction: row;
      padding: ${theme.spacing(1, 0)};
    `,
    selectedFileContainer: css`
      flex-direction: row;
      justify-content: space-between;
    `,
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const insertedFiles = event.target.files;

      if (!insertedFiles) {
        return;
      }

      const currentFiles: File[] | [] = getValues(name);
      const filteredFiles = Array.from(insertedFiles);

      const fileNames = filteredFiles.map((file) => file.name);
      const files = currentFiles.length ? [...currentFiles, ...filteredFiles] : filteredFiles;

      setSelectedFiles((prevState) => [...prevState, ...fileNames]);
      setValue(name, files, { shouldValidate: true });
    },
    [name, setValue, getValues]
  );

  const handleRemove = useCallback(
    (selectedFileIndex: number): void => {
      const currentFiles: File[] = getValues(name);
      const filteredFiles = currentFiles.filter((_, fileIndex) => fileIndex !== selectedFileIndex);

      setSelectedFiles((prevState) =>
        prevState.filter((_, fileIndex) => fileIndex !== selectedFileIndex)
      );
      setValue(name, filteredFiles, { shouldValidate: true });
    },
    [setValue, getValues, name]
  );

  return (
    <React.Fragment>
      <ButtonBase css={styles.fileContainer} onClick={(): void => inputRef.current?.click()}>
        <Upload sx={{ mb: 1 }} />
        <SceneParagraph>Upload document in JPEG, PNG or PDF</SceneParagraph>
        <InputField
          {...rest}
          css={styles.hiddenInput}
          dataQa={name}
          inputRef={inputRef}
          name={name}
          type="file"
          value=""
          onChange={handleChange}
        />
      </ButtonBase>

      {!!selectedFiles.length &&
        selectedFiles.map((file, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={`${file}-${index}`} css={styles.selectedFileContainer}>
            <Stack css={styles.selectedFile}>
              <CircleTickIcon />
              <Typography paragraph mb={0} ml={1} variant="paragraphBodyM">
                {file}
              </Typography>
            </Stack>

            <IconButton onClick={(): void => handleRemove(index)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
        ))}
    </React.Fragment>
  );
}
