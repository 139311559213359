import { css } from '@emotion/react';
import type { ButtonProps } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useClickToCopy } from '../hooks/useClickToCopy';
import type { TpSkeletonText } from '../types';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';
import { DynamicCopyIcon } from './DynamicCopyIcon';
import { getTruncatedAddress } from './getTruncatedAddress';

export type PpAddress = Pick<ButtonProps, 'sx' | 'variant'> & {
  /* IMPORTANT: DO NOT MANIPULATE THE ADDRESS - e.g. ADDRESSES ARE CASE SENSITIVE */
  address: TpSkeletonText;
  noWrap?: boolean;
  onCopy?: () => void;
  truncate?: boolean;
};

export function AddressWithCopy({
  truncate = true,
  noWrap = truncate,
  address,
  onCopy,
  sx,
  variant = 'contained',
}: PpAddress): React.ReactElement {
  const theme = useTheme();
  const styles = {
    button: css`
      text-align: left;
      justify-content: flex-start;
      min-width: 6em;
      max-width: 100%;
      text-transform: none !important;
    `,
    buttonText: css`
      box-sizing: content-box;
      margin: ${theme.spacing(-1)};
      padding: ${theme.spacing(1)};
      min-height: auto;
    `,
    textWrap: css`
      overflow: auto;
      word-wrap: break-word;
    `,
  };

  const buttonStyles = [styles.button];
  let buttonColor: ButtonProps['color'] = 'input';
  let fullWidth = true;

  if (variant === 'text') {
    buttonStyles.push(styles.buttonText);
    buttonColor = 'inherit';
    fullWidth = false;
  }

  const { copied, copyHandler } = useClickToCopy({ onCopy, text: address });

  return (
    <Button
      color={buttonColor}
      css={buttonStyles}
      data-qa="copy-button"
      data-qa-address={address}
      disabled={!address}
      fullWidth={fullWidth}
      startIcon={<DynamicCopyIcon copied={copied} />}
      sx={sx}
      variant={variant}
      onClick={copyHandler}
    >
      <Typography
        css={!noWrap && styles.textWrap}
        noWrap={noWrap}
        variant={variant === 'contained' ? 'paragraphBodyM' : 'paragraphBodyS'}
      >
        <TextOrSkeleton sx={{ px: '6em' }}>
          {truncate ? getTruncatedAddress(address, undefined, 12) : address}
        </TextOrSkeleton>
      </Typography>
    </Button>
  );
}
