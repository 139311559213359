import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { getPromoContent } from '../../../../utils/promos';
import { webConfigBrowser } from '../../../../webConfigBrowser';

type PpCheckoutFeeDialog = {
  action: 'buying' | 'selling';
  promoId?: string;
};

const { feeMinimumFiatAmount, feePercentage } = webConfigBrowser.checkout;

export const CheckoutFeeDialog = forwardRef<TpDialogToggle, PpCheckoutFeeDialog>(
  ({ action, promoId }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Fees</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            Transparency is essential to us, and we want to provide clear information about the fees
            associated with {action} Bitcoin through our crypto wallet app.
          </DialogContentText>

          <DialogContentText paragraph={!!promoId}>
            There is a {feePercentage}% fee on all buy and sell orders with a minimum fee of $
            {feeMinimumFiatAmount}. Your bank or card provider may charge additional fees. NOAH has
            no control over the fees set by your bank or card provider.
          </DialogContentText>

          {promoId && <DialogContentText>{getPromoContent(promoId)}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);

CheckoutFeeDialog.displayName = CheckoutFeeDialog.name;
