import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { AccountType, Network } from '@noah-labs/noah-schema';
import { routes } from '../routes';

export function getNextUrl(result: TpAddressData): string {
  const params = {
    accountType: AccountType.Current,
    currencyCode: result.currencyCode,
  };

  switch (result.network) {
    case Network.Bitcoin:
    case Network.BitcoinTest:
    case Network.Tron:
    case Network.TronTestShasta: {
      let withdrawNext = routes.withdraw.enterAmount.path;
      if (result.amount) {
        withdrawNext = routes.withdraw.confirm.path;
      }
      return generatePath(withdrawNext, params);
    }

    case Network.Lightning:
    case Network.LightningTest: {
      // if the expiryTime is up, redirect to invoice expired
      if (result.expiryTime && result.expiryTime - Date.now() <= 0) {
        return generatePath(routes.lightningSend.invoiceExpired.path, params);
      }

      // if there's an amount, we can skip the amount entry screen in LN
      let lightningNext = routes.lightningSend.enterAmount.path;
      if (result.amount) {
        lightningNext = routes.lightningSend.confirm.path;
      }
      return generatePath(lightningNext, params);
    }

    // network can be undefined when an address is multi network
    case undefined: {
      if (!result.address) {
        throw new Error('Unable to generate url, unknown address');
      }

      return generatePath(routes.withdraw.network.path, params);
    }
    default:
      throw new Error('Unable to generate url, unknown network');
  }
}
