import React, { useCallback } from 'react';
import type { PpAmountField } from '@noah-labs/core-web-ui/src/forms/AmountField';
import { AmountField } from '@noah-labs/core-web-ui/src/forms/AmountField';
import { AmountFieldSecondary } from '@noah-labs/core-web-ui/src/forms/AmountFieldSecondary';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { CurrencyUnit } from '@noah-labs/noah-schema';
import { CurrencyDisplayType } from '@noah-labs/noah-schema';
import type { TpSlippage } from '@noah-labs/shared-currencies/src/calculations';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import { useFormContext } from 'react-hook-form';
import type { TpPriceProvider } from '../../data';
import type { TpAmountForm } from '../../scenes';
import { TpDualCurrencyAmountFieldNames } from './dual-currency-hooks/types';
import { useCurrencySwitch } from './dual-currency-hooks/useCurrencySwitch';
import { usePrimaryCrypto } from './dual-currency-hooks/usePrimaryCrypto';
import { usePrimaryFiat } from './dual-currency-hooks/usePrimaryFiat';

type PpDualCurrencyAmountField = {
  InputFieldAtomProps: PpAmountField['InputFieldAtomProps'];
  SwitchCurrencySlot?: React.ReactElement;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit;
  disableSwitch: boolean;
  fiatCurrency: TpFiatCurrency;
  isCryptoAmountNet?: boolean;
  onBlurValues?: (values: Pick<TpAmountForm, 'cryptoAmount' | 'fiatAmount'>) => void;
  priceProvider: TpPriceProvider;
  slippage?: TpSlippage;
  userPrimaryCurrency: CurrencyDisplayType | null | undefined;
};

export function DualCurrencyAmountField({
  cryptoCurrency,
  cryptoUnit,
  disableSwitch,
  fiatCurrency,
  InputFieldAtomProps,
  isCryptoAmountNet = false,
  onBlurValues,
  priceProvider,
  slippage,
  SwitchCurrencySlot,
  userPrimaryCurrency,
}: PpDualCurrencyAmountField): React.ReactElement {
  const { getValues, watch } = useFormContext<TpAmountForm>();
  /**
   * DISPLAY ONLY
   * primaryAmount is directly controlled and only affected by what the user inputs.
   * It can include 'uniting' so this must be taken in to account when calculating the secondary amount
   * secondaryAmount is calculated from the primaryAmount, it excludes any 'uniting',
   * which is handle in the appropriate downstream display component. The 'uniting'
   * must be reapplied when the mode is switched
   *
   * SUBMITTED VALUES
   * fiatAmount is only reactive and is used when submitting & blurring, it excludes any 'uniting'
   * cryptoAmount is only reactive and is used when submitting & blurring, it excludes any 'uniting'
   */
  const [primaryAmount, secondaryAmount] = watch([
    TpDualCurrencyAmountFieldNames.primary,
    TpDualCurrencyAmountFieldNames.secondary,
  ]);

  usePrimaryFiat({
    cryptoCurrency,
    enabled: userPrimaryCurrency === CurrencyDisplayType.Fiat,
    fiatCurrency,
    isCryptoAmountNet,
    priceProvider,
    primaryAmount,
    slippage,
  });

  usePrimaryCrypto({
    cryptoCurrency,
    cryptoUnit,
    enabled: userPrimaryCurrency === CurrencyDisplayType.Crypto,
    fiatCurrency,
    priceProvider,
    primaryAmount,
    slippage,
  });

  /**
   * handleBlur is used when resetting the form if there is an iFrame for buy / sell
   * use getValues in here so the callback is not recreated on every rerender
   */
  const handleBlur = useCallback(() => {
    if (!onBlurValues) {
      return;
    }

    const formValues = getValues();
    onBlurValues({ cryptoAmount: formValues.cryptoAmount, fiatAmount: formValues.fiatAmount });
  }, [getValues, onBlurValues]);

  const { handleCurrencySwitch } = useCurrencySwitch({
    cryptoCurrency,
    cryptoUnit,
    currentPrimaryCurrency: userPrimaryCurrency,
  });

  const fiatMode = userPrimaryCurrency === CurrencyDisplayType.Fiat;
  return (
    <AmountField
      AmountFieldSecondary={
        <AmountFieldSecondary
          amount={secondaryAmount}
          cryptoUnit={cryptoUnit}
          currency={fiatMode ? cryptoCurrency : fiatCurrency}
          disableSwitch={disableSwitch}
          primaryCurrencyType={userPrimaryCurrency}
          SwitchCurrencySlot={SwitchCurrencySlot}
          onCurrencySwitch={handleCurrencySwitch}
        />
      }
      cryptoUnit={cryptoUnit}
      InputFieldAtomProps={InputFieldAtomProps}
      primaryAmountFieldName={TpDualCurrencyAmountFieldNames.primary}
      primaryCurrency={fiatMode ? fiatCurrency : cryptoCurrency}
      primaryCurrencyType={userPrimaryCurrency}
      // need to explicitly set value to control it correctly when switched
      value={primaryAmount}
      onBlur={handleBlur}
    />
  );
}
