import React from 'react';
import { List } from '@mui/material';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { DefaultListItemText } from '@noah-labs/core-web-ui/src/lists/atoms/DefaultListItemText';
import { PrimaryMenuItem } from '@noah-labs/core-web-ui/src/menus/PrimaryMenuItem';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

type PpSecurityPinScene = {
  backTo?: string;
  pinSetupRequired: boolean;
};

export function SecurityPinScene({
  backTo,
  pinSetupRequired,
}: PpSecurityPinScene): React.ReactElement {
  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData helpButton backTo={backTo}>
          <AppHeaderTitle>{routes.settings.securityPin.title}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="security-pin-menu"
    >
      <Helmet>
        <title>{routes.base.title}</title>
      </Helmet>

      <SceneMain dense>
        <List>
          {pinSetupRequired && (
            <PrimaryMenuItem href={routes.setupPin.createPin.path}>
              <DefaultListItemText
                data-qa="setup-pin"
                primary="Setup PIN"
                secondary="Create a new security PIN"
              />
            </PrimaryMenuItem>
          )}

          {!pinSetupRequired && (
            <React.Fragment>
              <PrimaryMenuItem data-qa="update-pin" href={routes.updatePin.enterCurrentPin.path}>
                <DefaultListItemText
                  primary={routes.updatePin.enterCurrentPin.title}
                  secondary="You will be asked for your current PIN"
                />
              </PrimaryMenuItem>

              <PrimaryMenuItem data-qa="recover-pin" href={routes.forgotPin.enterPhrase.path}>
                <DefaultListItemText
                  primary={routes.forgotPin.enterPhrase.title}
                  secondary="Use your recovery phrase to reset your PIN"
                />
              </PrimaryMenuItem>
            </React.Fragment>
          )}
        </List>
      </SceneMain>
    </AppContainer>
  );
}
