import { AvailableBalance } from '@noah-labs/core-web-ui/src/balance/AvailableBalance';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { AccountType as TpAccountType } from '@noah-labs/noah-schema';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';

type PpAvailableBalanceData = {
  AccountType: TpAccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrencyUI;
};

export function AvailableBalanceData({
  AccountType,
  cryptoCurrency,
  fiatCurrency,
}: PpAvailableBalanceData): React.ReactElement {
  const availableBalanceData = useAvailableBalance({
    AccountType,
    cryptoCurrency,
    fiatCurrency,
  });

  const Amounts = useCurrencyAmounts({
    ...availableBalanceData,
  });

  return <AvailableBalance cryptoCurrency={cryptoCurrency} {...Amounts} />;
}
