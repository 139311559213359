import React, { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Network } from '@noah-labs/noah-schema';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { networkForEnv } from '../../../../utils/networks';
import { AvailableBalanceData } from '../../components';
import { useWalletParams } from '../../data';
import { useCryptoUnit } from '../../hooks/useCryptoUnit';
import { usePrimaryCurrency } from '../../hooks/usePrimaryCurrency';
import { useWithdrawalAllowance } from '../../hooks/useWithdrawalAllowance';
import { routes } from '../../routes';
import type { TpAmountForm } from '../../scenes';
import { EnterAmountScene } from '../../scenes';
import { getCryptoUnitAmount } from '../../utils/utils';
import { getLnSendSchema } from '../../utils/validation';
import type { StLightningSendRouter } from './types';

export function EnterAmount({
  state,
  updateState,
}: TpStateMachine<StLightningSendRouter>): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { primaryCurrency } = usePrimaryCurrency();
  const cryptoUnit = useCryptoUnit();
  const allowance = useWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    network: networkForEnv(Network.Lightning),
  });
  const { fiatCurrency } = useUserFiatCurrency();

  const maxAmounts =
    state.lightningAddressProxy &&
    getCryptoUnitAmount({
      amount: state.lightningAddressProxy.MaxSendable,
      cryptoCurrency,
      cryptoUnit,
    });

  const minAmounts =
    state.lightningAddressProxy &&
    getCryptoUnitAmount({
      amount: state.lightningAddressProxy.MinSendable,
      cryptoCurrency,
      cryptoUnit,
    });

  const schema = getLnSendSchema({
    allowance: allowance?.lnAllowanceCrypto,
    fiatCurrency,
    maxAmounts,
    minAmounts,
  });

  const history = useHistory();
  const onSubmit = useCallback(
    (values: TpAmountForm) => {
      updateState({
        ...values,
        cryptoAmount: truncateAmount({
          amount: values.cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: values.fiatAmount,
          decimalPlaces: fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });
      history.push(generatePath(routes.lightningSend.confirm.path, params));
    },
    [history, params, cryptoCurrency, fiatCurrency.decimals, updateState]
  );

  return (
    <EnterAmountScene
      backButton
      ContentSlot={
        <AvailableBalanceData
          AccountType={AccountType}
          cryptoCurrency={cryptoCurrency}
          fiatCurrency={fiatCurrency}
        />
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={cryptoUnit}
      ctaButtonDisabled={!schema}
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatCurrency}
      htmlHeadTitle={routes.lightningSend.enterAmount.title}
      PageTitleSlot={<AppHeaderTitle>{routes.lightningSend.enterAmount.title}</AppHeaderTitle>}
      priceProvider="market"
      primaryCurrency={primaryCurrency}
      yupSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
