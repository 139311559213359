import { AuthGroups, Feature } from '@noah-labs/noah-schema';

const base = '/integrations';

const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  base: {
    path: base,
  },
  bitrefill: {
    feature: Feature.Bitrefill,
    groups,
    path: `${base}/bitrefill`,
    title: 'Bitrefill',
  },
};
