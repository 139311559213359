import { Network } from '@noah-labs/noah-schema';
import { isProd } from '../webConfigBrowser';

/**
 * Returns the test network for non-prod environments
 */
export function networkForEnv(network: Network): Network {
  if (isProd) {
    return network;
  }

  switch (network) {
    case Network.Bitcoin:
      return Network.BitcoinTest;
    case Network.Lightning:
      return Network.LightningTest;
    case Network.Ethereum:
      return Network.EthereumTestSepolia;
    default:
      return network;
  }
}
