import { SVGProps } from 'react';
const SvgEmptyBox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 216 90" {...props}>
    <path
      fill="#F7F7F7"
      fillRule="evenodd"
      d="M46 82h91c.515 0 1.017-.056 1.5-.161.483.105.985.161 1.5.161h52a7 7 0 1 0 0-14h-6a7 7 0 1 1 0-14h19a7 7 0 1 0 0-14h-22a7 7 0 1 0 0-14h-64a7 7 0 1 0 0-14H62a7 7 0 1 0 0 14H22a7 7 0 1 0 0 14h25a7 7 0 1 1 0 14H7a7 7 0 1 0 0 14h39a7 7 0 1 0 0 14Zm163 0a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M96.119 60.307A12.095 12.095 0 0 0 96 62c0 6.627 5.373 12 12 12s12-5.373 12-12c0-.575-.04-1.14-.119-1.693H149V87a3 3 0 0 1-3 3H70a3 3 0 0 1-3-3V60.307h29.119Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M121 60c0 7.18-5.82 13-13 13s-13-5.82-13-13c0-.233.006-.464.018-.693H67l9.56-28.268A3 3 0 0 1 79.402 29h57.196c1.286 0 2.429.82 2.842 2.039L149 59.307h-28.018c.012.23.018.46.018.693Z"
      clipRule="evenodd"
    />
    <path
      fill="#F1F1F1"
      fillRule="evenodd"
      d="M119.098 60.955C119.098 66.502 114.129 72 108 72c-6.129 0-11.098-5.498-11.098-11.045 0-.18.006-1.359.016-1.536H76l8.161-19.843C84.513 38.633 85.49 38 86.587 38h42.826c1.098 0 2.074.634 2.426 1.575L140 59.42h-20.918c.01.177.016 1.356.016 1.536Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#F60"
      strokeWidth={2.5}
      d="M68.25 59.512V86c0 .966.784 1.75 1.75 1.75h76a1.75 1.75 0 0 0 1.75-1.75V59.512l-9.495-28.073a1.75 1.75 0 0 0-1.657-1.189H79.402a1.75 1.75 0 0 0-1.657 1.19L68.25 59.511Z"
      clipRule="evenodd"
    />
    <path
      stroke="#F60"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M81 59h12.745c1.877 0 1.877 1.319 1.877 2 0 6.627 5.496 12 12.276 12 6.78 0 12.276-5.373 12.276-12 0-.681 0-2 1.876-2H147m-73.426 0h2.427-2.427Z"
    />
    <path
      stroke="#E9E9E9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M133.1 6.303 122 18.756M107.1 2v16.756V2ZM81 6.303l11.1 12.453L81 6.303Z"
    />
  </svg>
);
export default SvgEmptyBox;
