import type { FiatCurrencyCode } from '@noah-labs/noah-schema';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { BigNumber } from 'bignumber.js';
import type { TpAmount, TpSimpleAmount } from './types';

const satDecimalPlaces = 8;
const mSatDecimalPlaces = 11;

// Overloads and implementation for satsToBtc
export function satsToBtc(sats: undefined | null): undefined;
export function satsToBtc(sats: string | number): string;
export function satsToBtc(sats: TpAmount): TpSimpleAmount;
export function satsToBtc(sats: TpAmount): TpSimpleAmount {
  if (isUndefinedOrNull(sats)) {
    return undefined;
  }
  const btc = safeBN(sats).shiftedBy(-satDecimalPlaces);
  return btc.toString();
}

// Overloads and implementation for mSatsToBtc
export function mSatsToBtc(mSats: undefined | null): undefined;
export function mSatsToBtc(mSats: string | number): string;
export function mSatsToBtc(mSats: TpAmount): TpSimpleAmount;
export function mSatsToBtc(mSats: TpAmount): TpSimpleAmount {
  if (isUndefinedOrNull(mSats)) {
    return undefined;
  }
  const btc = safeBN(mSats).shiftedBy(-mSatDecimalPlaces);
  return btc.toString();
}

// Overloads and implementation for fiatToMinorUnit
export function fiatToMinorUnit(fiat: undefined | null): undefined;
export function fiatToMinorUnit(fiat: string | number): string;
export function fiatToMinorUnit(fiat: TpAmount): TpSimpleAmount;
export function fiatToMinorUnit(fiat: TpAmount): TpSimpleAmount {
  if (isUndefinedOrNull(fiat)) {
    return undefined;
  }
  const minorUnit = safeBN(fiat).shiftedBy(2);
  return minorUnit.toString();
}

// Overloads and implementation for btcToSats
export function btcToSats(btc: undefined | null): undefined;
export function btcToSats(btc: string | number): string;
export function btcToSats(btc: TpAmount): TpSimpleAmount;
export function btcToSats(btc: TpAmount): TpSimpleAmount {
  if (isUndefinedOrNull(btc)) {
    return undefined;
  }
  const sats = safeBN(btc).shiftedBy(satDecimalPlaces).integerValue(BigNumber.ROUND_DOWN);
  return sats.toString();
}

export function formatFiatNoDecimals(
  amount: string | number,
  currency: FiatCurrencyCode,
  locale?: string
): string {
  const formatter = new Intl.NumberFormat(locale, {
    compactDisplay: 'short',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    notation: 'standard',
    style: 'currency',
  });

  const intAmount = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  return formatter.format(intAmount);
}
