import type { QuestStatus } from '@noah-labs/noah-schema';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import type { UseQueryResult } from 'react-query';
import { useQuestsAndBadgesQuery } from '../data/rewards.generated';

export function useQuestStatusById(
  id: string | undefined | null
): UseQueryResult<QuestStatus | undefined> {
  return useQuestsAndBadgesQuery(undefined, {
    enabled: !isUndefinedOrNull(id),
    select: (data) =>
      data.questsAndBadges.QuestStatuses.find((qs) => compareStrings(qs.Quest.QuestID, id)),
  });
}
