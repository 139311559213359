import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { useHistory } from 'react-router-dom';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import type { TpAccountVerificationSubmitValues } from '../scenes/AccountVerification';
import { AccountVerificationScene } from '../scenes/AccountVerification';

export function AccountVerification({
  state,
  updateState,
}: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();

  const handleSave = useCallback(
    (values: TpAccountVerificationSubmitValues) => {
      updateState({
        userKycInput: {
          ...state.userKycInput,
          UsTaxpayer: values.UsTaxpayer,
          VerificationConsent: values.VerificationConsent,
        },
      });
      history.push(routes.location.path);
    },
    [history, updateState, state.userKycInput]
  );

  const verificationConsentFormState = state.userKycInput?.VerificationConsent
    ? { VerificationConsent: state.userKycInput.VerificationConsent }
    : undefined;

  return (
    <AccountVerificationScene
      verificationConsentFormState={verificationConsentFormState}
      onSubmit={handleSave}
    />
  );
}
