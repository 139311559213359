import { useCallback, useEffect } from 'react';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { AppHeaderData } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { useSumsubAccessTokenMutation } from '../../sumsub';
import { useUserInit } from '../../user/data/useUserInit';
import { routes } from '../routes';

export function Sumsub(): React.ReactElement {
  const {
    data: sumSubData,
    error: sumSubError,
    isLoading: isSumsubLoading,
    mutateAsync: sumSubAccessToken,
  } = useSumsubAccessTokenMutation();

  const auth = useAuth();
  const { invalidate: userInitInvalidate } = useUserInit();
  const history = useHistory();

  const accessToken = sumSubData?.sumSubAccessToken.Token;

  const getAccessToken = useCallback(async (): Promise<string | undefined> => {
    try {
      const { sumsub } = webConfigBrowser;
      if (!sumsub.fullLevelName) {
        logger.error('sumsub level name is not defined');
        return undefined;
      }

      const data = await sumSubAccessToken({
        Input: {
          LevelName: sumsub.fullLevelName,
        },
      });
      return data.sumSubAccessToken.Token;
    } catch (err) {
      logger.error(err);
      return undefined;
    }
  }, [sumSubAccessToken]);

  const onMessage = useCallback(
    async (event: string) => {
      switch (event) {
        case 'idCheck.onApplicantSubmitted':
          try {
            await userInitInvalidate();
            history.replace(routes.sumsub.complete.path);
          } catch (err) {
            logger.error(err);
          }
          break;

        default:
      }
    },
    [userInitInvalidate, history]
  );

  useEffect(() => {
    void getAccessToken();
  }, [getAccessToken]);

  if (sumSubError) {
    return <ErrorPage message="Sorry, something went wrong" />;
  }

  if (isSumsubLoading || auth.isLoading || !accessToken) {
    return <LoadingPage />;
  }

  return (
    <AppContainer AppHeaderSlot={<AppHeaderData backButton exitButton helpButton />}>
      <Helmet>
        <title>{routes.sumsub.base.title}</title>
      </Helmet>
      <SceneMain>
        <SumsubWebSdk
          accessToken={accessToken}
          config={{
            email: auth.data?.email,
          }}
          expirationHandler={getAccessToken}
          onMessage={onMessage}
        />
      </SceneMain>
    </AppContainer>
  );
}
