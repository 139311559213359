import { useMemo } from 'react';
import { AccountCardList } from '@noah-labs/core-web-ui/src/lists/AccountCardList';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Feature } from '@noah-labs/noah-schema';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-tools/src/browser/arrays';
import { useFeature } from '../../../user/hooks/useFeature';
import { sortFilterWithFallback, useAccountsQueryPoll } from '../../data';
import { routes } from '../../routes';
import { getFeatureCurrencies } from '../../utils/getFeatureCurrencies';
import { AccountCardData } from '../balance/AccountCardData';

export function AccountCardListData(): React.ReactElement {
  const checkoutBuyFF = useFeature(Feature.CheckoutBuy);
  const depositFF = useFeature(Feature.Deposit);

  const { data: accountsData, isFetched } = useAccountsQueryPoll();

  const sortedAccounts = useMemo(
    () =>
      sortFilterWithFallback(accountsData?.accounts.items, {
        currencies: getFeatureCurrencies([checkoutBuyFF, depositFF]),
        isFeatureEnabled: !!checkoutBuyFF?.Enabled || !!depositFF?.Enabled,
      }),
    [accountsData?.accounts.items, checkoutBuyFF, depositFF]
  );

  return (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(sortedAccounts) &&
        sortedAccounts.map((account) => (
          <AccountCardData
            key={account.ID}
            Balance={account.Balance}
            buttonSx={{ ':hover': { backgroundColor: 'inherit' } }}
            CurrencyCode={account.CurrencyCode}
            href={generatePath(routes.account.base.path, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            ID={account.ID}
          />
        ))}
    </AccountCardList>
  );
}
