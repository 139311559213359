import type { ListItemTextProps } from '@mui/material';
import { CardContent } from '@mui/material';
import { StructuredContent } from '../lists/atoms/StructuredContent';
import { ElevatedCard } from './ElevatedCard';

type PpContentCard = {
  Icon: React.ReactElement;
  endText?: ListItemTextProps;
  mainText?: ListItemTextProps;
};

export function ContentCard({ endText, Icon, mainText }: PpContentCard): React.ReactElement {
  return (
    <ElevatedCard>
      <CardContent>
        <StructuredContent
          endText={{
            primaryTypographyProps: {
              color: 'grayscale.500',
              variant: 'paragraphBodySBold',
            },
            secondaryTypographyProps: {
              variant: 'paragraphBodyS',
            },
            ...endText,
          }}
          Icon={Icon}
          mainText={{
            primaryTypographyProps: {
              color: 'grayscale.500',
              variant: 'paragraphBodySBold',
            },
            ...mainText,
          }}
        />
      </CardContent>
    </ElevatedCard>
  );
}
