import { useCallback } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { parseMarketingUrl } from '@noah-labs/core-web-ui/src/tools/parseMarketingUrl';
import { Feature } from '@noah-labs/noah-schema';
import { useHistory } from 'react-router-dom';
import { isProd } from '../../../../webConfigBrowser';
import { useFeature } from '../../../user/hooks/useFeature';
import { useWalletParams } from '../../data';
import { useAddress } from '../../hooks';
import { routes } from '../../routes';
import { AddressScanScene } from '../../scenes';
import { handleParseScannedAddress } from '../../utils/address';

type PpAddressScan = {
  handleScannedAddress: (scanData: string) => void;
};

export function AddressScan({ handleScannedAddress }: PpAddressScan): React.ReactElement {
  const { params } = useWalletParams();
  const history = useHistory();
  const pushAlert = usePushAlert();
  const withdrawFF = useFeature(Feature.Withdraw);

  const { setScannerUnavailable } = useAddress();

  const handleAddress = useCallback(
    (qrAddress: string) => {
      if (!withdrawFF?.Networks) {
        return;
      }

      const marketingUrl = parseMarketingUrl(qrAddress);
      if (marketingUrl) {
        history.push(marketingUrl);
        return;
      }

      const addressData = handleParseScannedAddress({
        address: qrAddress,
        availableNetworks: withdrawFF.Networks,
        isProd,
      });

      if (!addressData) {
        pushAlert({
          dismissable: true,
          key: 'addressError',
          message: 'That doesn’t look like a valid address, please try again.',
          preventDuplicate: true,
          severity: 'error',
        });
        return;
      }

      handleScannedAddress(addressData.address);
      history.push(generatePath(routes.address.text.path, params));
    },
    [history, params, withdrawFF?.Networks, pushAlert, handleScannedAddress]
  );

  return (
    <AddressScanScene
      onSuccess={handleAddress}
      onUnavailable={(): void => setScannerUnavailable(true)}
    />
  );
}
