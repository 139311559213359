import { TpFFUI } from '@noah-labs/core-web-ui/src/types';
import { KycApprovalStatus } from '@noah-labs/noah-schema';
import { useUserInit } from '../data/useUserInit';
import { useFeature } from './useFeature';

export function useKycNotApprovedAndCheckoutEnabled(): boolean {
  const { data } = useUserInit();
  const fbuy = useFeature(TpFFUI.CheckoutBuyRoutes);
  const fsell = useFeature(TpFFUI.CheckoutSellRoutes);

  return (
    Boolean(fbuy?.Enabled || fsell?.Enabled) &&
    data?.userProfile.KycVerification.ApprovalStatus !== KycApprovalStatus.Approved
  );
}
