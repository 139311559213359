import { useCallback } from 'react';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/noah-schema';
import { btcToSats } from '@noah-labs/shared-currencies/src/conversions';
import { isBTC } from '@noah-labs/shared-currencies/src/isBTC';
import { zeroToBlankOrValue } from '@noah-labs/shared-tools/src/browser/numbers';
import { useFormContext } from 'react-hook-form';
import { useOptimisticUserInitOptions } from '../../../../user/data/useOptimisticUserInitOptions';
import { useUpdateUserPrimaryCurrencySettingMutation } from '../../../../user/data/user.generated';
import type { TpAmountForm } from '../../../scenes';
import { TpDualCurrencyAmountFieldNames } from './types';

type PpUseCurrencySwitch = {
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit;
  currentPrimaryCurrency: CurrencyDisplayType | null | undefined;
};
type TpUseCurrencySwitch = {
  handleCurrencySwitch: () => Promise<void>;
};
export function useCurrencySwitch({
  cryptoCurrency,
  cryptoUnit,
  currentPrimaryCurrency,
}: PpUseCurrencySwitch): TpUseCurrencySwitch {
  const updateOptions = useOptimisticUserInitOptions();
  const { mutateAsync } = useUpdateUserPrimaryCurrencySettingMutation(updateOptions);
  const { getValues, setValue } = useFormContext<TpAmountForm>();

  const handleCurrencySwitch = useCallback(async () => {
    // use getValues in here so we don't need to recreate this callback on every value change
    const { cryptoAmount, fiatAmount, primaryAmount } = getValues();

    let newPrimaryCurrency;
    let newPrimaryAmount;
    switch (currentPrimaryCurrency) {
      case CurrencyDisplayType.Fiat:
        newPrimaryCurrency = CurrencyDisplayType.Crypto;
        newPrimaryAmount = cryptoAmount;
        // if user has sats selected as the display currency, the secondary amount will still be in BTC
        // hence before copying to the primary value, we have to convert it back to SATS
        if (cryptoUnit === CurrencyUnit.SATS && isBTC(cryptoCurrency.code)) {
          newPrimaryAmount = btcToSats(newPrimaryAmount);
        }
        break;

      case CurrencyDisplayType.Crypto:
        newPrimaryCurrency = CurrencyDisplayType.Fiat;
        newPrimaryAmount = fiatAmount;
        break;
      default:
        // user not loaded, not possible in reality
        return;
    }

    // Optimistically switch amounts as the currency switch is also an optimistic update
    setValue(TpDualCurrencyAmountFieldNames.primary, zeroToBlankOrValue(newPrimaryAmount));

    const Input = {
      PrimaryCurrency: newPrimaryCurrency,
    };

    try {
      await mutateAsync({ Input });
    } catch (error) {
      // On error, reset primary amount to it's previous value
      // which at this point is still primaryAmount as the
      // update hasn't happened on this function declaration
      setValue(TpDualCurrencyAmountFieldNames.primary, zeroToBlankOrValue(primaryAmount));
    }
  }, [currentPrimaryCurrency, cryptoCurrency.code, cryptoUnit, getValues, mutateAsync, setValue]);

  return {
    handleCurrencySwitch,
  };
}
