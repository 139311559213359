/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import type { ListItemButtonProps, ListItemProps, TypographyProps } from '@mui/material';
import {
  ListItemIcon as MuiListIcon,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { InlineLinkOrButton } from '../buttons/InlineLinkOrButton';
import { ElevatedCard } from '../cards/ElevatedCard';

const StyledListItemButton = styled(MuiListItemButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  padding: 0,
  width: '100%',
})) as typeof MuiListItemButton;

export function ListItemButton({
  children,
  ...props
}: ListItemButtonProps & { href?: string }): ReactElement {
  return (
    <StyledListItemButton component={InlineLinkOrButton} {...props}>
      {children}
    </StyledListItemButton>
  );
}

export function ListItem({ children, ...props }: ListItemProps): ReactElement {
  return (
    <MuiListItem disablePadding {...props}>
      {children}
    </MuiListItem>
  );
}

export const ListItemCard = styled(ElevatedCard)({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
});

export const ListItemContent = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  padding: theme.spacing(2),
  width: '100%',
}));

export const ListItemStartContent = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
});

export const ListItemEndContent = styled(Stack)({
  justifyContent: 'center',
  textAlign: 'right',
});

export function ListItemPrimaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography variant="paragraphBodyMBold" {...props}>
      {children}
    </Typography>
  );
}

export function ListItemSecondaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography color="text.secondary" variant="paragraphBodyS" {...props}>
      {children}
    </Typography>
  );
}

export const ListItemIcon = MuiListIcon;
