import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { KeyholeIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneIcon } from '@noah-labs/core-web-ui/src/scene/SceneIcon';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes as walletRoutes } from '../../wallet/routes';

export function CompletePendingScene(): React.ReactElement {
  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <PrimaryButton data-qa="done-button" href={walletRoutes.base.path}>
          Done
        </PrimaryButton>
      }
      AppHeaderSlot={<AppHeaderData disableFade exitButton helpButton />}
      dataQa="completePending"
    >
      <Helmet>
        <title>Verification in process</title>
      </Helmet>
      <SceneMain>
        <SceneIcon>
          <KeyholeIcon />
        </SceneIcon>
        <SceneTitleLarge>Verification in process</SceneTitleLarge>
        <SceneParagraph>
          Thanks for submitting your documentation to keep your account safe from fraud. We usually
          verify within 24 hours, but in some rare cases it can take up to 48 hours.
        </SceneParagraph>
        <SceneParagraph>You can check the status of your verification in settings.</SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
