import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpIcon } from '../types';

type PpCurrencyAvatar = {
  Icon: TpIcon | null;
  iconCss?: SerializedStyles;
  size?: number;
};

export function CurrencyAvatar({
  Icon,
  iconCss,
  size = 4,
}: PpCurrencyAvatar): React.ReactElement | null {
  const theme = useTheme();
  if (!Icon) {
    return null;
  }

  const iconSize = theme.spacing(size);

  const styles = {
    avatar: css`
      font-size: ${iconSize};
      height: 1em;
      width: 1em;

      &.MuiAvatar-root {
        background-color: transparent;
      }
    `,
    icon: css`
      font-size: inherit;
    `,
  };

  const iconStyles = [styles.icon];
  if (iconCss) {
    iconStyles.push(iconCss);
  }

  return (
    <Avatar css={styles.avatar}>
      <Icon css={iconStyles} />
    </Avatar>
  );
}
