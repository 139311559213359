/* eslint-disable react/no-array-index-key */
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { TextOrSkeleton } from '../../typography/TextOrSkeleton';

type PpSkeletonSelectList = {
  rows?: number;
};

export function SkeletonSelectList({ rows = 5 }: PpSkeletonSelectList): React.ReactElement {
  return (
    <List aria-hidden="true">
      {new Array(rows).fill(null).map((_, index) => (
        <ListItem key={index} divider>
          <ListItemIcon sx={{ color: 'inherit' }}>&nbsp;</ListItemIcon>
          <ListItemText primary={<TextOrSkeleton loading>&nbsp;</TextOrSkeleton>} />
        </ListItem>
      ))}
    </List>
  );
}
