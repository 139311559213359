import type { ReactElement } from 'react';
import { BitcoinIcon } from '@noah-labs/core-web-ui/src/icons';
import { Avatar, AvatarBadge, AvatarIcon } from '@noah-labs/core-web-ui/src/images';
import { cryptoCurrencyFromCode } from '../../../utils';
import { TransactionStatusBadge } from '../../wallet/components/transactions/atoms/TransactionStatusBadge';
import { getTransactionType } from '../../wallet/utils/getTransactionType';
import type { TransactionNotification } from '../types';

type PpTransactionNotificationIcon = {
  payload: TransactionNotification;
};

export function TransactionNotificationIcon({
  payload,
}: PpTransactionNotificationIcon): ReactElement {
  const transactionType = getTransactionType(payload.sourceType === 'Checkout', payload.direction);

  const Icon = cryptoCurrencyFromCode(payload.currency).Icon || BitcoinIcon;

  return (
    <Avatar>
      <AvatarIcon>
        <Icon />
      </AvatarIcon>

      <AvatarBadge>
        <TransactionStatusBadge size={2} status={payload.status} type={transactionType} />
      </AvatarBadge>
    </Avatar>
  );
}
