import { useEffect } from 'react';
import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { CurrencyUnit, SardineFlows } from '@noah-labs/noah-schema';
import { Route } from 'react-router-dom';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { AuthRouteData } from '../../../auth';
import { useSardineFlow } from '../../../sardine';
import { findDepositAddress, useAccountCreateMutation, useWalletParams } from '../../data';
import { useWalletError } from '../../hooks';
import { routes } from '../../routes';
import type { TpPaymentCard, TpPaymentMethod } from '../../types';
import { BuyWithMoonpay } from './BuyWithMoonpay';
import { Complete } from './Complete';
import { CompleteWithMoonpay } from './CompleteWithMoonpay';
import { Confirm } from './Confirm';
import { EnterAmount } from './EnterAmount';
import { EnterPaymentInfo } from './EnterPaymentInfo';

export type StBuyRouter = {
  cryptoAmount: string;
  cryptoUnit: CurrencyUnit;
  fetchedAt: string;
  fiatAmount: string;
  paymentMethod?: TpPaymentMethod;
  savePaymentCard?: boolean;
  selectedPaymentCard?: TpPaymentCard;
  transactionId?: string;
};

const emptyState: StBuyRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  paymentMethod: undefined,
  savePaymentCard: undefined,
  selectedPaymentCard: undefined,
  transactionId: undefined,
};

export function BuyRouter(): React.ReactElement {
  // the user could be using Moonpay, but doesn't matter since we'd only check this if it through CKO
  useSardineFlow({ flow: SardineFlows.CheckoutBuy });
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    mutate: accountCreate,
  } = useAccountCreateMutation();

  const address = findDepositAddress(accountCreateData?.accountCreate);

  const initialState = { ...emptyState };

  const sm = useStateMachine<StBuyRouter>({
    emptyState,
    initialState,
    name: 'BuyRouter',
  });

  useEffect(() => {
    // If accountCreate has run or there is already an address, don't do anything
    if (!accountCreateIdle || address) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({ Input: { AccountType, CurrencyCode } });
  }, [AccountType, CurrencyCode, accountCreate, accountCreateIdle, address, cryptoCurrency, sm]);

  const { ApiErrorScene } = useWalletError(accountCreateErr);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <Switch404>
      <Route
        exact
        path={routes.buy.moonpay.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.buy.moonpay}>
            <BuyWithMoonpay walletAddress={address} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={[routes.buy.enterAmount.path, routes.buy.payment.path, routes.buy.confirm.path]}
      >
        <Route
          exact
          path={[routes.buy.enterAmount.path]}
          render={(): React.ReactElement => (
            <AuthRouteData
              featureRedirect={generatePath(routes.buy.moonpay.path, params)}
              route={routes.buy.enterAmount}
            >
              <EnterAmount {...sm} />
            </AuthRouteData>
          )}
        />
        {/* Keep screen in the DOM to preload Frames card fields */}
        <Route
          exact
          path={[routes.buy.enterAmount.path, routes.buy.payment.path, routes.buy.confirm.path]}
          render={(): React.ReactElement => (
            <AuthRouteData route={routes.buy.payment}>
              <EnterPaymentInfo {...sm} />
            </AuthRouteData>
          )}
        />
        <Route
          exact
          path={routes.buy.confirm.path}
          render={(): React.ReactElement => (
            <AuthRouteData route={routes.buy.confirm}>
              <Confirm {...sm} />
            </AuthRouteData>
          )}
        />
      </Route>

      <Route
        exact
        path={routes.buy.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.buy.complete}>
            <Complete {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.buy.moonpayComplete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.buy.moonpayComplete}>
            <CompleteWithMoonpay />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
