/* eslint-disable react/iframe-missing-sandbox */
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { BitrefillService } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { parseAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { FiatAmountInput } from '@noah-labs/noah-schema';
import {
  CurrencyCode,
  Network,
  TransferDestinationType,
  TransferDestinationTypeInput,
} from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode, getDefaults } from '../../../../utils';
import { networkForEnv } from '../../../../utils/networks';
import { isProd } from '../../../../webConfigBrowser';
import { getAppType } from '../../../auth/utils';
import type { TpOnSign } from '../../../signing/controllers/Sign';
import { useSignWithdrawal } from '../../../signing/controllers/useSignWithdrawal';
import {
  useCalculateFiatFromCrypto,
  useLnPaymentSendMutation,
  useWithdrawOrderCreateMutation,
} from '../../../wallet/data';
import { useWalletError } from '../../../wallet/hooks';
import { useWithdrawalAllowance } from '../../../wallet/hooks/useWithdrawalAllowance';
import {
  InsufficientBalance,
  LightningLimitReached,
  ParsingLnPaymentError,
  SomethingWentWrong,
  UnsupportedCurrency,
} from '../Alerts';
import { Complete } from './Complete';
import { Confirm } from './Confirm';

const defaults = getDefaults();

type PpBitrefill = {
  email: string | undefined;
};

export function Bitrefill({ email }: PpBitrefill): React.ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const searchParams = useSearchParams();
  const [addressData, setAddressData] = useState<TpAddressData | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [service, resetService] = useReducer(() => new BitrefillService(), new BitrefillService());
  const url = useMemo(() => {
    if (!email) {
      return undefined;
    }

    const appType = getAppType();

    return service.iframeUrl(email, appType);
  }, [email, service]);

  const cryptoCurrency = cryptoCurrencyFromCode(defaults.cryptoCurrency.code);
  const allowance = useWithdrawalAllowance({
    accountType: defaults.accountType,
    cryptoCurrency,
    network: networkForEnv(Network.Bitcoin),
  });
  const { fiatCurrency } = useUserFiatCurrency();

  const { data: priceData, isLoading: isPriceLoading } = useCalculateFiatFromCrypto({
    cryptoAmount: service.paymentInfo?.amount,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });
  const [hasDismissed, dismiss] = useReducer(() => true, false);
  const pushAlert = usePushAlert();
  const [iframeKey, setIframeKey] = useState('bitrefill');

  const styles = {
    hideIframe: css`
      visibility: hidden;
    `,
    iframe: css`
      border: 0;
      flex-grow: 1;
    `,
  };

  const forceBtc = searchParams?.has('btc');

  const {
    error: lnPaymentSendError,
    isLoading: lnPaymentSendIsLoading,
    mutateAsync: lnPaymentSend,
    status: lnPaymentSendStatus,
  } = useLnPaymentSendMutation();

  const {
    error: btcPaymentError,
    isLoading: btcPaymentLoading,
    mutateAsync: btcPaymentSend,
    status: btcPaymentSendStatus,
  } = useWithdrawOrderCreateMutation();

  useEffect(() => {
    if (!service.unsupportedCurrency) {
      return;
    }

    pushAlert(UnsupportedCurrency);
  }, [pushAlert, service.unsupportedCurrency]);

  useEffect(() => {
    setShowConfirmDialog(service.awaitingPayment === true);
  }, [service.awaitingPayment]);

  const showCompleteDialog =
    !hasDismissed &&
    (lnPaymentSendStatus === 'success' || btcPaymentSendStatus === 'success') &&
    service.awaitingPayment === false;

  const invoiceAmount = useMemo(
    () => safeBN(service.paymentInfo?.amount),
    [service.paymentInfo?.amount]
  );

  const isEligibleForLn =
    allowance?.lnAllowanceCrypto && allowance.lnAllowanceCrypto.amount.gt(invoiceAmount);

  const payWithLightning = !forceBtc && isEligibleForLn;

  /**
   * Resets the payment flow and refreshes the iframe
   * Basket is persisted by cookies
   */
  const cancelPayment = useCallback(() => {
    setShowConfirmDialog(false);
    resetService();
    setIsLoading(true);
    // setting a key for the iframe and changing actually refreshes the content within it
    setIframeKey(`bitrefill-${Math.random() * 100}`);
  }, []);

  /**
   * Submit payment for the LN invoice if possible
   * Otherwise, try to submit a BTC payment
   */
  const submitPayment = useCallback(
    async ({ signature }: TpOnSign) => {
      if (!priceData) {
        return;
      }
      if (!service.paymentInfo || !allowance) {
        pushAlert(SomethingWentWrong);
        cancelPayment();
        return;
      }

      const insufficientFunds =
        allowance.balanceUserCrypto && invoiceAmount.gt(allowance.balanceUserCrypto);

      if (insufficientFunds) {
        pushAlert(InsufficientBalance);
        cancelPayment();
        return;
      }

      if (!isEligibleForLn) {
        pushAlert(LightningLimitReached);
      }

      const fiatAmountRequested: FiatAmountInput = {
        Amount: priceData.fiatAmount,
        FetchedAt: priceData.fetchedAt,
        FiatCurrency: fiatCurrency.code,
        Price: priceData.price,
      };

      try {
        if (payWithLightning) {
          await lnPaymentSend({
            Input: {
              AccountType: defaults.accountType,
              Amount: service.paymentInfo.amount,
              CurrencyCode: CurrencyCode.BTC,
              DestinationType: TransferDestinationTypeInput.Bitrefill,
              PaymentRequest: service.paymentInfo.lnInvoice || '',
              RequestedAmount: fiatAmountRequested,
              ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            },
          });
        } else {
          await btcPaymentSend({
            Input: {
              AccountType: defaults.accountType,
              Amount: service.paymentInfo.amount,
              CurrencyCode: defaults.cryptoCurrency.code,
              Destination: {
                DestinationAddress: {
                  Address: service.paymentInfo.address,
                },
                DestinationType: TransferDestinationType.Bitrefill,
                ID: service.paymentInfo.invoiceId,
              },
              RequestedAmount: fiatAmountRequested,
              ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            },
          });
        }

        service.awaitingPayment = false;
        setShowConfirmDialog(false);
      } catch (e) {
        logger.error(e);
        pushAlert(SomethingWentWrong);
        cancelPayment();
      }
    },
    [
      priceData,
      service,
      allowance,
      invoiceAmount,
      isEligibleForLn,
      fiatCurrency.code,
      pushAlert,
      cancelPayment,
      payWithLightning,
      lnPaymentSend,
      btcPaymentSend,
    ]
  );

  const { loading: signLoading, sign } = useSignWithdrawal({
    network: payWithLightning ? networkForEnv(Network.Lightning) : networkForEnv(Network.Bitcoin),
    payload: {
      AccountType: defaults.accountType,
      Amount: service.paymentInfo?.amount || '',
      CurrencyCode: defaults.cryptoCurrency.code,
      Destination: TransferDestinationTypeInput.Bitrefill,
      inputType: 'withdraw',
    },
  });

  const onConfirm = useCallback(async () => {
    await sign(submitPayment);
  }, [sign, submitPayment]);

  const handleMessage = useCallback(
    (event: MessageEvent): void => {
      const { data, origin } = event;
      if (origin !== service.messageOrigin) {
        return;
      }
      service.parseMessage(data);
      try {
        if (service.paymentInfo?.lnInvoice) {
          setAddressData(parseAddressData(service.paymentInfo.lnInvoice, isProd));
        }
      } catch (err) {
        logger.error('Error parsing LN payment request', err);
        pushAlert(ParsingLnPaymentError);
      }
    },
    [pushAlert, service]
  );

  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('message', handleMessage, {
      signal: abortController.signal,
    });
    return () => {
      abortController.abort();
    };
  });

  const submitButtonLoading =
    signLoading || lnPaymentSendIsLoading || btcPaymentLoading || isPriceLoading;

  const { ApiErrorScene } = useWalletError(lnPaymentSendError || btcPaymentError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <Fragment>
      {isLoading && <LoadingPage sx={{ inset: 0, position: 'absolute' }} />}
      {url && (
        <iframe
          key={iframeKey}
          ref={iFrameRef}
          css={isLoading ? styles.hideIframe : styles.iframe}
          height="100%"
          sandbox="allow-popups allow-same-origin allow-scripts allow-forms"
          src={url}
          title="Bitrefill"
          width="100%"
          onLoad={(): void => setIsLoading(false)}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      )}
      <Confirm
        addressData={addressData}
        cancelPayment={cancelPayment}
        email={email}
        loadedPrice={priceData?.price}
        open={showConfirmDialog}
        submitLoading={submitButtonLoading}
        onConfirm={onConfirm}
      />
      <Complete open={showCompleteDialog} onDismiss={dismiss} />
    </Fragment>
  );
}
