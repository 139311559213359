import { useCallback } from 'react';
import { ButtonBase } from '@mui/material';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { CurrencySwitchButton } from '@noah-labs/core-web-ui/src/buttons/CurrencySwitchButton';
import { CurrencyDisplayType } from '@noah-labs/noah-schema';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { useOptimisticUserInitOptions } from '../../../user/data/useOptimisticUserInitOptions';
import { useUpdateUserPrimaryCurrencySettingMutation } from '../../../user/data/user.generated';
import { useUserInit } from '../../../user/data/useUserInit';
import {
  defaultAccount,
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
  useCalculateFiatFromCrypto,
  useWalletParams,
} from '../../data';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';

/**
 * Displays a known crypto currency amount and calculates an approximate fiat amount
 */

export function CurrentBalanceData(): React.ReactElement {
  const { AccountType, CurrencyCode } = useWalletParams({
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  });
  const { fiatCurrency } = useUserFiatCurrency();

  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) =>
      selectFromFilterWithFallback({
        AccountType,
        CurrencyCode,
        data,
      }),
  });
  const cryptoBalance = account?.Balance?.Available;
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });
  const fiatAmount = priceData?.fiatAmount;

  const { data: userInitData } = useUserInit();
  const updateOptions = useOptimisticUserInitOptions();
  const { mutate } = useUpdateUserPrimaryCurrencySettingMutation(updateOptions);

  const handleClick = useCallback(() => {
    mutate({
      Input: {
        PrimaryCurrency:
          userInitData?.userProfile.PrimaryCurrency === CurrencyDisplayType.Fiat
            ? CurrencyDisplayType.Crypto
            : CurrencyDisplayType.Fiat,
      },
    });
  }, [mutate, userInitData?.userProfile.PrimaryCurrency]);

  const Amounts = useCurrencyAmounts({
    cryptoAmount: cryptoBalance,
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
    roundDown: true,
  });

  return (
    <ButtonBase sx={{ width: '100%' }} onClick={handleClick}>
      <Balance {...Amounts} IconSlot={<CurrencySwitchButton />} />
    </ButtonBase>
  );
}
