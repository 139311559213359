import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { BankIcon } from '@noah-labs/core-web-ui/src/icons';
import { MastercardLight, VisaLight } from '@noah-labs/core-web-ui/src/images';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';

export type PpNewPayoutMethodButton = {
  feeAndEta: string;
  href?: string;
  onClick?: () => void;
  type: 'bankAccount' | 'card';
};

export function NewPayoutMethodButton({
  feeAndEta,
  href,
  onClick,
  type,
}: PpNewPayoutMethodButton): React.ReactElement {
  const theme = useTheme();

  const styles = {
    buttonSx: {
      ':hover': {
        backgroundColor: 'inherit',
      },
      flexWrap: 'wrap',
      // static height from Figma
      height: '73px',
      padding: theme.spacing(1.25, 2),
    },
    cardIcon: css`
      height: 24px;
    `,
  };

  let IconSlot: React.ReactElement;
  let title: string;

  switch (type) {
    case 'bankAccount':
      IconSlot = <BankIcon css={styles.cardIcon} sx={{ color: 'common.black' }} />;
      title = 'Bank transfer';
      break;

    case 'card':
    default:
      IconSlot = (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <VisaLight css={styles.cardIcon} />
          <MastercardLight css={styles.cardIcon} />
        </Stack>
      );
      title = 'New credit/debit card';
      break;
  }

  return (
    <ElevatedCardListItem
      buttonSx={styles.buttonSx}
      dataQa={`new-${type}`}
      href={href}
      spacing={1}
      onClick={onClick}
    >
      <StructuredContent
        endText={{
          primary: IconSlot,
          sx: { display: 'flex', justifyContent: 'flex-end' },
        }}
        mainText={{
          primary: title,
          secondary: feeAndEta,
          sx: { mr: 1 },
        }}
        noWrap={false}
        sx={{ justifyContent: 'space-between' }}
      />
    </ElevatedCardListItem>
  );
}
