import type { PpHeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { HeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { CodeIcon } from '@noah-labs/core-web-ui/src/icons';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { defaultAccount } from '../../modules/wallet/data';
import { routes } from '../../modules/wallet/routes';

type PpScanIconButtonData = Pick<PpHeaderIconButton, 'edge'>;

export function ScanIconButtonData({ edge }: PpScanIconButtonData): React.ReactElement {
  const params = {
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  };

  return (
    <HeaderIconButton
      aria-label="scan"
      edge={edge}
      href={generatePath(routes.receive.base.path, params)}
    >
      <CodeIcon />
    </HeaderIconButton>
  );
}
