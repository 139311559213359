import type { UserBadge } from '@noah-labs/noah-schema';
import type { TpBadgeGridItem } from '../types';
import { badgeNames } from './badgeCopy';

export function getBadgeGridItem(ub: UserBadge): TpBadgeGridItem | null {
  if (!badgeNames[ub.BadgeTypeID]) {
    return null;
  }

  return {
    badgeId: ub.BadgeTypeID,
    earned: Boolean(ub.Earned),
    questId: ub.QuestID || undefined,
    stars: ub.Difficulty + 1,
  };
}
