import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import type { Maybe } from '@noah-labs/noah-schema';
import { Route, useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../webConfigBrowser';
import { AuthRouteData } from '../auth';
import { Username } from './controllers';
import { MarketingUpdates } from './controllers/MarketingUpdates';
import { TermsAndCookies } from './controllers/TermsAndCookies';
import { routes } from './routes';
import type { TpConsentFormValues } from './scenes';

export type StRegistration = Omit<TpConsentFormValues, 'EmailContact'> & {
  isDirty: boolean;
};

const emptyState = {
  Cookies: {
    advertising: false,
    functional: true,
    marketingAndAnalytics: false,
  },
  isDirty: false,
  Terms: true,
};

type PpRestrictedRouter = {
  logoutTo: string;
  successTo: string;
  usernameDisplay?: Maybe<string>;
};

/**
 * RestrictedRouter controls user's who still need to accept terms and / or set their username and security pin
 */
export function RestrictedRouter({
  logoutTo,
  successTo,
  usernameDisplay,
}: PpRestrictedRouter): React.ReactElement {
  const history = useHistory();
  const sm = useStateMachine<StRegistration>({ emptyState, name: 'registrationState' });

  return (
    <Switch404>
      <Route
        exact
        path={routes.username.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={Boolean(usernameDisplay) && successTo}
            route={routes.username}
          >
            <Username
              logoutTo={logoutTo}
              successTo={routes.consent.path}
              suffix={`@${webConfigBrowser.lnDomain}`}
            />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.consent.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={!usernameDisplay && routes.username.path}
            route={routes.consent}
          >
            <TermsAndCookies logoutTo={logoutTo} successTo={routes.acceptUpdates.path} {...sm} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.acceptUpdates.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.acceptUpdates}>
            <MarketingUpdates
              {...sm}
              nextAction={(): void => history.push(successTo)}
              nextActionDirty={(): void => window.location.assign(successTo)}
            />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
