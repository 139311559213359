import { SVGProps } from 'react';
const SvgApplepayLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 166 106" {...props}>
    <path
      fill="#000"
      d="M138.778 9.296H26.743c-.466 0-.934 0-1.4.003a57.94 57.94 0 0 0-1.179.018c-.857.023-1.72.074-2.567.226a8.668 8.668 0 0 0-2.44.804 8.19 8.19 0 0 0-3.586 3.587c-.397.78-.65 1.58-.803 2.44-.153.847-.204 1.71-.227 2.566-.01.393-.015.786-.018 1.179-.003.466-.002.933-.002 1.4v62.928c0 .467 0 .933.002 1.4.003.393.007.786.018 1.179.023.855.074 1.719.226 2.565.154.86.407 1.66.804 2.44.39.768.9 1.47 1.509 2.078.609.61 1.31 1.12 2.078 1.51.78.397 1.58.65 2.44.805.846.15 1.71.202 2.566.225.393.01.786.014 1.18.016.465.004.933.004 1.4.004h112.034c.466 0 .933 0 1.399-.004a65.64 65.64 0 0 0 1.18-.016c.855-.023 1.719-.074 2.567-.225a8.636 8.636 0 0 0 2.438-.806 8.204 8.204 0 0 0 3.587-3.587c.399-.78.651-1.58.804-2.44.153-.846.202-1.71.226-2.565.01-.393.016-.786.017-1.178.004-.468.004-.934.004-1.401V21.519c0-.467 0-.934-.004-1.4a51.448 51.448 0 0 0-.017-1.179c-.024-.856-.073-1.72-.226-2.566a8.557 8.557 0 0 0-.804-2.44 8.22 8.22 0 0 0-3.587-3.587 8.67 8.67 0 0 0-2.438-.804c-.848-.152-1.712-.203-2.567-.226a58.85 58.85 0 0 0-1.18-.018c-.466-.003-.933-.003-1.399-.003Z"
    />
    <path
      fill="#fff"
      d="m138.778 12.209 1.378.002c.373.003.747.007 1.122.017.653.018 1.417.053 2.129.18a5.76 5.76 0 0 1 1.637.535 5.295 5.295 0 0 1 2.312 2.315c.252.494.42 1.01.531 1.634.127.705.163 1.47.18 2.128.01.37.015.742.017 1.121.004.46.004.918.004 1.378v62.928c0 .46 0 .918-.004 1.387-.002.37-.007.742-.017 1.113-.017.656-.053 1.422-.182 2.134a5.632 5.632 0 0 1-.531 1.629 5.27 5.27 0 0 1-2.315 2.314 5.711 5.711 0 0 1-1.626.532c-.727.13-1.522.165-2.122.182a63.31 63.31 0 0 1-1.137.015c-.458.004-.918.004-1.376.004H26.725c-.453 0-.907 0-1.368-.004a61.959 61.959 0 0 1-1.114-.015c-.613-.017-1.41-.052-2.13-.18a5.721 5.721 0 0 1-1.638-.537 5.224 5.224 0 0 1-1.337-.973 5.239 5.239 0 0 1-.972-1.338 5.718 5.718 0 0 1-.533-1.637c-.128-.711-.163-1.474-.181-2.125-.01-.373-.014-.746-.017-1.117l-.002-1.095V21.23l.002-1.092c.003-.373.007-.746.017-1.12.017-.652.053-1.414.182-2.131a5.71 5.71 0 0 1 .533-1.634 5.274 5.274 0 0 1 2.313-2.311 5.738 5.738 0 0 1 1.632-.533c.713-.128 1.477-.163 2.132-.181.373-.01.746-.014 1.117-.017l1.382-.002h112.035Z"
    />
    <path
      fill="#000"
      d="M51.779 38.683c1.168-1.461 1.961-3.424 1.752-5.43-1.71.086-3.798 1.13-5.006 2.592-1.085 1.253-2.046 3.297-1.795 5.219 1.92.166 3.838-.96 5.049-2.38Zm1.731 2.756c-2.79-.166-5.16 1.583-6.492 1.583-1.332 0-3.372-1.5-5.578-1.459-2.87.042-5.534 1.665-6.991 4.247-2.996 5.165-.79 12.826 2.123 17.032 1.415 2.08 3.12 4.372 5.368 4.29 2.123-.084 2.955-1.375 5.536-1.375 2.579 0 3.328 1.375 5.576 1.333 2.33-.041 3.788-2.082 5.203-4.165 1.623-2.372 2.287-4.663 2.33-4.788-.043-.042-4.495-1.75-4.536-6.872-.042-4.288 3.495-6.328 3.662-6.455-1.998-2.954-5.12-3.287-6.201-3.371Zm24.281-5.804c6.062 0 10.282 4.178 10.282 10.261 0 6.105-4.307 10.304-10.434 10.304h-6.71v10.673h-4.85V35.635h11.712Zm-6.863 16.496h5.564c4.221 0 6.624-2.273 6.624-6.213s-2.403-6.191-6.602-6.191h-5.586V52.13ZM89.34 60.4c0-3.983 3.052-6.43 8.465-6.732l6.234-.368v-1.754c0-2.532-1.711-4.048-4.568-4.048-2.706 0-4.395 1.299-4.806 3.334H90.25c.26-4.113 3.767-7.144 9.395-7.144 5.52 0 9.049 2.923 9.049 7.49v15.695h-4.482v-3.745h-.107c-1.321 2.532-4.2 4.134-7.187 4.134-4.46 0-7.577-2.77-7.577-6.862Zm14.699-2.056v-1.797l-5.607.346c-2.793.195-4.373 1.43-4.373 3.377 0 1.992 1.645 3.29 4.156 3.29 3.269 0 5.824-2.25 5.824-5.216Zm8.884 16.906v-3.788c.346.086 1.125.086 1.515.086 2.165 0 3.334-.909 4.048-3.246 0-.044.411-1.386.411-1.408L110.672 44.1h5.065l5.758 18.53h.087l5.758-18.53h4.936l-8.53 23.964c-1.947 5.52-4.199 7.296-8.918 7.296-.39 0-1.559-.044-1.905-.109Z"
    />
  </svg>
);
export default SvgApplepayLight;
