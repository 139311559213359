import { useCallback } from 'react';
import { IncorrectPinError, LockedPinError, SafeWrapper } from '@noah-labs/core-services';
import type { UseFormSetError } from 'react-hook-form';
import type { TpPinForm } from '../../../utils';
import { useSigning } from '../context/useSigning';
import { SignDialog } from '../dialogs/Sign';
import type { TpPayloadSignature, TpSignable } from '../types';

export type FnOnSign = ({ signature }: TpOnSign) => Promise<void>;
export type TpOnSign = {
  signature?: TpPayloadSignature;
};

type PpSign = {
  onSign: FnOnSign;
  payload: TpSignable;
};

export function Sign({ onSign, payload }: PpSign): React.ReactElement {
  const { createSignature } = useSigning();

  /**
   * Signs the transcation with the given PIN.
   * Calls the onSign callback with the signature on success, otherwise throws an error
   * @param pin The PIN to sign the transaction with.
   * @returns A promise that resolves when the transaction has been signed.
   */
  const onSubmit = useCallback(
    async ({ pin }: TpPinForm, setError: UseFormSetError<TpPinForm>): Promise<void> => {
      try {
        const signature = await createSignature(new SafeWrapper(pin), payload);
        if (!signature) {
          throw new Error('Failed to create signature');
        }
        await onSign({ signature });
      } catch (error) {
        switch (true) {
          case error instanceof IncorrectPinError:
          case error instanceof LockedPinError:
            setError('pin', { message: (error as Error).message });
            break;
          default:
        }
      }
    },
    [createSignature, payload, onSign]
  );

  return <SignDialog onSubmit={onSubmit} />;
}
