import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../../components';

export type PpQuestionnaire = {
  onSubmit: () => void;
};

export function QuestionnaireScene({ onSubmit }: PpQuestionnaire): React.ReactElement {
  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          type="button"
          onClick={onSubmit}
        >
          Continue
        </PrimaryButton>
      }
      AppHeaderSlot={<AppHeaderData disableFade exitButton helpButton />}
      dataQa="questionnaire"
    >
      <Helmet>
        <title>Questionnaire</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>
          Almost done! We just need you to answer some final questions
        </SceneTitleMedium>
        <SceneParagraph>
          Although answering these may seem like a hassle, it’s a regulatory requirement we must
          follow to ensure we don’t cut corners and provide the safest experience for our users.
        </SceneParagraph>
      </SceneHeader>
    </AppContainer>
  );
}
