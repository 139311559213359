import { AuthGroups, Feature } from '@noah-labs/noah-schema';

const base = '/marketing';

const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  base: {
    feature: Feature.UIPromotions,
    groups,
    path: `${base}/:code`,
    title: 'Marketing',
  },
  scan: {
    feature: Feature.UIPromotions,
    groups,
    path: `${base}/scan`,
    title: 'Scan',
  },
};
