import React from 'react';
import { css } from '@emotion/react';
import { Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getTruncatedAddress } from '@noah-labs/core-web-ui/src/address/getTruncatedAddress';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { CryptoAmount } from '@noah-labs/core-web-ui/src/numbers/CryptoAmount';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { CurrencyCode, CurrencyUnit } from '@noah-labs/noah-schema';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { cryptoCurrencyFromCode } from '../../../../utils';

export type PpConfirm = {
  addressData: TpAddressData | null;
  cancelPayment: () => void;
  email: string | undefined;
  loadedPrice?: TpAmount | undefined;
  onConfirm: () => void;
  open: boolean;
  submitLoading: boolean;
};

export function Confirm({
  addressData,
  cancelPayment,
  email,
  loadedPrice,
  onConfirm,
  open,
  submitLoading,
}: PpConfirm): React.ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      margin-bottom: ${theme.spacing(2)};
      padding: ${theme.spacing(0, 1.5)};
    `,
    listItem: css`
      margin: 12px auto;
    `,
    notice: css`
      color: ${theme.palette.primary.main};
      margin: ${theme.spacing(0, 2, 3)};
    `,
    title: css`
      margin-bottom: ${theme.spacing(3)};
    `,
  };

  const formData = [
    {
      key: 'Email',
      value: getTruncatedAddress(email, undefined, 14),
    },
    {
      key: 'Invoice',
      value: addressData?.description,
    },
    {
      key: 'Amount',
      value: (
        <CryptoAmount
          amount={addressData?.amount}
          currency={cryptoCurrencyFromCode(CurrencyCode.BTC)}
          currencyUnit={CurrencyUnit.SATS}
        />
      ),
    },
  ];

  return (
    <LowDialog
      fullWidth
      data-qa="bitrefill-confirm-dialog"
      data-qa-price-loaded={loadedPrice ? 'true' : 'false'}
      fullScreen={window.innerHeight < 600} // content gets squished on small screens
      open={open}
      PaperProps={{
        sx: { padding: 3 },
      }}
    >
      <Stack css={styles.title} spacing={1}>
        <Typography variant="subHeadingL">Confirm Payment</Typography>
        <SceneParagraph>Your gift card purchase details</SceneParagraph>
      </Stack>
      <ElevatedCard css={styles.card} elevation={3}>
        <List disablePadding>
          {formData.map((item, i) => (
            <React.Fragment key={item.key}>
              <ListItem css={styles.listItem}>
                <StructuredContent
                  endText={{
                    primary: item.value,
                    primaryTypographyProps: {
                      variant: 'paragraphBodySBold',
                    },
                    style: {
                      maxWidth: '200px',
                    },
                  }}
                  mainText={{
                    primary: item.key,
                    primaryTypographyProps: {
                      color: theme.palette.grayscale[800],
                      variant: 'paragraphBodyS',
                    },
                  }}
                  noWrap={false}
                />
              </ListItem>
              {i < formData.length && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      </ElevatedCard>
      <Typography css={styles.notice} variant="paragraphBodyS">
        *Due to price volatility, there may be a small difference between the displayed amount and
        the amount paid.
      </Typography>
      <ButtonGroup>
        <PrimaryButton
          color="primaryBrand"
          data-qa="confirm-btn"
          loading={submitLoading}
          onClick={(): void => onConfirm()}
        >
          Confirm purchase
        </PrimaryButton>
        <PrimaryButton
          disabled={submitLoading}
          variant="text"
          onClick={(): void => cancelPayment()}
        >
          Cancel
        </PrimaryButton>
      </ButtonGroup>
    </LowDialog>
  );
}
