import {
  getLnSendLimitAndUsage,
  getWithdrawalDayLimitAndUsage,
  getWithdrawalSingleLimitAndUsage,
} from '@noah-labs/core-services';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { AccountType, Network } from '@noah-labs/noah-schema';
import { PolicyPeriod } from '@noah-labs/noah-schema';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import BigNumber from 'bignumber.js';
import type { UserLimitsQuery } from '../../user/data/user.generated';
import { useUserLimitsQuery } from '../../user/data/user.generated';
import { selectFromFilterWithFallback, useAccountsQueryPoll } from '../data';
import type { TpAllowance, TpWithdrawalAllowance } from '../types';

function getAllowance(limit?: BigNumber, usage?: BigNumber): BigNumber | undefined {
  if (!usage) {
    return limit;
  }
  return limit?.minus(usage);
}

export function processAllowances(
  limitsData: UserLimitsQuery,
  availableBalanceCrypto: string | undefined,
  network: Network | undefined
): TpWithdrawalAllowance {
  const lnDayLimit = getLnSendLimitAndUsage(limitsData.userLimit.Limits, PolicyPeriod.DAY);
  const lnSingleLimit = getLnSendLimitAndUsage(limitsData.userLimit.Limits, PolicyPeriod.SINGLE);

  const accountDayOverAllLimit = getWithdrawalDayLimitAndUsage(limitsData.userLimit.Limits);
  const accountDayNetworkLimit = getWithdrawalDayLimitAndUsage(
    limitsData.userLimit.Limits,
    network
  );
  const accountSingleLimit = getWithdrawalSingleLimitAndUsage(limitsData.userLimit.Limits, network);

  const lnDayLimitCrypto = lnDayLimit.limit?.CryptoAmounts
    ? safeBN(lnDayLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const lnDayUsageCrypto = lnDayLimit.usage?.CryptoAmounts
    ? safeBN(lnDayLimit.usage.CryptoAmounts[0].Amount)
    : undefined;
  const lnSingleLimitCrypto = lnSingleLimit.limit?.CryptoAmounts
    ? safeBN(lnSingleLimit.limit.CryptoAmounts[0].Amount)
    : undefined;

  const accountDayLimitCrypto = accountDayOverAllLimit.limit?.CryptoAmounts
    ? safeBN(accountDayOverAllLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const accountDayUsageCrypto = accountDayOverAllLimit.usage?.CryptoAmounts
    ? safeBN(accountDayOverAllLimit.usage.CryptoAmounts[0].Amount)
    : undefined;

  const accountDayCountLimitCrypto = accountDayNetworkLimit.count?.Limit
    ? safeBN(accountDayNetworkLimit.count.Limit)
    : undefined;
  const accountDayCountUsageCrypto = accountDayNetworkLimit.count?.Usage
    ? safeBN(accountDayNetworkLimit.count.Usage)
    : undefined;

  const accountSingleLimitCrypto = accountSingleLimit.limit?.CryptoAmounts
    ? safeBN(accountSingleLimit.limit.CryptoAmounts[0].Amount)
    : undefined;
  const withdrawMinimumSingleCrypto = accountSingleLimit.minimum?.CryptoAmounts
    ? safeBN(accountSingleLimit.minimum.CryptoAmounts[0].Amount)
    : undefined;

  const lnDayAllowanceCrypto = getAllowance(lnDayLimitCrypto, lnDayUsageCrypto);
  const accountDayAllowanceCrypto = getAllowance(accountDayLimitCrypto, accountDayUsageCrypto);
  const withdrawalRemainingTxs = getAllowance(
    accountDayCountLimitCrypto,
    accountDayCountUsageCrypto
  );

  const balanceUserCrypto = availableBalanceCrypto ? safeBN(availableBalanceCrypto) : undefined;

  const lnAllowanceCryptoAmount = BigNumber.min(
    ...([lnDayAllowanceCrypto, lnSingleLimitCrypto, balanceUserCrypto].filter((val) =>
      Boolean(val)
    ) as BigNumber[])
  );

  let lnAllowanceCrypto: TpAllowance | undefined;
  switch (lnAllowanceCryptoAmount.valueOf()) {
    case lnDayAllowanceCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: lnDayAllowanceCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case lnSingleLimitCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: lnSingleLimitCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case balanceUserCrypto?.valueOf():
      lnAllowanceCrypto = {
        amount: balanceUserCrypto as BigNumber,
        reason: 'Balance',
      };
      break;
    default:
  }

  const accountAllowanceCryptoAmount = BigNumber.min(
    ...([accountDayAllowanceCrypto, accountSingleLimitCrypto, balanceUserCrypto].filter((val) =>
      Boolean(val)
    ) as BigNumber[])
  );

  let accountAllowanceCrypto: TpAllowance | undefined;
  switch (accountAllowanceCryptoAmount.valueOf()) {
    case accountDayAllowanceCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: accountDayAllowanceCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case accountSingleLimitCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: accountSingleLimitCrypto as BigNumber,
        reason: 'PolicyLimit',
      };
      break;
    case balanceUserCrypto?.valueOf():
      accountAllowanceCrypto = {
        amount: balanceUserCrypto as BigNumber,
        reason: 'Balance',
      };
      break;
    default:
  }

  return {
    accountAllowanceCrypto,
    accountDayCountLimitCrypto,
    balanceUserCrypto,
    lnAllowanceCrypto,
    withdrawalRemainingTxs,
    withdrawMinimumSingleCrypto,
  };
}

type PpUseWithdrawalAllowance = {
  accountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  network: Network | undefined;
};
export function useWithdrawalAllowance({
  accountType,
  cryptoCurrency,
  network,
}: PpUseWithdrawalAllowance): TpWithdrawalAllowance | undefined {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) =>
      selectFromFilterWithFallback({
        AccountType: accountType,
        CurrencyCode: cryptoCurrency.code,
        data,
      }),
  });
  const { data: withdrawalAllowance } = useUserLimitsQuery(
    { currencyCodes: [cryptoCurrency.code] },
    {
      enabled: !!account,
      select: (data): TpWithdrawalAllowance =>
        processAllowances(data, account?.Balance?.Available, network),
    }
  );

  return withdrawalAllowance;
}
