import { useRef } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { webLinks } from '@noah-labs/cloud-shared-constants';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import {
  AccountLimitsIcon,
  BitcoinIcon,
  CreditCardIcon,
  DiscordIcon,
  DocumentIcon,
  EyeIcon,
  FaqIcon,
  InfoIcon,
  KeyholeIcon,
  LinkIcon,
  LockIcon,
  LogoutIcon,
  ProfileIcon,
} from '@noah-labs/core-web-ui/src/icons';
import { DefaultAvatar } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { DefaultListItemText } from '@noah-labs/core-web-ui/src/lists/atoms/DefaultListItemText';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { PrimaryMenuIcon, PrimaryMenuItem } from '@noah-labs/core-web-ui/src/menus/PrimaryMenuItem';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import type { Maybe } from '@noah-labs/noah-schema';
import { Feature } from '@noah-labs/noah-schema';
import { Helmet } from 'react-helmet';
import { TwitterIcon } from 'react-share';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { routes as authRoutes } from '../../auth/routes';
import { ReferFriendsSocialShareCard } from '../../rewards/components';
import { shareButtons } from '../../rewards/data/social';
import { LnUrlChip } from '../components';
import { LightningAddressDialog } from '../components/dialogs/LightningAddressDialog';
import { RestrictedFeature } from '../components/RestrictedFeature';
import { SignInWithBiometricToggle } from '../components/SignInWithBiometricToggle';
import type { TpKycApprovalStatusData } from '../data/useKycApprovalStatusData';
import { routes } from '../routes';

export type PpSettingsScene = {
  avatarAlt?: string;
  avatarContent: string;
  backTo: string;
  btcUnit?: string;
  displayCurrency?: TpFiatCurrencyUI;
  email?: string;
  kycApprovalStatusData?: TpKycApprovalStatusData;
  kycRoute?: string;
  lnAddress?: string;
  pageTitle: string;
  paymentCurrency?: TpFiatCurrencyUI;
  primaryCurrency?: string;
  referralUrl: string | undefined;
  usernameDisplay?: Maybe<string>;
};

export function SettingsScene({
  avatarAlt,
  avatarContent,
  backTo,
  btcUnit,
  displayCurrency,
  email,
  kycApprovalStatusData,
  kycRoute,
  lnAddress,
  pageTitle,
  paymentCurrency,
  primaryCurrency,
  referralUrl,
  usernameDisplay,
}: PpSettingsScene): React.ReactElement {
  const showAddressDialog = useRef<TpDialogToggle>(null);
  const { track } = useAnalytics();

  const { code: paymentCurrencyCode, Icon: PaymentCurrencyIcon } = paymentCurrency || {};
  const { code: displayCurrencyCode, Icon: DisplayCurrencyIcon } = displayCurrency || {};

  return (
    <AppContainer AppHeaderSlot={<AppHeader backTo={backTo} />} dataQa="settings">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <SceneMain dense>
        <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
          <LightningAddressDialog ref={showAddressDialog} />
        </RestrictedFeature>

        <Stack spacing={3}>
          <Stack spacing={1}>
            <Stack alignItems="flex-start" direction="row" spacing={2}>
              <Stack
                direction="column"
                flexGrow={1}
                // minWidth: 0 is needed to prevent the text from overflowing, see: https://css-tricks.com/flexbox-truncated-text/
                spacing={1}
                sx={{ minWidth: 0 }}
              >
                <Typography noWrap variant="subHeadingM">
                  <TextOrSkeleton>{usernameDisplay}</TextOrSkeleton>
                </Typography>
                <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
                  <Stack alignItems="center" direction="row">
                    <SceneParagraph>Your NOAH Lightning Address</SceneParagraph>
                    <IconButton onClick={(): void => showAddressDialog.current?.toggle()}>
                      <InfoIcon />
                    </IconButton>
                  </Stack>
                </RestrictedFeature>
              </Stack>
              <DefaultAvatar alt={avatarAlt} size={5.25} userInitials={avatarContent} />
            </Stack>

            <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
              <LnUrlChip lnAddress={lnAddress} />
            </RestrictedFeature>
          </Stack>

          <ListSection>
            <RestrictedFeature needs={[Feature.UIRewards]}>
              <Stack marginBottom={1}>
                <ReferFriendsSocialShareCard
                  referralUrl={referralUrl}
                  shareButtons={shareButtons}
                />
              </Stack>
            </RestrictedFeature>

            <PrimaryMenuItem
              className={webConfigBrowser.intercom.customLauncherSelector}
              dataQa={webConfigBrowser.intercom.customLauncherSelector}
              // use an empty onclick to force use of a button
              onClick={(): void => {}}
            >
              <PrimaryMenuIcon>
                <FaqIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Help" />
            </PrimaryMenuItem>

            <RestrictedFeature needs={[Feature.UIPromotions]}>
              <PrimaryMenuItem
                chevron={false}
                href={webLinks.social.twitter}
                onClick={(): void =>
                  track(TpAnalyticsEvent.SocialCardClicked, {
                    socialMedia: 'Twitter',
                  })
                }
              >
                <PrimaryMenuIcon>
                  <TwitterIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText
                  primary="Follow us on Twitter"
                  secondary="Join the community and receive a share of the millions in sats given away on Twitter."
                  secondaryTypographyProps={{
                    noWrap: false,
                  }}
                />
              </PrimaryMenuItem>

              <PrimaryMenuItem
                chevron={false}
                href={webLinks.social.discord}
                onClick={(): void =>
                  track(TpAnalyticsEvent.SocialCardClicked, {
                    socialMedia: 'Discord',
                  })
                }
              >
                <PrimaryMenuIcon>
                  <DiscordIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText
                  primary="Join the Discord community"
                  secondary="Stay in the loop with the latest news on NOAH"
                />
              </PrimaryMenuItem>
            </RestrictedFeature>
          </ListSection>

          {/* General */}
          <ListSection title="General" titleColor="text.light">
            <PrimaryMenuItem
              dataQa="display-currency-button"
              href={routes.settings.displayCurrency.path}
            >
              {DisplayCurrencyIcon && (
                <PrimaryMenuIcon>
                  <DisplayCurrencyIcon />
                </PrimaryMenuIcon>
              )}
              <DefaultListItemText
                primary="Display currency"
                secondary={<TextOrSkeleton>{displayCurrencyCode}</TextOrSkeleton>}
              />
            </PrimaryMenuItem>
            <RestrictedFeature
              needs={[[Feature.CheckoutBuy, Feature.CheckoutSell, Feature.ManualRampSell]]}
            >
              <PrimaryMenuItem
                dataQa="payment-currency-button"
                href={routes.settings.paymentCurrency.path}
              >
                {PaymentCurrencyIcon && (
                  <PrimaryMenuIcon>
                    <PaymentCurrencyIcon />
                  </PrimaryMenuIcon>
                )}
                <DefaultListItemText
                  primary="Payment currency"
                  secondary={<TextOrSkeleton>{paymentCurrencyCode}</TextOrSkeleton>}
                />
              </PrimaryMenuItem>
            </RestrictedFeature>
            <PrimaryMenuItem dataQa="bitcoin-unit-button" href={routes.settings.bitcoinUnit.path}>
              <PrimaryMenuIcon>
                <BitcoinIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText
                primary="Bitcoin unit"
                secondary={<TextOrSkeleton>{btcUnit}</TextOrSkeleton>}
              />
            </PrimaryMenuItem>
            <PrimaryMenuItem
              dataQa="primary-currency-button"
              href={routes.settings.primaryCurrency.path}
            >
              <DefaultListItemText
                primary="Primary currency"
                secondary={<TextOrSkeleton>{primaryCurrency}</TextOrSkeleton>}
              />
            </PrimaryMenuItem>
            <PrimaryMenuItem dataQa="account-limits" href={routes.settings.accountLimits.path}>
              <PrimaryMenuIcon>
                <AccountLimitsIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Account limits" />
            </PrimaryMenuItem>
            <PrimaryMenuItem dataQa="payment-methods" href={routes.settings.paymentMethods.path}>
              <PrimaryMenuIcon>
                <CreditCardIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Payment methods" />
            </PrimaryMenuItem>
          </ListSection>

          {/* Profile */}
          <ListSection title="Profile" titleColor="text.light">
            <PrimaryMenuItem dataQa="personal-detail-button" href={routes.profile.base.path}>
              <PrimaryMenuIcon>
                <ProfileIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Personal details" />
            </PrimaryMenuItem>
            <PrimaryMenuItem dataQa="linked-accounts-button" href={routes.accounts.path}>
              <PrimaryMenuIcon>
                <LinkIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Linked accounts" />
            </PrimaryMenuItem>
          </ListSection>

          {/* Security */}
          <ListSection title="Security" titleColor="text.light">
            <PrimaryMenuItem dataQa="privacy-consent" href={routes.settings.consent.path}>
              <PrimaryMenuIcon>
                <EyeIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Privacy" />
            </PrimaryMenuItem>
            <RestrictedFeature needs={[Feature.Pin]}>
              <PrimaryMenuItem dataQa="security-pin" href={routes.settings.securityPin.path}>
                <PrimaryMenuIcon>
                  <LockIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Security PIN" />
              </PrimaryMenuItem>
            </RestrictedFeature>
            <RestrictedFeature needs={[Feature.Kyc]}>
              <PrimaryMenuItem dataQa="kyc" href={kycRoute}>
                <PrimaryMenuIcon>{kycApprovalStatusData?.Icon ?? <KeyholeIcon />}</PrimaryMenuIcon>
                <DefaultListItemText
                  primary="Identity verification"
                  secondary={kycApprovalStatusData?.description}
                  {...(kycApprovalStatusData?.color
                    ? { secondaryTypographyProps: { color: kycApprovalStatusData.color } }
                    : {})}
                />
              </PrimaryMenuItem>
            </RestrictedFeature>
            <SignInWithBiometricToggle />
          </ListSection>

          {/* About Us */}
          <ListSection title="About Us" titleColor="text.light">
            <PrimaryMenuItem dataQa="terms-button" href={webLinks.termsAndConditions.path}>
              <PrimaryMenuIcon>
                <InfoIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Terms and conditions" />
            </PrimaryMenuItem>
            <PrimaryMenuItem dataQa="privacy-button" href={webLinks.privacyPolicy.path}>
              <PrimaryMenuIcon>
                <DocumentIcon />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Privacy Policy" />
            </PrimaryMenuItem>
          </ListSection>

          {/* Logout */}
          <ListSection
            sx={{
              // needs extra specificity to override the stack margin
              '&&': {
                mt: 7,
              },
            }}
          >
            <PrimaryMenuItem dataQa="logout-button" href={authRoutes.signOut.path}>
              <PrimaryMenuIcon>
                <LogoutIcon color="error" />
              </PrimaryMenuIcon>
              <DefaultListItemText primary="Log out" secondary={email} />
            </PrimaryMenuItem>
          </ListSection>
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
