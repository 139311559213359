import type { TpLimitAndUsage } from '@noah-labs/core-services';
import { getLnSendLimitAndUsage } from '@noah-labs/core-services';
import { PolicyPeriod } from '@noah-labs/noah-schema';
import { getDefaults } from '../../../utils';
import { useUserLimitsQuery } from '../../user/data/user.generated';

type TpWithdrawLimits = {
  lnSendDayLimit: TpLimitAndUsage;
  lnSendSingleLimit: TpLimitAndUsage;
};

const { cryptoCurrency } = getDefaults();

export function useWithdrawLimits(): TpWithdrawLimits | undefined {
  const { data: limitData } = useUserLimitsQuery(
    {
      currencyCodes: [cryptoCurrency.code],
    },
    {
      select: (data): TpWithdrawLimits => ({
        lnSendDayLimit: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.DAY),
        lnSendSingleLimit: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.SINGLE),
      }),
    }
  );

  return limitData;
}
