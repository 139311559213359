/* eslint-disable react/no-unstable-nested-components */
import type { PpWC, TpFeatureFlagUI, TpFeatureUI } from '@noah-labs/core-web-ui/src/types';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { AuthGroups } from '@noah-labs/noah-schema';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../routes';
import type { TpAuthRoute } from '../utils/createRoute';
import { getFeatureFlagForRoute, isGroupAllowed } from './utils';

export type PpAuthRouteData = PpWC<
  RouteProps & {
    /**
     * authRedirect controls where a user is redirected to in case they are not auth'd
     */
    authRedirect?: string;
    /**
     * featureRedirect controls where user is redirected to in case they do not have requested feature enabled
     */
    featureRedirect?: string;
    /**
     * if an invalidRedirect path (string) is provided, the component will immediately redirect to it - use to control stepped flows etc
     */
    invalidRedirect?: string | false;
    route: TpAuthRoute;
  }
>;

type PpAuthRoute = PpWC<
  RouteProps & {
    authGroups: AuthGroups[] | undefined;
    authRedirect: string;
    featureRedirect: string;
    invalidRedirect?: string | false;
    isAuthenticated: boolean;
    isVerified: boolean | undefined;
    pathname: string;
    route: TpAuthRoute;
    search: string;
    userFeatures: Map<TpFeatureUI, TpFeatureFlagUI> | undefined;
  }
>;

const disableAuthRoutes = process.env.GATSBY_DISABLE_AUTH_ROUTES;

const message = 'Preparing your NOAH wallet, hold tight...';

export function AuthRoute({
  authGroups,
  authRedirect,
  featureRedirect,
  invalidRedirect,
  isAuthenticated,
  isVerified,
  pathname,
  route,
  search,
  userFeatures,
  ...rest
}: PpAuthRoute): React.ReactElement | null {
  logger.debug('groups:', authGroups);
  logger.debug('features:', userFeatures);

  /**
   * Redirect immediately if invalidRedirect path is provided
   */
  if (invalidRedirect) {
    const invalidRedirectRoute = {
      pathname: invalidRedirect,
      state: { from: pathname },
    };
    logger.debug('invalid route:', pathname, 'redirecting to:', invalidRedirectRoute);
    return <Route component={(): React.ReactElement => <Redirect to={invalidRedirectRoute} />} />;
  }

  /**
   * If Auth Routing is disabled, just render the route
   */
  if (disableAuthRoutes) {
    logger.debug('auth routes disabled, rendering:', pathname);
    return <Route {...rest} />;
  }

  /**
   * Check if the user is verified & redirect to verify screen if not
   */
  if (
    isAuthenticated &&
    !isVerified &&
    route.path !== routes.verify.path &&
    route.path !== routes.signOut.path
  ) {
    return <Route component={(): React.ReactElement => <Redirect to={routes.verify.path} />} />;
  }

  /**
   * If authGroups is undefined we don't know whether to redirect or not, so just 'wait'
   */
  if (!authGroups) {
    return <LoadingPage message={message} />;
  }

  /**
   * Check user's group access
   */
  const groupAllowed = isGroupAllowed(authGroups, route);
  logger.debug('groupAllowed:', groupAllowed);

  if (!groupAllowed) {
    const pathnameUrl = search ? pathname.concat(search) : pathname;
    const groupRedirectRoute = {
      pathname: authRedirect,
      state: { from: pathnameUrl },
    };
    logger.debug('disallowed group route:', pathname, 'redirecting to:', groupRedirectRoute);
    return <Route component={(): React.ReactElement => <Redirect to={groupRedirectRoute} />} />;
  }

  /**
   * Groups are good, check user's feature access
   * If userFeatures is undefined we don't know whether to redirect or not, so just 'wait'
   */
  if (isAuthenticated && !userFeatures) {
    return <LoadingPage message={message} />;
  }
  const featureFlag = getFeatureFlagForRoute(userFeatures, route);
  logger.debug('featureFlag:', featureFlag);

  if (!featureFlag.Enabled) {
    const featureRedirectRoute = {
      pathname: featureRedirect,
      state: { featureFlag, from: pathname },
    };
    logger.debug('disallowed feature route:', pathname, 'redirecting to:', featureRedirectRoute);
    return <Route component={(): React.ReactElement => <Redirect to={featureRedirectRoute} />} />;
  }

  logger.debug('rendering:', pathname);
  return <Route {...rest} />;
}
