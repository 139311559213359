import { useState } from 'react';
import type { SafeWrapper } from '@noah-labs/core-services';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { routes as walletRoutes } from '../wallet/routes';
import { CreatePinRecover } from './controllers';
import { EnterCurrentPin } from './controllers/EnterCurrentPin';
import { routes } from './routes';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Users attempt to update their security PIN using its current PIN.
 */
export function UpdatePinRouter(): React.ReactElement {
  const [mnemonic, setMnemonic] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route
        path={routes.updatePin.enterCurrentPin.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.updatePin.enterCurrentPin}>
            <EnterCurrentPin
              setMnemonic={setMnemonic}
              successTo={routes.updatePin.createPin.path}
            />
          </AuthRouteData>
        )}
      />
      <Route
        path={routes.updatePin.createPin.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.updatePin.createPin}>
            <CreatePinRecover
              exitTo={routes.settings.securityPin.path}
              pageTitle={routes.updatePin.createPin.title as string}
              phrase={mnemonic}
              sceneConfirmTitle="Confirm your new security PIN"
              sceneTitle="Create a new security PIN"
              successTo={routes.updatePin.success.path}
            />
          </AuthRouteData>
        )}
      />

      <Route
        path={routes.updatePin.success.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.updatePin.success}>
            <PinSuccessScene
              pageTitle={routes.updatePin.success.title as string}
              sceneDescription="You can now use your new PIN to verify transactions for added security."
              sceneTitle="PIN updated sucessfully"
              successTo={walletRoutes.base.path}
            />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
