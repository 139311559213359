import React from 'react';
import { Divider, Stack } from '@mui/material';
import type { TypographyOwnProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import type { Network } from '@noah-labs/noah-schema';
import type { TpCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { NetworkItem } from '../send/NetworkItem';

export type PpAddressSummaryCard = {
  FeeSlot?: React.ReactElement;
  TotalAmountSlot?: TpCurrencyAmounts;
  address: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  network: Network | undefined;
};

const mainTextProps: TypographyOwnProps = {
  color: 'text.light',
  variant: 'paragraphBodyS',
};

const endTextProps: TypographyOwnProps = {
  variant: 'paragraphBodySBold',
};

const StructuredContentItem = styled(StructuredContent)(({ theme }) => ({
  minHeight: theme.spacing(4.5),
  wordBreak: 'break-word',
}));

export function AddressSummaryCard({
  address,
  cryptoCurrency,
  FeeSlot,
  network,
  TotalAmountSlot,
}: PpAddressSummaryCard): React.ReactElement {
  return (
    <ElevatedCard sx={{ padding: [2, 2] }}>
      <Stack spacing={1.5}>
        <StructuredContentItem
          endText={{
            primary: (
              <TextWithIcon sx={{ justifyContent: 'flex-end' }}>
                <CurrencyAvatar Icon={cryptoCurrency.Icon} size={3} />
                <span>{cryptoCurrency.label}</span>
              </TextWithIcon>
            ),
            primaryTypographyProps: endTextProps,
          }}
          mainText={{
            primary: 'Send',
            primaryTypographyProps: mainTextProps,
          }}
        />
        <Divider />
        <StructuredContentItem
          endText={{
            primary: address,
            primaryTypographyProps: {
              ...endTextProps,
              noWrap: false,
              sx: {
                paddingLeft: 3,
              },
            },
          }}
          mainText={{
            primary: 'To',
            primaryTypographyProps: mainTextProps,
          }}
        />

        {network && (
          <React.Fragment>
            <Divider />
            <NetworkItem cryptoCurrency={cryptoCurrency} network={network} />
          </React.Fragment>
        )}

        {FeeSlot && (
          <React.Fragment>
            <Divider />
            {FeeSlot}
          </React.Fragment>
        )}
        {TotalAmountSlot && (
          <React.Fragment>
            <Divider />
            <StructuredContentItem
              endText={{
                primary: TotalAmountSlot.PrimaryAmountSlot,
                primaryTypographyProps: endTextProps,
                secondary: TotalAmountSlot.SecondaryAmountSlot,
              }}
              mainText={{
                primary: 'Total',
                primaryTypographyProps: endTextProps,
              }}
            />
          </React.Fragment>
        )}
      </Stack>
    </ElevatedCard>
  );
}
