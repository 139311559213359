import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../utils';
import {
  defaultAccount,
  defaultAccountUsdc,
  defaultAccountUsdt,
  filterWithFallback,
  getAccount,
  useAccountsQueryPoll,
  useCalculateFiatFromCrypto,
} from '../data';

// TODO: this is just a workaround for now, we should be able to query the total balance once have this
// https://linear.app/noah-hq/issue/CORE-362/allow-fe-to-query-for-total-balance-over-all-user-accounts
export function useAccountsTotalBalance(): string | undefined {
  const { fiatCurrency } = useUserFiatCurrency();

  const { data: accounts } = useAccountsQueryPoll(undefined, {
    select: filterWithFallback,
  });

  const btcAccount = getAccount({
    AccountType: defaultAccount.AccountType,
    CurrencyCode: defaultAccount.CurrencyCode,
    data: accounts,
  });
  const usdcAccount = getAccount({
    AccountType: defaultAccountUsdc.AccountType,
    CurrencyCode: defaultAccountUsdc.CurrencyCode,
    data: accounts,
  });
  const usdtAccount = getAccount({
    AccountType: defaultAccountUsdt.AccountType,
    CurrencyCode: defaultAccountUsdt.CurrencyCode,
    data: accounts,
  });

  const btcBalance = btcAccount?.Balance?.Available;
  const usdcBalance = usdcAccount?.Balance?.Available;
  const usdtBalance = usdtAccount?.Balance?.Available;

  const { data: priceBtcData, isFetched: btcDataFetched } = useCalculateFiatFromCrypto({
    cryptoAmount: btcBalance,
    cryptoCurrency: cryptoCurrencyFromCode(defaultAccount.CurrencyCode),
    fiatCurrency,
    priceProvider: 'market',
  });
  const btcFiatAmount = priceBtcData?.fiatAmount;

  const { data: priceUsdcData, isFetched: usdcDataFetched } = useCalculateFiatFromCrypto({
    cryptoAmount: usdcBalance,
    cryptoCurrency: cryptoCurrencyFromCode(defaultAccountUsdc.CurrencyCode),
    fiatCurrency,
    priceProvider: 'market',
  });
  const usdcFiatAmount = priceUsdcData?.fiatAmount;

  const { data: priceUsdtData, isFetched: usdtDataFetched } = useCalculateFiatFromCrypto({
    cryptoAmount: usdtBalance,
    cryptoCurrency: cryptoCurrencyFromCode(defaultAccountUsdt.CurrencyCode),
    fiatCurrency,
    priceProvider: 'market',
  });
  const usdtFiatAmount = priceUsdtData?.fiatAmount;

  const isBalanceFetching = !btcDataFetched || !usdcDataFetched || !usdtDataFetched;

  // we want to show the text skeleton if amount is fetching, otherwise
  // safeBN will return '0'
  if (isBalanceFetching) {
    return undefined;
  }

  const totalBalance = safeBN(btcFiatAmount)
    .plus(safeBN(usdcFiatAmount))
    .plus(safeBN(usdtFiatAmount))
    .toString();

  return totalBalance;
}
