import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { UsTaxpayerFormValue } from '../../scenes/AccountVerification';

type PpUsTaxpayerDialog = {
  handleFormState: (usTaxpayer: UsTaxpayerFormValue) => void;
};

export const UsTaxpayerDialog = forwardRef<TpDialogToggle, PpUsTaxpayerDialog>(
  ({ handleFormState }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Quick question, are you a US taxpayer?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To comply with regulations, we have to know your status as a US tax payer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              data-qa="us-taxpayer-no"
              onClick={(): void => handleFormState({ UsTaxpayer: false })}
            >
              No. I’m not
            </PrimaryButton>
            <PrimaryButton
              data-qa="us-taxpayer-yes"
              variant="text"
              onClick={(): void => handleFormState({ UsTaxpayer: true })}
            >
              Yes, I’m a US taxpayer
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  }
);

UsTaxpayerDialog.displayName = UsTaxpayerDialog.name;
