import { SardineFlows } from '@noah-labs/noah-schema';
import { FeaturedCarousel } from '../../../marketing';
import { useSardineFlow } from '../../../sardine/hooks/useSardineFlow';
import { useUserInit } from '../../../user/data/useUserInit';
import {
  AccountCardListData,
  ActionsMenuData,
  CurrentBalanceDataWithCurrencyDialog,
} from '../../components';
import { defaultAccount, useWalletParams } from '../../data';
import { useAvailableCurrencyActions } from '../../hooks';
import { AccountsScene } from '../../scenes';

export function Accounts(): React.ReactElement {
  const { data } = useUserInit();
  useSardineFlow({ flow: SardineFlows.Dashboard });

  const { CurrencyCode } = useWalletParams({
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  });

  const availableCurrencyActions = useAvailableCurrencyActions(CurrencyCode);

  return (
    <AccountsScene
      AccountsSlot={<AccountCardListData />}
      ActionsMenuSlot={<ActionsMenuData availableCurrencyActions={availableCurrencyActions} />}
      MarketingSlot={<FeaturedCarousel userId={data?.userProfile.UserID} />}
      TotalBalanceSlot={<CurrentBalanceDataWithCurrencyDialog />}
    />
  );
}
