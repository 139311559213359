import { css } from '@emotion/react';
import type { ListItemTextProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BankIcon, CreditCardIcon } from '@noah-labs/core-web-ui/src/icons';
import { ApplepayLight, GooglepayLight } from '@noah-labs/core-web-ui/src/images';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { toTitleCase } from '@noah-labs/shared-tools/src/browser/strings';
import type { TpPaymentBank, TpPaymentCard } from '../../types';
import { TpPaymentMethod } from '../../types';
import { getAddressDisplay, getCardSchemeIcon } from '../../utils/utils';

type PpPaymentMethod = {
  ToggleSlot?: React.ReactElement;
  hideAddress?: boolean;
  method: TpPaymentMethod | undefined;
  minHeight?: string;
  paymentBank?: TpPaymentBank;
  paymentCard?: TpPaymentCard;
};

export function PaymentMethod({
  hideAddress,
  method,
  minHeight,
  paymentBank,
  paymentCard,
  ToggleSlot,
}: PpPaymentMethod): React.ReactElement | null {
  const theme = useTheme();
  const styles = {
    cardDetails: css`
      overflow: hidden;
    `,
    content: css`
      min-height: ${minHeight || theme.spacing(7)};
    `,
    icon: css`
      width: 48px;
      vertical-align: middle;
    `,
    toggle: css`
      align-self: center;
    `,
  };

  let Icon: React.FC | null = null;
  let dataQa;
  let address: string | undefined;
  let primary;
  let secondary;
  let primaryTypographyProps;

  switch (method) {
    case undefined:
      primary = 'Choose a payment method';
      dataQa = 'choose-payment-method';
      primaryTypographyProps = {
        color: 'text.link',
      };
      break;

    case TpPaymentMethod.ApplePay:
      Icon = ApplepayLight;
      primary = 'Apple Pay';
      dataQa = 'apple-pay';
      break;

    case TpPaymentMethod.GooglePay:
      Icon = GooglepayLight;
      primary = 'Google Pay';
      dataQa = 'google-pay';
      break;

    case TpPaymentMethod.Card: {
      if (!paymentCard) {
        Icon = CreditCardIcon;
        primary = 'New credit/debit card';
        secondary = 'We accept Visa and Mastercard';
        break;
      }
      const showAddress = paymentCard.billingAddress && !hideAddress;
      Icon = getCardSchemeIcon(paymentCard.scheme);
      primary = toTitleCase(paymentCard.issuer || 'Credit Card');
      secondary = `**** ${paymentCard.last4}`;
      dataQa = `credit-card-${paymentCard.last4}`;
      address = showAddress ? getAddressDisplay(paymentCard.billingAddress) : undefined;
      break;
    }

    case TpPaymentMethod.BankTransfer: {
      if (!paymentBank) {
        Icon = BankIcon;
        primary = 'Bank transfer | Add new account';
        break;
      }
      const bankCode = paymentBank.bankCode ? `- ${paymentBank.bankCode}` : '';
      const showAddress = paymentBank.accountHolderAddress && !hideAddress;
      Icon = BankIcon;
      primary = `${paymentBank.accountCurrency} account`;
      secondary = `${paymentBank.accountNumber} ${bankCode}`;
      dataQa = 'bank-transfer';
      address = showAddress ? getAddressDisplay(paymentBank.accountHolderAddress) : undefined;
      break;
    }

    default:
      logger.error('Invalid payment method', method);
  }

  const mainTextProps: ListItemTextProps = {
    primary,
    primaryTypographyProps,
    secondary,
  };

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Stack css={styles.cardDetails}>
        <StructuredContent
          noWrap
          css={styles.content}
          dataQa={dataQa}
          Icon={Icon && <Icon css={styles.icon} />}
          mainText={mainTextProps}
        />

        {address && (
          <Typography noWrap color="text.light" mb={1} variant="paragraphBodyS">
            {address}
          </Typography>
        )}
      </Stack>
      <div css={styles.toggle}>{ToggleSlot}</div>
    </Stack>
  );
}
