import type { Maybe } from '@noah-labs/noah-schema';
import { Network } from '@noah-labs/noah-schema';

export function getBlockchainExplorerLink(
  network: Network,
  publidId?: Maybe<string>
): string | undefined {
  if (!publidId) {
    return undefined;
  }

  let networkSlug = '';

  switch (network) {
    case Network.Bitcoin:
      networkSlug = 'btc';
      break;
    case Network.BitcoinTest:
      networkSlug = 'btc-testnet';
      break;
    default:
      return undefined;
  }

  return `https://live.blockcypher.com/${networkSlug}/tx/${publidId}`;
}
