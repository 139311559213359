import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UserCountrySelectField } from '../../../components';
import { KycAppHeader } from '../components/KycAppHeader';
import { PROGRESS } from '../data/progress';
import type { TpConfirmedPersonalData } from '../types';

const confirmFormId = 'kycConfirm';

const confirmFormSchema = yup.object({
  City: yup.string().required(),
  Country: yup.string().required(),
  CountryName: yup.string().required(),
  DateOfBirth: yup.string().required(),
  FirstName: yup.string().required(),
  LastName: yup.string().required(),
  Nationality: yup.string().required(),
  PostalCode: yup.string().required(),
  State: yup.string().required(),
  Street: yup.string().required(),
});

const emptyValues: TpConfirmedPersonalData = {
  City: '',
  Country: '',
  CountryName: '',
  DateOfBirth: '',
  FirstName: '',
  LastName: '',
  Nationality: '',
  PostalCode: '',
  State: '',
  Street: '',
};

export type PpConfirmScene = {
  ConfirmedPersonalData?: TpConfirmedPersonalData;
  backTo: string;
  isLoading: boolean;
  onSaveAndExit: (values: TpConfirmedPersonalData) => void;
  onSubmit: SubmitHandler<TpConfirmedPersonalData>;
};

export function ConfirmScene({
  backTo,
  ConfirmedPersonalData,
  isLoading,
  onSaveAndExit,
  onSubmit,
}: PpConfirmScene): React.ReactElement {
  const defaultValues = useMemo(
    () => ConfirmedPersonalData ?? emptyValues,
    [ConfirmedPersonalData]
  );
  const methods = useForm<TpConfirmedPersonalData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(confirmFormSchema),
  });

  const buttonDisabled = !methods.formState.isValid;
  const isFormSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    /**
     * since the user's prefs are loaded from an api, this can come after the form
     * already has default values, hence we need to reset it with the new ones
     * however, we don't want to reset whilst the form is submitting else the button will go to disabled state
     */
    if (isFormSubmitting) {
      return;
    }
    methods.reset(defaultValues);
  }, [defaultValues, methods, isFormSubmitting]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            disabled={buttonDisabled}
            form={confirmFormId}
            loading={isFormSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            disabled={buttonDisabled || isFormSubmitting}
            loading={isLoading}
            variant="text"
            onClick={(): void => {
              onSaveAndExit(methods.getValues());
            }}
          >
            Save and finish later
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.confirm} />}
      dataQa="account"
    >
      <Helmet>
        <title>Confirm your information</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Confirm your information</SceneTitleMedium>
        <SceneParagraph>
          Please verify the information below is correct and add any missing details.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={confirmFormId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <InputField required dataQa="first-name" label="First name" name="FirstName" />
              <InputField required dataQa="last-name" label="Last name" name="LastName" />
              <InputField
                required
                dataQa="date-of-birth"
                label="Date of Birth"
                name="DateOfBirth"
              />
              <InputField required dataQa="nationality" label="Nationality" name="Nationality" />
              <InputField required dataQa="street" label="Street" name="Street" />
              <InputField required dataQa="city" label="City" name="City" />
              <InputField required dataQa="state" label="State" name="State" />
              <InputField required dataQa="postal-code" label="Postal code" name="PostalCode" />
              <UserCountrySelectField
                // no country filtering here because all countries are potentially allowed for ids
                required
                dataQa="country"
                handleChange={(value): void => {
                  methods.setValue('Country', value?.value || '');
                  methods.setValue('CountryName', value?.label || '', { shouldValidate: true });
                }}
                label="Country"
                name="CountryName"
              />
            </Stack>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
