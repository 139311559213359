import { routes } from '../routes';
import { TpKycSteps } from '../types';

// depending on the route will return the next kyc step
export const nextBaseSteps = {
  [TpKycSteps.IdSelect]: routes.select.path,
  [TpKycSteps.Activity]: routes.questionnaire.sourceOfFunds.path,
  [TpKycSteps.AnnualDeposit]: routes.questionnaire.transactionFrequency.path,
  [TpKycSteps.Location]: routes.privacy.path,
  [TpKycSteps.PrivacyConsent]: routes.select.path,
  [TpKycSteps.SourceOfFunds]: routes.questionnaire.employmentStatus.path,
  [TpKycSteps.WorkIndustry]: routes.questionnaire.proofSourcesOfFunds.path,
  [TpKycSteps.ProofOfSourcesOfFunds]: routes.complete.path,
  [TpKycSteps.TransactionFrequency]: routes.complete.path,
  [TpKycSteps.EmploymentStatus]: routes.questionnaire.annualDeposit.path,
  [TpKycSteps.ConfirmPersonalData]: routes.questionnaire.base.path,
  [TpKycSteps.EndDocKyc]: routes.confirm.path,
};
