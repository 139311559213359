import type { TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { FiatCurrencyCode } from '@noah-labs/noah-schema';
import { useUserInit } from '../modules/user/data/useUserInit';
import { fiatCurrencyFromCode } from '../utils';

type TpUseUserFiatCurrency = {
  fiatCurrency: TpFiatCurrencyUI;
  fiatPaymentCurrency: TpFiatCurrencyUI;
};

export function useUserFiatCurrency(): TpUseUserFiatCurrency {
  const { data: userData } = useUserInit();
  const fiatCurrency = userData?.userProfile.FiatCurrencyCode || FiatCurrencyCode.USD;
  const fiatPaymentCurrency = userData?.userProfile.FiatPaymentCurrencyCode || FiatCurrencyCode.USD;

  const fiatCurrencyData = fiatCurrencyFromCode(fiatCurrency);
  const fiatPaymentCurrencyData = fiatCurrencyFromCode(fiatPaymentCurrency);

  return {
    fiatCurrency: fiatCurrencyData,
    fiatPaymentCurrency: fiatPaymentCurrencyData,
  };
}
