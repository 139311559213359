import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { FileInput } from '@noah-labs/core-web-ui/src/forms/FileInput';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { KycAppHeader } from '../../components/KycAppHeader';
import { PROGRESS } from '../../data/progress';

const defaultValues = {
  ProofOfSourcesOfFunds: [],
};

export type TpProofOfSourcesOfFundsFormSchema = {
  ProofOfSourcesOfFunds: File[];
};

// 12 MB
const MAX_FILE_SIZE = 1048576 * 12;

const formId = 'proofOfSourcesOfFundsFormId';

const supportedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];

function isValidFileType(fileType: string): boolean {
  return supportedFileTypes.includes(fileType);
}

function checkDuplicateds(files: File[]): boolean {
  return files.every((file, index) => files.findIndex((f) => f.name === file.name) === index);
}

const proofOfSourcesOfFundsFormSchema = yup.object({
  ProofOfSourcesOfFunds: yup
    .mixed<File[]>()
    .required('')
    .test({
      message: 'You need to upload a proof of sources of funds',
      test: (files) => files.length > 0,
    })
    .test({
      message: 'Unsupported file type',
      test: (files) => files.every((file) => isValidFileType(file.type)),
    })
    .test({
      message: 'You can’t have duplicated files',
      test: (files) => checkDuplicateds(files),
    })
    .test({
      message: 'Max allowed size for each file is 12MB',
      test: (files) => files.every((file) => file.size <= MAX_FILE_SIZE),
    }),
});

export type PpProofOfSourcesOfFunds = {
  backTo: string;
  onSubmit: SubmitHandler<TpProofOfSourcesOfFundsFormSchema>;
};

export function ProofOfSourcesOfFundsScene({
  backTo,
  onSubmit,
}: PpProofOfSourcesOfFunds): React.ReactElement {
  const methods = useForm<TpProofOfSourcesOfFundsFormSchema>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(proofOfSourcesOfFundsFormSchema),
  });
  const buttonDisabled = !methods.formState.isValid;
  const isFormSubmitting = methods.formState.isSubmitting;

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          disabled={buttonDisabled || isFormSubmitting}
          form={formId}
          loading={isFormSubmitting}
          type="submit"
        >
          Finish
        </PrimaryButton>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.answers} />}
      dataQa="proofOfSourcesOfFunds"
    >
      <Helmet>
        <title>Proof of sources of funds</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>Upload proof of sources of funds</SceneTitleMedium>
        <SceneParagraph>
          Please upload a bank statement dated in the last 3 months.
          {/* TODO: add link to other accepted documents */}
          {/* <Link href="/" target="_blank" >
            see complete list of accepted documents here.
          </Link> */}
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
            <FileInput
              inputProps={{ accept: supportedFileTypes.join(', '), multiple: true }}
              name="ProofOfSourcesOfFunds"
            />
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
