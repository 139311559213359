import { CurrencyDisplayType } from '@noah-labs/noah-schema';
import { useUserInit } from '../../user/data/useUserInit';

type TpUserPrimaryCurrency = {
  isPrimaryCrypto: boolean;
  isPrimaryFiat: boolean;
  primaryCurrency: CurrencyDisplayType | null | undefined;
};

export function usePrimaryCurrency(): TpUserPrimaryCurrency {
  const { data } = useUserInit();
  const primaryCurrency = data?.userProfile.PrimaryCurrency || CurrencyDisplayType.Fiat;
  return {
    isPrimaryCrypto: primaryCurrency === CurrencyDisplayType.Crypto,
    isPrimaryFiat: primaryCurrency === CurrencyDisplayType.Fiat,
    primaryCurrency,
  };
}
