import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import React, { useCallback, useRef } from 'react';
import { OnlyIconButton } from '../buttons/OnlyIconButton';
import type { TpDialogToggle } from '../hooks/useToggleDialog';
import type { TpIcon } from '../types';

export type TpActionsOptionsRef = TpDialogToggle;

type PpActionsMenuItem = {
  ActionOptions?: ForwardRefExoticComponent<RefAttributes<TpActionsOptionsRef>>;
  Icon: TpIcon;
  dataQa: string;
  disabled?: boolean;
  href?: string;
  label?: React.ReactNode;
  onClick?: () => void;
  showOptions?: boolean;
};

export function ActionsMenuItem({
  ActionOptions,
  dataQa,
  disabled = true,
  href,
  Icon,
  label,
  onClick,
  showOptions = true,
}: PpActionsMenuItem): React.ReactElement {
  const optionsRef = useRef<TpActionsOptionsRef>(null);

  const onActionButtonClick = useCallback((): void => {
    if (onClick) {
      onClick();
    }
    if (ActionOptions) {
      optionsRef.current?.toggle();
    }
  }, [ActionOptions, onClick]);

  return (
    <React.Fragment>
      <OnlyIconButton
        data-qa={dataQa}
        disabled={disabled}
        href={href}
        outsideLabel={label}
        sx={{ flexGrow: 0 }}
        onClick={onActionButtonClick}
      >
        <Icon sx={{ height: '22px', width: '22px' }} />
      </OnlyIconButton>
      {!disabled && showOptions && ActionOptions && <ActionOptions ref={optionsRef} />}
    </React.Fragment>
  );
}
