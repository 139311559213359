import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchOcrData } from '@noah-labs/core-services';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { nextBaseSteps } from '../data/steps';
import { kycInputQueryKey, useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { ConfirmScene } from '../scenes/Confirm';
import type { TpConfirmedPersonalData } from '../types';
import { TpKycSteps } from '../types';

export function Confirm({ state, updateState }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const { handleSubmit, isMutationLoading } = useSubmitKycInput();
  const [personalOcrData, setPersonalOcrData] = useState<TpConfirmedPersonalData | undefined>(
    state.userKycInput?.ConfirmedPersonalData
  );
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const { incodeApiBaseUrl, sardineToken } = state;

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }
        setIsLoading(true);

        await queryClient.invalidateQueries(kycInputQueryKey);

        const { data: ocrData } = await fetchOcrData({
          baseURL: incodeApiBaseUrl,
          token: sardineToken,
        });

        // map kyc personal data to form values
        const mappedPersonalData: Omit<TpConfirmedPersonalData, 'Country' | 'CountryName'> = {
          City: ocrData.checkedAddressBean?.city || '',
          DateOfBirth: ocrData.birthDate ? dayjs.utc(ocrData.birthDate).format('YYYY-MM-DD') : '',
          FirstName: ocrData.name?.firstName || '',
          LastName: ocrData.name?.paternalLastName || '',
          Nationality: ocrData.birthPlace || '',
          PostalCode: ocrData.checkedAddressBean?.postalCode || '',
          State: ocrData.checkedAddressBean?.state || '',
          Street: ocrData.checkedAddressBean?.street || '',
        };
        const mappedOcrData = {
          ...mappedPersonalData,
          Country: state.country || '',
          CountryName: state.countryName || '',
        };
        setPersonalOcrData((prevState) => ({
          ...prevState,
          ...mappedOcrData,
        }));
      } catch (err) {
        // TODO: add error handling
        logger.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    void fetchData();
  }, [incodeApiBaseUrl, sardineToken, queryClient, state.country, state.countryName]);

  const handleContinue = useCallback(
    (personalData: TpConfirmedPersonalData) => {
      updateState({
        userKycInput: {
          ...state.userKycInput,
          ConfirmedPersonalData: personalData,
        },
      });
      history.push(nextBaseSteps.ConfirmPersonalData);
    },
    [state.userKycInput, updateState, history]
  );

  const handleSave = useCallback(
    async (personalData: TpConfirmedPersonalData): Promise<void> => {
      await handleSubmit({
        errorKey: 'updatePersonalDetailsError',
        errorMessage: 'There was an error saving your details',
        to: nextBaseSteps.ConfirmPersonalData,
        values: {
          ConfirmedPersonalData: personalData,
          LastStep: TpKycSteps.ConfirmPersonalData,
        },
      });
    },
    [handleSubmit]
  );

  const confirmedPersonalDataState = useMemo(() => personalOcrData, [personalOcrData]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfirmScene
      backTo={routes.select.path}
      ConfirmedPersonalData={confirmedPersonalDataState}
      isLoading={isMutationLoading}
      onSaveAndExit={handleSave}
      onSubmit={handleContinue}
    />
  );
}
