import { useCallback } from 'react';
import type { TpAddIdRequest, TpIncodeError } from '@noah-labs/core-services';
import { addFace } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { getErrorMessageByCode, getSelfieErrorPrompt } from '../data/incodeErrorMessages';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import { IdCaptureScene } from '../scenes/IdCapture';
import type { TpCaptureResponse } from '../types';

export function FaceCapture(props: TpStateMachine<StKycRouter>): React.ReactElement {
  const pushAlert = usePushAlert();

  const { state } = props;

  const handleCapture = useCallback(
    async ({ base64Image }: TpAddIdRequest): Promise<TpCaptureResponse> => {
      try {
        const { incodeApiBaseUrl, sardineToken } = state;

        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        const res = await addFace(
          { baseURL: incodeApiBaseUrl, token: sardineToken },

          { base64Image }
        );

        const errorPrompt = getSelfieErrorPrompt(res.data);
        if (errorPrompt) {
          return {
            error: errorPrompt,
            success: false,
          };
        }

        return { redirectTo: routes.processface.path, success: true };
      } catch (err) {
        logger.error(err);

        const incodeError = err as TpIncodeError;

        if (!incodeError.response?.data.status) {
          pushAlert({
            autoHideDuration: 3000,
            key: 'incodeSelfieCaptureError',
            message: 'An error has occured when capturing your selfie',
            preventDuplicate: true,
            severity: 'error',
          });
          return {
            success: false,
          };
        }

        if (incodeError.response.data.error) {
          return {
            error: incodeError.response.data.error,
            success: false,
          };
        }

        const message = getErrorMessageByCode(incodeError.response.data.status);

        return { error: message, success: false };
      }
    },
    [pushAlert, state]
  );

  return (
    <IdCaptureScene
      backTo={routes.select.path}
      captureType="selfie"
      description="We will compare the photo in your document with your selfie. Please keep a straight face, find balanced light and remove any glasses and hats."
      processingLabel="Processing..."
      reviewDescription="Check that your entire face is in the frame below and continue."
      reviewTitle="Review your selfie"
      {...state}
      title="Take a selfie"
      onCapture={handleCapture}
    />
  );
}
