import React from 'react';
import { css } from '@emotion/react';
import { Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CodeIcon } from '@noah-labs/core-web-ui/src/icons';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';

export type PpLnUrlChip = {
  lnAddress: string | undefined;
};

export function LnUrlChip({ lnAddress }: PpLnUrlChip): React.ReactElement {
  const theme = useTheme();
  const styles = {
    chipContent: css`
      align-items: center;
    `,
    infoIcon: css`
      font-size: 1.35rem;
      color: ${theme.palette.text.light};
    `,
    lnChip: css`
      max-width: fit-content;
      &.MuiChip-root {
        border: 1px solid ${theme.palette.brand.light};
        .MuiChip-label {
          padding: ${theme.spacing(0.5, 1.25)};
        }
        p {
          margin-top: -2px;
        }
      }
    `,
    qrIcon: css`
      font-size: 1rem;
      color: ${theme.palette.brand.light};
    `,
  };

  return (
    <Stack spacing={1}>
      <Chip
        css={styles.lnChip}
        label={
          <Stack css={styles.chipContent} direction="row" spacing={0.5}>
            <CodeIcon css={styles.qrIcon} />
            <Typography color="brand.light" variant="paragraphBodyM">
              <TextOrSkeleton>{lnAddress}</TextOrSkeleton>
            </Typography>
          </Stack>
        }
        variant="outlined"
      />
    </Stack>
  );
}
