import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import type { CountryCode } from '@noah-labs/noah-schema';
import { useHistory } from 'react-router-dom';
import { routes as walletRoutes } from '../../wallet/routes';
import { nextBaseSteps } from '../data/steps';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';
import type { TpLocationFormValues } from '../scenes/Location';
import { LocationScene } from '../scenes/Location';
import { TpKycSteps } from '../types';

export function Location({ state, updateState }: TpStateMachine<StKycRouter>): React.ReactElement {
  const { handleSubmit, isKycInputQueryFetched, isMutationLoading } = useSubmitKycInput();
  const history = useHistory();

  const handleContinue = useCallback(
    (values: TpLocationFormValues) => {
      updateState({
        country: values.Country as CountryCode,
        countryName: values.CountryName,
        userKycInput: {
          ...state.userKycInput,
          ...values,
          State: values.State || null,
        },
      });
      history.push(nextBaseSteps.Location);
    },
    [updateState, history, state.userKycInput]
  );

  const handleSave = useCallback(
    async (values: TpLocationFormValues) => {
      await handleSubmit({
        errorKey: 'updateLocationError',
        errorMessage: 'There was an error saving your location',
        to: walletRoutes.base.path,
        values: {
          ...state.userKycInput,
          ...values,
          LastStep: TpKycSteps.Location,
          State: values.State || null,
        },
      });
    },
    [handleSubmit, state.userKycInput]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <LocationScene
      backTo={routes.base.path}
      isLoading={isMutationLoading}
      locationFormState={{
        Country: state.userKycInput?.Country || '',
        CountryName: state.userKycInput?.CountryName || '',
        State: state.userKycInput?.State || '',
        StateName: state.userKycInput?.StateName || '',
      }}
      onSaveAndExit={handleSave}
      onSubmit={handleContinue}
    />
  );
}
