import { CurrencyCode as TpCurrencyCode } from '@noah-labs/noah-schema';

export function getLabelAndSubtitle(currencyCode: TpCurrencyCode): {
  label: string;
  subTitle: string;
} {
  let subTitle: string;
  let label: string;

  switch (currencyCode) {
    case TpCurrencyCode.USDC:
    case TpCurrencyCode.USDC_TEST:
      subTitle = 'We only support sending USDC on the ethereum network.';
      label = 'USDC address';
      break;

    case TpCurrencyCode.USDT:
    case TpCurrencyCode.USDT_TEST:
      subTitle = 'We only support sending USDT on the ethereum network.';
      label = 'USDT address';
      break;

    case TpCurrencyCode.BTC:
    case TpCurrencyCode.BTC_TEST:
      subTitle = 'We only support sending bitcoin on the on-chain and lightning network.';
      label = 'Bitcoin address';
      break;

    default:
      return { label: 'Address', subTitle: '' };
  }

  return { label, subTitle };
}
