import {
  parseBtcAddressData,
  parseUsdcAddressData,
  parseUsdtAddressData,
} from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import type { TpParseAddressData, TpParseAddressDataWCc } from '@noah-labs/core-web-ui/src/types';
import { CurrencyCode } from '@noah-labs/noah-schema';

export enum AddressErrors {
  notFound = 'not-found',
  notSupported = 'not-supported',
}

// Manual address

/**
 * We're parsing the address by currency, in case user adds
 * a BTC address when selecting USDC as currency or vice-versa, we want to return
 * a "Not supported" error message or "Not Found" if not valid
 */

function handleBtcParseData({
  address,
  availableNetworks,
  isProd,
}: TpParseAddressData): TpAddressData {
  const addressData = parseBtcAddressData({ address, isProd });
  if (addressData) {
    return addressData;
  }

  if (parseUsdcAddressData({ address, availableNetworks, isProd })) {
    throw new Error(AddressErrors.notSupported);
  }

  throw new Error(AddressErrors.notFound);
}

function handleUsdcParseData({
  address,
  availableNetworks,
  isProd,
}: TpParseAddressData): TpAddressData {
  const addressData = parseUsdcAddressData({
    address,
    availableNetworks,
    isProd,
  });
  if (addressData) {
    return addressData;
  }

  if (parseBtcAddressData({ address, isProd })) {
    throw new Error(AddressErrors.notSupported);
  }

  throw new Error(AddressErrors.notFound);
}

function handleUsdtParseData({
  address,
  availableNetworks,
  isProd,
}: TpParseAddressData): TpAddressData {
  const addressData = parseUsdtAddressData({
    address,
    availableNetworks,
    isProd,
  });
  if (addressData) {
    return addressData;
  }

  if (parseBtcAddressData({ address, isProd })) {
    throw new Error(AddressErrors.notSupported);
  }

  throw new Error(AddressErrors.notFound);
}

function parseCurrencyAddressData({
  address,
  availableNetworks,
  currencyCode,
  isProd,
}: TpParseAddressDataWCc): TpAddressData {
  switch (currencyCode) {
    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
      return handleBtcParseData({ address, availableNetworks, isProd });
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
      return handleUsdcParseData({ address, availableNetworks, isProd });
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      return handleUsdtParseData({ address, availableNetworks, isProd });

    default:
      throw new Error(AddressErrors.notFound);
  }
}

export function handleParseAddress({
  address,
  availableNetworks,
  currencyCode,
  isProd,
}: TpParseAddressDataWCc): { addressData: TpAddressData; isLnAddressOrLnUrl: boolean } {
  const addressData = parseCurrencyAddressData({
    address,
    availableNetworks,
    currencyCode,
    isProd,
  });
  const addressDataType = addressData.addressType;
  const isLnAddressOrLnUrl = addressDataType === 'lnaddress' || addressDataType === 'lnurl';

  return { addressData, isLnAddressOrLnUrl };
}

export function isAddressCustomError(error: string | undefined): boolean {
  const hasCustomError = !!error && Object.values(AddressErrors).includes(error as AddressErrors);
  return hasCustomError;
}

// Scan

/**
 * In case user scans a valid address but with the wrong currency,
 * we want to show a warning message with "address is valid but not supported",
 * that's why we're trying to parse all available currencies. The warning part
 * is being handled by parseCurrencyAddressData(), which is trigerred after the scan.
 */
export function handleParseScannedAddress({
  address,
  availableNetworks,
  isProd,
}: TpParseAddressData): TpAddressData | undefined {
  return (
    parseBtcAddressData({ address, isProd }) ||
    parseUsdcAddressData({ address, availableNetworks, isProd })
  );
}
