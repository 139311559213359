/* eslint-disable sort-keys-fix/sort-keys-fix */

// Kyc screens progress

// progress is being multiplied by 100
// in ProgressBar component
export const PROGRESS = {
  ustaxpayer: 0.2,
  location: 0.3,
  privacy: 0.4,
  select: 0.5,
  capture: 0.6,
  selfie: 0.65,
  confirm: 0.8,
  activity: 0.9,
  answers: 0.95,
  transaction: 1,
};
