import {
  BitcoinIcon,
  EthereumIcon,
  EuropeanUnionIcon,
  LightningCircleIcon,
  NoahCurrencyIcon,
  PolygonIcon,
  TronIcon,
  UnitedKingdomIcon,
  UnitedStatesIcon,
  UsdcoinIcon,
  UsdtIcon,
} from '@noah-labs/core-web-ui/src/icons';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { CurrencyCode, FiatCurrencyCode } from '@noah-labs/noah-schema';
import deepmerge from 'deepmerge';

export function addIconsToCryptoCurrencies(
  currencies: Record<CurrencyCode, Omit<TpCryptoCurrencyUI, 'Icon' | 'NetworkIcons'>>
): Record<CurrencyCode, TpCryptoCurrencyUI> {
  const currencyIcons: Record<CurrencyCode, Pick<TpCryptoCurrencyUI, 'Icon' | 'NetworkIcons'>> = {
    BTC: {
      Icon: BitcoinIcon,
      NetworkIcons: { Bitcoin: BitcoinIcon, Lightning: LightningCircleIcon },
    },
    BTC_TEST: {
      Icon: BitcoinIcon,
      NetworkIcons: { BitcoinTest: BitcoinIcon, LightningTest: LightningCircleIcon },
    },
    ETH: { Icon: EthereumIcon, NetworkIcons: { Ethereum: EthereumIcon } },
    ETH_TEST_SEPOLIA: { Icon: EthereumIcon, NetworkIcons: { Ethereum: EthereumIcon } },
    MATIC: { Icon: null, NetworkIcons: null },
    MATIC_TEST: { Icon: null, NetworkIcons: null },
    NOAH: { Icon: NoahCurrencyIcon, NetworkIcons: null },
    TRX: { Icon: null, NetworkIcons: null },
    TRX_TEST: { Icon: null, NetworkIcons: null },
    USDC: {
      Icon: UsdcoinIcon,
      NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon, Tron: TronIcon },
    },
    USDC_TEST: {
      Icon: UsdcoinIcon,
      NetworkIcons: {
        EthereumTestSepolia: EthereumIcon,
        PolygonTestMumbai: PolygonIcon,
        TronTestShasta: TronIcon,
      },
    },
    USDT: {
      Icon: UsdtIcon,
      NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon, Tron: TronIcon },
    },
    USDT_TEST: {
      Icon: UsdtIcon,
      NetworkIcons: {
        EthereumTestSepolia: EthereumIcon,
        PolygonTestMumbai: PolygonIcon,
        TronTestShasta: TronIcon,
      },
    },
  };

  return deepmerge(currencyIcons, currencies);
}
export function addIconsToFiatCurrencies(
  currencies: Record<FiatCurrencyCode, Omit<TpFiatCurrencyUI, 'Icon'>>
): Record<FiatCurrencyCode, TpFiatCurrencyUI> {
  const currencyIcons: Partial<Record<FiatCurrencyCode, Pick<TpFiatCurrencyUI, 'Icon'>>> = {
    EUR: { Icon: EuropeanUnionIcon },
    GBP: { Icon: UnitedKingdomIcon },
    USD: { Icon: UnitedStatesIcon },
  };

  return deepmerge(currencyIcons, currencies);
}
