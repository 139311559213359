import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import type {
  TpAmount,
  TpCryptoCurrency,
  TpSimpleAmount,
} from '@noah-labs/shared-currencies/src/types';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import BigNumber from 'bignumber.js';
import { webConfigBrowser } from '../webConfigBrowser';
import { calculateFee } from './calculateFee';

type PpGetNetCryptoAmount = {
  cryptoCurrency: TpCryptoCurrency;
  fiatAmount: TpAmount;
  price: TpAmount;
};

const { feeMinimumFiatAmount, feePercentage } = webConfigBrowser.checkout;

export function getNetCryptoAmount({
  cryptoCurrency,
  fiatAmount,
  price,
}: PpGetNetCryptoAmount): TpSimpleAmount {
  if (isUndefinedOrNull(price) || isUndefinedOrNull(fiatAmount)) {
    return undefined;
  }

  const fiatFee = calculateFee({
    amount: fiatAmount,
    fee: feePercentage,
    feeMin: feeMinimumFiatAmount,
  });

  const netFiatAmount = safeBN(fiatAmount).minus(fiatFee);
  const newCryptoAmount = netFiatAmount.dividedBy(price);
  const zeroOrAmount = BigNumber.max(0, newCryptoAmount);

  return truncateAmount({
    amount: zeroOrAmount.toString(),
    decimalPlaces: cryptoCurrency.decimals,
  });
}
