import React, { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { Network } from '@noah-labs/noah-schema';
import { CurrencyCode } from '@noah-labs/noah-schema';
import { BitcoinNetworkDialog, UsdStablecoinsNetworkDialog } from '../dialogs';

export type PpNetworkItem = {
  cryptoCurrency: TpCryptoCurrencyUI;
  network: Network;
};

export function NetworkItem({ cryptoCurrency, network }: PpNetworkItem): React.ReactElement {
  const theme = useTheme();
  const networkInfoDialog = useRef<TpDialogToggle>(null);

  let Dialog: React.ReactElement;

  switch (cryptoCurrency.code) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      Dialog = (
        <UsdStablecoinsNetworkDialog ref={networkInfoDialog} currencyCode={cryptoCurrency.code} />
      );
      break;

    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
    default:
      Dialog = <BitcoinNetworkDialog ref={networkInfoDialog} />;
  }

  return (
    <React.Fragment>
      <StructuredContent
        endText={{
          primary: network,
          primaryTypographyProps: {
            variant: 'paragraphBodySBold',
          },
        }}
        mainText={{
          disableTypography: true,
          primary: (
            <InfoTooltip
              color="text.light"
              variant="paragraphBodyS"
              onClick={(): void => networkInfoDialog.current?.toggle()}
            >
              Network
            </InfoTooltip>
          ),
        }}
        sx={{ minHeight: theme.spacing(4.5), wordBreak: 'break-word' }}
      />
      {Dialog}
    </React.Fragment>
  );
}
