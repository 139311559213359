import React from 'react';
import { Launch } from '@mui/icons-material';
import type { TpMoonpayTransactionStatus } from '@noah-labs/core-services';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';

export function getCta(
  status: TpMoonpayTransactionStatus | undefined,
  exitTo: string | undefined,
  moonpayUrl: string | undefined
): React.ReactElement {
  switch (status) {
    case 'waitingAuthorization':
      return (
        <PrimaryButton
          color="primaryBrand"
          data-qa="authorize-button"
          endIcon={<Launch />}
          href={moonpayUrl}
        >
          Authorize Purchase
        </PrimaryButton>
      );
    case 'waitingPayment':
      return (
        <PrimaryButton
          color="primaryBrand"
          data-qa="complete-payment-button"
          endIcon={<Launch />}
          href={moonpayUrl}
        >
          Complete payment
        </PrimaryButton>
      );
    case 'failed':
    case 'pending':
    case 'completed':
    default:
      return (
        <PrimaryButton data-qa="done-button" href={exitTo}>
          Done
        </PrimaryButton>
      );
  }
}
