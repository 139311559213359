import type { AvatarProps } from '@mui/material';
import { AvatarWithBadge, CurrencyAvatar, DefaultAvatar } from '@noah-labs/core-web-ui/src/images';
import type { TpIcon } from '@noah-labs/core-web-ui/src/types';
import type { TransactionStatus } from '@noah-labs/noah-schema';
import type { TpTransactionType } from '../../types';
import { TransactionStatusBadge } from './atoms/TransactionStatusBadge';

type PpAvatarWithTransactionStatus = {
  CurrencyIcon: TpIcon | null | undefined;
  transactionStatus?: TransactionStatus;
  transactionType?: TpTransactionType;
};

const avatarSize = 8;
const badgeSize = 3.5;

export function AvatarWithTransactionStatus({
  CurrencyIcon,
  transactionStatus,
  transactionType,
  ...rest
}: PpAvatarWithTransactionStatus & AvatarProps): React.ReactElement {
  return (
    <AvatarWithBadge
      {...rest}
      AvatarSlot={
        CurrencyIcon ? (
          <CurrencyAvatar Icon={CurrencyIcon} size={avatarSize} />
        ) : (
          <DefaultAvatar size={avatarSize} />
        )
      }
      BadgeSlot={
        transactionStatus &&
        transactionType && (
          <TransactionStatusBadge
            size={badgeSize}
            status={transactionStatus}
            type={transactionType}
          />
        )
      }
      size={avatarSize}
    />
  );
}
