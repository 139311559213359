import type { ReactElement } from 'react';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { CircleTickIcon } from '@noah-labs/core-web-ui/src/icons';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneIcon } from '@noah-labs/core-web-ui/src/scene/SceneIcon';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';

export type PpPinSuccessScene = {
  pageTitle: string;
  sceneDescription: string;
  sceneTitle: string;
  successTo: string;
};

export function PinSuccessScene({
  pageTitle,
  sceneDescription = 'You can now use your new PIN to verify transactions for added security.',
  sceneTitle,
  successTo,
}: PpPinSuccessScene): ReactElement {
  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton data-qa="done-btn" href={successTo}>
          Done
        </PrimaryButton>
      }
      AppHeaderSlot={<AppHeaderData helpButton />}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain>
        <SceneIcon>
          <CircleTickIcon color="success" />
        </SceneIcon>
        <SceneTitleLarge>{sceneTitle}</SceneTitleLarge>
        <SceneParagraph>{sceneDescription}</SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
