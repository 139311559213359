import { useCallback, useEffect, useState } from 'react';
import type {
  TpApplePaySessionData,
  TpCheckoutTokenResponse,
  TpGooglePaySessionData,
} from '@noah-labs/core-services';
import { useRequestPaymentToken as useRequestCheckoutPaymentToken } from '@noah-labs/core-services';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { CountryCode, FiatPaymentMethodWalletCardSaveInput } from '@noah-labs/noah-schema';
import {
  CurrencyDisplayType,
  FiatPaymentMethodTokenProvider,
  FiatPaymentProvider,
  FiatPaymentTokenizationSource,
  FiatPaymentType,
  FiatPaymentWalletCardType,
} from '@noah-labs/noah-schema';
import { fiatToMinorUnit } from '@noah-labs/shared-currencies/src/conversions';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode, fiatAmountForCurrency } from '../../../../utils';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { useKycNotApprovedAndCheckoutEnabled } from '../../../user/hooks/useKycNotApprovedAndCheckoutEnabled';
import { useKycRoute } from '../../../user/hooks/useKycRoute';
import type { FiatPaymentMutation } from '../../data';
import {
  useFiatPaymentMethodProviderSaveMutation,
  useFiatPaymentMethodWalletCardSaveMutation,
  useFiatPaymentMutation,
  usePromotionsQuery,
  useWalletParams,
} from '../../data';
import { useApplePay, useCardIssuerNotifications, useGooglePay, useWalletError } from '../../hooks';
import { useAmountsWithFee } from '../../hooks/useAmountsWithFee';
import { useCurrencyAmountsWithFee } from '../../hooks/useCurrencyAmountsWithFee';
import { routes } from '../../routes';
import { ConfirmBuyScene } from '../../scenes';
import type { TpFiatPaymentToken, TpPaymentMethodChange } from '../../types';
import { TpCheckoutPaymentStatus, TpPaymentMethod } from '../../types';
import type { StBuyRouter } from './BuyRouter';
import { getCheckoutPaymentStatus, getCheckoutTokenType, getTokenSource } from './utils';

/**
 * For GooglePay / ApplePay
 *
 * - Get tokenized card details from Apple / Google SDK
 * - Exchange this token for a Checkout Token (useRequestCheckoutPaymentToken)
 * - Use this token directly in the fiatPaymentMutation
 * - for now, skip fiatPaymentMethodProviderSave
 */

const { feeMinimumFiatAmount, feePercentage } = webConfigBrowser.checkout;

const fees = {
  feeMinimumFiatAmount,
  feePercentage,
};

const NoFeeBuysPromotionID = 'NoFeeBuys20230925';

export function Confirm({
  setStore,
  state,
  updateState,
}: TpStateMachine<StBuyRouter>): React.ReactElement {
  useCardIssuerNotifications(state.selectedPaymentCard?.issuer);
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { fiatPaymentCurrency } = useUserFiatCurrency();
  const [redirect3ds, setRedirect3ds] = useState<string | null>(null);
  const history = useHistory();
  const kycRequired = useKycNotApprovedAndCheckoutEnabled();
  const kycRoute = useKycRoute();
  const { data: feePromotion } = usePromotionsQuery(undefined, {
    select: (data) =>
      data.promotions.UnclaimedPromotions?.find((p) => p.PromoID === NoFeeBuysPromotionID),
  });

  const { paymentError: googlePaymentError, startSession: startGooglePaySession } = useGooglePay();
  const { paymentError: applePaymentError, startSession: startApplePaySession } = useApplePay();

  const { error: checkoutPaymentTokenError, mutateAsync: getCheckoutPaymentToken } =
    useRequestCheckoutPaymentToken();

  const {
    error: fiatPaymentMethodProviderSaveError,
    mutateAsync: mutateFiatPaymentMethodProviderSave,
  } = useFiatPaymentMethodProviderSaveMutation();

  const {
    error: fiatPaymentMethodWalletCardSaveError,
    mutateAsync: mutateFiatPaymentMethodWalletCardSave,
  } = useFiatPaymentMethodWalletCardSaveMutation();

  const { error: fiatPaymentError, mutateAsync: mutateFiatPayment } = useFiatPaymentMutation();

  const onPaymentMethodChange = useCallback(
    ({ card, type }: TpPaymentMethodChange): void => {
      updateState({
        paymentMethod: type,
        selectedPaymentCard: card,
      });
    },
    [updateState]
  );

  /**
   * Initiates alternative payment method sessions and returns token data
   */
  const getApmTokenData = useCallback(async (): Promise<
    TpGooglePaySessionData | TpApplePaySessionData | undefined
  > => {
    try {
      switch (state.paymentMethod) {
        case TpPaymentMethod.GooglePay: {
          const res = await startGooglePaySession({
            currencyCode: fiatPaymentCurrency.code,
            totalPrice: state.fiatAmount,
          });
          return res;
        }
        case TpPaymentMethod.ApplePay: {
          const res = await startApplePaySession({
            amount: state.fiatAmount,
            currencyCode: fiatPaymentCurrency.code,
          });
          return res;
        }
        default:
          logger.error('Invalid payment method', state.paymentMethod);
          return undefined;
      }
    } catch (error) {
      // useWalletError handles error
      return undefined;
    }
  }, [
    state.fiatAmount,
    state.paymentMethod,
    fiatPaymentCurrency.code,
    startApplePaySession,
    startGooglePaySession,
  ]);

  /**
   * Requests a payment token from Checkout
   */
  const requestCheckoutToken = useCallback(
    async (
      tokenData: TpApplePaySessionData | TpGooglePaySessionData
    ): Promise<TpCheckoutTokenResponse | undefined> => {
      if (!state.paymentMethod) {
        return undefined;
      }

      try {
        // exchange token data for a token from Checkout
        const { data: tokenResponse } = await getCheckoutPaymentToken({
          token_data: tokenData,
          type: getCheckoutTokenType(state.paymentMethod),
        });

        return tokenResponse;
      } catch (error) {
        // useWalletError handles error
        return undefined;
      }
    },
    [getCheckoutPaymentToken, state.paymentMethod]
  );

  /**
   * Submits the fiatPayment mutation and redirects to a Complete
   * failed page if it fails
   */
  const submitFiatPaymentRequest = useCallback(
    async (
      paymentId: string,
      paymentToken: FiatPaymentTokenizationSource
    ): Promise<FiatPaymentMutation | undefined> => {
      try {
        const data = await mutateFiatPayment({
          Input: {
            CurrencyCode,
            PaymentDetails: {
              ID: paymentId,
              Type: FiatPaymentType.FiatPaymentMethodID,
            },
            Provider: FiatPaymentProvider.Checkout,
            RequestedAmount: {
              Amount: fiatToMinorUnit(state.fiatAmount),
              FiatCurrency: fiatPaymentCurrency.code,
            },
            TokenizationSource: paymentToken,
          },
        });

        return data;
      } catch {
        history.push(
          generatePath(routes.buy.complete.path, {
            ...params,
            paymentStatus: TpCheckoutPaymentStatus.failure,
          })
        );
        return undefined;
      }
    },
    [CurrencyCode, history, mutateFiatPayment, params, fiatPaymentCurrency.code, state.fiatAmount]
  );

  /**
   * Submits the payment request
   */
  const submitFiatPayment = useCallback(
    async (
      paymentToken: TpFiatPaymentToken,
      paymentType: FiatPaymentType,
      tokenResponse?: TpCheckoutTokenResponse,
      save = false
    ) => {
      try {
        let paymentId = paymentToken.id;

        switch (state.paymentMethod) {
          case TpPaymentMethod.ApplePay:
          case TpPaymentMethod.GooglePay:
            if (!tokenResponse) {
              throw new Error('Invalid token response');
            }
            {
              const walletCardSaveInput: FiatPaymentMethodWalletCardSaveInput = {
                Details: {
                  Bin: tokenResponse.bin,
                  ExpiryMonth: tokenResponse.expiry_month,
                  ExpiryYear: tokenResponse.expiry_year,
                  Last4: tokenResponse.last4,
                  WalletType:
                    state.paymentMethod === TpPaymentMethod.ApplePay
                      ? FiatPaymentWalletCardType.ApplePay
                      : FiatPaymentWalletCardType.GooglePay,
                },
                Integration: {
                  Provider: FiatPaymentMethodTokenProvider.Checkout,
                  Token: paymentToken.id,
                },
              };

              if (tokenResponse.billing_address) {
                walletCardSaveInput.Details.BillingAddress = {
                  City: tokenResponse.billing_address.city,
                  CountryCode: tokenResponse.billing_address.country as CountryCode,
                  PostCode: tokenResponse.billing_address.zip,
                  State: tokenResponse.billing_address.state,
                  Street: tokenResponse.billing_address.address_line1,
                  Street2: tokenResponse.billing_address.address_line2,
                };
              }

              const fpmWalletSaveData = await mutateFiatPaymentMethodWalletCardSave({
                Input: walletCardSaveInput,
              });
              if (!fpmWalletSaveData.fiatPaymentMethodWalletCardSave.DynamoID) {
                throw new Error('Failed to save payment method');
              }
              paymentId = fpmWalletSaveData.fiatPaymentMethodWalletCardSave.DynamoID;
            }
            break;

          default:
            /**
             * If the card was not previously saved, save it and update paymentId and paymentType
             */
            if (paymentType !== FiatPaymentType.FiatPaymentMethodID) {
              const fpmProviderSaveData = await mutateFiatPaymentMethodProviderSave({
                Input: {
                  Provider: FiatPaymentMethodTokenProvider.Checkout,
                  SaveForFutureUse: save,
                  Token: paymentToken.id,
                },
              });

              if (!fpmProviderSaveData.fiatPaymentMethodProviderSave.DynamoID) {
                throw new Error('Failed to save payment method');
              }
              paymentId = fpmProviderSaveData.fiatPaymentMethodProviderSave.DynamoID;
            }
            break;
        }

        const data = await submitFiatPaymentRequest(paymentId, paymentToken.source);

        if (!data) {
          throw new Error('Failed to submit payment request');
        }

        // add the transaction ID to the state
        if (typeof data.fiatPayment.PaymentID === 'string') {
          updateState({
            transactionId: data.fiatPayment.PaymentID,
          });
        }

        // redirect to the bank verification screen if required
        if (data.fiatPayment.Links) {
          const redirectLink = data.fiatPayment.Links.find((link) =>
            compareStrings(link.LinkType, 'redirect')
          );
          if (typeof redirectLink?.Location === 'string') {
            setRedirect3ds(redirectLink.Location);
            return;
          }
        }

        history.push(
          generatePath(routes.buy.complete.path, {
            ...params,
            paymentStatus: getCheckoutPaymentStatus(data.fiatPayment.Status),
          })
        );
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      history,
      mutateFiatPaymentMethodProviderSave,
      mutateFiatPaymentMethodWalletCardSave,
      submitFiatPaymentRequest,
      params,
      state.paymentMethod,
      updateState,
    ]
  );

  /**
   * Requests the appropriate token from Checkout and submits the fiat payment
   */
  const { isLoading: isSubmittingPayment, mutateAsync: onConfirm } = useMutation(
    ['checkout/PreparePayment'],
    async () => {
      if (!state.paymentMethod) {
        return;
      }

      // payment using a saved card
      if (state.selectedPaymentCard) {
        await submitFiatPayment(
          {
            id: state.selectedPaymentCard.id,
            source: FiatPaymentTokenizationSource.Checkout,
          },
          state.selectedPaymentCard.type === 'saved'
            ? FiatPaymentType.FiatPaymentMethodID
            : FiatPaymentType.CheckoutToken
        );
        return;
      }

      // alternative payment methods
      try {
        const tokenData = await getApmTokenData();
        if (!tokenData) {
          return;
        }

        const tokenResponse = await requestCheckoutToken(tokenData);
        if (!tokenResponse) {
          return;
        }

        await submitFiatPayment(
          {
            id: tokenResponse.token,
            source: getTokenSource(state.paymentMethod),
          },
          FiatPaymentType.FiatPaymentMethodID,
          tokenResponse
        );
      } catch (error) {
        // useWalletError handles error
      }
    }
  );

  const { feeCryptoAmount, feeFiatAmount, netCryptoAmount, netFiatAmount, price } =
    useAmountsWithFee({
      cryptoCurrency,
      feePromotion: feePromotion
        ? fiatAmountForCurrency(feePromotion.Amounts, fiatPaymentCurrency.code).Amount
        : undefined,
      fees,
      fiatAmount: state.fiatAmount,
      fiatCurrency: fiatPaymentCurrency,
      priceProvider: 'buy',
    });

  const { fee, net, total } = useCurrencyAmountsWithFee({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    feeCryptoAmount,
    feeFiatAmount,
    fiatAmount: state.fiatAmount,
    fiatCurrency: fiatPaymentCurrency,
    netCryptoAmount,
    netFiatAmount,
    primaryCurrency: CurrencyDisplayType.Fiat,
  });

  useEffect(() => {
    if (!redirect3ds) {
      return;
    }
    // persist store directly to try to avoid inconsistent beforeunload event handling,
    // e.g. when triggered after checkout redirect in Safari
    setStore();

    // push the redirect on to the next 'tick' to allow setStore to correctly set the sessionStorage
    // this 'error' seems to only manifest in e2e tests but is possible it could happen in other browsers etc.
    setTimeout(() => {
      window.location.assign(redirect3ds);
    }, 10);
  }, [redirect3ds, setStore]);

  const { ApiErrorScene } = useWalletError(
    fiatPaymentError ||
      checkoutPaymentTokenError ||
      googlePaymentError ||
      applePaymentError ||
      fiatPaymentMethodProviderSaveError ||
      fiatPaymentMethodWalletCardSaveError
  );
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmBuyScene
      backTo={generatePath(routes.buy.enterAmount.path, params)}
      cryptoCurrency={cryptoCurrency}
      CryptoPriceSlot={<FiatAmount amount={price} currency={fiatPaymentCurrency} />}
      ctaButtonLabel="Pay now"
      FeeAmountSlot={fee.PrimaryAmountSlot}
      feePromotion={feePromotion}
      isLoading={isSubmittingPayment}
      kycRequired={kycRequired}
      kycRoute={kycRoute}
      NetCryptoAmountSlot={net.SecondaryAmountSlot}
      pageTitle={routes.buy.confirm.title}
      paymentMethod={state.paymentMethod}
      selectedPaymentCard={state.selectedPaymentCard}
      TotalFiatAmountSlot={total.PrimaryAmountSlot}
      onConfirm={onConfirm}
      onPaymentMethodChange={onPaymentMethodChange}
      onSubmitCardDetailsRedirect={generatePath(routes.buy.confirm.path, params)}
    />
  );
}
