import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';
import { RewardsIcon, WalletIcon } from '@noah-labs/core-web-ui/src/icons';
import type { TpIcon } from '@noah-labs/core-web-ui/src/types';
import { Feature } from '@noah-labs/noah-schema';
import { useLocation } from 'react-router-dom';
import { routes as rewardsRoutes } from '../../modules/rewards/routes';
import { RestrictedFeature } from '../../modules/user/components/RestrictedFeature';
import { routes as walletRoutes } from '../../modules/wallet/routes';

type PpComponent = {
  Icon: TpIcon;
  label: ReactNode;
  selected?: boolean;
  to: string;
  value: string;
};

type PpNavItems = {
  Component: React.ComponentType<PpComponent>;
};
export function NavItems({ Component }: PpNavItems): ReactElement {
  const location = useLocation();
  return (
    <Fragment>
      <Component
        Icon={WalletIcon}
        label={walletRoutes.base.title}
        selected={location.pathname.startsWith(walletRoutes.base.path)}
        to={walletRoutes.base.path}
        value={walletRoutes.base.path}
      />
      <RestrictedFeature needs={[Feature.UIRewards]}>
        <Component
          Icon={RewardsIcon}
          label={rewardsRoutes.base.title}
          selected={location.pathname.startsWith(rewardsRoutes.base.path)}
          to={rewardsRoutes.base.path}
          value={rewardsRoutes.base.path}
        />
      </RestrictedFeature>
    </Fragment>
  );
}
