import { useEffect } from 'react';
import { processId } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { CircularSpinner } from '@noah-labs/core-web-ui/src/utility/CircularSpinner';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory } from 'react-router-dom';
import type { StKycRouter } from '../Router';
import { routes } from '../routes';

export function ProcessId({ state }: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();

  const { incodeApiBaseUrl, sardineToken } = state;

  useEffect(() => {
    async function process(): Promise<void> {
      try {
        if (!incodeApiBaseUrl || !sardineToken) {
          throw new Error('Kyc session not initialised');
        }

        const response = await processId({ baseURL: incodeApiBaseUrl, token: sardineToken });

        if (!response.data.success) {
          throw new Error('Processing failed');
        }

        history.push(routes.face.path);
      } catch (err) {
        logger.error(err);

        pushAlert({
          autoHideDuration: 3000,
          key: 'incodeProcessIdError',
          message: 'An error has occured when processing your ID',
          preventDuplicate: true,
          severity: 'error',
        });
      }
    }

    void process();
  }, [pushAlert, history, incodeApiBaseUrl, sardineToken]);

  return <LoadingPage Icon={<CircularSpinner />} message="Your ID is being processed..." />;
}
