import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useHistory } from 'react-router-dom';
import { routes as walletRoutes } from '../../wallet/routes';
import { nextBaseSteps } from '../data/steps';
import { useSubmitKycInput } from '../hooks/useSubmitKycInput';
import type { StKycRouter } from '../Router';
import type { RadioFormSchema } from '../scenes/Questionnaire/types';
import { WorkIndustryScene } from '../scenes/Questionnaire/WorkIndustry';
import { TpKycSteps } from '../types';

export function WorkIndustry({
  state,
  updateState,
}: TpStateMachine<StKycRouter>): React.ReactElement {
  const history = useHistory();
  const { handleSubmit, isKycInputQueryFetched, isMutationLoading } = useSubmitKycInput();

  const handleContinue = useCallback(
    (values: RadioFormSchema) => {
      updateState({
        userKycInput: {
          ...state.userKycInput,
          ...values,
        },
      });
      history.push(nextBaseSteps.WorkIndustry);
    },
    [state.userKycInput, updateState, history]
  );

  const handleSave = useCallback(
    async (values: RadioFormSchema) => {
      await handleSubmit({
        errorKey: 'updateWorkIndustryError',
        errorMessage: 'There was an error saving your work industry',
        to: walletRoutes.base.path,
        values: {
          ...state.userKycInput,
          ...values,
          LastStep: TpKycSteps.WorkIndustry,
        },
      });
    },
    [handleSubmit, state.userKycInput]
  );

  if (!isKycInputQueryFetched) {
    return <LoadingPage />;
  }

  return (
    <WorkIndustryScene
      isLoading={isMutationLoading}
      workIndustryState={state.userKycInput?.WorkIndustry}
      onSaveAndExit={handleSave}
      onSubmit={handleContinue}
    />
  );
}
