import { css } from '@emotion/react';
import { IconButton } from '@mui/material';
import type { IconButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon } from '../icons';
import { getInteractiveProps } from '../links/getInteractiveProps';
import { cssMixins } from '../theme/cssMixins';

export function ChevronDownButton({ onClick, ...rest }: IconButtonProps): React.ReactElement {
  const theme = useTheme();
  const styles = {
    dialogIcon: css`
      ${cssMixins.centerVAbsolute};
      right: ${theme.spacing(3)};
    `,
  };

  const interactiveProps = getInteractiveProps({ onClick });

  return (
    <IconButton
      {...interactiveProps}
      color="primary"
      css={styles.dialogIcon}
      onClick={onClick}
      {...rest}
    >
      <ChevronDownIcon fontSize="small" />
    </IconButton>
  );
}
