import { useEffect } from 'react';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import { RadioGroup } from '@noah-labs/core-web-ui/src/forms/RadioGroup';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { KycAppHeader } from '../../components/KycAppHeader';
import { PROGRESS } from '../../data/progress';
import { transactionFrequencyItems } from './data/radioForm';
import type { PpRadioForm } from './types';

const defaultValues = {
  TransactionFrequency: '',
};

type TpTransactionFrequencyFormSchema = typeof defaultValues;

export type PpTransactionFrequency = Omit<PpRadioForm, 'onSaveAndExit'> & {
  transactionFrequencyState?: string;
};

export function TransactionFrequencyScene({
  backTo,
  onSubmit,
  transactionFrequencyState,
}: PpTransactionFrequency): React.ReactElement {
  const methods = useForm<TpTransactionFrequencyFormSchema>({
    defaultValues,
    mode: 'all',
  });
  const buttonDisabled = !methods.formState.isDirty;

  useEffect(() => {
    if (!transactionFrequencyState) {
      return;
    }
    methods.reset({ TransactionFrequency: transactionFrequencyState }, { keepDefaultValues: true });
  }, [methods, transactionFrequencyState]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          disabled={buttonDisabled}
          form="transactionFrequencyFormId"
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Finish
        </PrimaryButton>
      }
      AppHeaderSlot={<KycAppHeader backTo={backTo} progress={PROGRESS.transaction} />}
      dataQa="transactionFrequency"
    >
      <Helmet>
        <title>Transaction frequency</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>What is your expected frequency of transactions?</SceneTitleMedium>
      </SceneHeader>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id="transactionFrequencyFormId" onSubmit={methods.handleSubmit(onSubmit)}>
            <RadioGroup label="Transaction frequency" name="TransactionFrequency">
              {transactionFrequencyItems.map((item) => (
                <RadioButton key={item.value} hideValue label={item.label} value={item.value} />
              ))}
            </RadioGroup>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
