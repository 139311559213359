import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { TransferDestinationType } from '@noah-labs/noah-schema';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import type { TpOnSign } from '../../../signing/controllers/Sign';
import { useSignWithdrawal } from '../../../signing/controllers/useSignWithdrawal';
import { useWalletParams, useWithdrawOrderCreateMutation } from '../../data';
import { useWalletError } from '../../hooks';
import { routes } from '../../routes';
import { ConfirmScene } from '../../scenes';
import type { StWithdrawRouter } from './WithdrawRouter';

export function Confirm({
  state,
  updateState,
}: TpStateMachine<StWithdrawRouter>): React.ReactElement {
  const history = useHistory();
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const { error, isLoading, mutateAsync } = useWithdrawOrderCreateMutation();
  const { fiatCurrency } = useUserFiatCurrency();

  const onNext = useCallback(
    async ({ signature }: TpOnSign): Promise<void> => {
      try {
        const data = await mutateAsync({
          Input: {
            AccountType,
            Amount: state.cryptoAmount,
            CurrencyCode,
            Destination: {
              DestinationAddress: {
                Address: state.payeeData?.address || '',
              },
              DestinationType: TransferDestinationType.Address,
            },
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            Network: state.payeeData?.network,
            RequestedAmount: {
              Amount: state.fiatAmount,
              FetchedAt: state.fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: state.price,
            },
          },
        });
        const { EventId: withdrawOrderId } = data.withdrawOrderCreate.Entries[0];
        updateState({ withdrawOrderId });
        history.push(generatePath(routes.withdraw.complete.path, params));
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      AccountType,
      CurrencyCode,
      history,
      mutateAsync,
      params,
      state.cryptoAmount,
      state.fetchedAt,
      state.fiatAmount,
      state.payeeData?.address,
      state.payeeData?.network,
      state.price,
      fiatCurrency.code,
      updateState,
    ]
  );

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const { loading: signLoading, sign } = useSignWithdrawal({
    network: state.payeeData?.network,
    payload: {
      AccountType,
      Amount: state.cryptoAmount,
      CurrencyCode,
      Destination: state.payeeData?.address || '',
      inputType: 'withdraw',
    },
  });

  const onConfirm = useCallback(async () => {
    await sign(onNext);
  }, [sign, onNext]);

  const { ApiErrorScene } = useWalletError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmScene
      backButton
      addressData={state.payeeData}
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatCurrency}
      isCtaDisabled={signLoading}
      isLoading={isLoading}
      pageTitle={routes.withdraw.confirm.title}
      onConfirm={onConfirm}
    />
  );
}
