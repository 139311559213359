import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { cryptoCurrencyFromCode } from '../../../../utils';
import { useLnInvoiceCreateMutation, useWalletParams } from '../../data';
import { useWalletError } from '../../hooks';
import { useCryptoUnit } from '../../hooks/useCryptoUnit';
import { usePrimaryCurrency } from '../../hooks/usePrimaryCurrency';
import { useWithdrawLimits } from '../../hooks/useWithdrawLimits';
import { routes } from '../../routes';
import type { TpAmountForm } from '../../scenes';
import { EnterAmountScene } from '../../scenes';
import { getLnReceiveSchema } from '../../utils/validation';
import type { StReceiveRouter } from '../receive';

export function EnterAmount({ updateState }: TpStateMachine<StReceiveRouter>): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();

  const history = useHistory();
  const { error, mutateAsync } = useLnInvoiceCreateMutation();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { primaryCurrency } = usePrimaryCurrency();
  const cryptoUnit = useCryptoUnit();
  const { fiatCurrency } = useUserFiatCurrency();
  const limits = useWithdrawLimits();

  const schema = getLnReceiveSchema({
    fiatCurrency,
    maxLnSingleSendFiat: limits?.lnSendSingleLimit.limit,
  });

  const onSubmit = useCallback(
    async ({ cryptoAmount, description, fetchedAt, fiatAmount, price }: TpAmountForm) => {
      if (!price || !fetchedAt) {
        return;
      }
      try {
        const truncateFiatAmount = truncateAmount({
          amount: fiatAmount,
          decimalPlaces: fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        });

        const data = await mutateAsync({
          Input: {
            AccountType,
            Amount: truncateAmount({
              // user can leave amount blank for a zero amount invoice.
              // empty strings won't be parsed as '0' in JSON / Go, so need to set it explicitly here
              amount: cryptoAmount || '0',
              decimalPlaces: cryptoCurrency.decimals,
              roundingMode: BigNumber.ROUND_DOWN,
            }),
            CurrencyCode,
            Description: description,
            RequestedAmount: {
              // user can leave amount blank for a zero amount invoice.
              // empty strings won't be parsed as '0' in JSON / Go, so need to set it explicitly here
              Amount: truncateFiatAmount || '0',
              FetchedAt: fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: price,
            },
          },
        });
        const { PaymentRequest, PublicID } = data.lightningInvoiceCreate;
        updateState({
          fiatAmount: truncateFiatAmount,
          paymentRequest: PaymentRequest,
          publicID: PublicID,
        });
        history.push(generatePath(routes.receive.lightning.viewInvoice.path, params));
      } catch (e) {
        // error is handled in useWalletError
      }
    },
    [
      AccountType,
      CurrencyCode,
      history,
      mutateAsync,
      params,
      cryptoCurrency,
      fiatCurrency.code,
      fiatCurrency.decimals,
      updateState,
    ]
  );

  const { ApiErrorScene } = useWalletError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterAmountScene
      backButton
      cryptoAmount=""
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={cryptoUnit}
      description=""
      fiatAmount=""
      fiatCurrency={fiatCurrency}
      htmlHeadTitle={routes.receive.lightning.enterAmount.title}
      PageTitleSlot={<AppHeaderTitle>{routes.receive.lightning.enterAmount.title}</AppHeaderTitle>}
      priceProvider="market"
      primaryCurrency={primaryCurrency}
      yupSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
