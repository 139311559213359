import { forwardRef, useCallback } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useHistory } from 'react-router-dom';
import { TpAnalyticsEvent, useAnalytics } from '../../modules/analytics';

export type PpVerifyAccountDialog = {
  action: 'buy' | 'sell';
  initialOpen?: boolean;
  kycRoute: string;
};

export const VerifyAccountDialog = forwardRef<TpDialogToggle, PpVerifyAccountDialog>(
  ({ action, initialOpen, kycRoute }, ref) => {
    const history = useHistory();
    const analytics = useAnalytics();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const onVerifyClick = useCallback(() => {
      analytics.track(TpAnalyticsEvent.KycVerifyClicked);
      history.push(kycRoute);
    }, [analytics, history, kycRoute]);

    let title = '';
    let content = '';

    switch (action) {
      case 'buy':
        title = 'Verify your account before you buy bitcoin';
        content =
          'Financial regulations require us to verify your ID before buying cryptocurrency. This helps to ensure the safety and security of your NOAH account.';
        break;
      case 'sell':
        title = 'Verify your account before you sell bitcoin';
        content =
          'Financial regulations require us to verify your ID before selling cryptocurrency. This helps to ensure the safety and security of your NOAH account.';
        break;
      default:
        break;
    }

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              data-qa="verify-account-btn"
              onClick={onVerifyClick}
            >
              Verify account
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={toggle}>
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  }
);
VerifyAccountDialog.displayName = VerifyAccountDialog.name;
