import type { TpFeatureFlagUI } from '@noah-labs/core-web-ui/src/types';
import type { CurrencyCode, Network } from '@noah-labs/noah-schema';
import { cryptoCurrencyFromCode } from '../../../utils';

export function getCryptoNetworksToDisplay(
  CurrencyCode: CurrencyCode,
  feature: TpFeatureFlagUI | undefined,
  networksToDisplay: Network[]
): Array<Network> | undefined {
  if (!feature) {
    return [];
  }
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  return cryptoCurrency.networks?.filter(
    (network) => feature.Networks?.includes(network) && networksToDisplay.includes(network)
  );
}
