import { useMemo } from 'react';
import type { TpCountryFilterFunction } from '@noah-labs/core-services';
import { useCountriesForSelect } from '@noah-labs/core-services';
import { DialogSelectField } from '@noah-labs/core-web-ui/src/forms/DialogSelectField';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { useUserInit } from '../../modules/user/data/useUserInit';

type PpUserCountrySelectField = {
  dataQa?: string;
  filterFunction?: TpCountryFilterFunction;
  handleChange: (value: TpSelectOption | null) => void;
  label: string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
};

export function UserCountrySelectField({
  dataQa,
  filterFunction,
  handleChange,
  label,
  name,
  readOnly = false,
  required,
}: PpUserCountrySelectField): React.ReactElement {
  const { data: allCountriesOptions, isFetched: countriesFetched } =
    useCountriesForSelect(filterFunction);
  const { data: userData, isFetched: userDataFetched } = useUserInit();

  const allCountriesWithUserCountryOnTop = useMemo(() => {
    if (!allCountriesOptions) {
      return [];
    }

    const userLatestCountry = userData?.userProfile.LatestCountry;
    if (!userLatestCountry) {
      return allCountriesOptions;
    }

    const allCountriesCopy = [...allCountriesOptions];
    const countryIndex = allCountriesCopy.findIndex((country) =>
      compareStrings(country.value, userLatestCountry)
    );

    if (countryIndex !== -1) {
      const userCountry = allCountriesCopy[countryIndex];
      allCountriesCopy.splice(countryIndex, 1);
      allCountriesCopy.splice(0, 0, userCountry);
    }

    return allCountriesCopy;
  }, [allCountriesOptions, userData?.userProfile.LatestCountry]);

  return (
    <DialogSelectField
      dataQa={dataQa}
      disabled={readOnly}
      isFetched={countriesFetched && userDataFetched}
      label={label}
      name={name}
      options={allCountriesWithUserCountryOnTop}
      required={required}
      onChange={handleChange}
    />
  );
}
