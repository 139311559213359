import type { TpCryptoCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import type { TpSlippage } from '@noah-labs/shared-currencies/src/calculations';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { TpPriceProvider } from '../../../data';

export enum TpDualCurrencyAmountFieldNames {
  crypto = 'cryptoAmount',
  fetchedAt = 'fetchedAt',
  fiat = 'fiatAmount',
  price = 'price',
  primary = 'primaryAmount',
  secondary = 'secondaryAmount',
}

export type PpUsePrimaryBase = {
  cryptoCurrency: TpCryptoCurrencyUI;
  enabled: boolean;
  fiatCurrency: TpFiatCurrency;
  priceProvider: TpPriceProvider;
  primaryAmount: string;
  slippage?: TpSlippage;
};
