import { Box, Stack } from '@mui/material';
import { AddressWithCopy } from '@noah-labs/core-web-ui/src/address/AddressWithCopy';
import { AlertTransactionTime } from '@noah-labs/core-web-ui/src/alerts/AlertTransactionTime';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { useShare } from '@noah-labs/core-web-ui/src/hooks/useShare';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { QRCodeWithImage } from '@noah-labs/core-web-ui/src/qr/QRCodeWithImage';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/core-web-ui/src/types';
import { assetPaths } from '@noah-labs/fe-assets/src/lib/assetPaths';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { useBackHijack } from '../../../hooks';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { TransactionSummary } from '../components';
import { useCurrencyAmounts } from '../hooks/useCurrencyAmounts';

export type PpQRDisplayScene = {
  addressData?: Partial<TpAddressData>;
  cryptoAmount?: TpAmount;
  cryptoCurrency?: TpCryptoCurrencyUI;
  faqLink?: string;
  fiatAmount?: TpAmount;
  fiatCurrency?: TpFiatCurrencyUI;
  sceneTitle: string;
  transactionId?: string;
};

export function QRDisplayScene({
  addressData,
  cryptoAmount,
  cryptoCurrency,
  faqLink,
  fiatAmount,
  fiatCurrency,
  sceneTitle,
  transactionId,
}: PpQRDisplayScene): React.ReactElement {
  const Amounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
  });
  const { handleSharing, isSupported } = useShare({ text: addressData?.address });
  const { track } = useAnalytics();
  const { goBack } = useBackHijack();
  return (
    <AppContainer
      AppFooterSlot={
        isSupported ? (
          <PrimaryButton data-qa="share-button" onClick={handleSharing}>
            Share QR Code
          </PrimaryButton>
        ) : undefined
      }
      AppHeaderSlot={
        <AppHeaderData exitButton helpButton backButton={goBack}>
          <AppHeaderTitle>{sceneTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="qr-display"
    >
      <Helmet>
        <title>{sceneTitle}</title>
      </Helmet>
      <SceneMain dense>
        <Stack spacing={2}>
          <TransactionSummary
            dataQa={{ publicId: transactionId }}
            description={addressData?.description}
            expiryTime={addressData?.expiryTime}
            transactionId={transactionId}
          >
            {/* need to wrap in a div so we can apply -ve margin that is not removed by the stack */}
            <div>
              <AddressWithCopy
                address={addressData?.address}
                variant="text"
                onCopy={(): void => track(TpAnalyticsEvent.LNInvoiceUrlCopied)}
              />
            </div>
            <Box maxWidth="300px">
              <QRCodeWithImage
                data={addressData?.address}
                height={300}
                imageUrl={`${assetPaths.images}/logo-icon-black.svg`}
                width={300}
              />
            </Box>
            {cryptoAmount && <Balance {...Amounts} />}
          </TransactionSummary>
          {addressData?.processingTime && (
            <AlertTransactionTime delay={addressData.processingTime} faqLink={faqLink} />
          )}
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
